import { useMemo } from 'react';
import { TCurrency, useCurrencyOptionsMap } from '@payler/bank-utils';
import { useAccountsQuery } from './accounts/queries';

/**
 * Возвращает options для списка валют из открытых счетов
 */
export const useAccountsCurrenсyOptions = (): Array<{
  label: string;
  value: TCurrency;
}> => {
  const { data } = useAccountsQuery();
  const allCurrecyOptionMap = useCurrencyOptionsMap();
  return useMemo(() => {
    const uniqCurrenciesFromAccounts = [
      ...new Set<TCurrency>((data ?? []).map((account) => account.currency)),
    ];
    return uniqCurrenciesFromAccounts.map((currency) => ({
      label: `${allCurrecyOptionMap[currency].name} - ${allCurrecyOptionMap[currency].currencyCode}`,
      value: allCurrecyOptionMap[currency].id,
    }));
  }, [data, allCurrecyOptionMap]);
};
