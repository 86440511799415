import React, { ReactNode, useCallback, useMemo } from 'react';
import { ReactComponent } from '*.svg';
import {
  Button,
  Flex,
  Icon,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
  UseToastOptions,
  VStack,
  ToastProps,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CrossIcon } from '@payler/ui-icons';
import { css } from '@emotion/react';
import {
  ErrorSmallIcon,
  InfoSmallIcon,
  SuccessSmallIcon,
  WarningSmallIcon,
} from '../icons';

type TToastStatusType = 'success' | 'error' | 'info' | 'warning';

interface IBaseAlert {
  details: ToastProps;
  status: TToastStatusType;
  description?: ReactNode;
  title?: ReactNode;
  isIcon?: boolean;
  icon?: typeof ReactComponent;
  closeButtonColor?: string;
}

const closeButtonStyles = css`
  position: absolute;
  border: 0;
  border-radius: 12px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  overflow: hidden;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  padding: 0;
`;

const toastOptions: UseToastOptions = {
  position: 'top-right',
  isClosable: true,
  duration: 5000,
};

const BaseToast: React.FC<IBaseAlert> = ({
  details,
  description,
  title,
  status,
  isIcon = true,
  icon,
  closeButtonColor,
}) => {
  const { colorMode } = useColorMode();
  const { onClose } = details;
  const closeColorValue = useColorModeValue(
    status === 'info' ? 'white.300' : 'black.300',
    'black.300',
  );
  return (
    <Flex
      position="relative"
      pl="18px"
      pr="40px"
      pt="14px"
      pb="14px"
      boxShadow="accountInfo"
      borderRadius={1}
      backgroundColor={getToastColor(status, colorMode)}
    >
      {(icon || isIcon) && (
        <Icon
          as={icon ? icon : getToastIcon(status)}
          w={3}
          h={3}
          mr={1.5}
          mt={title ? 0.25 : 0}
          color="white.500"
        />
      )}
      <VStack alignItems="stretch" spacing={0}>
        {title && (
          <Text as="h4" textStyle={TextStyles.h4} color="white.500">
            {title}
          </Text>
        )}
        {description && (
          <Text textStyle={TextStyles.Subtitle14Regular} color="white.500">
            {description}
          </Text>
        )}
      </VStack>
      <Button variant="secondary" onClick={onClose} css={closeButtonStyles}>
        <Icon
          as={CrossIcon}
          w={3}
          h={3}
          color={closeButtonColor || closeColorValue}
        />
      </Button>
    </Flex>
  );
};

const getToastIcon = (status: TToastStatusType): typeof ReactComponent => {
  switch (status) {
    case 'error':
      return ErrorSmallIcon;
    case 'warning':
      return WarningSmallIcon;
    case 'success':
      return SuccessSmallIcon;
    case 'info':
      return InfoSmallIcon;
  }
};

const getToastColor = (
  status: TToastStatusType,
  colorMode: 'dark' | 'light',
): string => {
  const isColorModeLight = colorMode === 'light';
  switch (status) {
    case 'error':
      return isColorModeLight ? 'red.500' : 'red.400';
    case 'warning':
      return isColorModeLight ? 'yellow.500' : 'yellow.400';
    case 'success':
      return isColorModeLight ? 'green.500' : 'green.400';
    case 'info':
      return isColorModeLight ? 'black.500' : 'primary.400';
  }
};

type ToastCallbackFn = (
  description?: ReactNode,
  title?: ReactNode,
  isIcon?: boolean,
) => void;

type CustomToastCallbackFn = (props: {
  description?: ReactNode;
  title?: ReactNode;
  icon?: typeof ReactComponent;
  closeButtonColor?: string;
}) => void;

const useSuccessToast = () => {
  const toast = useToast({
    ...toastOptions,
    status: 'success',
  });

  return useCallback<ToastCallbackFn>(
    (description, title, isIcon) => {
      toast({
        render: (props) => (
          <BaseToast
            details={props}
            status="success"
            title={title}
            description={description}
            isIcon={isIcon}
          />
        ),
      });
    },
    [toast],
  );
};

const useErrorToast = () => {
  const toast = useToast({
    ...toastOptions,
    status: 'error',
  });

  return useCallback<ToastCallbackFn>(
    (description, title, isIcon) => {
      toast({
        render: (props) => (
          <BaseToast
            details={props}
            status="error"
            title={title}
            description={description}
            isIcon={isIcon}
          />
        ),
      });
    },
    [toast],
  );
};

const useWarningToast = () => {
  const toast = useToast({
    ...toastOptions,
    status: 'warning',
  });

  return useCallback<ToastCallbackFn>(
    (description, title, isIcon) => {
      toast({
        render: (props) => (
          <BaseToast
            details={props}
            status="warning"
            title={title}
            description={description}
            isIcon={isIcon}
          />
        ),
      });
    },
    [toast],
  );
};

const useInfoToast = () => {
  const toast = useToast({
    ...toastOptions,
    status: 'info',
  });

  return useCallback<ToastCallbackFn>(
    (description, title, isIcon) => {
      toast({
        render: (props) => (
          <BaseToast
            details={props}
            status="info"
            title={title}
            description={description}
            isIcon={isIcon}
          />
        ),
      });
    },
    [toast],
  );
};

export const useCustomizableToast = (
  toastOptions: UseToastOptions,
  status: TToastStatusType,
) => {
  const toast = useToast({
    ...toastOptions,
    status: status,
  });
  return useCallback<CustomToastCallbackFn>(
    ({ description, title, icon, closeButtonColor }) => {
      toast({
        render: (props) => (
          <BaseToast
            details={props}
            status={status}
            title={title}
            description={description}
            isIcon={false}
            icon={icon}
            closeButtonColor={closeButtonColor}
          />
        ),
      });
    },
    [toast, status],
  );
};
export const useToasts = () => {
  const success = useSuccessToast();
  const error = useErrorToast();
  const warning = useWarningToast();
  const info = useInfoToast();

  return useMemo(
    () => ({ success, error, warning, info }),
    [success, error, warning, info],
  );
};
