import React, { MutableRefObject } from 'react';

export const useIntersectionObserver = (
  ref: MutableRefObject<HTMLDivElement | null>,
  options: IntersectionObserverInit = {}
) => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(!!entry?.isIntersecting);
    }, options);

    const el = ref.current;
    if (el) {
      observer.observe(el);
      return () => {
        observer.unobserve(el);
      };
    } else {
      return;
    }
  }, [options, ref]);

  return isIntersecting;
};
