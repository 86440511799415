import { emotionColors, TextStyles } from '@payler/ui-theme';

export const tableStyleConfig = {
  baseStyle: {
    table: {
      width: '100%',
    },
    tr: {
      height: 9,
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
      cursor: 'pointer',
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};
