import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FloatingInputField } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankLoader } from '../../components/BankLoader/BankLoader';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import ExchangeDropdownField from '../../components/ExchangeDropdown/ExchangeDropdownField';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { useAccountsQuery } from '../../hooks/accounts/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { ArrowDownIcon, ArrowIcon } from '../../icons';
import {
  TExchangeSelectForm,
  useExchangeWizardContext,
} from './ExchangeWizard';
import {
  getExchangeAccountsOptions,
  getExchangeAccountOptionByAccount,
  getExchangeFilteredReceiverAccountsByAccount,
} from './helpers';
import { useFormContext } from 'react-hook-form';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';

export const ExchangeSelect: FC = () => {
  const { t } = useTranslation(['common', 'accounts']);
  const { formatNumber } = useLanguageFeatures();
  const {
    sendingAmount,
    exchangeRate,
    setStep,
    receivingAmount,
    detailedExchangeRate,
    senderAccount,
    setSenderAccount,
    receiverAccount,
    setReceiverAccount,
    isExchangeRateFetching,
    exchangeRateError,
    onAmountChange,
  } = useExchangeWizardContext();
  const { isMobile } = useLayoutContext();
  const { data: accounts } = useAccountsQuery();

  const methods = useFormContext<TExchangeSelectForm>();
  const {
    formState: { errors },
    trigger,
    clearErrors,
  } = methods;

  const handleSubmit = () => {
    trigger([
      'sendingAmount',
      'receivingAmount',
      'receiverAccId',
      'senderAccId',
    ]).then((isValid) => isValid && setStep('confirmation'));
  };

  const receiverAccountOptions = useMemo(() => {
    const availableReceiverAccounts =
      getExchangeFilteredReceiverAccountsByAccount(senderAccount, accounts);
    return getExchangeAccountsOptions(availableReceiverAccounts, formatNumber);
  }, [senderAccount, receiverAccount, accounts, formatNumber]);

  const onAccountChange = useCallback(
    (selectedAccountId: string, isSenderSelector: boolean) => {
      if (errors) {
        clearErrors('sendingAmount');
      }
      const selectedAccount = accounts?.find(
        (account) => selectedAccountId === account.id,
      );
      const setAccount = isSenderSelector
        ? setSenderAccount
        : setReceiverAccount;
      setAccount(selectedAccount);
    },
    [errors, accounts, setSenderAccount, setReceiverAccount, clearErrors],
  );

  const onSendingAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onAmountChange({ amount: e.target.value, amountDirection: 'sending' });
    },
    [onAmountChange],
  );

  const onReceivingAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onAmountChange({ amount: e.target.value, amountDirection: 'receiving' });
    },
    [onAmountChange],
  );

  return (
    <>
      <ModalHeaderActions />
      <BankModalTitle
        title={t('accounts:exchange.selectStep.title')}
        description={t('accounts:exchange.selectStep.description')}
      />
      <BankModalBody>
        <VStack spacing={3}>
          {isMobile ? (
            <VStack w="full">
              <Box w="inherit">
                <ExchangeDropdownField
                  isDisabled
                  fieldName="senderAccId"
                  options={
                    senderAccount
                      ? [
                          getExchangeAccountOptionByAccount(
                            senderAccount,
                            formatNumber,
                          ),
                        ]
                      : []
                  }
                />
              </Box>
              <HStack w="inherit" justifyContent="space-between" px={1}>
                <Text>
                  {t('accounts:exchange.selectStep.exchangeDirection', {
                    senderCurrency: senderAccount?.currency.toUpperCase(),
                    receiverCurrency: receiverAccount?.currency.toUpperCase(),
                  })}
                </Text>
                <Box>
                  <ArrowDownIcon />
                </Box>
              </HStack>
              <Box w="inherit">
                <ExchangeDropdownField
                  fieldName="receiverAccId"
                  options={receiverAccountOptions || []}
                  onChange={(id) => onAccountChange(id as string, false)}
                />
              </Box>
            </VStack>
          ) : (
            <HStack spacing={1} width="full" justifyContent="space-between">
              <Box w="full">
                <ExchangeDropdownField
                  isDisabled
                  fieldName="senderAccId"
                  options={
                    senderAccount
                      ? [
                          getExchangeAccountOptionByAccount(
                            senderAccount,
                            formatNumber,
                          ),
                        ]
                      : []
                  }
                />
              </Box>
              <Box w="24px">
                <ArrowIcon />
              </Box>
              <Box w="full">
                <ExchangeDropdownField
                  fieldName="receiverAccId"
                  options={receiverAccountOptions || []}
                  onChange={(id) => onAccountChange(id as string, false)}
                />
              </Box>
            </HStack>
          )}
          <VStack spacing={2} w="full">
            <FloatingInputField
              value={sendingAmount}
              name="sendingAmount"
              variant="currency"
              currencySuffix={
                sendingAmount
                  ? senderAccount?.currency.toUpperCase()
                  : undefined
              }
              label={t('accounts:exchange.selectStep.youSpend')}
              onChange={(e) => onSendingAmountChange(e)}
              allowNegative={false}
            />
            <FloatingInputField
              value={receivingAmount}
              name="receivingAmount"
              variant="currency"
              currencySuffix={
                receivingAmount
                  ? receiverAccount?.currency.toUpperCase()
                  : undefined
              }
              label={t('accounts:exchange.selectStep.youGet')}
              onChange={(e) => onReceivingAmountChange(e)}
              allowNegative={false}
            />
          </VStack>
          {!!exchangeRateError ? (
            <ApiErrorText>
              {t('accounts:exchange.selectStep.exchangeRateError')}
            </ApiErrorText>
          ) : !isNil(exchangeRate) && !isExchangeRateFetching ? (
            <HStack w="full" justifyContent="center">
              <Box>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                  textAlign="center"
                >
                  {t('accounts:exchange.selectStep.exchangeRate')}
                </Text>
                <Text textStyle={TextStyles.tables}>
                  {detailedExchangeRate}
                </Text>
              </Box>
            </HStack>
          ) : (
            <Box h="55px">
              <BankLoader />
            </Box>
          )}
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={handleSubmit}
          isDisabled={!isEmpty(errors) || !exchangeRate}
        >
          {t('common:continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};
