import { FiltersWrapper } from './FiltersWrapper';
import { BankDrawer } from '../BankDrawer/BankDrawer';
import { BankDrawerContent } from '../BankDrawer/BankDrawerContent';

export const FiltersDrawer: FCC<{
  close: VoidFunction;
  isOpen: boolean;
  selectedFiltersCount: number;
  formId: string;
}> = ({ children, close, isOpen, ...props }) => {
  return (
    <BankDrawer isOpen={isOpen} onClose={close}>
      <BankDrawerContent drawerId="transfer-filters-drawer">
        <FiltersWrapper close={close} {...props}>
          {children}
        </FiltersWrapper>
      </BankDrawerContent>
    </BankDrawer>
  );
};
