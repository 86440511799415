export const shadows = {
  checkbox: `inset 0 0 0px 2px var(--payler-colors-primary-200)`,
  checkboxHover: `inset 0 0 0px 2px var(--payler-colors-primary-300)`,
  checkboxDisabled: `inset 0 0 0px 2px var(--payler-colors-primary-100)`,
  checkboxChecked: `inset 0 0 0px 2px var(--payler-colors-brands-500)`,
  checkboxFocus: `inset 0 0 0px 0.1px var(--payler-colors-brands-500)`,

  switch: `inset 0 0 0px 1px var(--payler-colors-primary-50)`,
  switchThumb:
    '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',

  pin: `inset 0 0 0px 1px var(--payler-colors-primary-100)`,
  pinHover: `inset 0 0 0px 1px var(--payler-colors-brands-500)`,
  pinFocus: `inset 0 0 0px 1px var(--payler-colors-brands-500)`,

  input: `inset 0 0 0 1px var(--payler-colors-primary-100)`,
  inputHover: `inset 0 0 0 1px var(--payler-colors-primary-200)`,
  inputFocus: `inset 0 0 0 1px var(--payler-colors-brands-500)`,
  inputError: `inset 0 0 0 1px var(--payler-colors-red-500)`,

  radio: `0 0 0px 1.5px var(--payler-colors-primary-200)`,
  radioHover: `0 0 0px 1.5px var(--payler-colors-primary-300)`,
  radioDisabled: `0 0 0px 1.5px var(--payler-colors-primary-100)`,
  radioChecked: `0 0 0px 1.5px var(--payler-colors-brands-500)`,
  radioDisabledChecked: `0 0 0px 1.5px var(--payler-colors-brands-300)`,

  paymentForm: `inset 0 0 0 1px var(--payler-colors-primary-100)`,
  outline: 'none',
  dropdown: `0px 4px 8px var(--payler-colors-primary-25), 0px 0px 2px var(--payler-colors-primary-50), 0px 0px 1px var(--payler-colors-primary-25);`,
  segmentedPickerContainer: `inset 0 0 0 1px var(--payler-colors-primary-200)`,
  pager: '0 0 0 1px var(--payler-colors-primary-100)',
  pagerList: 'inset 0 0 0 1px var(--payler-colors-primary-50)',
  pagerItem: 'inset 0 0 0 1px var(--payler-colors-primary-500)',
  popover: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
};
