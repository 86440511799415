import { ComponentStyleConfig } from '@chakra-ui/react';

export const DrawerStyles: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      bg: 'white.500',
      _dark: {
        bg: 'secondary.500',
      },
    },
  },
  variants: {
    nonOverlay: {
      dialogContainer: {
        pointerEvents: 'none',
      },
      dialog: {
        pointerEvents: 'auto',
      },
    },
  },
};
