import { TAccountDto, TBaasProviderDto } from '../types';

const mockBaasProvider: TBaasProviderDto = {
  id: '1',
  shortName: 'myEUPay',
  bic: '11111111',
  bankName: 'string',
  bankProviderCode: '001',
  countryCode: 'LI',
  products: ['incomingTransfer', 'outgoingTransfer', 'fx'],
};

export const mockAccounts: TAccountDto[] = [
  {
    id: '1',
    clientId: '1',
    number: '20151928505001',
    name: 'Euro',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-08-18T09:06:58.613693+00:00',
    currency: 'eur',
    amount: 15278.53,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '2',
    clientId: '1',
    number: '20151928505002',
    name: 'Pound Sterling',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-09-14T09:27:16.035125+00:00',
    currency: 'gbp',
    amount: 10117.11,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '3',
    clientId: '1',
    number: '20151928505003',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-12-11T09:05:01.966099+00:00',
    currency: 'pln',
    amount: 123117.53,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '4',
    clientId: '1',
    number: '20151928505004',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-12-05T11:07:30.863393+00:00',
    currency: 'cad',
    amount: 0,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '5',
    clientId: '1',
    number: '20151928505005',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-12-05T11:09:25.564971+00:00',
    currency: 'sek',
    amount: 0,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '6',
    clientId: '1',
    number: '20151928505006',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-09-14T09:27:02.535843+00:00',
    currency: 'eur',
    amount: 1434,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '7',
    clientId: '1',
    number: '20151928505007',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-12-05T13:13:23.065918+00:00',
    currency: 'chf',
    amount: 0,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '8',
    clientId: '1',
    number: '20151928505008',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-12-11T09:05:26.164712+00:00',
    currency: 'eur',
    amount: 0,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '9',
    clientId: '1',
    number: '20151928505009',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-08-18T09:01:16.212047+00:00',
    currency: 'gbp',
    amount: 1001467.89,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '10',
    clientId: '1',
    number: '20151928505010',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-08-18T09:06:58.613693+00:00',
    currency: 'eur',
    amount: 1032957,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
  {
    id: '11',
    clientId: '1',
    number: '20151928505011',
    name: 'string',
    description: 'string',
    type: 'virtual',
    normalBalance: 'credit',
    baasProvider: mockBaasProvider,
    state: 'active',
    openedDate: '2023-09-14T09:27:24.536438+00:00',
    currency: 'usd',
    amount: 14.84,
    allowOutgoingOperations: true,
    allowIncomingOperations: true,
    iban: 'LT333250092880563317',
    alerts: [],
  },
];
