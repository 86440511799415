import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Dropzone, TDropzoneProps } from './Dropzone';

export type TDropzoneFieldProps = {
  name: string;
} & Pick<
  TDropzoneProps,
  'maxFileSize' | 'title' | 'description' | 'help' | 'accept' | 'maxFiles'
>;

/**
 * Поле для загрузки файлов (для react-hook-form)
 * Для валидации см. createFilesFieldResolver
 *
 * @param props
 * @constructor
 */
export const DropzoneField: FC<TDropzoneFieldProps> = (props) => {
  const { name, maxFileSize, title, help, description, accept, maxFiles } =
    props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState }) => {
        return (
          <Dropzone
            files={field.value ?? []}
            onChange={field.onChange}
            maxFileSize={maxFileSize}
            title={title}
            help={help}
            maxFiles={maxFiles}
            description={description}
            accept={accept}
            hasErrors={!!formState.errors[name]}
            testIdSuffix={name}
          />
        );
      }}
    />
  );
};

export default DropzoneField;
