import {
  TParticipantAddressDto,
  TRecipientDto,
  TRecipientType,
} from '@payler/api/client-office';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export type TRecipientNameInfo = {
  recipientType: TRecipientType;
  legalName?: string;
  firstName?: string;
  lastName?: string;
};

/** Получить у реципиента его локализованный тип  */
export const useLocalizedRecipientType = (type: TRecipientType | undefined) => {
  const { t } = useTranslation(['recipients']);
  switch (type) {
    case 'corporate':
      return t('recipients:corporate');
    case 'individual':
      return t('recipients:individual');
    case 'internal':
      return t('recipients:internal');
    default:
      return '';
  }
};

const isRecipientDto = (
  recipient: TRecipientDto | TRecipientNameInfo,
): recipient is TRecipientDto => _.has(recipient, 'id');

/** Получить у реципиента его имя  */
export const getRecipientTitle = (
  recipient: TRecipientDto | TRecipientNameInfo | undefined | null,
) => {
  if (!recipient) return '';
  if (isRecipientDto(recipient)) {
    switch (recipient.recipientType) {
      case 'internal':
      case 'individual':
        return `${recipient?.firstName} ${recipient?.lastName}`;
      case 'corporate':
      default:
        return recipient?.legalName || '';
    }
  } else {
    return recipient.recipientType === 'corporate'
      ? recipient.legalName
      : `${recipient.firstName} ${recipient.lastName}`;
  }
};

export const beautifyIban = (iban: string) => iban?.replace(/(.{4})/g, '$1 ');
