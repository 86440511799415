import {
  Box,
  Button,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { OnboardingWrapper } from './OnboardingWrapper';
import { mockProhibitedCountries } from '@payler/api/client-office';
import { ScrollBarWithFade } from '@payler/ui-components';

export const RejectionPage: FC = () => {
  const { t } = useTranslation(['onboarding']);
  return (
    <OnboardingWrapper>
      <VStack
        spacing={2}
        alignItems="stretch"
        p={3}
        bg="secondary.500"
        borderRadius={1.5}
        textAlign="left"
        minH="650px"
        h="80vh"
      >
        <Text as="h1" textStyle={TextStyles.h1}>
          {t('onboarding:rejectionPage.title')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
          {t('onboarding:rejectionPage.text1')}
        </Text>
        <ScrollBarWithFade>
          <Box
            display="flex"
            padding={2}
            flexDirection="column"
            alignItems="stretch"
            gap={1}
            alignSelf="stretch"
            borderRadius={1.5}
            border="1px solid primary.25"
            background="primary.25"
          >
            <Text as="h4" textStyle={TextStyles.h4}>
              {t('onboarding:rejectionPage.listTitle')}
            </Text>
            <OrderedList
              style={{ marginInlineStart: '0em' }}
              stylePosition="inside"
              textStyle={TextStyles.Subtitle14Regular}
            >
              {mockProhibitedCountries.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </OrderedList>
          </Box>
        </ScrollBarWithFade>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
          {t('onboarding:rejectionPage.text2')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
          {t('onboarding:rejectionPage.text3')}
        </Text>
        <Button
          onClick={() => {
            window.location.href = 'https://payler.com';
          }}
          variant="primary"
        >
          {t('onboarding:rejectionPage.buttonText')}
        </Button>
      </VStack>
    </OnboardingWrapper>
  );
};
