import { ComponentStyleConfig } from '@chakra-ui/react';

export const MenuStyles: ComponentStyleConfig = {
  baseStyle: {
    list: {
      _dark: {
        bg: 'secondary.500',
      },
    },
  },
};
