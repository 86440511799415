import { Drawer, DrawerProps } from '@chakra-ui/react';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useLayoutContext } from '../../context/LayoutContextProvider';

export const BankDrawer: FC<PropsWithChildren<DrawerProps>> = ({
  children,
  ...rest
}) => {
  const { isDesktop } = useLayoutContext();

  const drawerProps: Partial<DrawerProps> = useMemo(() => {
    if (isDesktop) {
      return {
        variant: 'nonOverlay',
        placemen: 'right',
        closeOnOverlayClick: false,
        blockScrollOnMount: false,
        trapFocus: false,
      };
    }

    return {};
  }, [isDesktop]);

  return (
    <Drawer {...drawerProps} {...rest}>
      {children}
    </Drawer>
  );
};
