import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import createLogger from 'debug';
import queryString from 'query-string';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import {
  TAccountDto,
  TAccountStatusDto,
  TAddAccountMutationParams,
  TAccountDraftedDto,
  TEditAccountNameMutationParams,
  TSendOtpCommand,
  TConfirmAccountCommand,
  TChangeAccountsOrderMutationParams,
  TBaasProviderCurrenciesDto,
  TBankAccountsSettings,
} from './types/accounts';
import {
  TRecipientDto,
  TRecipientPagedDataDto,
  TCreateRecipientCommand,
  TRecipientsListQueryPagedParams,
} from './types/recipients';
import {
  TClientDto,
  TCreateFxTransferCommand,
  TTransferFeeParams,
  TTransferTypeParams,
  TTransfersFeeDto,
  TOutgoingTransferDto,
  TIncomingTransferDto,
  TTransferSystemDto,
  TFxTransferDto,
  TToken,
  TRateRequestParams,
} from './types';
import {
  TTransferDtoPagedDataDto,
  TTransfersExportQueryParams,
  TTransfersQueryParams,
} from './types/transfers';
import {
  TOnboardingStageStepDto,
  TOnboardingStepDto,
  TPaymentResultDto,
  TStageTypeDto,
  TStepTypeDto,
} from './types/onboarding';
import { TCurrency, TCurrencyInfo } from '@payler/bank-utils';
import { TReportsStatementsQueryParams } from './types/reports';
import { IApiClientOffice } from './types/api';
import { TAuth } from '@payler/auth';
import { downloadFile, prepareBlob } from '@payler/utils';

dayjs.extend(Utc);
const log = createLogger('api:bank');

const paramsSerializer = (data: object): string => {
  return queryString.stringify(data, { arrayFormat: 'none' });
};

export class ApiClientOffice implements IApiClientOffice {
  private _axios: AxiosInstance;

  public get axios(): AxiosInstance {
    return this._axios;
  }

  constructor(
    private baseURL: string,
    private auth?: TAuth<TToken>,
  ) {
    log('ApiBank instance %O', { baseURL, token: auth?.accessToken });
    this._axios = axios.create({
      baseURL,
      paramsSerializer,
    });
    this.axios.interceptors.request.use(
      async function (config) {
        if (auth?.accessToken) {
          let token = auth.accessToken;
          try {
            const refreshed = await auth.updateToken(30);
            if (refreshed) {
              token = refreshed.access_token;
              log('token refreshed');
            }
            if (config.headers) {
              config.headers['Authorization'] = `Bearer ${token}`;
            } else {
              config.headers = new AxiosHeaders({
                Authorization: `Bearer ${token}`,
              });
            }
          } catch (e) {
            auth.logout();
          }
        } else {
          const token = localStorage.getItem('STORYBOOK_TOKEN');
          if (process.env['NODE_ENV'] !== 'production' && config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
          } else {
            config.headers.set('Authorization', `Bearer ${token}`);
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );
  }

  /**
   * Получение списка всех счетов
   */
  async getAccounts(params?: {
    Status?: TAccountStatusDto[];
    Currency?: TCurrency;
  }): Promise<TAccountDto[]> {
    const resp = await this.axios.get<TAccountDto[]>(
      '/api/generalledger/Accounts',
      {
        params,
      },
    );
    log('getAccounts success');
    return resp.data;
  }

  async getAccount(accountId: string): Promise<TAccountDto> {
    const resp = await this.axios.get<TAccountDto>(
      `/api/generalledger/Accounts/${accountId}`,
    );
    log('getAccount success');
    return resp.data;
  }

  /**
   * Изменить порядок аккаунтов
   */
  async changeAccountsOrder(
    params: TChangeAccountsOrderMutationParams,
  ): Promise<void> {
    const resp = await this.axios.put(
      '/api/generalledger/Accounts/reorder-accounts',
      {
        ...params,
      },
    );
    log('changeAccountsOrder success, %o', params);
    return resp.data;
  }

  /**
   * Добавить новый счет
   */
  async addAccount(
    params: TAddAccountMutationParams,
  ): Promise<TAccountDraftedDto> {
    const resp = await this.axios.post('/api/generalledger/Accounts', {
      ...params,
    });
    log('addAccount success, %o', params);
    return resp.data;
  }

  /**
   * Отправить OTP код при добавлении нового счета
   */
  async addAccountSendOTP(params: TSendOtpCommand): Promise<void> {
    const resp = await this.axios.post(
      '/api/generalledger/Accounts/send-otp',
      params,
    );
    log('addAccountSendOTP success, %o', params);
    return resp.data;
  }

  /**
   * Проверить OTP код при добавлении нового счета
   */
  async addAccountOTPVerification(
    params: TConfirmAccountCommand,
  ): Promise<TAccountDto> {
    const resp = await this.axios.post(
      '/api/generalledger/Accounts/otp-verification',
      params,
    );
    log('addAccountOTPVerification success, %o', params);
    return resp.data;
  }

  /**
   * Изменить название счета
   */
  async editAccountName({
    accountId,
    name,
  }: TEditAccountNameMutationParams): Promise<boolean> {
    await this.axios.patch(`/api/generalledger/Accounts/${accountId}`, {
      name,
    });
    log('addAccount success, %o', { accountId, name });
    return true;
  }

  /**
   * Выписка по счету
   */
  async getReportsStatements(params: TReportsStatementsQueryParams) {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/Reports/account-statement',
      params,
    );
    downloadFile(blob, fileName);
    log('getReportsStatements success');
  }

  /**
   * Получение данных текущего юзера
   */
  public async getClient(): Promise<TClientDto> {
    const response = await this.axios.get('/api/clientprofile/Client');
    return response.data;
  }

  /**
   * Получение списка всех реципиентов
   */
  async getRecipients(
    params?: TRecipientsListQueryPagedParams,
  ): Promise<TRecipientPagedDataDto> {
    const resp = await this.axios.get('/api/transferorchestrator/Recipients', {
      params,
    });
    log('getRecipients success');
    return resp.data;
  }

  /**
   * Получение реципиента по id
   */
  async getRecipientById(id: string): Promise<TRecipientDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/Recipients/${id}`,
    );
    log('getRecipientById success');
    return resp.data;
  }

  /**
   * Добавление нового реципиента
   */
  async addRecipient(data: TCreateRecipientCommand): Promise<string> {
    const resp = await this.axios.post(
      '/api/transferorchestrator/Recipients',
      data,
    );
    log('addRecipient success');
    return resp.data;
  }

  /**
   * Отображать реципиента в списке (сохранение реципиента)
   */
  async saveRecipient(recipientId: string) {
    await this.axios.put(
      `/api/transferorchestrator/Recipients/${recipientId}/visibility`,
      {
        isVisible: true,
      },
    );
    log('saveRecipient success');
  }

  /**
   * Создание outgoing перевода
   */
  async createOutgoingTransfer(params: FormData): Promise<string> {
    const resp = await this.axios.post(
      '/api/transferorchestrator/Transfers/outgoing',
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    log('createOutgoingTransfer success');
    return resp.data;
  }

  /**
   * Получение outgoing трансфера по id
   */
  async getOutgoingTransferById(
    transferId: string,
  ): Promise<TOutgoingTransferDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/Transfers/outgoing/${transferId}`,
    );
    log('getOutgoingTransferById success');
    return resp.data;
  }

  /**
   * Получение incoming трансфера по id
   */
  async getIncomingTransferById(
    transferId: string,
  ): Promise<TIncomingTransferDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/Transfers/incoming/${transferId}`,
    );
    log('getIncomingTransferById success');
    return resp.data;
  }

  /**
   * Получение fx трансфера по id
   */
  async getFxTransferById(transferId: string): Promise<TFxTransferDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/Transfers/fx/${transferId}`,
    );
    log('getFxTransferById success');
    return resp.data;
  }

  /**
   * Подтверждение outgoing трансфера
   */
  async confirmOutgoingTransfer(transferId: string, confirmationCode: string) {
    const resp = await this.axios.put(
      `/api/transferorchestrator/Transfers/outgoing/${transferId}/confirmation/${confirmationCode}`,
    );
    log('confirmOutgoingTransfer success');
    return resp.data;
  }

  /**
   * Отмена outgoing трансфера
   */
  async cancelOutgoingTransfer(transferId: string) {
    const resp = await this.axios.put(
      `/api/transferorchestrator/Transfers/outgoing/${transferId}/cancellation`,
    );
    log('cancelOutgoingTransfer success');
    return resp.data;
  }
  /**
   * Получение списка трансферов
   * @param params
   */
  async getTransfers(
    params: TTransfersQueryParams,
  ): Promise<TTransferDtoPagedDataDto> {
    const resp = await this.axios.get('/api/transferorchestrator/Transfers', {
      params,
    });
    log('getTransfers success');
    return resp.data;
  }

  /** Получение ссылки экспорта */
  async getIncomingTransfersExport(
    params: TTransfersExportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/transferorchestrator/Transfers/incoming/report',
      params,
    );
    downloadFile(blob, fileName);
    log('getIncomingTransfersExport success');
  }

  /** Получение ссылки экспорта */
  async getOutgoingTransfersExport(
    params: TTransfersExportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/transferorchestrator/Transfers/outgoing/report',
      params,
    );
    downloadFile(blob, fileName);
    log('getOutgoingTransfersExport success');
  }

  /**
   * Старт онбординга
   */
  async startOnboarding(): Promise<void> {
    const resp = await this.axios.post('/api/clientprofile/Onboarding');
    log('startOnboarding success');
    return resp.data;
  }

  /**
   * Запрос шага онбординга
   */
  async getOnboardingStep(step?: TStepTypeDto): Promise<TOnboardingStepDto> {
    const resp = await this.axios.get(
      `/api/clientprofile/Onboarding/stages/companyApplication/steps/${step}`,
    );
    log('getOnboardingStep success, %o', step);
    return resp.data;
  }

  /**
   * Запрос информации о платеже для оплаты онбординга
   */
  async getOnboardingPayment(): Promise<TPaymentResultDto> {
    const resp = await this.axios.get('/api/clientprofile/Payment');
    log('getOnboardingPayment success');
    return resp.data;
  }

  /**
   * Запрос словаря валют
   */
  async getCurrenciesInfo(): Promise<TCurrencyInfo[]> {
    const resp = await this.axios.get('/api/clientprofile/Currencies');
    log('getCurrenciesInfo success');
    return resp.data;
  }

  async getOnboardingStages() {
    const resp = await this.axios.get(`/api/clientprofile/Onboarding/stages`);
    log('getOnboardingStages success');
    return resp.data;
  }

  async getOnboardingStageStep(
    stage?: TStageTypeDto,
  ): Promise<TOnboardingStageStepDto> {
    const resp = await this.axios.get(
      `/api/clientprofile/Onboarding/stages/${stage}`,
    );
    log('getOnboardingStageStep success');
    return resp.data;
  }

  /**
   * Запрос токена для zoho forms
   */
  async getToken(): Promise<string> {
    const resp = await this.axios.get('/api/clientprofile/Tokens');
    log('getToken success');
    return resp.data.token;
  }

  /**
   * Генерация нового кода подтверждения
   */

  async generateConfirmationCode(
    transferId: string | undefined,
  ): Promise<void> {
    await this.axios.post(
      `api/transferorchestrator/Confirmations/${transferId}`,
    );
    log('generateConfirmationCode success');
  }

  /**
   * Получение курса обмена
   */
  async getFxRate(params: TRateRequestParams): Promise<number> {
    const resp = await this.axios.get(
      'api/exchange/api/v1/Transactions/fx/calculation/rate',
      {
        params,
      },
    );
    log('getFxRate success');
    return resp.data;
  }

  /**
   * Создание перевода (обмена валюты)
   */
  async createExchangeTransfer(
    data: TCreateFxTransferCommand,
  ): Promise<string> {
    const resp = await this.axios.post(
      'api/transferorchestrator/Transfers/fx',
      data,
    );
    log('createExchangeTransfer success');
    return resp.data;
  }

  /**
   * Подтверждение exchange трансфера
   */
  async confirmExchangeTransfer(transferId: string, confirmationCode: string) {
    const resp = await this.axios.put(
      `/api/transferorchestrator/Transfers/fx/${transferId}/confirmation/${confirmationCode}`,
    );
    log('confirmExchangeTransfer success');
    return resp.data;
  }

  /**
   * Отмена exchange трансфера
   */
  async cancelExchangeTransfer(transferId: string) {
    const resp = await this.axios.put(
      `/api/transferorchestrator/Transfers/fx/${transferId}/cancellation`,
    );
    log('cancelExchangeTransfer success');
    return resp.data;
  }

  async getTransferType(
    params: TTransferTypeParams,
  ): Promise<TTransferSystemDto> {
    const resp = await this.axios.get(
      'api/transferorchestrator/Transfers/type',
      { params },
    );

    log('getTransferType success');
    return resp.data;
  }

  async getTransferFee(params: TTransferFeeParams): Promise<TTransfersFeeDto> {
    const resp = await this.axios.get('api/fee/Transfers/fee', { params });
    log('getTransferFee success');
    return resp.data;
  }

  /**
   * Выписка account confirmation
   */
  async getAccountConfirmation(accountId: string) {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/Reports/account-confirmation',
      { accountId },
    );
    downloadFile(blob, fileName);
    log('getAccountConfirmation success');
  }

  /**
   * Выписка по трансферу
   */
  async getTransferStatement(transferId: string) {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/Reports/transfer-statement',
      { transferId },
    );
    downloadFile(blob, fileName);
    log('getTransferStatement success');
  }

  async sendDeletionNotifications() {
    await this.axios.post('/api/clientprofile/Client/deletion-notifications');
    log('sendDeletionNotifications success');
  }

  /**
   * Метод для получения файла, прикрепленного при создании трансфера
   */
  async getTransferDocument(fileName: string) {
    const { blob } = await prepareBlob(
      this.axios,
      `/api/transferorchestrator/Documents/${fileName}`,
    );
    downloadFile(blob, fileName);
    log('getTransferDocument success');
  }

  /**
   * Получение поддерживаемого списка валют
   */
  async getBaasProviderCurrencies(): Promise<TBaasProviderCurrenciesDto> {
    const resp = await this.axios.get(
      `api/generalledger/BaasProvider/available-currencies`,
    );
    log('getBaasProviderCurrencies success');
    return resp.data;
  }

  /**
   * Получение настроек сервиса bankAccounts
   */
  async getBankAccountsSettings(): Promise<TBankAccountsSettings> {
    const resp = await this.axios.get(
      `api/generalledger/bankAccounts/Settings`,
    );
    log('getBankAccountsSettings success');
    return resp.data;
  }
}
