import Menu from './Menu';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { createRecoilKey } from '../../state/createKey';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Hamburger } from '@payler/ui-components';
import { css } from '@emotion/react';
import { DEFAULT_MOBILE_HEADER_HEIGHT } from '../../layouts/BaseLayout/const';
import { Box } from '@chakra-ui/react';
import { useClientOfficeConfig } from '../../config/ConfigProvider';

export const mobileMenuOpenedAtom = atom({
  key: createRecoilKey('mobileMenuOpenedAtom'),
  default: false,
  effects: [
    ({ onSet }) => {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      onSet((v) => {
        document.body.style.overflow = v ? 'hidden' : originalStyle;
      });
    },
  ],
});

const openStyle = css({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1000,
});

const getClosedStyle = (mobileHeaderHeight: number) =>
  css({
    position: 'relative',
    height: mobileHeaderHeight,
    overflow: 'hidden',
  });

export const BaseMobileMenu: FCC = ({ children }) => {
  const location = useLocation();
  const { mobileHeaderHeight = DEFAULT_MOBILE_HEADER_HEIGHT } =
    useClientOfficeConfig();
  const hideMenu = useRecoilCallback(
    ({ set }) =>
      () => {
        set(mobileMenuOpenedAtom, false);
      },
    [],
  );
  const toggle = useRecoilCallback(
    ({ set }) =>
      () =>
        set(mobileMenuOpenedAtom, (v) => !v),
    [],
  );
  const menuVisible = useRecoilValue(mobileMenuOpenedAtom);

  useEffect(() => {
    hideMenu();
  }, [hideMenu, location]);

  const style = menuVisible ? openStyle : getClosedStyle(mobileHeaderHeight);
  return (
    <Box as="nav" css={style} bg="bg">
      <Box
        borderRadius={1.5}
        px="13px"
        py="14px"
        bg="primary.25"
        borderColor="primary.50"
        borderWidth="1px"
        position="absolute"
        top={2}
        right={2}
        w={6}
        h={6}
      >
        <Hamburger
          sx={{ svg: { w: '20px', h: '20px' } }}
          onClick={toggle}
          isOpen={menuVisible}
        />
      </Box>
      {children}
    </Box>
  );
};

export const MobileMenu = () => (
  <BaseMobileMenu>
    <Menu />
  </BaseMobileMenu>
);
export default MobileMenu;
