import { Box } from '@chakra-ui/react';
import Menu from '../../components/Menu/Menu';
import { Outlet, useLocation } from 'react-router-dom';
import { DEFAULT_MOBILE_HEADER_HEIGHT, MENU_WIDTH } from './const';
import { breakpoints, emotionColors } from '@payler/ui-theme';
import styled from '@emotion/styled';
import MobileMenu from '../../components/Menu/MobileMenu';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { ErrorBoundary } from '@payler/ui-components';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { useClientOfficeConfig } from '../../config/ConfigProvider';

const Wrap = styled.main`
  display: grid;
  grid-template-areas: 'nav' 'content';

  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

export function BaseLayout() {
  const location = useLocation();
  const { mobileHeaderHeight = DEFAULT_MOBILE_HEADER_HEIGHT } =
    useClientOfficeConfig();
  return (
    <ErrorBoundary errorPage={<ErrorPage />}>
      <Wrap>
        <Content $mobileHeaderHeight={mobileHeaderHeight}>
          <ErrorBoundary key={location.pathname} errorPage={<ErrorPage />}>
            <Outlet />
          </ErrorBoundary>
        </Content>
        <Nav />
      </Wrap>
    </ErrorBoundary>
  );
}

const Nav = () => {
  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  return isDesktop ? <DesktopMenu /> : <MobileMenu />;
};

const Content = styled.div<{ $mobileHeaderHeight: number }>`
  grid-area: content;
  min-height: ${({ $mobileHeaderHeight }) =>
    `calc(100vh - ${$mobileHeaderHeight}px)`};
  padding: 16px;
  background-color: ${emotionColors.bg};
  @media (min-width: ${breakpoints.md}) {
    min-height: 100vh;
    padding: 40px 24px 24px ${MENU_WIDTH + 48}px;
  }
`;

const DesktopMenu = () => {
  return (
    <Box
      gridArea="nav"
      position="fixed"
      top={0}
      left={0}
      height="100vh"
      width={`${MENU_WIDTH + 48}px`}
      bg="bg"
    >
      <Menu />
    </Box>
  );
};

export default BaseLayout;
