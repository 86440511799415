import { emotionColors } from '../emotionColors';
import { TextStyles } from '../textStyles';

export const TableStyles = {
  baseStyle: {
    table: {
      minW: { base: '340px', sm: '600px'  },
      width: '100%',
    },
    tr: {
      height: 9,
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
      cursor: 'pointer',
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: 3,
      },
      '&:last-of-type': {
        pr: 3,
      },
    },
  },
};
