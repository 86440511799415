import { TCustomerPaymentSessionDto } from '../types/payment';

export const mockedCustomerPaymentSession: TCustomerPaymentSessionDto = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  created: '2024-07-16T21:20:30.111Z',
  expirationDateTime: '2024-07-16T21:20:30.111Z',
  state: 'accepted',
  merchantId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  currency: 'gbp',
  amount: 150,
  reference: 'Order № 25',
  externalId: 'string',
  callbackUrl: 'string',
  successUrl: 'string',
  rejectUrl: 'https://google.com',
  rejectionReason: 'string',
  additionalData: [
    {
      item1: 'string',
      item2: 'string',
    },
  ],
  purchaseAppUrl: 'string',
  customerClientId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  customerAccountId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  merchantName: 'string',
  transferId: 'string'
};

export const mockedCustomerAfterPaymentSession: TCustomerPaymentSessionDto = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  created: '2024-07-16T21:20:30.111Z',
  expirationDateTime: '2024-07-16T21:20:30.111Z',
  state: 'accepted',
  merchantId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  currency: 'gbp',
  amount: 150,
  reference: 'Order № 25',
  externalId: 'string',
  callbackUrl: 'string',
  successUrl: 'string',
  rejectUrl: 'string',
  rejectionReason: 'string',
  additionalData: [
    {
      item1: 'string',
      item2: 'string',
    },
  ],
  purchaseAppUrl: 'string',
  customerClientId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  customerAccountId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  merchantName: 'string',
  transferId: 'string'
};
