import React, { useEffect, useState } from 'react';
import { SegmentedPicker } from '../SegmentedPicker';
import { Box, Button, Flex, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const DevPanel: React.FC = () => {
  const [show, setShow] = useState(false);
  const { i18n } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const [lang, setLang] = useState('en');
  const [langIndex, setLangIndex] = useState(0);
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (process.env.NODE_ENV === 'production') {
        return;
      }
      if (e.code === 'KeyZ' && e.altKey) {
        setShow((v) => !v);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  if (process.env.NODE_ENV === 'production' || !show) return null;

  return (
    <Box position="fixed" left={0} right={0} top={0} zIndex={9999}>
      <Flex
        height={12}
        alignItems="center"
        justifyContent="center"
        mb={4}
        bg="secondary.500"
        boxShadow="0 0 14px 1px #000000ea"
      >
        <SegmentedPicker
          options={['en', 'ru']}
          selection={langIndex}
          onSelectionChange={() => {
            setLang(lang === 'en' ? 'ru' : 'en');
            setLangIndex(lang === 'en' ? 1 : 0);
            i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru');
          }}
        />
        <Button style={{ marginLeft: 24 }} onClick={toggleColorMode}>
          Переключить тему, текущая {colorMode}
        </Button>
      </Flex>
    </Box>
  );
};
