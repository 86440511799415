import { TOnboardingStagesDto } from '../types';

export const mockOnboardingStages: TOnboardingStagesDto = {
  status: 'started',
  result: null,
  stages: [
    {
      type: 'companyApplication',
      title: 'test',
      description: 'test',
      status: 'completed',
      orderNumber: 1,
      isRequired: true,
    },
    {
      type: 'kycVerification',
      title: 'test',
      description: 'test',
      status: 'completed',
      orderNumber: 2,
      isRequired: false,
    },
    {
      type: 'payment',
      title: 'test',
      description: 'test',
      status: 'inProgress',
      orderNumber: 3,
      isRequired: false,
    },
    {
      type: 'accountActivation',
      title: 'test',
      description: 'test',
      status: 'inProgress',
      orderNumber: 4,
      isRequired: false,
    },
  ],
};

// API получения списка запрещенных стран будет реализовано в задаче BANK-2986
export const mockProhibitedCountries = [
  'AFGHANISTAN',
  'ÅLAND ISLANDS',
  'ALBANIA',
  'BARBADOS',
  'BELARUS',
  'BONAIRE, SINT EUSTATIUS AND SABA',
  'BOTSWANA',
  'BOUVET ISLAND',
  'BRITISH INDIAN OCEAN TERRITORY',
  'BURKINA FASO',
  'CAMBODIA',
  'CENTRAL AFRICAN REPUBLIC',
  'CHRISTMAS ISLAND',
  'COCOS (KEELING) ISLANDS',
  'CONGO',
  "COTE D'IVOIRE",
  'CRIMEA',
  'CUBA',
  'DEMOCRATIC REPUBLIC OF THE CONGO',
  'ERITREA',
  'ESWATINI',
  'FALKLAND ISLANDS (MALVINAS)',
  'FAROE ISLANDS',
  'FRENCH GUIANA',
  'FRENCH POLYNESIA',
  'FRENCH SOUTHERN TERRITORIES',
  'GREENLAND',
  'GUADELOUPE',
  'GUINEA-BISSAU',
  'HAITI',
  'HEARD ISLAND AND MCDONALD ISLANDS',
  'IRAN',
  'IRAQ',
  'JAMAICA',
  'LEBANON',
  'LIBERIA',
  'LIBYA',
  'MARTINIQUE',
  'MAURITIUS',
  'MAYOTTE',
  'MINOR OUTLYING ISLANDS (UNITED STATES)',
  'MOROCCO',
  'MOZAMBIQUE',
  'MYANMAR',
  'NETHERLANDS ANTILLES',
  'NEW CALEDONIA',
  'NICARAGUA',
  'NORFOLK ISLAND',
  'NORTH KOREA',
  'NORTHERN MARIANA ISLANDS',
  'PAKISTAN',
  'PALESTINE',
  'PANAMA',
  'PHILIPPINES',
  'PITCAIRN',
  'RÉUNION',
  'RUSSIAN FEDERATION',
  'SAINT BARTHÉLEMY',
  'SAINT HELENA',
  'SAINT MARTIN',
  'SAINT PIERRE AND MIQUELON',
  'SENEGAL',
  'SINT MAARTEN (DUTCH PART)',
  'SOMALIA',
  'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
  'SOUTH SUDAN',
  'SUDAN',
  'SVALBARD AND JAN MAYEN',
  'SWAZILAND',
  'SYRIA',
  'TIMOR LESTE',
  'TOKELAU',
  'TRINIDAD AND TOBAGO',
  'UGANDA',
  'USA',
  'VANUATU',
  'VATICAN',
  'VENEZUELA',
  'WALLIS AND FUTUNA',
  'WESTERN SAHARA',
  'YEMEN',
  'ZIMBABWE',
];

//spush
export const mockAllowedCountries = [
	'Åland Islands',
	'Andorra',
	'Australia',
	'Austria',
	'Belgium',
	'Bosnia and Herzegovina',
	'Bulgaria',
	'Canada',
	'Croatia',
	'Cyprus',
	'Czech Republic',
	'Denmark',
	'Estonia',
	'Finland',
	'France',
	'Germany',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Guernsey',
	'Hungary',
	'Iceland',
	'Ireland',
	'Isle of Man',
	'Italy',
	'Jersey',
	'Latvia',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macedonia ',
	'Malta',
	'Monaco',
	'Montenegro',
	'Netherlands',
	'New Zealand',
	'Norway',
	'Poland',
	'Portugal',
	'Romania',
	'San Marino',
	'Serbia',
	'Slovakia',
	'Slovenia',
	'Spain',
	'Sweden',
	'Switzerland',
	'Saint Kitts and Nevis',
	'Saint Helena',
	'Pitcairn',
	'Faroe Islands',
	'United States of America',
	'Virgin Islands (British)',
	'Armenia',
	'United Kingdom'
];