import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { TextProps, VStack } from '@chakra-ui/react';
import { ThinDivider } from '@payler/ui-components';
import {
  ExchangeDropdownOption,
  TExchangeDropdownOption,
} from './ExchangeDropdownOption';
import { TExchangeDropdownProps } from './ExchangeDropdown';

type TExchangeDropdownOptionsProps = {
  options: TExchangeDropdownOption[];
  selected?: TExchangeDropdownProps['value'];
  onOptionClick: (val: string | number) => void;
  optionLabelProps?: TextProps;
};

type OptionsRefCollection = Record<number, HTMLDivElement | null>;

export const ExchangeDropdownOptions = ({
  options,
  selected,
  onOptionClick,
  optionLabelProps,
}: TExchangeDropdownOptionsProps) => {
  const optionsRefCollection = useRef<OptionsRefCollection>({});
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const setOptionRef =
    (index: number) =>
    (node: HTMLDivElement | null): void => {
      if (node && !isEqual(node, optionsRefCollection.current[index])) {
        optionsRefCollection.current[index] = node;
      }
    };

  useEffect(() => {
    const selectedOption = optionsRefCollection.current[selectedOptionIndex];
    if (selectedOption) {
      selectedOption.scrollIntoView({ block: 'nearest' });
    }
  }, [selectedOptionIndex]);

  return (
    <VStack
      alignItems="stretch"
      divider={<ThinDivider />}
      spacing={0}
      userSelect="none"
    >
      {options.map((x, i) => {
        return (
          <div ref={setOptionRef(i)} key={`${i}_${x.value}`}>
            <ExchangeDropdownOption
              option={x}
              onClick={(val) => {
                setSelectedOptionIndex(i);
                onOptionClick(val);
              }}
              isSelected={selected === x.value}
              optionLabelProps={optionLabelProps}
            />
          </div>
        );
      })}
    </VStack>
  );
};
