import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react';

export type TDropzoneContext = {
  files: File[];
  removeFile: (index: number) => void;
  maxFileSize: number;
};

export const DropzoneComponentContext = createContext<TDropzoneContext>({
  files: [],
  removeFile: (index) =>
    console.log('DropzoneComponentContext remove file', index),
  maxFileSize: Number.POSITIVE_INFINITY,
});

export const useDropzoneComponentContext = () =>
  useContext(DropzoneComponentContext);

type DropzoneComponentContextProps = {
  maxFileSize?: number;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
};
export const DropzoneComponentContextProvider: FCC<
  DropzoneComponentContextProps
> = ({ children, maxFileSize, files, setFiles }) => {
  const removeFile = useCallback(
    (index: number) => setFiles((state) => state.filter((x, i) => i !== index)),
    [setFiles]
  );

  const ctx = useMemo<TDropzoneContext>(
    () => ({
      files,
      removeFile,
      maxFileSize: maxFileSize ?? Number.POSITIVE_INFINITY,
    }),
    [files, removeFile, maxFileSize]
  );

  return (
    <DropzoneComponentContext.Provider value={ctx}>
      {children}
    </DropzoneComponentContext.Provider>
  );
};
