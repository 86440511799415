import React from 'react';
import { Flex, Icon, useClipboard } from '@chakra-ui/react';
import { CopyDoneIcon, CopyIcon } from '@payler/ui-icons';

export interface ClipboardTextProps {
  shortText?: string;
  text: string;
  variant?: 'secondary' | 'primary';
}

export const ClipboardText: React.FC<ClipboardTextProps> = (props) => {
  const { hasCopied, onCopy } = useClipboard(props.text);

  return (
    <Flex
      as="span"
      alignItems="center"
      cursor="pointer"
      color={{ color: props?.variant === 'primary' ? 'brands.500' : undefined }}
      onClick={onCopy}
    >
      {props.shortText ? props.shortText : props.text}
      <Icon
        w="20px"
        h="20px"
        as={hasCopied ? CopyDoneIcon : CopyIcon}
        fill="primary.350"
        color="primary.350"
        ml={1}
      />
    </Flex>
  );
};
ClipboardText.displayName = 'ClipboardText';
ClipboardText.defaultProps = {
  variant: 'secondary',
};
