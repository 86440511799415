import { chakra, keyframes } from '@chakra-ui/react';
import { emotionColors } from '@payler/ui-theme';

const fade = keyframes`
  from { background: ${emotionColors.primary25}; }
  to { background: ${emotionColors.primary100}; }
`;

export const Skeleton = chakra('div', {
  baseStyle: {
    borderRadius: 4,
    animation: `1s linear infinite alternate ${fade}`,
    w: 20,
    h: 2,
  },
});

export default Skeleton;
