import { TAccountDto } from '@payler/api/client-office';
import { OutlinedWarningSmallIcon } from '@payler/bank-utils';
import { MayBe } from '@payler/utils';
import { FunctionComponent, SVGProps, useMemo } from 'react';

type TAccountWarning = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconColor?: string;
  message: string;
};

const getWarningsByAccount = (account: TAccountDto): TAccountWarning[] => {
  return account.alerts.map((alert) => ({
    icon: OutlinedWarningSmallIcon,
    iconColor: 'red.500',
    message: alert,
  }));
};

export const useAccountWarnings = (
  account: MayBe<TAccountDto>,
): TAccountWarning[] => {
  if (!account) return [];

  return getWarningsByAccount(account);
};

export const useFirstAccountWarning = (account: MayBe<TAccountDto>) => {
  const warnings = useAccountWarnings(account);
  return warnings[0];
};

const useAccountListWarnings = (accounts: MayBe<TAccountDto[]>) => {
  return useMemo(() => {
    const allWarnings: TAccountWarning[] = [];
    accounts?.forEach((account) => {
      allWarnings.push(...getWarningsByAccount(account));
    });
    return allWarnings;
  }, [accounts]);
};

export const useFirstAccountListWarning = (accounts: MayBe<TAccountDto[]>) => {
  return useAccountListWarnings(accounts)[0];
};
