import { tableStyleConfig } from './tableStyleConfig';
import { Table, Tbody } from '@chakra-ui/react';
import React from 'react';
import { TableSkeleton as TableSkeletonBase } from '@payler/ui-components';

export const TableSkeleton: React.FC = () => (
  <Table styleConfig={tableStyleConfig} bg="secondary.500">
    <Tbody bg="secondary.500">
      <TableSkeletonBase />
    </Tbody>
  </Table>
);
