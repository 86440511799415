import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import React from 'react';
import { TextStyles } from '@payler/ui-theme';
import { RequiredStyledProvider } from '../../required-styled-provider';

export interface FormOptionProps extends FormControlProps {
  /** Подпись */
  label?: string;
  /** Текст ошибки */
  errorMessage?: string;
}

export const FormOption: React.FC<FormOptionProps> = ({
  isInvalid,
  errorMessage,
  children,
  label,
  ...rest
}) => {
  return (
    <FormControl isInvalid={isInvalid} {...rest} as="fieldset">
      <RequiredStyledProvider>
        {label && <FormLabel as="legend">{label}</FormLabel>}
        {children}
        {isInvalid && errorMessage && (
          <FormHelperText
            color="red.500"
            mt={1}
            textStyle={TextStyles.Caption12Regular}
            px={1.5}
          >
            {errorMessage}
          </FormHelperText>
        )}
      </RequiredStyledProvider>
    </FormControl>
  );
};
FormOption.displayName = 'FormOption';
