import {
  Box,
  Button,
  Center,
  Container,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useCallback,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { getCurrencyIcon } from '../../currency';
import {
  useAccountsQuery,
  useSetAccountIdToUrl,
} from '../../hooks/accounts/queries';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import {
  DNDIcon,
  ReorderIcon,
  useRecoilBoolean,
  useRecoilBooleanValue,
} from '@payler/bank-utils';
import { AllAccountsIcon, EllipsisIcon } from '../../icons';
import { useShowAddAccountModal } from '../../modals/AddAccount/AddAccount';
import { ActionsPopover } from '../ActionsPopover';
import { BankDrawer } from '../BankDrawer/BankDrawer';
import { BankDrawerContent } from '../BankDrawer/BankDrawerContent';
import { CloseButton } from '../CloseButton/CloseButton';
import { useCheckAppFlagEnabled } from '../../config/ConfigProvider';
import { TAccountDto } from '@payler/api/client-office';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  AccountsReorderContextProvider,
  useAccountsReorderContext,
} from '../../context/AccountsReorderContext';
import { useChangeAccountsOrderMutation } from '../../hooks/accounts/mutations';
import { useFirstAccountWarning } from '../../hooks/use-account-warning';
import { useBaasProviderCurrenciesQuery } from '../../hooks/currencies/queries';
import { LoaderBox } from '../LoaderBox/LoaderBox';

const KEY = 'drawers:AllAccountsDrawer';

export const useShowAllAccountsDrawer = () => useRecoilBoolean(KEY).on;
export const useCloseAllAccountsDrawer = () => useRecoilBoolean(KEY).off;
export const useIsOpenAllAccountsDrawer = () => useRecoilBooleanValue(KEY);

export const AllAccountsDrawer = () => {
  const isOpen = useIsOpenAllAccountsDrawer();
  const close = useCloseAllAccountsDrawer();
  const { data: accounts } = useAccountsQuery();

  if (!accounts) {
    return null;
  }
  return (
    <BankDrawer isOpen={isOpen} onClose={close}>
      <AccountsReorderContextProvider>
        <DrawerContent />
      </AccountsReorderContextProvider>
    </BankDrawer>
  );
};

const DrawerContent = () => {
  return (
    <BankDrawerContent drawerId="all-accounts-drawer">
      <Header />
      <ThinDivider />
      <DrawerBody p={0}>
        <AccountsList />
      </DrawerBody>
      <DrawerFooter p={0} display="block">
        <ThinDivider />
        <Suspense fallback={<LoaderBox />}>
          <FooterContent />
        </Suspense>
      </DrawerFooter>
    </BankDrawerContent>
  );
};

const Header = () => {
  const { t } = useTranslation(['accounts']);
  const close = useCloseAllAccountsDrawer();
  const { data: accounts, isLoading: isAccountsLoading } = useAccountsQuery();
  const { isPending: isChangeOrderLoading, mutate: changeOrderMutation } =
    useChangeAccountsOrderMutation();
  const { isReorderMode, setIsReorderMode, reorderedAccounts } =
    useAccountsReorderContext();

  const handleReorderClick = useCallback(() => {
    if (isReorderMode) {
      // отключаем режим, и применяем новый порядок
      const oldOrder = accounts?.map((acc) => acc.id);
      const newOrder = reorderedAccounts?.map((acc) => acc.id);
      if (newOrder && !isEqual(oldOrder, newOrder)) {
        changeOrderMutation({ accountIds: newOrder });
      }
    }
    setIsReorderMode(!isReorderMode);
  }, [
    accounts,
    isReorderMode,
    changeOrderMutation,
    reorderedAccounts,
    setIsReorderMode,
  ]);

  return (
    <DrawerHeader p={3}>
      <Flex justifyContent="space-between">
        <HStack spacing={2}>
          <Icon w="40px" h="40px" as={AllAccountsIcon} color="brands.500" />
          <Box>
            <Text textStyle={TextStyles.h4} color="primary.500">
              {t('accounts:allAccounts')}
            </Text>
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {t('accounts:accountsDrawer.accounts', {
                number: accounts?.length ?? 0,
              })}
            </Text>
          </Box>
        </HStack>
        <HStack spacing={2}>
          <IconButton
            isLoading={isChangeOrderLoading || isAccountsLoading}
            variant={isReorderMode ? 'primary' : 'secondary'}
            icon={<ReorderIcon />}
            aria-label={
              isReorderMode
                ? t('accounts:accountsDrawer.ariaLabels.applySorting')
                : t('accounts:accountsDrawer.ariaLabels.enableSortingMode')
            }
            w={6}
            h={6}
            onClick={handleReorderClick}
            data-testid={`button_account-reorder-${
              isReorderMode ? 'apply' : 'enable'
            }`}
          />
          <CloseButton onClick={close} />
        </HStack>
      </Flex>
    </DrawerHeader>
  );
};

const AccountsList = () => {
  const [hoveredElementId, setHoveredElementId] = useState<string>();
  const { reorderedAccounts, setReorderedAccounts } =
    useAccountsReorderContext();

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id && over && active.id !== over.id) {
      setReorderedAccounts((items) => {
        if (!items) return null;
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  if (!reorderedAccounts) return null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={reorderedAccounts}
        strategy={verticalListSortingStrategy}
      >
        <VStack m={0} spacing={0} divider={<ThinDivider />}>
          {reorderedAccounts.map((account) => {
            return (
              <AccountItem
                key={account.id}
                account={account}
                isHover={hoveredElementId === account.id}
                setHoveredElementId={setHoveredElementId}
              />
            );
          })}
        </VStack>
      </SortableContext>
    </DndContext>
  );
};

const FooterContent = () => {
  const isFeatureAddAccountsEnabled = useCheckAppFlagEnabled('accounts.add');
  const { t } = useTranslation(['accounts']);
  const showAddAccountModal = useShowAddAccountModal();
  const { data } = useBaasProviderCurrenciesQuery();

  if (!isFeatureAddAccountsEnabled) {
    return null;
  }

  if (!data || data.currencies.length < 1) {
    return null;
  }

  return (
    <Container p={3} pt={2}>
      <Button w="100%" onClick={showAddAccountModal}>
        <Text textStyle={TextStyles.Buttons16Medium}>
          {t('accounts:accountsDrawer.addAccount')}
        </Text>
      </Button>
    </Container>
  );
};

const AccountItem = ({
  account,
  isHover,
  setHoveredElementId,
}: {
  account: TAccountDto;
  isHover: boolean;
  setHoveredElementId: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { t } = useTranslation(['accounts']);
  const { isReorderMode } = useAccountsReorderContext();
  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  const { formatAmountByCurrency } = useLanguageFeatures();
  const setSelectedAccountId = useSetAccountIdToUrl();
  const close = useCloseAllAccountsDrawer();
  const accountWarning = useFirstAccountWarning(account);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: account.id, data: account, disabled: !isReorderMode });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isReorderMode
      ? ({
          opacity: isDragging ? 0.8 : 1,
          position: 'relative',
          zIndex: isDragging ? 1 : 0,
        } as const)
      : {}),
  };

  return (
    <Box
      w="100%"
      p={0}
      cursor={isReorderMode ? undefined : 'pointer'}
      style={style}
      ref={setNodeRef}
      bg="secondary.500"
    >
      <Flex
        justifyContent="space-between"
        w="100%"
        px={3}
        py={2}
        _hover={isReorderMode ? undefined : { bg: 'primary.25' }}
        onMouseEnter={
          isReorderMode ? undefined : () => setHoveredElementId(account.id)
        }
        onMouseLeave={
          isReorderMode ? undefined : () => setHoveredElementId(undefined)
        }
        onClick={
          isReorderMode
            ? undefined
            : () => {
                setSelectedAccountId(account.id);
                close();
              }
        }
        data-testid="account-item"
      >
        <HStack spacing={2}>
          {isReorderMode && (
            <Icon
              w="24px"
              h="24px"
              color="primary.300"
              as={DNDIcon}
              cursor="move"
              outline="none"
              {...attributes}
              {...listeners}
            />
          )}
          <Box position="relative" w="40px" h="40px">
            <Icon w="40px" h="40px" as={getCurrencyIcon(account.currency)} />
            {accountWarning && (
              <Icon
                position="absolute"
                right="-2px"
                bottom="-2px"
                w="18px"
                h="18px"
                as={accountWarning.icon}
                color={accountWarning.iconColor}
              />
            )}
          </Box>
          <Box>
            <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
              {account.name}
            </Text>
            <Text textStyle={TextStyles.BodyText16Medium} color="primary.500">
              {formatAmountByCurrency(account.amount, account.currency)}
            </Text>
          </Box>
        </HStack>
        {isDesktop && isHover && !isReorderMode && (
          <ActionsPopover placement="left-start" accountId={account.id}>
            <Center onClick={(e) => e.stopPropagation()}>
              <Icon
                aria-label={t('accounts:accountsDrawer.ariaLabels.showOptions')}
                as={EllipsisIcon}
                color="primary.500"
                data-testid="button_account-context-menu"
              />
            </Center>
          </ActionsPopover>
        )}
      </Flex>
    </Box>
  );
};
