import { useTranslation } from 'react-i18next';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { BankModal } from '../../components/BankModal/BankModal';
import { Icon } from '@chakra-ui/react';
import { createRecoilKey } from '../../state/createKey';
import { EventStatus } from '../../components/EventStatus/EventStatus';
import { AlertIcon, PendingIcon } from '../../icons';
import { useSendDeletionNotifications } from '../../hooks/clients/queries';

const WarningRedIcon = () => (
  <Icon as={AlertIcon} w={8} h={8} mb={5} alignSelf="center" color="red.500" />
);
const PendingYellowIcon = () => (
  <Icon
    as={PendingIcon}
    w={8}
    h={8}
    mb={5}
    alignSelf="center"
    color="yellow.500"
  />
);

const closeAccountModalStateAtom = atom<'close' | 'step1' | 'step2'>({
  key: createRecoilKey('closeAccountModalStateAtom'),
  default: 'close',
});

export const useCloseAccountModal = () =>
  useSetRecoilState(closeAccountModalStateAtom);

export const CloseAccountModal = () => {
  const modalState = useRecoilValue(closeAccountModalStateAtom);
  const onOpenCloseAccountModal = useCloseAccountModal();

  return (
    <BankModal
      close={() => onOpenCloseAccountModal('close')}
      open={modalState !== 'close'}
    >
      {modalState === 'step1' ? <Step1Modal /> : <Step2Modal />}
    </BankModal>
  );
};

const Step1Modal = () => {
  const onOpenCloseAccountModal = useCloseAccountModal();
  const { t } = useTranslation(['profile', 'common']);
  const { mutate, isPending } = useSendDeletionNotifications(() =>
    onOpenCloseAccountModal('step2'),
  );

  return (
    <EventStatus
      statusType="custom"
      title={t('profile:closeAccount.title1')}
      description={t('profile:closeAccount.description1')}
      onCloseButtonClick={() => onOpenCloseAccountModal('close')}
      closeButtonTitle={t('common:cancel')}
      onActionButtonClick={mutate}
      actionButtonTitle={t('common:confirm')}
      actionButtonProps={{ variant: 'red', isLoading: isPending }}
      icon={WarningRedIcon}
    />
  );
};

const Step2Modal = () => {
  const onOpenCloseAccountModal = useCloseAccountModal();
  const { t } = useTranslation(['profile']);

  return (
    <EventStatus
      statusType="custom"
      title={t('profile:closeAccount.title2')}
      description={t('profile:closeAccount.description2')}
      onCloseButtonClick={() => onOpenCloseAccountModal('close')}
      closeButtonProps={{ variant: 'secondary' }}
      icon={PendingYellowIcon}
    />
  );
};
