import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { useMemo } from 'react';
import MultiDropdown, { TMultiDropdownProps } from '../MultiDropdown';
import { mc1Preset, mc2Preset, mc3Preset } from './presets';
import { RequiredStyledProvider } from '../../../required-styled-provider';

export type TMultiDropdownFieldProps = Omit<
  TMultiDropdownProps,
  'value' | 'onChange' | 'onBlur' | 'floatingLabel'
> & {
  /**
   * Имя поля формы
   */
  fieldName: string;
  /**
   * заголовок поля Type 1
   */
  label?: string;
  /**
   * Плавающие заголовки поля?
   */
  floating?: boolean;
  /**
   * Комбинация пропсов (см. сторибук)
   */
  preset?: 'mc1' | 'mc2' | 'mc3';
};

export const MultiDropdownField = (props: TMultiDropdownFieldProps) => {
  const { preset, ...otherProps } = props;

  const presetProps = useMemo(() => {
    switch (preset) {
      case 'mc1':
        return mc1Preset;
      case 'mc2':
        return mc2Preset;
      case 'mc3':
        return mc3Preset;
      default:
        return {};
    }
  }, [preset]);

  const { fieldName, label, floating, ...dropdownProps } = {
    ...presetProps,
    ...otherProps,
  };

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.fieldName}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={!!fieldState.error}>
            {!floating && label && (
              <FormLabel as="legend">{props.label}</FormLabel>
            )}
            <RequiredStyledProvider>
              <MultiDropdown
                {...dropdownProps}
                floatingLabel={floating && label ? label : undefined}
                value={field.value}
                onChange={(v) => field.onChange(v ?? [])}
                onBlur={field.onBlur}
                isError={!!fieldState.error}
              />
              {fieldState.error && (
                <FormHelperText
                  color="red.500"
                  mt={1}
                  textStyle={TextStyles.Caption12Regular}
                  px={1.5}
                >
                  {fieldState.error.message}
                </FormHelperText>
              )}
            </RequiredStyledProvider>
          </FormControl>
        );
      }}
    />
  );
};

export default MultiDropdownField;
