import { Center, VStack } from '@chakra-ui/react';
import { emotionColors } from '@payler/ui-theme';
import { Logo } from '../../components/Logo/Logo';
import { ErrorBoundary } from '@payler/ui-components';
import ErrorPage from '../ErrorPage/ErrorPage';

export const OnboardingWrapper: FCC = ({ children }) => {
  return (
    <Center bg={emotionColors.menuBg} minH="100vh" px={4}>
      <ErrorBoundary errorPage={<ErrorPage />}>
        <Logo position="absolute" left={3} top={4} m={0} forLogin />
        <VStack
          spacing={4}
          textAlign="center"
          maxW="586px"
          mt={{ base: 4 * 2 + 6, xsm: 4 }}
          mb={4}
        >
          {children}
        </VStack>
      </ErrorBoundary>
    </Center>
  );
};
