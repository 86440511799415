import React, { useEffect, useRef } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { useIntersectionObserver } from '@payler/utils';
import { Box, chakra } from '@chakra-ui/react';
import { css } from '@emotion/react';

const containerStyle = css`
  .ps__rail-x {
    z-index: 5;
  }
  .ps__rail-y {
    z-index: 5;
  }
`;

/**
 * Обёртка над react-perfect-scrollbar с градиентом по бокам и сверху/снизу.
 * Для того что бы обертка работала корректно, родителем должен быть flex-подобный элемент, который ограничивает максимальную высоту/ширину
 */
export const ScrollBarWithFade: FCC = ({ children }) => {
  const scrollBarRef = useRef<ScrollBar | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const topMarkerRef = useRef<HTMLDivElement>(null);
  const rightMarkerRef = useRef<HTMLDivElement>(null);
  const bottomMarkerRef = useRef<HTMLDivElement>(null);
  const leftMarkerRef = useRef<HTMLDivElement>(null);
  const topMarkerOnScreen = useIntersectionObserver(topMarkerRef, {
    root: containerRef.current,
  });
  const rightMarkerOnScreen = useIntersectionObserver(rightMarkerRef, {
    root: containerRef.current,
  });
  const bottomMarkerOnScreen = useIntersectionObserver(bottomMarkerRef, {
    root: containerRef.current,
  });
  const leftMarkerOnScreen = useIntersectionObserver(leftMarkerRef, {
    root: containerRef.current,
  });

  /** Исправление бага, когда начальные размеры вычисляются не корректно и отображается скролл, даже если его не должно быть */
  useEffect(() => {
    const timer = setTimeout(() => {
      scrollBarRef.current?.updateScroll();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', overflow: 'hidden', width: '100%' }}
      css={containerStyle}
    >
      {!topMarkerOnScreen && <TopFade />}
      {!rightMarkerOnScreen && <RightFade />}
      {!bottomMarkerOnScreen && <BottomFade />}
      {!leftMarkerOnScreen && <LeftFade />}
      <ScrollBar ref={scrollBarRef}>
        <chakra.div minW="max-content" minH="max-content" position="relative">
          <Box
            ref={topMarkerRef}
            position="absolute"
            pointerEvents="none"
            top={0}
            w="100%"
            h={1}
            bg="transparent"
          />
          <Box
            ref={rightMarkerRef}
            position="absolute"
            right={0}
            pointerEvents="none"
            w={1}
            h="100%"
            bg="transparent"
          />
          <Box
            ref={bottomMarkerRef}
            position="absolute"
            pointerEvents="none"
            bottom={0}
            w="100%"
            h={1}
            bg="transparent"
          />
          <Box
            ref={leftMarkerRef}
            position="absolute"
            left={0}
            pointerEvents="none"
            w={1}
            h="100%"
            bg="transparent"
          />
          {children}
        </chakra.div>
      </ScrollBar>
    </div>
  );
};

const RightFade = () => (
  <Box
    position="absolute"
    pointerEvents="none"
    right="-1px"
    zIndex={1}
    w={10}
    h="100%"
    bgGradient="linear(to-r, transparent, secondary.500)"
  />
);

const LeftFade = () => (
  <Box
    position="absolute"
    pointerEvents="none"
    left="-1px"
    zIndex={1}
    w={10}
    h="100%"
    bgGradient="linear(to-l, transparent, secondary.500)"
  />
);

const BottomFade = () => (
  <Box
    position="absolute"
    pointerEvents="none"
    bottom="-1px"
    zIndex={1}
    w="100%"
    h={10}
    bgGradient="linear(to-b, transparent, secondary.500)"
  />
);

const TopFade = () => (
  <Box
    position="absolute"
    pointerEvents="none"
    top="-1px"
    zIndex={1}
    w="100%"
    h={10}
    bgGradient="linear(to-t, transparent, secondary.500)"
  />
);
