import {
  useCanSelectMonth,
  useLocale,
  useMonthsDisplayedCount,
  useSetMonth,
} from './state';
import dayjs from 'dayjs';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Wrap = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ChooseMonth = () => {
  return (
    <Wrap>
      <Month month={1} />
      <Month month={2} />
      <Month month={3} />
      <Month month={4} />
      <Month month={5} />
      <Month month={6} />
      <Month month={7} />
      <Month month={8} />
      <Month month={9} />
      <Month month={10} />
      <Month month={11} />
      <Month month={12} />
    </Wrap>
  );
};

const Month = ({ month }: { month: number }) => {
  const monthDisplayed = useMonthsDisplayedCount();
  const disabled = !useCanSelectMonth(month);

  const format = monthDisplayed > 1 ? 'MMMM' : 'MMM';
  const locale = useLocale();
  const chooseMonth = useSetMonth();
  const color = disabled ? 'primary.300' : 'primary.500';
  const cursor = disabled ? 'not-allowed' : 'pointer';
  const hoverColor = disabled ? undefined : 'brands.100';
  const monthName = dayjs(`2000-${month}-01`).locale(locale).format(format);

  const shadow =
    dayjs().month() === month
      ? `inset 0 0 0 2px ${emotionColors.brands500}`
      : undefined;
  const handleClick = () => {
    if (!disabled) {
      chooseMonth(month);
    }
  };

  return (
    <chakra.div
      maxW="fit-content"
      boxSizing="border-box"
      borderRadius={1}
      cursor={cursor}
      textStyle={TextStyles.BodyUI16Regular}
      color={color}
      textAlign="center"
      px={3}
      py={1}
      shadow={shadow}
      onClick={handleClick}
      _hover={{
        bg: hoverColor,
      }}
      data-testid={`month_${month}`}
    >
      {monthName}
    </chakra.div>
  );
};
