import { useActiveDate, useSetYear, useYears } from './state';
import { chakra, HStack, VStack } from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import dayjs from 'dayjs';

export const ChooseYear = () => {
  const rows = useYears().years;

  return (
    <VStack spacing={0.5}>
      {rows.map((row, i) => (
        <HStack key={i} maxW="fit-content">
          {row.map((year) => (
            <Year key={year.year} {...year} />
          ))}
        </HStack>
      ))}
    </VStack>
  );
};

const Year = ({ year, disabled }: { year: number; disabled: boolean }) => {
  const currentYear = useActiveDate().year();
  const chooseYear = useSetYear();
  const bg = currentYear === year ? 'brands.500' : undefined;
  let color = disabled ? 'primary.300' : 'primary.500';
  const cursor = disabled ? 'not-allowed' : 'pointer';
  let hoverColor = disabled ? undefined : 'brands.100';
  if (year === currentYear) {
    hoverColor = disabled ? undefined : 'brands.hover';
    color = disabled ? 'primary.300' : 'secondary.500';
  }

  const shadow =
    dayjs().year() === year
      ? `inset 0 0 0 2px ${emotionColors.brands500}`
      : undefined;
  const handleClick = () => {
    if (!disabled) {
      chooseYear(year);
    }
  };

  return (
    <chakra.div
      bg={bg}
      borderRadius={1}
      cursor={cursor}
      textStyle={TextStyles.BodyUI16Regular}
      color={color}
      px={3}
      py={1}
      shadow={shadow}
      onClick={handleClick}
      _hover={{
        bg: hoverColor,
      }}
    >
      {year}
    </chakra.div>
  );
};
