import React, { forwardRef } from 'react';
import { Input, InputProps } from '../Input/Input';
import { Icon, useClipboard } from '@chakra-ui/react';
import { CopyDoneIcon, CopyIcon } from '@payler/ui-icons';

export type InputClipboardProps = Omit<InputProps, 'rightElementProps'>;

export const InputClipboard = forwardRef<HTMLInputElement, InputClipboardProps>(
  (props, ref) => {
    const { hasCopied, onCopy } = useClipboard(props.value as string);
    const rightElementProps = {
      width: 'unset',
      padding: '0 16px 0 10px',
      children: hasCopied ? (
        <CopyDoneIcon />
      ) : (
        <Icon
          as={CopyIcon}
          w="20px"
          h="20px"
          cursor="pointer"
          fill="primary.300"
          onClick={onCopy}
        />
      ),
    };
    return (
      <Input
        paddingEnd="46px"
        paddingStart={2}
        {...props}
        ref={ref}
        rightElementProps={rightElementProps}
        isReadOnly={true}
      />
    );
  }
);
InputClipboard.displayName = 'InputClipboard';
