import {
  TAddAccountMutationParams,
  TChangeAccountsOrderMutationParams,
  TConfirmAccountCommand,
  TEditAccountNameMutationParams,
  TSendOtpCommand,
} from '@payler/api/client-office';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useApi } from '../../state/api';
import { useDropAccountsCache } from './cache';
import { useHandleToastError } from '@payler/bank-utils';
import { useDropBaasProviderCurrenciesCache } from '../currencies/cache';

/** Добавить счёт */
export const useAddAccountMutation = () => {
  const api = useApi();
  return useMutation({
    mutationKey: ['addAccount'],
    mutationFn: async (data: TAddAccountMutationParams) =>
      await api.addAccount(data),
  });
};

/** Запросить новый ОТП код*/
export const useAddAccountResendOTPMutation = () => {
  const api = useApi();
  return useMutation({
    mutationKey: ['addAccountSendOTP'],
    mutationFn: async (data: TSendOtpCommand) =>
      await api.addAccountSendOTP(data),
  });
};

/** Подтвердить OTP код*/
export const useAddAccountConfirmOTPMutation = () => {
  const api = useApi();
  const dropAccountsCache = useDropAccountsCache();
  const dropCurrenciesCache = useDropBaasProviderCurrenciesCache();

  return useMutation({
    mutationKey: ['addAccountConfirmOTP'],
    mutationFn: async (data: TConfirmAccountCommand) => {
      await api.addAccountOTPVerification(data);
    },
    onSuccess: () => {
      dropAccountsCache();
      dropCurrenciesCache();
    },
  });
};

/**
 * Изменить название счета (так же сбрасывается кэш счетов)
 */
export const useEditAccountNameMutation = () => {
  const api = useApi();
  const dropCache = useDropAccountsCache();

  const onSuccess = useCallback(() => {
    dropCache();
  }, [dropCache]);

  return useMutation({
    mutationKey: ['editAccountName'],
    mutationFn: (data: TEditAccountNameMutationParams) =>
      api.editAccountName(data),
    onSuccess,
  });
};

/** Изменить порядок счетов */
export const useChangeAccountsOrderMutation = () => {
  const api = useApi();
  const dropCache = useDropAccountsCache();
  const handleToastError = useHandleToastError();

  return useMutation({
    mutationKey: ['changeAccountsOrder'],
    mutationFn: async (data: TChangeAccountsOrderMutationParams) =>
      await api.changeAccountsOrder(data),
    onSuccess: () => {
      dropCache();
    },
    onError: (e) => {
      handleToastError(e);
      dropCache();
    },
  });
};
