import { TAccountDto, TRecipientDto } from '@payler/api/client-office';
import React, { useMemo } from 'react';
import { BankModal } from '../../components/BankModal/BankModal';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import {
  TransferWizard,
  TTransferDetailsForm,
  TTransferWizardStep,
} from './TransferWizard';

const KEY = 'modals:TransferModal';

export const useShowTransferModal = () => useRecoilBoolean(KEY).on;
export const useCloseTransferModal = () => useRecoilBoolean(KEY).off;
const useIsOpenTransferModal = () => useRecoilBooleanValue(KEY);

export type TTransferModalProps = {
  initialSenderAccount?: TAccountDto;
  initialRecipient?: TRecipientDto;
  initialStep?: TTransferWizardStep;
  initialFormValues?: TTransferDetailsForm;
  isOpen?: boolean;
  close?: VoidFunction;
  isSelfTransfer?: boolean;
  isRepeatTransfer?: boolean;
};
export const TransferModal: React.FC<TTransferModalProps> = ({
  isOpen,
  close,
  ...props
}) => {
  const isOpenDefault = useIsOpenTransferModal();
  const closeDefault = useCloseTransferModal();
  const isModalOpen = isOpen ?? isOpenDefault;
  const closeModal = useMemo(
    () => close ?? closeDefault,
    [close, closeDefault],
  );
  return (
    <BankModal close={closeModal} open={isModalOpen} scrollBehavior="inside">
      <React.Suspense fallback={<LoaderBox minH="312px" />}>
        <TransferWizard closeModal={closeModal} {...props} />
      </React.Suspense>
    </BankModal>
  );
};
