import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import createLogger from 'debug';
import { TStepTypeDto } from '@payler/api/client-office';
import { TCurrency } from '@payler/bank-utils';

const log = createLogger('ConfigProvider');

// флаги, существующие в приложении
const ALL_APP_FLAGS = [
  // placeholderFlag нужен что бы массив не был пустым, иначе типы сходят с ума
  'placeholderFlag',
  'accounts.add',
  'accounts.exchange',
  'finlego.demo',
] as const;

type TAppFlag = (typeof ALL_APP_FLAGS)[number];
type TAppFlags = Partial<Record<TAppFlag, boolean>>;
type TLogoFileExtension = 'svg' | 'png';

export type TBankLanguage = 'en' | 'es';
export type TClientEnvConfig = {
  production: boolean;
  apiServer: string;
  auth: {
    config: {
      url: string;
      realm: string;
      clientId: string;
    };
  };
  onboardingMockUrls?: Record<TStepTypeDto, string>;
  isDarkThemeAllowed?: boolean;
  flags?: TAppFlags;
  staticPath: string;
  /**
   * Расширение файла логотипа
   */
  logoFileExtension?: TLogoFileExtension;
  /**
   * Официальное название банка
   */
  bankLegalName?: string;
  /**
   * Доступные валюты на уровне приложения
   */
  availableCurrencies?: Array<{
    id: TCurrency;
    name: string;
    currencyCode: Uppercase<TCurrency>;
  }>;
  /**
   * Высота хедера в мобильной версии
   */
  mobileHeaderHeight?: number;
  /**
   * Длина ОТП кода
   */
  otpLength?: number;
  properties?: Record<string, string>;
};

export type TClientOfficeAppContext = {
  production: boolean;
  /**
   * Сервер
   * @example https://my.dev.account.payler.com/
   */
  apiServer: string;
  onboardingMockUrls?: Record<TStepTypeDto, string>;
  isDarkThemeAllowed?: boolean;
  staticPath: string;
  flags?: TAppFlags;
  /**
   * Расширение файла логотипа
   */
  logoFileExtension?: TLogoFileExtension;
  /**
   * Официальное название банка
   */
  bankLegalName?: string;
  /**
   * Доступные валюты на уровне приложения
   */
  availableCurrencies?: Array<{
    id: TCurrency;
    name: string;
    currencyCode: Uppercase<TCurrency>;
  }>;
  /**
   * Высота хедера в мобильной версии
   */
  mobileHeaderHeight?: number;
  /**
   * Длина ОТП кода
   */
  otpLength?: number;
};



const ConfigContext = createContext<TClientOfficeAppContext>({
  production: false,
  apiServer: '',
  staticPath: '',
});

type ProviderProps = {
  env: TClientEnvConfig;
  /**
   * Для storybook
   */
  skipLanguageInit?: boolean;
};

export const ConfigProvider: FCC<ProviderProps> = ({ children, env }) => {
  const [flags, setFlags] = useState<TAppFlags>(() => {
    const tmp = { ...(env.flags ?? {}) };
    if (!env.production) {
      ALL_APP_FLAGS.forEach((x) => {
        if (!x) return;
        try {
          const parsed = JSON.parse(sessionStorage.getItem(x) ?? 'undefined');
          if (typeof parsed === 'boolean') {
            tmp[x] = parsed;
          }
        } catch (e) {
          log('bad flag in storage: %s', x);
        }
      });
    }
    return tmp;
  });

  const ctxValue: TClientOfficeAppContext = useMemo(() => {
    const result = {
      production: env.production,
      apiServer: env.apiServer.replace(/\/$/, ''),
      onboardingMockUrls: env.onboardingMockUrls,
      isDarkThemeAllowed: env.isDarkThemeAllowed,
      staticPath: env.staticPath,
      flags,
      logoFileExtension: env.logoFileExtension,
      bankLegalName: env.bankLegalName,
      availableCurrencies: env.availableCurrencies,
      mobileHeaderHeight: env.mobileHeaderHeight,
      otpLength: env.otpLength,
    };
    log('config context value: %O', result);
    return result;
  }, [env, flags]);

  useEffect(() => {
    if (!env.production) {
      log('ALL_APP_FLAGS: %O', ALL_APP_FLAGS);
      const listener = (e: StorageEvent) => {
        const key = e.key as TAppFlag;
        if (key && ALL_APP_FLAGS.includes(key)) {
          if (e.newValue !== null) {
            try {
              const parsed = JSON.parse(e.newValue);
              if (typeof parsed === 'boolean') {
                setFlags((prev) => {
                  return { ...prev, [key]: parsed };
                });
              } else {
                // eslint-disable-next-line no-console
                console.warn('bad flag value from storage', {
                  key,
                  value: e.newValue,
                });
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(`can't parse sessionStorage flag from event:`, e);
            }
          } else {
            setFlags((prev) => ({ ...prev, [key]: env.flags?.[key] }));
          }
        }
      };
      window.addEventListener('storage', listener);
      return () => window.removeEventListener('storage', listener);
    }
    return;
  }, [env]);

  return (
    <ConfigContext.Provider value={ctxValue}>{children}</ConfigContext.Provider>
  );
};

export const useClientOfficeConfig = () => useContext(ConfigContext);
export const useAppFlags = () => useClientOfficeConfig().flags ?? {};
export const useCheckAppFlagEnabled = (flag: TAppFlag): boolean => {
  return useAppFlags()[flag] ?? false;
};
