import { FC, PropsWithChildren, useCallback } from 'react';
import {
  Button,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Pencil24Icon, DownloadIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import { useShowEditAccountNameModal } from '../../modals/EditAccountName';
import { useShowAccountStatementModal } from '../../modals/AccountStatement';
import { FileTextIcon } from '../../icons';
import { useAccountConfirmationQuery } from '../../hooks/reports/queries';
import { useHandleToastError } from '@payler/bank-utils';

type TActionsPopoverProps = PropsWithChildren<
  Omit<PopoverProps, 'isOpen' | 'onClose' | 'onOpen'> & {
    accountId: string;
  }
>;

export const ActionsPopover: FC<TActionsPopoverProps> = ({
  children,
  accountId,
  ...props
}) => {
  const { t } = useTranslation(['accounts']);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const handleToastError = useHandleToastError();
  const onOpenEditAccountNameModal = useShowEditAccountNameModal();
  const onOpenStatementModal = useShowAccountStatementModal();
  const {
    handleAccountConfirmationQuery,
    loading: accountConfirmationLoading,
  } = useAccountConfirmationQuery();

  const handleDownloadAccountConfirmation = useCallback(async () => {
    try {
      await handleAccountConfirmationQuery(accountId);
    } catch (e) {
      handleToastError(e);
    }
    onClose();
  }, [accountId, handleAccountConfirmationQuery, handleToastError, onClose]);

  return (
    <Popover {...props} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        w="auto"
        border="4px solid"
        borderColor="secondary.500"
        boxShadow="popover"
      >
        <PopoverBody bg="secondary.500" p={0}>
          <VStack w="159px" spacing={0.5} alignItems="start">
            <Button
              variant="borderless"
              size="small"
              w="full"
              justifyContent="start"
              onClick={(e) => {
                e.stopPropagation();
                onOpenEditAccountNameModal(accountId);
              }}
              data-testid="button_open_edit-name-modal"
            >
              <Icon as={Pencil24Icon} mr="14px" />
              {t('accounts:accountInfo.editName')}
            </Button>
            <Button
              variant="borderless"
              size="small"
              w="full"
              justifyContent="start"
              onClick={(e) => {
                e.stopPropagation();
                onOpenStatementModal(accountId);
              }}
              data-testid="button_open_statement-modal"
            >
              <Icon as={DownloadIcon} mr="14px" />
              {t('accounts:accountInfo.statement')}
            </Button>
            <Button
              variant="borderless"
              size="small"
              w="full"
              justifyContent="start"
              onClick={handleDownloadAccountConfirmation}
              isLoading={accountConfirmationLoading}
              loadingText={t('accounts:accountInfo.confirmation')}
              data-testid="button_download_confirmation"
            >
              <Icon as={FileTextIcon} mr="14px" />
              {t('accounts:accountInfo.confirmation')}
            </Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
