import { useEffect } from 'react';

/**
 * Удаление UTM-меток
 */
export const useClearUTMs = (utms: string[]) => {
  useEffect(() => {
    utms.forEach((item) => {
      document.cookie = `${item}=''; max-age=-1`;
    });
  }, [utms]);
};
