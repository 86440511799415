import { TCreateRecipientCommand } from '@payler/api/client-office';
import { useHandleToastError } from '@payler/bank-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../state/api';

/**
 * добавить нового реципиента
 */
export const useAddRecipientMutation = () => {
  const api = useApi();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['addRecipient'],
    mutationFn: (data: TCreateRecipientCommand) => api.addRecipient(data),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['recipients', 'list'] });
      client.invalidateQueries({
        queryKey: ['recipients', 'transfer', 'list'],
      });
    },
  });
};

export const useSaveRecipientMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  const handleToastError = useHandleToastError();

  return useMutation({
    mutationKey: ['saveRecipient'],
    mutationFn: (recipientId: string) => api.saveRecipient(recipientId),
    onError: handleToastError,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['recipients', 'list'] });
      client.invalidateQueries({
        queryKey: ['recipients', 'transfer', 'list'],
      });
    },
  });
};
