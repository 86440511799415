import { darkCPColors as dark } from './dark';
import { lightCPColors as light } from './light';
import { generalColors as general } from './general';

export * from './dark';
export * from './light';
export * from './general';

export const colors = {
  dark,
  light,
  ...general,
};
