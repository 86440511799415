import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const ApiSuccessText = chakra('p', {
  baseStyle: {
    color: 'green.500',
    my: 2,
    textStyle: TextStyles.Caption12Regular,
  },
});
