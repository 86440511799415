import { useBreakpointValue } from '@chakra-ui/react';

/**
 * useBreakpointValue, который в первый рендер сразу выдаёт реальное значение, а не base
 * @param values - необходимые значения
 * @param arg - объект параметров, строка будет игнорироваться
 */
export const useBankBreakpointValue: typeof useBreakpointValue = (
  values,
  arg,
) => {
  return useBreakpointValue(values, {
    ssr: false,
    ...(typeof arg === 'object' ? (arg ?? {}) : {}),
  });
};
