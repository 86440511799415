import { Icon, StackProps, Text, VStack } from '@chakra-ui/react';
import { Folder2Icon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string;
} & Omit<StackProps, 'children'>;

export const NoData = ({ text, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <VStack
      spacing={1}
      as="span"
      width="100%"
      alignItems="center"
      h="200px"
      justifyContent="center"
      position="relative"
      {...rest}
    >
      <Icon width={5} height={5} as={Folder2Icon} color="primary.400" />
      <Text as="span" textStyle={TextStyles.h3} color="primary.400">
        {text ?? t('noData', { defaultValue: 'No data' })}
      </Text>
    </VStack>
  );
};

export default NoData;
