import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { FieldPath, FieldValues } from 'react-hook-form';
import { MayBe } from './maybe';

type TErrorValue = {
  code: string;
  message: string;
};

type TValidationProblemDetails = {
  code: MayBe<string>;
  errors?: Record<string, TErrorValue>;
  detail: MayBe<string>;
  instance: MayBe<string>;
  status: MayBe<number>;
  title: MayBe<string>;
  type: MayBe<string>;
};

type TFieldError<T extends FieldValues> = {
  fieldName: FieldPath<T>;
  errorMessage: string;
  fieldErrorCode?: string;
};

interface TUseGetAxiosErrorReturn<T extends FieldValues> {
  fieldErrors?: TFieldError<T>[];
  errorTitle: string;
  errorCode: string;
  status?: MayBe<number>;
}

export function useGetAxiosError<T extends FieldValues>() {
  const { t } = useTranslation();
  return useCallback(
    (e: unknown): TUseGetAxiosErrorReturn<T> => {
      if (!(e instanceof AxiosError)) {
        return {
          errorTitle: t('unknownError', {
            message: (e as Error | undefined)?.message,
          }),
          errorCode: '',
        };
      }
      let responseData:
        | AxiosResponse<TValidationProblemDetails>['data']
        | undefined = e.response?.data;
      if (e.request?.responseType === 'arraybuffer') {
        const stringResponse = new TextDecoder().decode(
          new Uint8Array(e.response?.data as ArrayBuffer),
        );
        try {
          responseData = JSON.parse(stringResponse);
        } catch (e) {
          responseData = undefined;
        }
      }

      if (!responseData) {
        return {
          errorTitle: t('unknownError', {
            message: e.message,
          }),
          errorCode: '',
        };
      }

      const { code, title, detail, errors, status } = responseData;
      const fieldErrors: TFieldError<T>[] = [];
      if (errors) {
        Object.entries(errors).forEach((entry) => {
          const [key, value] = entry;
          const typifiedError = {
            fieldName: key as FieldPath<T>,
            errorMessage: (value as AxiosError).message,
            fieldErrorCode: (value as AxiosError).code,
          };
          fieldErrors.push(typifiedError);
        });
      }
      return {
        fieldErrors: fieldErrors.length ? fieldErrors : undefined,
        errorTitle: detail || title || '',
        errorCode: code || '',
        status,
      };
    },
    [t],
  );
}
