import { StackProps, StyleProps, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ThinDivider } from '../../components';
import { DropzoneFile } from './DropzoneFile';
import { useDropzoneComponentContext } from './DropzoneContext';
import { ReactElement } from 'react';

export type TDropzoneCompProps = {
  variant?: 'normal' | 'drag-accept' | 'drag-reject' | 'errors';
  title?: string | ReactElement;
  description?: string | ReactElement;
  help?: string;
} & Omit<StackProps, 'title'>;

export const DropzoneComp = ({
  variant,
  title,
  description,
  help,
  ...rest
}: TDropzoneCompProps) => {
  return (
    <VStack
      className="dropzone-component"
      alignItems="stretch"
      borderRadius={1.5}
      borderWidth="1px"
      borderStyle="dashed"
      {...getStyleProps(variant)}
      transitionDuration="fast"
      divider={<ThinDivider />}
      spacing={0}
      {...rest}
    >
      <VStack spacing={2} p={2} textAlign="center">
        {title && (
          <Text
            textStyle={TextStyles.h3}
            color="primary.400"
            data-testid="dropzone-title"
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            textStyle={TextStyles.Subtitle14Medium}
            color="primary.400"
            data-testid="dropzone-description"
          >
            {description}
          </Text>
        )}
        <Text
          textStyle={TextStyles.Caption12Regular}
          color="primary.400"
          data-testid="dropzone-help"
        >
          {help ?? 'Drop file(s) or click on field'}
        </Text>
      </VStack>
      <FileList />
    </VStack>
  );
};

const FileList = () => {
  const { files } = useDropzoneComponentContext();
  return (
    <VStack
      alignItems="stretch"
      borderStyle="dashed"
      divider={<ThinDivider />}
      spacing={0}
      data-testid="dropzone-files-list"
    >
      {files?.map((x, i) => (
        <DropzoneFile key={i} file={x} index={i} />
      ))}
    </VStack>
  );
};

function getStyleProps(variant: TDropzoneCompProps['variant']): StyleProps {
  switch (variant) {
    case 'drag-reject': {
      return {
        bg: 'red.100',
        borderColor: 'red.500',
        cursor: 'no-drop',
      };
    }
    case 'drag-accept': {
      return {
        bg: 'brands.100',
        borderColor: 'brands.500',
        cursor: 'copy',
      };
    }
    case 'errors': {
      return {
        bg: 'red.100',
        borderColor: 'red.500',
        cursor: 'pointer',
      };
    }
    default: {
      return {
        borderColor: 'primary.200',
        bg: 'primary.25',
        cursor: 'pointer',
      };
    }
  }
}
