import React from 'react';
import {
  FormOption,
  FormOptionProps,
} from '../components/FormOption/FormOption';
import { Otp, OtpProps } from '../components/Otp/Otp';
import { Controller, useFormContext } from 'react-hook-form';

interface OtpFieldProps extends OtpProps {
  name: string;
  label?: FormOptionProps['label'];
}

export const OtpField: React.FC<OtpFieldProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { name, defaultValue, ...rest } = props;
  const errorMessage = errors[name]?.message;

  return (
    <FormOption
      isInvalid={!!errorMessage}
      label={props?.label}
      errorMessage={errorMessage as string | undefined}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => (
          <Otp {...rest} onChange={onChange} />
        )}
      />
    </FormOption>
  );
};
