import {
  Button,
  ButtonProps,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react';
import { GlobeAltIcon } from '@payler/ui-icons';
import { Icon } from '@chakra-ui/icons';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { memo, useCallback } from 'react';

interface Lang {
  shortName: string;
  longName: string;
  value: string;
}

const defaultLangs: Lang[] = [
  {
    shortName: 'Ru',
    longName: 'Russian',
    value: 'ru',
  },
  {
    shortName: 'En',
    longName: 'English',
    value: 'en',
  },
];

const Trigger = ({
  activeLanguage,
  shouldTruncateNameOnMobile,
}: {
  activeLanguage?: Lang;
  shouldTruncateNameOnMobile: boolean;
}) =>
  forwardRef<ButtonProps, 'button'>((props, ref) => {
    return (
      <Button
        ref={ref}
        border="none"
        __css={{
          display: 'inline-flex',
          alignItems: 'center',
          svg: {
            transitionProperty: 'color',
            transitionDuration: 'var(--payler-transition-duration-normal)',
          },
          _hover: {
            bg: 'none',
            color: 'primary.400',
            'svg, span': {
              color: 'primary.400',
            },
          },
        }}
        color="primary.350"
        textStyle={TextStyles.BodyUI16Medium}
        leftIcon={<Icon as={GlobeAltIcon} />}
        iconSpacing="6px"
        {...props}
      >
        {useBreakpointValue({
          base: shouldTruncateNameOnMobile
            ? activeLanguage?.shortName || ''
            : activeLanguage?.longName || '',
          xsm: activeLanguage?.longName || '',
        })}
      </Button>
    );
  });

const menuItemProps: MenuItemProps = {
  bg: 'transparent',
  _hover: { bg: 'transparent', color: 'primary.500' },
  __css: {
    transitionProperty: 'color',
    transitionDuration: 'var(--payler-transition-duration-normal)',
  },
  px: 4,
  py: 2,
  textStyle: TextStyles.BodyText16Regular,
  justifyContent: 'center',
  _focus: { bg: 'transparent' },
};
/**
 * Параметры компонента LanguageSelector
 * @param shouldTruncateNameOnMobile - Если true, то при мобильном расширении меняет длинные тексты языков на короткие
 * @param languages - Список доступных языков
 */
export type LanguageSelectorProps = {
  offset?: [number, number];
  shouldTruncateNameOnMobile?: boolean;
  languages?: Lang[];
};

export const LanguageSelector = memo(
  ({
    offset,
    languages,
    shouldTruncateNameOnMobile,
  }: LanguageSelectorProps) => {
    const langs = languages || defaultLangs;
    const {
      i18n: { language },
    } = useTranslation();
    const activeLanguage = langs.find(({ value }) => value === language);
    const menuItems = langs.map((lang) =>
      LanguageMenuItem(lang, !!shouldTruncateNameOnMobile)
    );
    return (
      <Menu autoSelect={false} offset={offset}>
        <MenuButton
          as={Trigger({
            activeLanguage,
            shouldTruncateNameOnMobile: !!shouldTruncateNameOnMobile,
          })}
        />
        <MenuList
          borderRadius={1.5}
          p={0}
          minWidth="120px"
          border="1px solid"
          borderColor="primary.50"
          boxShadow="languageSelector"
        >
          {menuItems}
        </MenuList>
      </Menu>
    );
  }
);

const LanguageMenuItem = (
  { shortName, longName, value }: Lang,
  shouldTruncateNameOnMobile: boolean
) => {
  const text = useBreakpointValue({
    base: shouldTruncateNameOnMobile ? shortName : longName,
    xsm: longName,
  });
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  const setLanguage = useCallback(
    (lang: string) => {
      changeLanguage(lang).then(() => {
        localStorage.setItem('selected-language', lang);
      });
    },
    [changeLanguage]
  );
  const setLang = useCallback(() => setLanguage(value), [setLanguage, value]);
  const isActive = language === value;
  return (
    <MenuItem
      color={isActive ? 'primary.500' : 'primary.350'}
      key={shortName}
      {...menuItemProps}
      onClick={setLang}
      borderBottom="0.5px solid"
      borderBottomColor="primary.50"
    >
      {text}
    </MenuItem>
  );
};
LanguageSelector.displayName = 'LanguageSelector';

export default LanguageSelector;
