export const currencies = {
  eur: 'Euro',
  gbp: 'Pound Sterling',
  usd: 'Dollar',
  cad: 'Canadian Dollar',
  chf: 'Swiss Franc',
  dkk: 'Danish Krone',
  nok: 'Norwegian Krone',
  pln: 'Polish Zloty',
  sek: 'Swedish Krona',
} as const;
