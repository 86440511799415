import { useMemo } from 'react';
import { useLanguageFeatures } from './use-language-features';

export const useTransferMoneyInfo = ({
  currency,
  feeAmount = 0,
  sendingAmount = 0,
}: {
  currency: string;
  feeAmount?: number;
  sendingAmount?: number;
}) => {
  const { formatAmountByCurrency } = useLanguageFeatures();

  const amount = formatAmountByCurrency(sendingAmount, currency);

  const fee = formatAmountByCurrency(feeAmount, currency);

  const total = sendingAmount + feeAmount;
  const totalAmount = formatAmountByCurrency(total, currency);

  return {
    amount,
    fee,
    totalAmount,
  };
};
