import { emotionColors, TextStyles } from '@payler/ui-theme';
import React, { ComponentProps, memo } from 'react';
import { Dayjs } from 'dayjs';
import {
  DayVariant,
  useDayMeta,
  useOnDayClick,
  useSetHoverDate,
} from './state';
import { chakra } from '@chakra-ui/react';

export const Day = memo(({ date, offset }: { date: Dayjs; offset: number }) => {
  // const width = '42px';
  const width = undefined;
  const flex = 1;

  const meta = useDayMeta(date, offset);
  const setHover = useSetHoverDate();
  const onClick = useOnDayClick();
  const isHidden = meta.includes('hidden');
  const isSelected = meta.includes('selected');
  const isInRange = meta.includes('inSelectionRange');
  const isDisabled = meta.includes('disabled');

  if (isHidden && isInRange) {
    return (
      <chakra.div
        w={width}
        flex={flex}
        h={5}
        pointerEvents="none"
        bg="brands.100"
      />
    );
  }
  if (isHidden && isSelected) {
    return (
      <chakra.div
        w={width}
        flex={flex}
        h={5}
        pointerEvents="none"
        bg="brands.500"
      />
    );
  }
  if (isHidden) {
    return <chakra.div w={width} flex={flex} h={5} pointerEvents="none" />;
  }

  const tmp = meta.map((v) => allVariants.find((x) => x.variant === v));
  const backProps = tmp.reduce((acc, v) => ({ ...acc, ...v?.back }), {});
  const forwardProps = tmp.reduce((acc, v) => ({ ...acc, ...v?.forward }), {});

  return (
    <chakra.div
      py="2px"
      width={width}
      flex={flex}
      onMouseEnter={() => !isDisabled && setHover(date)}
      onMouseLeave={() => !isDisabled && setHover(undefined)}
      onClick={() => !isDisabled && onClick(date)}
    >
      <chakra.div
        w={width}
        flex={flex}
        h={5}
        display="flex"
        flexDir="column"
        alignItems="center"
        {...backProps}
      >
        <chakra.div
          w={5}
          h={5}
          textAlign="center"
          textStyle={TextStyles.BodyUI16Regular}
          lineHeight="40px"
          transitionDuration="fast"
          {...forwardProps}
        >
          {date.get('date')}
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
});

type StyleProps = Pick<
  ComponentProps<typeof chakra.div>,
  | 'bg'
  | '_hover'
  | 'color'
  | 'cursor'
  | 'shadow'
  | 'borderRadius'
  | 'borderTopLeftRadius'
  | 'borderBottomLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomRightRadius'
>;

type DayStyles = {
  variant: DayVariant;
  back?: StyleProps;
  forward?: StyleProps;
};

const allVariants: DayStyles[] = [
  {
    variant: 'normal',
    back: { cursor: 'pointer' },
    forward: {
      color: 'primary.500',
      _hover: { bg: 'brands.100' },
      borderRadius: 1,
    },
  },
  {
    variant: 'today',
    forward: {
      shadow: `inset 0 0 0 2px ${emotionColors.brands500}`,
    },
  },
  {
    variant: 'anotherMonth',
    forward: {
      color: 'primary.300',
    },
  },
  {
    variant: 'inSelectionRange',
    back: {
      bg: 'brands.100',
    },
    forward: {
      _hover: {
        bg: 'brands.300',
        color: 'white',
      },
    },
  },
  {
    variant: 'selectionEnd',
    back: {
      bg: 'brands.500',
      borderTopRightRadius: 1,
      borderBottomRightRadius: 1,
    },
    forward: {
      color: 'white',
    },
  },
  {
    variant: 'selectionStart',
    back: {
      bg: 'brands.500',
      borderTopLeftRadius: 1,
      borderBottomLeftRadius: 1,
    },
    forward: {
      color: 'white',
    },
  },
  {
    variant: 'disabled',
    back: {
      bg: undefined,
      cursor: 'not-allowed',
    },
    forward: {
      color: 'primary.100',
      _hover: {
        bg: 'none',
      },
    },
  },
  {
    variant: 'selected',
    back: {
      bg: 'brands.500',
      _hover: {
        bg: 'brands.hover',
      },
    },
    forward: {
      color: 'white',
    },
  },
  {
    variant: 'selectedEnd',
    back: {
      bg: 'brands.500',
      borderRadius: '0 8px 8px 0',
      _hover: {
        bg: 'brands.hover',
      },
    },
    forward: {
      color: 'white',
    },
  },
  {
    variant: 'selectedStart',
    back: {
      bg: 'brands.500',
      borderRadius: '8px 0 0 8px',
      _hover: {
        bg: 'brands.hover',
      },
    },
    forward: {
      color: 'white',
    },
  },
  {
    variant: 'selectedSingle',
    back: {
      bg: undefined,
      borderRadius: 1,
    },
    forward: {
      bg: 'brands.500',
      borderRadius: 1,
      _hover: {
        bg: 'brands.hover',
      },
    },
  },
  {
    variant: 'hidden',
    back: {
      bg: undefined,
    },
    forward: {
      bg: undefined,
      color: 'transparent',
    },
  },
];
