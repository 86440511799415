export const INTERVAL_IN_MILISECONDS = 1000;

export const ONE_MB = 1_000_000;

export const MAX_FILE_SIZE = 6;
export const MAX_TOTAL_FILE_SIZE = 12;
export const MAX_FILE_NAME_LENGTH = 64;

export const MIN_DESCRIPTION_LENGTH = 5;
export const MAX_DESCRIPTION_LENGTH = 256;

export const MAX_FILES_QUANTITY = 4;

export const VALID_FILE_FORMATS = [
  'image/png',
  'image/jpeg',
  'application/pdf',
];
