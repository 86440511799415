import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  IconProps,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
  StackProps,
  TextProps,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react';
import ScrollBar from 'react-perfect-scrollbar';
import { ExchangeDropdownOptions } from './ExchangeDropdownOptions';
import { ExchangeDropdownTrigger } from './ExchangeDropdownTrigger';
import { TExchangeDropdownOption } from './ExchangeDropdownOption';
import { defaultPopoverProps, popoverContentVariants } from './const';
import { useTranslation } from 'react-i18next';
import { ReactComponent } from '*.svg';

export type TExchangeDropdownProps = {
  /**
   * Опции выпадающего списка
   * Если есть опция со значением null - она показывается при невыбранном значении
   */
  options: TExchangeDropdownOption[];
  /**
   * значение (не использовать undefined!)
   */
  value?: string | number | null;
  /**
   * Обработчик при выборе значения
   * @param value
   */
  onChange?: (value: string | number | null) => void;
  /**
   * Выключен
   */
  isDisabled?: boolean;
  /**
   * Ошибка
   */
  isError?: boolean;
  /**
   * Доп. стили для PopoverContent
   */
  popoverContentProps?: PopoverContentProps;
  /**
   * При потере фокуса
   */
  onBlur?: VoidFunction;
  /**
   * Пропсы обёртки триггера
   */
  triggerWrapperProps?: Omit<StackProps, 'borderColor'>;
  /**
   * Пропсы лейбла опции
   */
  optionLabelProps?: TextProps;
  /**
   * Подсказки браузера при заполнении формы
   */
  autocomplete?: string;
  /**
   * Иконка открытия дропдауна
   */
  dropdownIcon?: {
    component?: typeof ReactComponent;
    props?: IconProps;
  };
};

export const ExchangeDropdown = (props: TExchangeDropdownProps) => {
  const { t } = useTranslation();
  const didMountRef = useRef(false);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const scrollBarRef = useRef<HTMLElement | null>(null);

  const { onBlur: outerOnBlur } = props;
  const { isOpen, onClose, onToggle } = useDisclosure();

  const [isFocused, setIsFocused] = useState(
    !props.isDisabled && triggerRef.current === document.activeElement,
  );
  const [value, setValue] = useControllableState({
    value: props.value,
    onChange: props.onChange,
    defaultValue: null,
  });

  const handleFocus = useCallback(() => {
    if (!props.isDisabled) {
      setIsFocused(true);
    }
  }, [props.isDisabled]);

  const handleBlur = useCallback(() => {
    if (!props.isDisabled) {
      setIsFocused(false);
    }
  }, [props.isDisabled]);

  useEffect(() => {
    if (didMountRef.current && !isFocused && !isOpen) {
      outerOnBlur?.();
    }
  }, [isFocused, isOpen, outerOnBlur]);

  const handleOptionClick = useCallback(
    (val: string | number) => {
      setValue(val);
      onClose();
    },
    [onClose, setValue],
  );

  const selectedOption = useMemo(() => {
    return props.options.find((x) => x.value === value);
  }, [props.options, value]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  return (
    <Popover
      matchWidth
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
      variant="dropdown"
      isLazy
      lazyBehavior="unmount"
    >
      <PopoverTrigger>
        <Box
          userSelect="none"
          ref={triggerRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={props.isDisabled ? undefined : onToggle}
          position="relative"
          outline="none"
          tabIndex={0}
        >
          <ExchangeDropdownTrigger
            selectedOption={selectedOption}
            isOpen={isOpen}
            isDisabled={props.isDisabled ?? false}
            isError={props.isError ?? false}
            isActive={isFocused || isOpen}
            triggerWrapperProps={props.triggerWrapperProps}
            isFocused={isFocused}
            optionLabelProps={props.optionLabelProps}
            dropdownIcon={props.dropdownIcon}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          variants={popoverContentVariants}
          {...defaultPopoverProps(props.options)}
          {...props.popoverContentProps}
          ref={dropdownRef}
        >
          <ScrollBar
            containerRef={(el) => (scrollBarRef.current = el)}
            options={{ suppressScrollX: true, wheelPropagation: true }}
          >
            {props.options.length ? (
              <ExchangeDropdownOptions
                options={props.options}
                onOptionClick={handleOptionClick}
                selected={value}
                optionLabelProps={props.optionLabelProps}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={2}
              >
                {t('common:noOptions', 'No options')}
              </Box>
            )}
          </ScrollBar>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ExchangeDropdown;
