import { isNil } from 'lodash';

export function stringOrUndefined(
  params: URLSearchParams,
  key: string,
): string | undefined {
  const tmp = params.get(key);
  if (isNil(tmp)) return undefined;
  const str = String(tmp);
  if (!str.length) return undefined;
  return str;
}

export function numberOrUndefined(
  params: URLSearchParams,
  key: string,
): number | undefined {
  const tmp = params.get(key);
  if (isNil(tmp)) return undefined;
  const num = Number(tmp);
  if (isNaN(num)) return undefined;
  return num;
}

export function stringArrayOrUndefined(val: string[]): string[] | undefined {
  return val.length > 0 ? val : undefined;
}
