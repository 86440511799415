import { Breakpoints } from './types';

export const breakpoints: Breakpoints = {
  xs: '360px',
  xssm: '412px',
  xsm: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1224px',
  xl: '1440px',
  '2xl': '1536px',
  '3xl': '1920px',
};
