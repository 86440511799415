import { createContext, RefObject, useContext, useMemo, useRef } from 'react';

type TFilterWrapperContext = {
  ref: RefObject<{
    reset: VoidFunction;
  }>;
};
const FilterWrapperContext = createContext<TFilterWrapperContext>(
  {} as TFilterWrapperContext,
);

export const FilterWrapperContextProvider: FCC = ({ children }) => {
  const ref = useRef<{ reset: VoidFunction }>(null);
  const ctx = useMemo<TFilterWrapperContext>(
    () => ({
      ref,
    }),
    [ref],
  );
  return (
    <FilterWrapperContext.Provider value={ctx}>
      {children}
    </FilterWrapperContext.Provider>
  );
};

export const useFilterWrapperContext = () => useContext(FilterWrapperContext);
