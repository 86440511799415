import { INTERVAL_IN_MILISECONDS } from '../const';

export class Timer {
  private isStarted = false;
  private interval: NodeJS.Timeout | undefined;
  private remainingTime = 0;
  private paused = true;
  private lastTime = Date.now();
  private notify: ((timerInstance: Timer) => void) | undefined;

  private tick = () => {
    const now = Date.now();
    const tempRemainingTime = this.remainingTime - (now - this.lastTime);
    this.remainingTime = tempRemainingTime <= 0 ? 0 : tempRemainingTime;
    this.lastTime = now;
    this.notify?.(this);
  };

  public subscribe(notifyFunc: (timerInstance: Timer) => void) {
    this.notify = notifyFunc;
  }

  public getTime = () => this.remainingTime;

  public isPaused = () => this.paused;

  public start = (time: number) => {
    if (!this.isStarted) {
      this.isStarted = true;
      this.paused = false;
      this.remainingTime = time;
      this.lastTime = Date.now();
      this.interval = setInterval(this.tick, INTERVAL_IN_MILISECONDS);
      this.notify?.(this);
    }
  };

  public pause = () => {
    if (!this.paused) {
      this.paused = true;
      clearInterval(this.interval);
      this.interval = undefined;
      this.notify?.(this);
    }
  };

  public unpause = () => {
    if (this.paused) {
      this.paused = false;
      this.lastTime = Date.now();
      this.interval = setInterval(this.tick, INTERVAL_IN_MILISECONDS);
      this.notify?.(this);
    }
  };

  public stop = () => {
    this.isStarted = false;
    this.paused = true;
    this.remainingTime = 0;
    this.lastTime = Date.now();
    clearInterval(this.interval);
    this.interval = undefined;
    this.notify?.(this);
  };
}
