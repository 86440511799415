import {
  AccountsPageLazy,
  ActivityPageLazy,
  RecipientsPageLazy,
  NotFoundPageLazy,
  ProfilePageLazy,
  BaseLayout,
} from '@payler/ui/client-office';
import { Navigate, Route } from 'react-router-dom';

export const getMainAppRoutes = () => (
  <Route path="/" element={<BaseLayout />}>
    <Route index element={<AccountsPageLazy />} />
    <Route path="/recipients" element={<RecipientsPageLazy />} />
    <Route path="/activity" element={<ActivityPageLazy />} />
    <Route path="/profile" element={<ProfilePageLazy />} />
    <Route path="/onboarding" element={<Navigate to="/" replace />} />
    <Route path="*" element={<NotFoundPageLazy />} />
  </Route>
);
