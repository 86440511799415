import React, { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ApiErrorText, FloatingInputField } from '@payler/ui-components';
import { Button, ButtonProps, HStack, VStack } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEditAccountNameMutation } from '../../hooks/accounts/mutations';
import {
  BankModalBody,
  BankModalFooter,
} from '../../components/BankModal/BankModal';
import { useHandleFormError } from '@payler/bank-utils';
import {
  useCloseEditAccountNameModal,
  useIsOpenEditAccountNameModal,
} from '../../modals/EditAccountName';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';

type FormValue = { accountName?: string };

function createResolver(t: TFunction<['fields', 'common']>) {
  return yupResolver(
    yup.object().shape({
      accountName: yup
        .string()
        .required(t('common:validate.required'))
        .max(20, t('common:validate.maxAccountNameLength')),
    }),
  );
}

const errorsToFields = {
  name: 'accountName',
} as const;

export const EditAccountNameForm: React.FC = () => {
  const isMobile = useBankBreakpointValue({ base: true, sm: false });
  const close = useCloseEditAccountNameModal();
  const { payload: accountId } = useIsOpenEditAccountNameModal();
  const mutation = useEditAccountNameMutation();
  const [errorText, setErrorText] = useState('');
  const handleFormError = useHandleFormError<FormValue>();
  const { t } = useTranslation(['fields', 'common']);
  const methods = useForm({
    resolver: createResolver(t),
    mode: 'onChange',
  });

  const onSubmitHandle = methods.handleSubmit(
    async ({ accountName }: FormValue) => {
      try {
        if (accountName && accountId) {
          await mutation.mutateAsync({
            name: accountName,
            accountId,
          });
          close();
        }
      } catch (e) {
        const unknownFieldErrors = handleFormError(e, methods, errorsToFields);
        if (unknownFieldErrors.length)
          setErrorText(unknownFieldErrors.join(' '));
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <BankModalBody>
        <VStack
          id="editNameForm"
          alignItems="stretch"
          width="100%"
          mt={1}
          spacing={2}
          height="100%"
          as="form"
          onSubmit={onSubmitHandle}
        >
          <FloatingInputField
            name="accountName"
            label={t('fields:accountName')}
            autoComplete="off"
          />
          {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <HStack w="full" spacing={2}>
          {!isMobile && (
            <Button variant="secondary" w="100%" onClick={close}>
              {t('common:close')}
            </Button>
          )}
          <SubmitButton
            form="editNameForm"
            isLoading={mutation.isPending}
            isDisabled={!methods.formState.isValid}
          />
        </HStack>
      </BankModalFooter>
    </FormProvider>
  );
};

export const SubmitButton: React.FC<{ isLoading: boolean } | ButtonProps> = ({
  isLoading,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const { watch } = useFormContext();
  const accountName = watch('accountName');
  return (
    <Button
      isLoading={isLoading}
      type="submit"
      variant="primary"
      w="100%"
      disabled={!accountName}
      {...props}
    >
      {t('common:confirm')}
    </Button>
  );
};
