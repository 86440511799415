export const demo = {
  completeYourOnboardingProcess: 'Complete Your Onboarding Process',
  yourAccountIsCurrentlyNotActivated:
    "Your account is currently not activated. To unlock this functionality, kindly complete all the necessary steps, and we'll activate your account promptly",
  companyApplicationReceived: 'Company Application Received',
  companyApplicationReceivedDescription:
    "We've received your company application. Sit tight as we process your request to get you started on your journey with us.",
  kycVerificationSent:
    'KYC Verification Sent to All Indicated Shareholders / UBO / Directors',
  kycVerificationSentDescription:
    "We're taking the necessary steps to verify key individuals in your company. We've sent Know Your Customer (KYC) verification requests to all indicated shareholders, ultimate beneficial owners (UBO), and directors. Please ensure prompt responses to expedite the process.",
  onboardingFeePaid: 'Onboarding Fee Paid',
  onboardingFeePaidDescription:
    'To activate your account and enjoy our services, please complete the onboarding process by paying the required onboarding fee. Once paid, your account will be one step closer to activation.',
  yourAccountIsActivated: 'Your Account is Activated',
  yourAccountIsActivatedDescription:
    'Congratulations! Your account is now activated. You can start enjoying the benefits of our services and conducting business seamlessly with us.',
  pay: 'Pay',
} as const;
