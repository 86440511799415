import { css, Global } from '@emotion/react';
import React from 'react';

const GolosTextBlackWoof = 'Golos-Text/Golos-Text_Black.woff';
const GolosTextBlackWoof2 = 'Golos-Text/Golos-Text_Black.woff2';
const GolosTextBoldWoof = 'Golos-Text/Golos-Text_Bold.woff';
const GolosTextBoldWoof2 = 'Golos-Text/Golos-Text_Bold.woff2';
const GolosTextDemiBoldWoof = 'Golos-Text/Golos-Text_DemiBold.woff';
const GolosTextDemiBoldWoof2 = 'Golos-Text/Golos-Text_DemiBold.woff2';
const GolosTextMediumWoof = 'Golos-Text/Golos-Text_Medium.woff';
const GolosTextMediumWoof2 = 'Golos-Text/Golos-Text_Medium.woff2';
const GolosTextRegularWoof = 'Golos-Text/Golos-Text_Regular.woff';
const GolosTextRegularWoof2 = 'Golos-Text/Golos-Text_Regular.woff2';
const GolosTextVFWoof = 'Golos-Text/Golos-Text_VF.woff';
const GolosTextVFWoof2 = 'Golos-Text/Golos-Text_VF.woff2';
const GolosUIBoldWoof = 'Golos-UI/Golos-UI_Bold.woff';
const GolosUIBoldWoof2 = 'Golos-UI/Golos-UI_Bold.woff2';
const GolosUIMediumWoof = 'Golos-UI/Golos-UI_Medium.woff';
const GolosUIMediumWoof2 = 'Golos-UI/Golos-UI_Medium.woff2';
const GolosUIRegularWoof = 'Golos-UI/Golos-UI_Regular.woff';
const GolosUIRegularWoof2 = 'Golos-UI/Golos-UI_Regular.woff2';
const GolosUIVFWoof = 'Golos-UI/Golos-UI_VF.woff';
const GolosUIVFWoof2 = 'Golos-UI/Golos-UI_VF.woff2';

/**
 * hack against chrome autofill styles
 */
const chromeAutofillStylesHack = css`
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

/**
 * Путь до ассетов шрифтов, по умолчанию 'assets/fonts/'
 * @param prefix
 * @constructor
 */
export const GlobalFonts = ({
  prefix = 'assets/fonts/',
}: {
  prefix?: string;
}) => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
      @font-face {
        font-family: 'GolosTextWebBlack';
        src: url(${prefix}${GolosTextBlackWoof2}) format('woff2'),
          url(${prefix}${GolosTextBlackWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosTextWebBold';
        src: url(${prefix}${GolosTextBoldWoof2}) format('woff2'),
          url(${prefix}${GolosTextBoldWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosTextWebDemiBold';
        src: url(${prefix}${GolosTextDemiBoldWoof2}) format('woff2'),
          url(${prefix}${GolosTextDemiBoldWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosTextWebMedium';
        src: url(${prefix}${GolosTextMediumWoof2}) format('woff2'),
          url(${prefix}${GolosTextMediumWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosTextWebRegular';
        src: url(${prefix}${GolosTextRegularWoof2}) format('woff2'),
          url(${prefix}${GolosTextRegularWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosTextWebVF';
        src: url(${prefix}${GolosTextVFWoof2}) format('woff2'),
          url(${prefix}${GolosTextVFWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'GolosUIWebBold';
        src: url(${prefix}${GolosUIBoldWoof2}) format('woff2'),
          url(${prefix}${GolosUIBoldWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosUIWebMedium';
        src: url(${prefix}${GolosUIMediumWoof2}) format('woff2'),
          url(${prefix}${GolosUIMediumWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosUIWebRegular';
        src: url(${prefix}${GolosUIRegularWoof2}) format('woff2'),
          url(${prefix}${GolosUIRegularWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'GolosUIWebVF';
        src: url(${prefix}${GolosUIVFWoof2}) format('woff2'),
          url(${prefix}${GolosUIVFWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      ${chromeAutofillStylesHack};
    `}
  />
);
