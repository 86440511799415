import { Controller, useFormContext } from 'react-hook-form';
import ExchangeDropdown, {
  TExchangeDropdownProps,
} from '../ExchangeDropdown/ExchangeDropdown';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { RequiredStyledProvider } from '@payler/ui-components';

export type TExchangeDropdownFieldProps = Omit<
  TExchangeDropdownProps,
  'value' | 'onBlur'
> & {
  /**
   * Имя поля формы
   */
  fieldName: string;
  /**
   * Заголовок поля
   */
  label?: string;
};

export const ExchangeDropdownField = (props: TExchangeDropdownFieldProps) => {
  const { fieldName, label, onChange, ...dropdownProps } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={!!fieldState.error}>
            {label && <FormLabel as="legend">{label}</FormLabel>}
            <RequiredStyledProvider>
              <ExchangeDropdown
                {...dropdownProps}
                value={field.value}
                onChange={(v) => onChange?.(v ?? '') || field.onChange(v ?? '')}
                onBlur={field.onBlur}
              />
              {fieldState.error && (
                <FormHelperText
                  color="red.500"
                  mt={1}
                  textStyle={TextStyles.Caption12Regular}
                  px={1.5}
                >
                  {fieldState.error.message}
                </FormHelperText>
              )}
            </RequiredStyledProvider>
          </FormControl>
        );
      }}
    />
  );
};

export default ExchangeDropdownField;
