import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import createLogger from 'debug';

const log = createLogger('AnalyticsManager');

/**
 * Сервис отправки событий в различные системы аналитики
 *
 * Пример:
 * sendSomeEvent() {
 *  this.sendGoogleAnalyticsEvent('someEvent');
 *  this.sendGoogleTagManagerEvent({
 *    dataLayer: {
 *      event: 'someEvent',
 *    },
 *  });
 *  ...другие сервисы
 * }
 */
class AnalyticsManager {
  constructor() {
    if (process.env['NODE_ENV'] === 'production' && !createLogger.enabled('')) {
      const tagManagerArgs = {
        gtmId: 'GTM-MT34JBZ',
      };
      TagManager.initialize(tagManagerArgs);
      ReactGA.initialize('G-8HPB593CMV');
    }
  }

  private sendGoogleAnalyticsEvent(options: string | UaEventOptions) {
    ReactGA.event(options);
    log('sendGoogleAnalyticsEvent %o', options);
  }

  private sendGoogleTagManagerEvent(options: DataLayerArgs) {
    TagManager.dataLayer(options);
    log('sendGoogleTagManagerEvent %o', options);
  }

  sendSignUpEvent() {
    this.sendGoogleAnalyticsEvent('SignUp');
  }

  sendSignUpSuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('SignUp-successful-continue');
  }

  sendSignUpUnsuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('SignUp-unsuccessful-continue');
  }

  sendKeyloak2FaSuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('Keyloak-2FA-successful-continue');
  }

  sendKeyloak2FaUnsuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('Keyloak-2FA-unsuccessful-continue');
  }

  sendSignInSuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('SignIn-successful-continue');
  }

  sendSignInUnsuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('SignIn-unsuccessful-continue');
  }

  sendSuccessfulActivationEvent() {
    this.sendGoogleAnalyticsEvent('successful-activation');
  }

  sendExpiredActivationEvent() {
    this.sendGoogleAnalyticsEvent('expired-activation');
  }

  sendBriefSubmitEvent() {
    this.sendGoogleAnalyticsEvent('brief-submit');
  }

  sendDocumentSubmitEvent() {
    this.sendGoogleAnalyticsEvent('document-submit');
  }

  sendPrescoringSubmitEvent() {
    this.sendGoogleAnalyticsEvent('prescoring-submit');
  }

  sendPhoneNumberSuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('PhoneNumber-successful-continue');
  }

  sendPhoneNumberUnsuccessfulContinueEvent() {
    this.sendGoogleAnalyticsEvent('PhoneNumber-unsuccessful-continue');
  }

  sendPaymentClickEvent() {
    this.sendGoogleAnalyticsEvent('payment-click');
  }

  sendPaymentSuccessfulEvent() {
    this.sendGoogleAnalyticsEvent('payment-successful');
  }
}

export default AnalyticsManager;
