import { TransactionEvent } from '@sentry/types/types/event';

// Список полей для поиска и удаления из url
const keys = ['state', 'key', 'jwt', 'token'];
// Регулярное выражение на основе списка выше
const regExp = new RegExp(keys.join('|'));
/**
 * Функция для очистки search параметров от чувствительных данных
 * @param url - урл для очистки от чувствительных данных
 */
const clearUrl = (url: string) => {
  // Заглушка на тот случай, если url начинается с /, чтобы не падало new URL, выпиливается перед возвратом результата
  const basePath = 'https://emptyhost';
  const newUrl = new URL(url.replace('#', ''), basePath);
  const params = new URLSearchParams(newUrl.search);
  keys.forEach((k) => {
    params.delete(k);
  });
  newUrl.search = params.toString();
  return newUrl.toString().replace(basePath, '');
};
export const clearTransactionEvent = (event: TransactionEvent) => {
  let newEvent = { ...event };
  // Если есть URL, то чистим его
  if (newEvent?.request?.url && newEvent?.request?.url?.match(regExp)) {
    newEvent = {
      ...newEvent,
      request: { ...newEvent.request, url: clearUrl(newEvent?.request?.url) },
    };
  }
  // Если есть хлебные крошки, то смотрим в них, и удаляемся из URL чувствительные данные
  if (newEvent?.breadcrumbs) {
    newEvent.breadcrumbs = newEvent?.breadcrumbs?.map((b) => {
      if (b?.data?.from && b?.data?.to) {
        return {
          ...b,
          data: {
            ...b.data,
            from: clearUrl(b?.data?.from),
            to: clearUrl(b?.data?.to),
          },
        };
      }
      if (b?.data?.url) {
        return {
          ...b,
          data: {
            ...b.data,
            url: clearUrl(b?.data?.url),
          },
        };
      }
      return b;
    });
  }
  return newEvent;
};
