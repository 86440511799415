import { useCallback, useEffect, useState } from 'react';
import { Timer } from '../classes';
import { INTERVAL_IN_MILISECONDS } from '../const';

export const useCountdownTimer = (initialValue: number) => {
  const [timerInstance] = useState(new Timer());
  const [remainingTime, setRemainingTime] = useState(initialValue);
  const [isPaused, setIsPaused] = useState(false);

  const resetTimer = useCallback(() => {
    timerInstance.stop();
    timerInstance.start(initialValue);
  }, [initialValue, timerInstance]);

  const setPaused = useCallback(
    (isPause: boolean) => {
      if (isPause) {
        timerInstance.pause();
      } else {
        timerInstance.unpause();
      }
    },
    [timerInstance],
  );

  useEffect(() => {
    timerInstance.subscribe((timer) => {
      setRemainingTime(timer.getTime());
      setIsPaused(timer.isPaused());
    });
    timerInstance.start(initialValue);
    return () => {
      timerInstance.stop();
    };
  }, [initialValue, timerInstance]);

  useEffect(() => {
    if (remainingTime <= 0) timerInstance.stop();
  }, [remainingTime, timerInstance]);

  return {
    remainingTime: Math.round(remainingTime / INTERVAL_IN_MILISECONDS),
    isPaused,
    resetTimer,
    setPaused,
  };
};
