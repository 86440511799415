import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const InputStyles: ComponentStyleConfig = {
  baseStyle: {
    field: {
      transitionProperty: 'bg',
      transitionDuration: 'normal',
      textStyle: TextStyles.BodyUI16Regular,
      _placeholder: {
        color: 'primary.350',
      },
      color: 'primary.500',
      _invalid: {
        '&+.chakra-input__right-element': {
          color: 'red.300',
        },
      },
    },
    element: {
      width: '44px',
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: 1.5,
        height: 7,
        py: 2,
        paddingInlineStart: 2,
        paddingInlineEnd: 2,
      },
    },
  },
  variants: {
    floating: {
      element: {
        textStyle: TextStyles.Tagline14Medium,
        textAlign: 'center',
        color: 'primary.300',
      },
      field: {
        pb: 1,
        pt: 3,
        border: 'none',
        color: 'primary.500',
        boxShadow: 'input',
        bg: 'primary.25',
        _placeholder: {
          color: 'transparent',
        },
        _hover: {
          '& + label': {
            color: 'primary.400',
          },
          boxShadow: 'inputHover',
        },
        '&:not(:placeholder-shown) + label': {
          textStyle: TextStyles.Caption12Regular,
          top: 1,
          fontSize: '12px',
        },
        _focus: {
          color: 'primary.500',
          boxShadow: 'inputFocus',
          bg: 'brands.100',
          _invalid: {
            bg: 'red.100',
          },
          _placeholder: {
            color: 'primary.200',
          },
        },
        _invalid: {
          color: 'primary.500',
          boxShadow: `inputError`,
          bg: 'red.100',
          _focus: {
            borderColor: 'red.500',
            boxShadow: `inputError`,
          },
        },
        _disabled: {
          bg: 'primary.25',
          boxShadow: `input`,
          color: 'primary.350',
          '& + label': {
            color: 'primary.300',
          },
        },
        _readOnly: {
          border: 'none',
          color: 'primary.500',
          boxShadow: `input`,
          bg: 'primary.25',
        },
        '&:-webkit-autofill ~ label': {
          textStyle: TextStyles.Caption12Regular,
          top: 1,
          fontSize: '12px',
        },
      },
    },
    outline: {
      element: {
        textStyle: TextStyles.Tagline14Medium,
        textAlign: 'center',
        color: 'primary.300',
      },
      field: {
        border: 'none',
        color: 'primary.500',
        boxShadow: 'input',
        bg: 'primary.25',
        _placeholder: {
          color: 'primary.350',
        },
        _hover: {
          color: 'primary.400',
          boxShadow: 'inputHover',
        },
        _focus: {
          color: 'primary.500',
          boxShadow: 'inputFocus',
          bg: 'brands.100',
          _invalid: {
            bg: 'red.100',
          },
        },
        _invalid: {
          color: 'primary.500',
          boxShadow: `inputError`,
          bg: 'red.100',
          _focus: {
            borderColor: 'red.500',
            boxShadow: `inputError`,
          },
        },
        _disabled: {
          bg: 'transparent',
          boxShadow: `input`,
          color: 'primary.300',
        },
        _readOnly: {
          border: 'none',
          color: 'primary.500',
          boxShadow: `input`,
          bg: 'primary.25',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    focusBorderColor: 'brands.500',
    size: 'md',
  },
};
