import { isNilOrEmpty } from '@payler/bank-utils';

export const replaceIfNoData = (
  value: string | undefined | null,
  noDataContent = '-',
) => {
  if (isNilOrEmpty(value)) {
    return noDataContent;
  }
  return value as string;
};
