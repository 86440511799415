import { TParticipantAddressDto } from '@payler/api/client-office';

/** Преобразовать адрес participant'a в читаемый вид  */
export const useParticipantAddress = (
  addr: TParticipantAddressDto | undefined,
) => {
  if (!addr || !(addr.city || addr.country || addr.postalCode || addr.street)) {
    return '';
  }

  const calcAddress = [
    addr.street,
    `${addr.city} ${addr.postalCode}`,
    addr.country,
  ]
    .filter((k) => !!k)
    .join(', ');

  return calcAddress;
};
