import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const OnboardingProgressBlock = () => {
  return (
    <VStack
      bg="secondary.500"
      border="0.5px solid"
      borderColor="primary.100"
      borderRadius={1.5}
    >
      <OnboardingProgress />
    </VStack>
  );
};

export const OnboardingProgress: FC<{ isButton?: boolean }> = ({
  isButton = true,
}) => {
  const { t } = useTranslation(['demo', 'common']);
  const navigate = useNavigate();
  return (
    <VStack px={3} py={2}>
      <HStack spacing={5}>
        <VStack width="100%" alignItems="baseline">
          <Text textStyle={TextStyles.h4} color="primary.500">
            {t('demo:completeYourOnboardingProcess')}
          </Text>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.400">
            {t('demo:yourAccountIsCurrentlyNotActivated')}
          </Text>
        </VStack>
        {isButton && (
          <Button
            variant="primary"
            size="sm"
            bg="green.500"
            onClick={() => navigate('/')}
          >
            {t('common:details')}
          </Button>
        )}
      </HStack>
    </VStack>
  );
};
