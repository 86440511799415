import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const TabsStyles: ComponentStyleConfig = {
  variants: {
    brand: {
      tablist: {
        h: '54px',
        textStyle: TextStyles.BodyUI14Medium,
        color: 'primary.350',
        borderBottom: '1px solid',
        borderColor: 'inherit',
      },
      tab: {
        fontSize: 'sm',
        p: '1',
        borderBottom: '2px solid',
        borderColor: 'transparent',
        marginBottom: '-1px',
        _selected: {
          color: 'brands.500',
          borderColor: 'currentColor',
        },
        _active: {
          bgColor: 'primary.50',
        },
      },
    },
  },
  defaultProps: {
    variant: 'brand',
  },
};
