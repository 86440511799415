import React, { useEffect, useMemo } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { useColorMode } from '@chakra-ui/react';
import { GlobalFonts } from '@payler/ui-theme';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom';
import { DevPanel, ErrorBoundary } from '@payler/ui-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  ApiContext,
  AppState,
  ConfigContextProvider,
  ConfigProvider,
  LayoutContextProvider,
  LoaderBox,
  queryClient,
  useClientOfficeConfig,
  useConfigContext,
  usePermissions,
} from '@payler/ui/client-office';
import {
  PaymentLayout,
  SpushOnboardingPageLazy,
  SpushPaymentPageLazy,
  SpushRejectionPageLazy,
  getMainAppRoutes,
  theme,
} from '@payler/spush';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsManagerContextProvider,
  useAnalyticsManager,
} from '@payler/analytics';
import * as Sentry from '@sentry/react';
import { AuthProvider, useAuth } from '@payler/auth';
import {
  SpushApiClientOffice,
  SpushDemoApiClientOffice,
} from '@payler/api-spush';
import { TToken } from '@payler/api/client-office';
import { WhiteLabelThemeProvider } from '@payler/utils';

export const App: React.FC = () => {
  const { t } = useTranslation();
  const isDevtoolsShown = localStorage.getItem('showDevtools');

  return (
    <ConfigContextProvider path="assets/config">
      <WLWithPath>
        <GlobalFonts prefix="assets/fonts/" />
        <ErrorBoundary>
          <EnvComp>
            <AnalyticsManagerContextProvider>
              <AppApiWrapper>
                <AppState>
                  <QueryClientProvider client={queryClient}>
                    <HelmetProvider>
                      <Helmet title={t('menu:title')} />
                      <LayoutContextProvider>
                        <AppContent />
                        <DevPanel />
                      </LayoutContextProvider>
                    </HelmetProvider>
                    {isDevtoolsShown === 'true' && <ReactQueryDevtools />}
                  </QueryClientProvider>
                </AppState>
              </AppApiWrapper>
            </AnalyticsManagerContextProvider>
          </EnvComp>
        </ErrorBoundary>
      </WLWithPath>
    </ConfigContextProvider>
  );
};

const WLWithPath: FCC = ({ children }) => {
  const { environment, isLoading } = useConfigContext();
  if (isLoading) return null;
  return (
    <WhiteLabelThemeProvider path={environment.staticPath} theme={theme}>
      {children}
    </WhiteLabelThemeProvider>
  );
};

const EnvComp: FCC = ({ children }) => {
  const { environment, isLoading } = useConfigContext();
  if (isLoading) return <LoaderBox />;
  return (
    <ConfigProvider env={environment}>
      <AuthProvider config={environment.auth.config}>{children}</AuthProvider>
    </ConfigProvider>
  );
};

const AppApiWrapper: FCC = ({ children }) => {
  const { apiServer } = useClientOfficeConfig();
  const auth = useAuth<TToken>();
  const api = useMemo(() => {
    return auth.tokenParsed?.GlobalAccounts
      ? new SpushApiClientOffice(apiServer, auth)
      : new SpushDemoApiClientOffice(apiServer, auth);
  }, [apiServer, auth]);

  return <ApiContext api={api}>{children}</ApiContext>;
};

const DebugRouter = ({ children }: React.PropsWithChildren<unknown>) => {
  const location = useLocation();

  // alert(
  //   `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
  //     location.state,
  //   )}`,
  // );

  return children;
};

const AppContent = () => {
  const { isAuthLoading, isAuthenticated } = useAuth();
  const analyticsManager = useAnalyticsManager();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isDarkThemeAllowed } = useClientOfficeConfig();

  useEffect(() => {
    if (colorMode === 'dark' && !isDarkThemeAllowed) {
      toggleColorMode();
    }
  }, [colorMode, isDarkThemeAllowed, toggleColorMode]);

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated) {
      const prevPageId = localStorage.getItem('keycloakPage');
      if (prevPageId === 'login.ftl' || prevPageId === 'login-otp.ftl') {
        analyticsManager.sendSignInSuccessfulContinueEvent();
      } else if (
        prevPageId === 'login-config-totp.ftl' ||
        prevPageId === 'login-sms.ftl'
      ) {
        analyticsManager.sendKeyloak2FaSuccessfulContinueEvent();
      } else if (prevPageId === 'login-verify-email.ftl') {
        analyticsManager.sendSuccessfulActivationEvent();
      }
      localStorage.removeItem('keycloakPage');
    }
  }, [analyticsManager, isAuthLoading, isAuthenticated]);

  if (isAuthLoading || !isAuthenticated) return <LoaderBox />;

  return (
    <BrowserRouter>
      <DebugRouter>
        <AppRoutes />
      </DebugRouter>
    </BrowserRouter>
  );
};

const AppRoutes = () => {
  const { isGlobalAccounts } = usePermissions();
  return (
    <Routes>
      {isGlobalAccounts ? getMainAppRoutes() : SpushOnboardingRoutes()}
      <Route path="/purchase" element={<PaymentLayout />}>
        <Route path=":id" element={<SpushPaymentPageLazy />} />
      </Route>
    </Routes>
  );
};

const SpushOnboardingRoutes = () => {
  return (
    <>
      <Route path="/" element={<SpushOnboardingPageLazy />} />
      <Route path="/rejection" element={<SpushRejectionPageLazy />} />
    </>
  );
};

export default Sentry.withProfiler(App);
