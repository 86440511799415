import { Box } from '@chakra-ui/react';
import { TDocumentDto } from '@payler/api/client-office';
import { DocumentLink } from './DocumentLink';

export const DocumentsWrapper = ({
  documents,
  isClickable,
}: {
  documents?: TDocumentDto[];
  isClickable?: boolean;
}) => {
  if (!documents?.length) return null;

  return documents.length > 1 ? (
    <Box display="flex" flexWrap="wrap" gap={0.5}>
      {documents.map((doc) => (
        <DocumentLink
          key={doc.name}
          documentName={doc.name}
          isClickable={isClickable}
        />
      ))}
    </Box>
  ) : (
    <DocumentLink documentName={documents[0]?.name} isClickable={isClickable} />
  );
};
