import { TDropdownOption } from './types';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import * as React from 'react';
import { useCallback } from 'react';
import { CheckedIcon, UncheckedIcon } from '@payler/ui-icons';

type TDropdownOptionProps = {
  option: TDropdownOption;
  selected?: boolean;
  onClick: (val: string | number) => void | VoidFunction;
  multi?: boolean;
  alignCenter?: boolean;
  wrapperBgColor?: string;
  wrapperHoverColor?: string;
  testId?: string;
};

export const DropdownOption = ({
  option,
  selected,
  onClick,
  multi,
  alignCenter,
  wrapperBgColor,
  wrapperHoverColor,
  testId,
}: TDropdownOptionProps) => {
  const handleClick = useCallback(() => {
    if (!option.disabled) {
      onClick(option.value);
    }
  }, [option, onClick]);

  const bgColor = wrapperBgColor || 'secondary.500';
  const textColor = option.disabled
    ? 'primary.100'
    : selected
    ? 'primary.500'
    : 'primary.350';
  const hoverColor = option.disabled ? 'primary.100' : 'primary.500';
  const hoverBgColor =
    wrapperHoverColor || option.disabled ? 'secondary.500' : 'primary.25';
  const cursor = option.disabled ? 'not-allowed' : 'pointer';

  return (
    <HStack
      h={7}
      spacing={1}
      px={2}
      cursor={cursor}
      onClick={handleClick}
      bg={bgColor}
      color={textColor}
      _hover={{
        color: hoverColor,
        bg: hoverBgColor,
      }}
      transitionDuration="fast"
      transitionProperty="color, background"
      data-testid={testId}
    >
      {option.icon && <Icon as={option.icon} w={3} h={3} />}
      <Text
        flex={1}
        textStyle={TextStyles.BodyUI16Regular}
        minW={0}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        textAlign={alignCenter ? 'center' : undefined}
      >
        {option.label}
      </Text>
      {multi && (
        <Icon
          as={selected ? CheckedIcon : UncheckedIcon}
          sx={{
            '.brand': {
              fill: 'brands.500',
            },
          }}
          color={
            selected
              ? undefined
              : option.disabled
              ? 'primary.100'
              : 'primary.300'
          }
        />
      )}
    </HStack>
  );
};
