import { TOnboardingStageDto } from '@payler/api/client-office';
import { useCallback, useMemo } from 'react';

export const useOnboardingStages = () => {
  const getCurrentStage = useCallback((stages: TOnboardingStageDto[]) => {
    return stages.find(({ status }) => status === 'inProgress');
  }, []);

  /**
   * Проверка доступа к демо кабинету. Если текущий шаг обязателен, то доступа нет. У эквайринга
   * текущий шаг обязателен всегда.
   */
  const checkDemoAccess = useCallback(
    (stages: TOnboardingStageDto[]) => {
      const currentStage = getCurrentStage(stages);
      return (
        !!currentStage &&
        currentStage.type !== 'companyApplication' &&
        currentStage.type !== 'kycVerification' &&
        !currentStage.isRequired
      );
    },
    [getCurrentStage],
  );

  return useMemo(
    () => ({
      getCurrentStage,
      checkDemoAccess,
    }),
    [checkDemoAccess, getCurrentStage],
  );
};
