import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const TextareaStyles: ComponentStyleConfig = {
  baseStyle: {
    transitionProperty: 'bg',
    transitionDuration: 'normal',
    textStyle: TextStyles.BodyUI16Regular,
    _placeholder: {
      color: 'primary.350',
    },
    color: 'primary.500',
    _invalid: {
      '&+.chakra-input__right-element': {
        color: 'red.300',
      },
    },
  },
  sizes: {
    md: {
      borderRadius: 1.5,
      height: 7,
      py: 2,
      paddingInlineStart: 2,
      paddingInlineEnd: 2,
    },
  },
  variants: {
    floating: {
      pb: 1,
      pt: 3,
      border: 'none',
      color: 'primary.500',
      boxShadow: 'input',
      bg: 'primary.25',
      _placeholder: {
        color: 'transparent',
      },
      _hover: {
        border: 'none',
        '& + label': {
          color: 'primary.400',
        },
        boxShadow: 'inputHover',
      },
      '&:not(:placeholder-shown) + label': {
        textStyle: TextStyles.Caption12Regular,
        top: 1,
        fontSize: '12px',
      },
      _focus: {
        border: 'none',
        color: 'primary.500',
        boxShadow: 'inputFocus',
        bg: 'brands.100',
        _invalid: {
          bg: 'red.100',
        },
        _placeholder: {
          color: 'primary.200',
        },
      },
      _invalid: {
        border: 'none',
        color: 'primary.500',
        boxShadow: `inputError`,
        bg: 'red.100',
        _focus: {
          borderColor: 'red.500',
          boxShadow: `inputError`,
        },
      },
      _disabled: {
        border: 'none',
        bg: 'primary.25',
        boxShadow: `input`,
        color: 'primary.350',
        '& + label': {
          color: 'primary.300',
        },
      },
      _readOnly: {
        border: 'none',
        color: 'primary.500',
        boxShadow: `input`,
        bg: 'primary.25',
      },
      '&:-webkit-autofill ~ label': {
        textStyle: TextStyles.Caption12Regular,
        top: 1,
        fontSize: '12px',
      },
    },
    outline: {
      border: 'none',
      color: 'primary.500',
      boxShadow: 'input',
      bg: 'primary.25',
      _placeholder: {
        color: 'primary.350',
      },
      _hover: {
        color: 'primary.400',
        boxShadow: 'inputHover',
      },
      _focus: {
        color: 'primary.500',
        boxShadow: 'inputFocus',
        bg: 'brands.100',
        _invalid: {
          bg: 'red.100',
        },
      },
      _invalid: {
        color: 'primary.500',
        boxShadow: `inputError`,
        bg: 'red.100',
        _focus: {
          borderColor: 'red.500',
          boxShadow: `inputError`,
        },
      },
      _disabled: {
        bg: 'transparent',
        boxShadow: `input`,
        color: 'primary.300',
      },
      _readOnly: {
        border: 'none',
        color: 'primary.500',
        boxShadow: `input`,
        bg: 'primary.25',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    focusBorderColor: 'brands.500',
    size: 'md',
  },
};
