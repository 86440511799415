import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const usePagerUrlCallbacks = () => {
  const [params, setParams] = useSearchParams();

  const goToPage = useCallback(
    (page: number) => {
      const num = page;
      if (num === 1) {
        params.delete('Page');
      } else {
        params.set('Page', num.toString());
      }
      setParams(params);
    },
    [params, setParams],
  );
  const setPageSize = useCallback(
    (pageSize: number) => {
      if (pageSize === 10) {
        // 10 is default value
        params.delete('PageSize');
      } else {
        params.set('PageSize', pageSize.toString());
      }
      params.delete('Page');
      setParams(params);
    },
    [params, setParams],
  );
  return useMemo(() => ({ goToPage, setPageSize }), [goToPage, setPageSize]);
};
