import { Text } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { OnboardingWrapper } from './OnboardingWrapper';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  useOnboardingStagesQuery,
  useOnboardingStageStepQuery,
} from '../../hooks/onboarding/queries';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useOnboardingStages } from '../../hooks/onboarding/use-onboarding-stages';

export const CreatingAccountPage: FC = () => {
  const { t } = useTranslation(['onboarding']);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { checkDemoAccess } = useOnboardingStages();
  const { data: kycVerificationStage } = useOnboardingStageStepQuery(
    'kycVerification',
    3000,
  );
  const { data: stages, refetch } = useOnboardingStagesQuery({
    isRefetchingAllowed: false,
    refetchInterval: 12000,
  });

  /* Если у клиента есть доступ к демо банка, то он после создания аккаунта перенаправляется в
  демо кабинет. Если у него только эквайринг, то сразу переходит на страницу оплаты */
  useEffect(() => {
    if (kycVerificationStage?.status === 'completed') {
      refetch().then(({ data }) => {
        if (data && checkDemoAccess(data.stages)) {
          navigate('/');
        } else {
          queryClient.invalidateQueries({ queryKey: ['onboarding'] });
          navigate('/onboarding');
        }
      });
    }
  }, [
    checkDemoAccess,
    kycVerificationStage?.status,
    navigate,
    queryClient,
    refetch,
  ]);

  /* Костыль чтобы при выборе запрещенной страны на последней форме выкидывало на rejection page */
  useEffect(() => {
    if (stages?.result === 'rejected') {
      navigate('/onboarding/rejection');
    }
  }, [navigate, stages?.result]);

  return (
    <OnboardingWrapper>
      <LoaderBox />
      <Text as="h1" textStyle={TextStyles.h1}>
        {t('onboarding:creatingAccountPage.title')}
      </Text>
      <Text textStyle={TextStyles.BodyText16Medium} color="primary.400">
        {t('onboarding:creatingAccountPage.subtitle')}
      </Text>
    </OnboardingWrapper>
  );
};
