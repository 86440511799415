import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BankModal,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { EditAccountNameForm } from '../../forms/EditAccountName/EditAccountNameForm';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import {
  useRecoilBooleanWithPayload,
  useRecoilBooleanWithPayloadValue,
} from '@payler/bank-utils';

const KEY = 'modals:EditAccountNameModal';
export const useShowEditAccountNameModal = () =>
  useRecoilBooleanWithPayload<string>(KEY).on;
export const useCloseEditAccountNameModal = () =>
  useRecoilBooleanWithPayload<string>(KEY).off;
export const useIsOpenEditAccountNameModal = () =>
  useRecoilBooleanWithPayloadValue<string>(KEY);

export const EditAccountNameModal: React.FC = () => {
  const { t } = useTranslation(['accounts']);
  const { isOpen } = useIsOpenEditAccountNameModal();
  const close = useCloseEditAccountNameModal();
  const isMobile = useBankBreakpointValue({ base: true, sm: false });

  return (
    <BankModal close={close} open={isOpen} isCentered={true}>
      {isMobile && <ModalHeaderActions />}
      <BankModalTitle title={t('accounts:accountEditName.title')} />
      <EditAccountNameForm />
    </BankModal>
  );
};
