/**
 * https://chakra-ui.com/docs/styled-system/features/text-and-layer-styles#text-styles
 */

/**
 * Стили текста (шорткат - название в фигме)
 */
export enum TextStyles {
  'h1' = 'H1',
  'h2' = 'H2',
  'h3' = 'H3',
  'h4' = 'H4',
  BodyUI14Semibold = 'Body UI / 14 - Semibold',
  BodyUI14Medium = 'Body UI / 14 - Medium',
  BodyUI14Regular = 'Body UI / 14 - Regular',
  BodyUI16Semibold = 'Body UI / 16 - Semibold',
  BodyUI16Medium = 'Body UI / 16 - Medium',
  BodyUI16Regular = 'Body UI / 16 - Regular',
  BodyText16Regular = 'Body text / 16 - Regular',
  BodyText16Semibold = 'Body text / 16 - Semibold',
  BodyText16Medium = 'Body text / 16 - Medium',
  BodyText14Medium = 'Body text / 14 - Medium',
  Subtitle14Semibold = 'Subtitle / 14 - Semibold',
  Subtitle14Medium = 'Subtitle / 14 - Medium',
  Subtitle14Regular = 'Subtitle / 14 - Regular',
  Caption12Semibold = 'Caption / 12 - Semibold',
  Caption12Medium = 'Caption / 12 - Medium',
  Caption12Regular = 'Caption / 12 - Regular',
  Tagline14Medium = 'Tagline / 14 - Medium',
  Tagline10Bold = 'Tagline / 10 - Bold',
  Buttons16Medium = 'Buttons / 16 - Medium',
  Buttons12Small = 'Buttons / 12 - Small',
  Buttons12Medium = 'Buttons / 12 - Medium',
  code = 'Additional / Code',
  exchange = 'Additional / Exchange',
  tables = 'Additional / Tables',
  link = 'Additional / Link',
}

export const textStyles = {
  [TextStyles.h1]: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'DemiBold',
  },
  [TextStyles.h2]: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'DemiBold',
  },
  [TextStyles.h3]: {
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'DemiBold',
  },
  [TextStyles.h4]: {
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'DemiBold',
  },
  [TextStyles.BodyUI14Semibold]: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.08px',
    fontWeight: 700,
    fontFamily: 'UIRegular',
  },
  [TextStyles.BodyUI14Medium]: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.08px',
    fontWeight: 500,
    fontFamily: 'UIMedium',
  },
  [TextStyles.BodyUI14Regular]: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: 'UIRegular',
  },
  [TextStyles.BodyUI16Semibold]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 700,
    fontFamily: 'UIRegular',
  },
  [TextStyles.BodyUI16Medium]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: 'UIMedium',
  },
  [TextStyles.BodyUI16Regular]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: 'UIRegular',
  },
  [TextStyles.BodyText16Medium]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: 'Medium',
  },
  [TextStyles.BodyText14Medium]: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '1px',
    fontWeight: 400,
    fontFamily: 'Medium',
  },
  [TextStyles.BodyText16Regular]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: 'Regular',
  },
  [TextStyles.BodyText16Semibold]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 600,
    fontFamily: 'DemiBold',
  },
  [TextStyles.Subtitle14Semibold]: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 700,
    fontFamily: 'DemiBold',
  },
  [TextStyles.Subtitle14Medium]: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: 'Medium',
  },
  [TextStyles.Subtitle14Regular]: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: 'Regular',
  },
  [TextStyles.Caption12Semibold]: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 700,
    fontFamily: 'UIRegular',
  },
  [TextStyles.Caption12Medium]: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 500,
    fontFamily: 'UIMedium',
  },
  [TextStyles.Caption12Regular]: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 400,
    fontFamily: 'UIRegular',
  },
  [TextStyles.Tagline14Medium]: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '1px',
    fontWeight: 400,
    fontFamily: 'UIMedium',
  },
  [TextStyles.Tagline10Bold]: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.8px',
    fontWeight: 700,
    fontFamily: 'UIBold',
  },
  [TextStyles.Buttons16Medium]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 500,
    fontFamily: 'UIMedium',
  },
  [TextStyles.Buttons12Small]: {
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: 0,
    fontWeight: 400,
    fontFamily: 'UIMedium',
  },
  [TextStyles.Buttons12Medium]: {
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: 0,
    fontWeight: 500,
    fontFamily: 'UIMedium',
  },
  [TextStyles.code]: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.16px',
    fontWeight: 400,
    fontFamily: 'Code',
  },
  [TextStyles.exchange]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 400,
    fontFamily: 'UIRegular',
  },
  [TextStyles.tables]: {
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: 'UIRegular',
  },
  [TextStyles.link]: {
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: 'UIRegular',
  },
};
