import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { chakra, HStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const WeekDays = ({
  offset,
  locale,
}: {
  locale: string;
  offset: number;
}) => {
  const days = useMemo(() => {
    let date = dayjs().add(offset, 'month').locale(locale).startOf('w');
    const end = dayjs().add(offset, 'month').locale(locale).endOf('w');
    const res = [];
    while (date.isBefore(end) || date.isSame(end, 'w')) {
      res.push(date);
      date = date.add(1, 'day');
    }
    return res;
  }, [locale, offset]);
  return (
    <HStack justifyContent="center" spacing={0}>
      {days.map((d, i) => {
        const day = d.format('dd');
        const text = day.charAt(0).toUpperCase() + day.slice(1);
        return (
          <chakra.div
            key={i}
            flex={1}
            h={5}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <chakra.div
              w={5}
              h={5}
              textAlign="center"
              textStyle={TextStyles.BodyUI16Regular}
              lineHeight="40px"
              color="primary.400"
            >
              {text}
            </chakra.div>
          </chakra.div>
        );
      })}
    </HStack>
  );
};
