import { DateInputProps } from './types';
import { Stack, StackProps } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import DateField from './DateField';
import createLogger from 'debug';
import { getDayjsOrNull } from '@payler/utils';

const log = createLogger('components:DatesFields');

export type DatesFieldsProps = Pick<
  DateInputProps,
  'dateFormat' | 'minDate' | 'maxDate' | 'floating'
> & {
  /**
   * Название полей формы
   */
  fieldNames: [string, string];
  /**
   * Ключи локализации для заголовков полей
   */
  labelsKeys?: [string, string];

  /**
   * Стили Stack с input-ами
   */
  stackProps?: StackProps;
};

const DEFAULT_FORMAT = 'DD.MM.YYYY';

export const DatesFields = (props: DatesFieldsProps) => {
  const { watch, trigger } = useFormContext();
  const {
    fieldNames,
    stackProps,
    labelsKeys = ['datePicker.from', 'datePicker.to'],
    dateFormat = DEFAULT_FORMAT,
    minDate,
    maxDate,
    floating,
  } = props;
  const [fromField, toField] = fieldNames;

  const [fromString, toString] = watch([fromField, toField]);

  const fromValue = useMemo(
    () => getDayjsOrNull(fromString, dateFormat),
    [dateFormat, fromString]
  );
  const toValue = useMemo(
    () => getDayjsOrNull(toString, dateFormat),
    [dateFormat, toString]
  );

  const fromMinDate = minDate;
  const fromMaxDate = useMemo(() => {
    if (toValue && maxDate) {
      return toValue.isBefore(maxDate, 'date') ? toValue : maxDate;
    }
    if (toValue) {
      return toValue;
    }
    if (maxDate) {
      return maxDate;
    }
    return undefined;
  }, [maxDate, toValue]);

  const toMaxDate = maxDate;
  const toMinDate = useMemo(() => {
    if (fromValue && minDate) {
      return fromValue.isAfter(minDate, 'date') ? fromValue : minDate;
    }
    if (fromValue) {
      return fromValue;
    }
    if (minDate) {
      return minDate;
    }
    return undefined;
  }, [fromValue, minDate]);
  const handleClear = useCallback(() => {
    trigger(fieldNames);
  }, [fieldNames, trigger]);

  log(
    'from: %O;  to: %O',
    {
      minDate: fromMinDate?.format(dateFormat),
      maxDate: fromMaxDate?.format(dateFormat),
    },
    {
      minDate: toMinDate?.format(dateFormat),
      maxDate: toMaxDate?.format(dateFormat),
    }
  );

  return (
    <Stack direction="row" spacing={2} {...stackProps}>
      <DateField
        labelKey={labelsKeys[0]}
        fieldName={fromField}
        dateFormat={dateFormat}
        floating={props.floating}
        minDate={fromMinDate}
        maxDate={fromMaxDate}
        onClear={handleClear}
      />
      <DateField
        labelKey={labelsKeys[1]}
        fieldName={toField}
        dateFormat={dateFormat}
        floating={floating}
        minDate={toMinDate}
        maxDate={toMaxDate}
        onClear={handleClear}
      />
    </Stack>
  );
};
