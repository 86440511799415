export const fields = {
  dateFrom: 'Date from',
  dateTo: 'Date to',
  accountName: 'Account name',
  currency: 'Currency',
  account: 'Account',
  type: 'Type',
  system: 'System',
  status: 'Status',
  amountFrom: 'Amount from',
  amountTo: 'Amount to',
} as const;
