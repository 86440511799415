import { useCallback } from 'react';
import { useGetAxiosError } from './use-get-axios-error';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { get } from 'lodash';
import { useHandleToastError } from './use-handle-toast-error';

export const useHandleFormError = <T extends FieldValues>() => {
  const getError = useGetAxiosError<T>();
  const handleToastError = useHandleToastError();

  return useCallback(
    (
      error: unknown,
      methods: UseFormReturn<T>,
      fieldsMap?: Record<string, FieldPath<T>>,
    ) => {
      const { fieldErrors } = getError(error);
      const unknownFieldErrors: string[] = [];
      const fields = methods.getValues();

      if (fieldErrors) {
        fieldErrors.forEach(({ fieldName, errorMessage }) => {
          const mappedFieldName = fieldsMap?.[fieldName];
          if (mappedFieldName) {
            methods.setError(mappedFieldName, {
              message: errorMessage,
            });
          } else if (get(fields, fieldName) !== undefined) {
            methods.setError(fieldName, {
              message: errorMessage,
            });
          } else {
            unknownFieldErrors.push(errorMessage);
          }
        });
      } else {
        handleToastError(error);
      }

      return unknownFieldErrors;
    },
    [getError, handleToastError],
  );
};
