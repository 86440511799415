import { chakra, StackProps, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ThinDivider } from '../Divider/ThinDivider';

export const Card = (props: StackProps) => {
  return (
    <VStack
      spacing={0}
      alignItems="stretch"
      divider={<ThinDivider />}
      border="0.5px solid"
      borderColor="primary.100"
      borderRadius={{ base: 0, sm: 1.5 }}
      bg="secondary.500"
      {...props}
    />
  );
};

export const CardHead = chakra('div', {
  baseStyle: {
    px: 3,
    py: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const CardTitle = chakra('h4', {
  baseStyle: {
    textStyle: TextStyles.h4,
    color: 'primary.500',
  },
});

export const CardContent = chakra('div', {
  baseStyle: {
    px: 3,
    py: 2,
  },
});
