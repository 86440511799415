import * as React from 'react';
import { forwardRef, MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { Box, Flex, Icon, Text, TextProps } from '@chakra-ui/react';
import { FloatingLabel } from './FloatingLabel';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { ClearButton } from '../PaylerDatepicker/fields/ClearButton';
import { TDropdownOption } from './types';
import { TriggerWrapper } from './TriggerWrapper';
import { TDropdownTriggerProps } from './DropdownTrigger';

export type TMultiDropdownTriggerProps = {
  floatingLabel?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedOptions: TDropdownOption[];
  onClear?: VoidFunction;
  noEllipsis?: boolean;
  hideClearButton?: boolean;
  placeholder?: string;
  selectAllLabel?: string;
  valueText?: string;
  showSelectAllLabelAsValue?: boolean;
  isSearchable?: boolean;
  searchField?: ReactNode;
  isFocused?: boolean;
  onOptionClick: (val: string | number) => void;
};

export const MultiDropdownTrigger = forwardRef<
  HTMLDivElement,
  TMultiDropdownTriggerProps
>((props, ref) => {
  const borderColor = getBorderColor(!!props.isActive, !!props.isError);

  const bg = getBgColor(props);

  const hasValue =
    props.searchField && props.isSearchable
      ? props.selectedOptions.length > 0 ||
        !!props.isFocused ||
        !!props.isActive
      : props.selectedOptions.length > 0;

  const textColor = props.isDisabled ? 'primary.300' : 'primary.500';
  const chevronColor = props.isActive ? 'primary.500' : 'primary.300';
  const chevronTransform = props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)';
  const cursor = props.isDisabled ? 'not-allowed' : 'pointer';

  const showClearButton =
    !props.hideClearButton && hasValue && !props.isDisabled;

  const handleClear = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      props.onClear?.();
    },
    [props],
  );

  const getEllipsisProps = useCallback((truncate: boolean): TextProps => {
    if (!truncate) return {};
    return {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };
  }, []);

  const selectedOptionsTextOrPlaceholder = useMemo(() => {
    if (props.valueText) return props.valueText;
    if (props.selectedOptions.length)
      return props.selectedOptions.map((item: TDropdownOption) => (
        <Box
          key={item.value}
          display="inline-flex"
          alignItems="center"
          mr={1}
          mb={0.5}
          pl={1}
          py={0.5}
          backgroundColor="brands.100"
          borderRadius={0.5}
        >
          <Text
            {...getEllipsisProps(!props.noEllipsis)}
            color={textColor}
            transitionDuration="fast"
            textStyle={TextStyles.BodyUI16Regular}
          >
            {item.label}
          </Text>
          <ClearButton
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              props.onOptionClick(item.value);
            }}
            mx={0.5}
          />
        </Box>
      ));
    if (props.placeholder) return props.placeholder;
    return undefined;
  }, [
    props.placeholder,
    props.selectedOptions,
    props.valueText,
    props.noEllipsis,
    props.onOptionClick,
    textColor,
    getEllipsisProps,
  ]);

  const valuePadding =
    (hasValue || selectedOptionsTextOrPlaceholder) && props.floatingLabel
      ? '24px 0 8px'
      : '16px 0';

  return (
    <TriggerWrapper ref={ref} cursor={cursor} bg={bg} borderColor={borderColor}>
      {props.floatingLabel && (
        <FloatingLabel
          label={props.floatingLabel}
          hasValue={hasValue || !!selectedOptionsTextOrPlaceholder}
          isDisabled={props.isDisabled}
        />
      )}
      <Box minW={0} flex={1} display="flex" flexWrap="wrap">
        <Flex
          padding={valuePadding}
          transitionDuration="fast"
          flexWrap="wrap"
          w="100%"
        >
          {selectedOptionsTextOrPlaceholder && selectedOptionsTextOrPlaceholder}
          {props.isSearchable && props.searchField && props.searchField}
        </Flex>
      </Box>
      {showClearButton && (
        <ClearButton
          onClick={handleClear}
          mx={1}
          data-testid="button-clear_all"
        />
      )}
      <Icon
        as={ChevronDownIcon}
        transitionDuration="fast"
        color={chevronColor}
        transform={chevronTransform}
      />
    </TriggerWrapper>
  );
});

function getBgColor(props: TDropdownTriggerProps) {
  if (props.isError) {
    return 'red.100';
  }
  return props.isActive ? 'brands.100' : 'primary.25';
}

function getBorderColor(isActive: boolean, isError: boolean) {
  if (isError) return 'red.500';
  return isActive ? 'brands.500' : 'primary.100';
}
