import { i18nResources as paylerI18n } from '@payler/ui/client-office';
import { onboarding } from './namespaces/onboarding';
import { menu } from './namespaces/menu';
import { payment } from './namespaces/payment';
import { mergeDeep } from '@payler/utils';
import { accounts } from './namespaces/accounts';
import { recipients } from './namespaces/recipients';

const translationEn = mergeDeep(paylerI18n.en, {
  onboarding,
  menu,
  payment,
  accounts,
  recipients
});

export default translationEn;

export type TBankTranslation = typeof translationEn;
