import { TTransferStateDto, TTransferType } from '@payler/api/client-office';
import {
  BackArrowCircledIcon,
  DoubleArrowCircledIcon,
  ExchangeCircledIcon,
  Failed18Icon,
  ForwardArrowCircledIcon,
  Pending18Icon,
} from '@payler/ui-icons';
import { DepositIcon } from '../../icons';
import { Flex, Icon } from '@chakra-ui/react';
import { useMemo } from 'react';

export const TransferIconWithStatus: React.FC<{
  status?: TTransferStateDto;
  type?: TTransferType;
  isSelfTransfer?: boolean;
}> = ({ status, type, isSelfTransfer }) => {
  const statusIcon = useMemo(() => {
    switch (status) {
      case 'failed':
      case 'technicalProblem':
        return Failed18Icon;
      case 'processing':
        return Pending18Icon;
      case 'completed':
        return DepositIcon;
      default:
        return undefined;
    }
  }, [status]);

  const typeIcon = useMemo(() => {
    if (isSelfTransfer) return DoubleArrowCircledIcon;
    switch (type) {
      case 'incoming':
        return BackArrowCircledIcon;
      case 'outgoing':
        return ForwardArrowCircledIcon;
      case 'fx':
        return ExchangeCircledIcon;
      default:
        return undefined;
    }
  }, [isSelfTransfer, type]);

  return (
    <Flex alignItems="baseline" position="relative">
      <Icon as={typeIcon} color="brands.500" width="40px" height="40px" />
      <Icon
        as={statusIcon}
        width="18px"
        height="18px"
        position="absolute"
        right="-2px"
        bottom="-2px"
      />
    </Flex>
  );
};
