import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { getSize } from './QRCode.styles';
import { Icon, useColorMode, chakra } from '@chakra-ui/react';
import { ReactComponent } from '*.svg';

type TQRCodeSizePreset = 'default' | 'small' | 'medium' | 'large' | 'xl';

export interface QRCodeProps {
  value: string;
  size?: TQRCodeSizePreset | number;
  className?: string;
  icon?: typeof ReactComponent;
}

const pxImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

const imageSettingsWhiteHole = (size: number): QRCodeImageSettings => ({
  width: (30 / 144) * size,
  height: (30 / 144) * size,
  excavate: false,
  src: pxImg,
});

export const QRCode: React.FC<QRCodeProps> = ({
  value,
  size,
  className,
  icon,
}) => {
  const px =
    typeof size === 'number'
      ? size
      : getSize(size as TQRCodeSizePreset).numberSize;
  const { colorMode } = useColorMode();
  const fgColor = colorMode === 'dark' ? 'white' : 'black';
  const bgColor = colorMode === 'dark' ? 'black' : 'white';

  const imgSettings = icon ? imageSettingsWhiteHole(px) : undefined;

  return (
    <chakra.div className={className} css={{ position: 'relative' }}>
      <QRCodeCanvas
        level="L"
        value={value}
        size={px}
        includeMargin={true}
        bgColor={bgColor}
        fgColor={fgColor}
        imageSettings={imgSettings}
      />
      {icon && (
        <Icon
          as={icon}
          w="17%"
          h="17%"
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
        />
      )}
    </chakra.div>
  );
};
QRCode.displayName = 'QRCode';
QRCode.defaultProps = {
  size: 'default',
};

export default QRCode;

type QRCodeImageSettings = {
  src: string;
  height: number;
  width: number;
  excavate: boolean;
  x?: number;
  y?: number;
};
