import { createStylesContext } from '@chakra-ui/react';
import React from 'react';

const [StylesProvider] = createStylesContext('RequiredStyledProvider');

export const RequiredStyledProvider: FCC = ({ children }) => {
  return (
    <StylesProvider
      value={{
        requiredIndicator: { color: 'red.400', ml: 0.5 },
      }}
    >
      {children}
    </StylesProvider>
  );
};
