import { TPagedParams, DEFAULT_SORT } from '@payler/bank-utils';
import { PaginationProps } from '@payler/ui-components';
import {
  useQuery,
  useQueryClient,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { usePagerPropsFromUrl } from '../../helpers/use-pager-props-from-url';
import { useApi } from '../../state/api';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { TRecipientsListQueryParams } from '@payler/api/client-office';

const STALE_TIME = 60_000;

const RECIPIENT_ID = 'recipientId';
const INFINITE_QUERY_PAGE_SIZE = 20;

export const useSetRecipientIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (userId?: string) => {
      userId ? params.set(RECIPIENT_ID, userId) : params.delete(RECIPIENT_ID);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useRecipientIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(RECIPIENT_ID);
};

export const useRecipientsQuery = () => {
  const api = useApi();
  const pagination = usePagerPropsFromUrl();

  return useQuery({
    queryKey: ['recipients', 'list', pagination],
    queryFn: async () =>
      await api.getRecipients({
        ...pagination,
        ...DEFAULT_SORT,
      }),
    staleTime: STALE_TIME,
  });
};

export const usePrefetchRecipientsQuery = (totalPages?: number) => {
  const pagination = usePagerPropsFromUrl();
  const api = useApi();
  const queryClient = useQueryClient();
  const nextPage = pagination.PageNumber + 1;
  if (totalPages && nextPage > totalPages) {
    return;
  }

  const prefetchPagination = {
    ...pagination,
    PageNumber: nextPage,
  };

  return queryClient.prefetchQuery({
    queryKey: ['recipients', 'list', prefetchPagination],
    queryFn: async () => await api.getRecipients(prefetchPagination),
    staleTime: STALE_TIME,
  });
};

export const useTransferRecipientsInfiniteQuery = ({
  Currency,
  RecipientName,
}: TRecipientsListQueryParams) => {
  const api = useApi();

  return useSuspenseInfiniteQuery({
    queryKey: ['recipients', 'transfer', 'list', Currency, RecipientName],
    queryFn: async ({ pageParam }) => {
      const pageParams: TPagedParams = {
        PageSize: INFINITE_QUERY_PAGE_SIZE,
        PageNumber: pageParam,
      };

      return await api.getRecipients({
        Currency,
        RecipientName,
        ...pageParams,
        ...DEFAULT_SORT,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page.pageNumber === lastPage.page.totalPages) {
        return undefined;
      }
      return lastPage.page.pageNumber + 1;
    },
    staleTime: STALE_TIME,
  });
};

export const useRecipientQueryPagerProps = () => {
  const { status, data } = useRecipientsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { pageNumber, pageSize, totalRecords, totalPages } = data.page;

  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: pageNumber,
    totalRecords,
    totalPages,
  } as PaginationProps;
};

export const useRecipientQuery = () => {
  const api = useApi();
  const id = useRecipientIdFromUrl();

  return useSuspenseQuery({
    queryKey: ['recipients', id],
    queryFn: async () => (!!id ? await api.getRecipientById(id) : null),
    staleTime: STALE_TIME,
  });
};
