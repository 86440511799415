import { useModalContext } from '@chakra-ui/react';
import { TCreateRecipientFields } from '@payler/api/client-office';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventStatus } from '../../components/EventStatus/EventStatus';
import { getRecipientTitle, TRecipientNameInfo } from '../../helpers/recipient';
import { useAddRecipientWizardContext } from './AddRecipientWizard';

export const RecipientSuccess = () => {
  const { onClose } = useModalContext();
  const { t } = useTranslation(['recipients']);
  const { recipient } = useAddRecipientWizardContext();
  const { getValues } = useFormContext<TCreateRecipientFields>();
  const recipientInfo = useMemo(() => {
    if (recipient) {
      return recipient;
    } else {
      const { recipientType, legalName, firstName, lastName } = getValues();
      return {
        recipientType,
        legalName,
        firstName,
        lastName,
      } as TRecipientNameInfo;
    }
  }, [getValues, recipient]);

  const modalTitle = useMemo(
    () => getRecipientTitle(recipientInfo),
    [recipientInfo],
  );

  return (
    <EventStatus
      statusType="success"
      title={modalTitle}
      description={t('recipients:addModal.successDescription')}
      onCloseButtonClick={onClose}
    />
  );
};
