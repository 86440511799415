import {
  DrawerContent,
  DrawerContentProps,
  useModalContext,
} from '@chakra-ui/react';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { useSynchronizeSideMenuPlaceholder } from '../../hooks/use-opened-side-menu';
import { SIDE_MENU_WIDTH } from '../../layouts/ContentLayout/ContentLayout';

interface IBankDrawerContentProps extends DrawerContentProps {
  drawerId: string;
}

export const BankDrawerContent: FC<
  PropsWithChildren<IBankDrawerContentProps>
> = ({ children, drawerId, ...rest }) => {
  const { isDesktop } = useLayoutContext();
  const { isOpen } = useModalContext();
  useSynchronizeSideMenuPlaceholder(isOpen, drawerId);

  const drawerContentProps: DrawerContentProps = useMemo(() => {
    if (isDesktop) {
      return {
        overflow: 'hidden',
        my: 4,
        mr: 3,
        borderRadius: 2,
      };
    }

    return {};
  }, [isDesktop]);

  return (
    <DrawerContent
      w={`${SIDE_MENU_WIDTH}px`}
      maxW={`${SIDE_MENU_WIDTH}px`}
      bg="secondary.500"
      {...drawerContentProps}
      {...rest}
    >
      {children}
    </DrawerContent>
  );
};
