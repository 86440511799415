import { createRoot } from 'react-dom/client';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nResources } from '@payler/spush';
import App from './app/App';

// TODO: TT-74 отправлять в свой сентри
// const log = createLogger('spush-client-office');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;

i18n.use(initReactI18next).init({
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'common',
  resources: i18nResources,
  fallbackLng: 'en',
});

const root = createRoot(container);
root.render(<App />);
// TODO: TT-74 отправлять в свой сентри
// const webVitalsLogs = (args: unknown) => log('WebVitals', args);
// reportWebVitals(
//   process.env.NODE_ENV === 'development' ? webVitalsLogs : undefined
// );
