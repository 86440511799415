import React, { forwardRef, useCallback, useState } from 'react';
import { CloseEyesIcon, OpenEyesIcon } from '@payler/ui-icons';
import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

export interface PasswordInputProps
  extends Omit<InputProps, 'rightElementProps'> {
  /** Удаленное управление переключением состояния (например для подтверждения пароля) */
  onChangeTypeClick?: () => void;
  /** Удаленное состояние отображения */
  visible?: boolean;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    const { visible: visibleProps, onChangeTypeClick, ...rest } = props;
    const [visible, setVisible] = useState(false);
    const v = visibleProps || visible;
    const onIconClickHandle = useCallback(() => {
      if (typeof onChangeTypeClick === 'function') {
        onChangeTypeClick?.();
      } else {
        setVisible((pS) => !pS);
      }
    }, [onChangeTypeClick]);

    return (
      <InputGroup>
        <Input ref={ref} type={v ? 'text' : 'password'} {...rest} />
        <InputRightElement>
          <Icon
            width={2.5}
            height={2.5}
            as={v ? CloseEyesIcon : OpenEyesIcon}
            onClick={onIconClickHandle}
            color="primary.300"
            cursor="pointer"
          />
        </InputRightElement>
      </InputGroup>
    );
  }
);
PasswordInput.displayName = 'PasswordInput';
