import { DropzoneComp, TDropzoneCompProps } from './DropzoneComp';
import { FC, useEffect, useMemo } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, useControllableState } from '@chakra-ui/react';
import { DropzoneComponentContextProvider } from './DropzoneContext';

export type TDropzoneProps = Pick<
  TDropzoneCompProps,
  'title' | 'description' | 'help'
> & {
  maxFiles?: number;
  maxFileSize?: number;
  onChange?: (files: File[]) => void;
  files: File[];
  accept?: Accept;
  hasErrors?: boolean;
  testIdSuffix?: string;
};

/**
 * Компонент для загрузки файлов.
 * Для использования в контексте react-hook-form - смотрите DropzoneField
 * @param props
 * @constructor
 */
export const Dropzone: FC<TDropzoneProps> = (props) => {
  const {
    maxFileSize,
    title,
    help,
    description,
    onChange,
    accept,
    maxFiles,
    hasErrors,
    testIdSuffix,
  } = props;
  const multiple = maxFiles !== 1;
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
  } = useDropzone({ accept, maxFiles, maxSize: maxFileSize, multiple });

  const [files, setFiles] = useControllableState<File[]>({
    value: props.files,
    onChange: onChange,
  });

  useEffect(() => {
    setFiles((v) => {
      const tmp = [...v, ...acceptedFiles];
      return maxFiles ? tmp.slice(-maxFiles) : tmp;
    });
    // bug в chakra-ui useControllableState (возвращает разный setFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const variant = useMemo(() => {
    return getVariant(
      isDragAccept,
      isDragReject,
      isDragActive,
      hasErrors ?? false
    );
  }, [hasErrors, isDragAccept, isDragActive, isDragReject]);

  return (
    <Box {...getRootProps()} outline="none">
      <input {...getInputProps()} />
      <DropzoneComponentContextProvider
        files={files}
        setFiles={setFiles}
        maxFileSize={maxFileSize}
      >
        <DropzoneComp
          variant={variant}
          title={title}
          help={help}
          description={description}
          data-testid={testIdSuffix ? `dropzone-${testIdSuffix}` : 'dropzone'}
        />
      </DropzoneComponentContextProvider>
    </Box>
  );
};

const getVariant = (
  isDragAccept: boolean,
  isDragReject: boolean,
  isDragActive: boolean,
  hasErrors: boolean
): TDropzoneCompProps['variant'] => {
  if (hasErrors) {
    return 'errors';
  }
  if (isDragAccept) {
    return 'drag-accept';
  }
  if (isDragReject) {
    return 'drag-reject';
  }
  return 'normal';
};

export default Dropzone;
