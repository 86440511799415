import { TDropdownOption } from '@payler/ui-components';
import { HStack, Icon, Text, TextProps, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useCallback } from 'react';

export type TExchangeDropdownOption = TDropdownOption & {
  /**
   * Подпись
   */
  caption?: string;
  /**
   * Второй заголовок справа
   */
  secondLabel?: string;
  /**
   * Подпись под вторым заголовком
   */
  secondCaption?: string;
};

type TExchangeDropdownOptionProps = {
  option: TExchangeDropdownOption;
  isSelected: boolean;
  onClick: (val: string | number) => void | VoidFunction;
  optionLabelProps?: TextProps;
};

export const ExchangeDropdownOption = ({
  option,
  isSelected,
  onClick,
  optionLabelProps,
}: TExchangeDropdownOptionProps) => {
  const handleClick = useCallback(() => {
    if (!option.disabled) {
      onClick(option.value);
    }
  }, [option, onClick]);

  const bgColor = isSelected ? 'primary.25' : 'secondary.500';
  const hoverBgColor = option.disabled
    ? 'secondary.500'
    : isSelected
      ? 'primary.50'
      : 'primary.25';
  const cursor = option.disabled ? 'not-allowed' : 'pointer';

  return (
    <HStack
      h={9}
      spacing={2}
      px={2}
      cursor={cursor}
      onClick={handleClick}
      bg={bgColor}
      color={option.disabled ? 'primary.100' : 'primary.500'}
      _hover={{
        bg: hoverBgColor,
      }}
      transitionDuration="fast"
      transitionProperty="color, background"
    >
      {option.icon && <Icon as={option.icon} w="36px" h="36px" />}

      <VStack flex={1} minW={0} align={'start'}>
        <Text
          textStyle={TextStyles.BodyUI16Medium}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          width="100%"
          {...optionLabelProps}
        >
          {option.label}
        </Text>
        {option.caption && (
          <Text
            textStyle={TextStyles.Caption12Regular}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            width="100%"
            color={option.disabled ? 'primary.100' : 'primary.350'}
            style={{ marginTop: 0 }}
          >
            {option.caption}
          </Text>
        )}
      </VStack>
      {option.secondLabel && (
        <VStack flexShrink={1} minW={0} textAlign={'end'}>
          <Text
            textStyle={TextStyles.BodyUI16Medium}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            width="100%"
          >
            {option.secondLabel}
          </Text>
          {option.secondCaption && (
            <Text
              textStyle={TextStyles.Caption12Regular}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              width="100%"
              color={option.disabled ? 'primary.100' : 'primary.350'}
              style={{ marginTop: 0 }}
            >
              {option.secondCaption}
            </Text>
          )}
        </VStack>
      )}
    </HStack>
  );
};
