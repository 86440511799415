import { chakra } from '@chakra-ui/react';
import { MENU_WIDTH } from './const';

export const FullWidthBlock = chakra('div', {
  baseStyle: {
    mx: { base: 0, sm: 2 },
    boxSizing: 'content-box',
    maxW: { base: '100vw', sm: `calc(100vw - ${MENU_WIDTH}px - 48px)` },
    overflowX: 'hidden',
    position: 'relative',
  },
});
