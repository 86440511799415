import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const ApiErrorText = chakra('p', {
  baseStyle: {
    color: 'red.500',
    my: 2,
    textStyle: TextStyles.Caption12Regular,
  },
});
