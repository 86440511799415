import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';

export function useGetConfig<T>(url: string) {
  const [data, setData] = useState<T>({} as T);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      axios
        .get<T>([url, 'environment.json'].join('/'))
        .then((response) => {
          setData(response.data as T);
          setIsLoading(false);
        })
        .catch((e) => {
          throw e;
        });
    };

    getData();
  }, [url]);

  return useMemo(
    () => ({
      data,
      isLoading,
    }),
    [data, isLoading]
  );
}
