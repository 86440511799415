import { ErrorResponse } from 'oidc-client-ts';
import { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { getUrlWithoutAuthParams } from './auth-provider';

/**
 * Редирект на страницу логина если сессия закончилась, либо в случае ошибки авторизации
 */
export const useAutomaticLogin = () => {
  const {
    isAuthenticated,
    activeNavigator,
    isLoading,
    error,
    signinSilent,
    signinRedirect,
    removeUser,
  } = useAuth();
  const [isLoginAttempted, setLoginAttempted] = useState(false);

  useEffect(() => {
    if (
      !isLoginAttempted &&
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading &&
      !error
    ) {
      setLoginAttempted(true);
      signinRedirect();
    }
  }, [
    activeNavigator,
    error,
    isAuthenticated,
    isLoading,
    isLoginAttempted,
    signinRedirect,
  ]);

  useEffect(() => {
    if (error) {
      if (error instanceof ErrorResponse && error.error === 'login_required') {
        signinSilent();
      } else if (
        error instanceof ErrorResponse &&
        error.error === 'invalid_grant'
      ) {
        removeUser();
        /* При переходе в приложение из внешнего сайта библиотека keycloak-js сохраняет у себя
        параметр state. Затем react-oidc-context у себя не может найти этот параметр, и выдает
        ошибку что state не найден.
        Удаляем этот параметр из адреса, чтобы он не проверялся, и перезагружаем страницу.
        Пользователь входит в систему потому что уже авторизован. */
      } else if (new URL(window.location.href).searchParams.has('state')) {
        window.history.replaceState(null, '', getUrlWithoutAuthParams());
        window.location.reload();
      } else {
        throw new Error(error.message);
      }
    }
  }, [error, removeUser, signinSilent]);
};
