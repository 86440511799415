import { PopoverContentProps } from '@chakra-ui/react';
import { TDropdownOption } from './types';

export const DEFAULT_ROWS = 5;

export const DEFAULT_ROW_HEIGHT = 56;

export const defaultPopoverProps = (
  options: TDropdownOption[]
): PopoverContentProps => {
  const rows = Math.min(
    DEFAULT_ROWS,
    options.filter((x) => x.disabled !== true).length
  );
  return {
    maxH: `${DEFAULT_ROWS * DEFAULT_ROW_HEIGHT + (rows - 1) + 2}px`,
    overflow: 'hidden',
  };
};

export const popoverContentVariants = {
  enter: {
    transition: {
      duration: 0.125,
    },
    opacity: 1,
  },
  exit: {
    transition: {
      duration: 0,
    },
    opacity: 0,
  },
};
