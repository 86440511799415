import { Dayjs } from 'dayjs';
import { HStack } from '@chakra-ui/react';
import { Day } from './Day';
import React from 'react';

export const Week = ({ week, offset }: { week: Dayjs[]; offset: number }) => {
  return (
    <HStack justifyContent="center" spacing={0} userSelect="none">
      {week.map((x, i) => (
        <Day key={i} date={x} offset={offset} />
      ))}
    </HStack>
  );
};
