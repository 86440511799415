import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';
import { emotionColors } from '@payler/ui-theme';

const animate1 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const animate2 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(24px, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;
const animate3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-24px, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;
const animate4 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 24px, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;
const animate5 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -24px, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

export const BankLoader = () => {
  return (
    <Box
      height="38px"
      width="38px"
      animation={`${animate1} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite`}
      _before={{
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 'auto',
        margin: 'auto',
        width: '10px',
        height: '10px',
        background: emotionColors.primary300,
        borderRadius: '50%',
        animation: `${animate2} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite`,
      }}
      _after={{
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 'auto',
        bottom: 0,
        right: 0,
        margin: 'auto',
        width: '10px',
        height: '10px',
        background: emotionColors.primary300,
        borderRadius: '50%',
        animation: `${animate3} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite`,
      }}
    >
      <Box
        as="span"
        display="block"
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        margin="auto"
        height="38px"
        width="38px"
        _before={{
          content: "''",
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 'auto',
          right: 0,
          margin: 'auto',
          width: '10px',
          height: '10px',
          background: emotionColors.primary300,
          borderRadius: '50%',
          animation: `${animate4} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite`,
        }}
        _after={{
          content: "''",
          display: 'block',
          position: 'absolute',
          top: 'auto',
          left: 0,
          bottom: 0,
          right: 0,
          margin: 'auto',
          width: '10px',
          height: '10px',
          background: emotionColors.primary300,
          borderRadius: '50%',
          animation: `${animate5} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite`,
        }}
      />
    </Box>
  );
};
