import React, { FC, ReactElement } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const InfoItem: FC<{
  label: string;
  value?: string | ReactElement | null;
}> = ({ label, value }) => {
  if (!value) return null;
  return (
    <VStack spacing={0.5} alignItems="start">
      {label && (
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {label}
        </Text>
      )}
      {typeof value === 'string' ? (
        <Text textStyle={TextStyles.BodyUI16Regular} color="primary.500">
          {value}
        </Text>
      ) : (
        value
      )}
    </VStack>
  );
};
