import {
  TFxTransferDto,
  TIncomingTransferDto,
  TOutgoingTransferDto,
  TTransferDto,
} from '../types';

export const mockOutgoingTransfers: TOutgoingTransferDto[] = [
  {
    id: '4',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'outgoing',
    reason: null,
    senderSide: {
      amount: 15550,
      feeAmount: 20,
      totalAmount: 15570,
      currency: 'USD',
    },
    sender: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb3',
      created: '2022-09-21T05:20:21.062293+00:00',
      modified: '2022-09-23T05:20:21.062293+00:00',
      legalName: 'Name',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: 'ES',
        postalCode: '777',
        city: 'Madrid',
        street: 'Velazquez street',
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'LT333250092880563317',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
    },
    state: 'technicalProblem',
    system: 'sepa',
    description: 'Outgoing transfer',
    documents: [
      {
        name: 'account_statements (2).pdf',
      },
    ],
    recipient: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb4',
      created: '2022-09-22T05:20:21.062293+00:00',
      modified: '2022-09-25T05:20:21.062293+00:00',
      recipientType: 'individual',
      legalName: 'Name',
      firstName: 'Diego',
      lastName: 'Velázquez',
      registrationAddress: {
        country: 'ES',
        postalCode: '777',
        city: 'Madrid',
        street: 'Velazquez street',
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'ES9121000418450200051332',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
      isVisible: true,
    },
  },
  {
    id: '5',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'outgoing',
    reason: null,
    senderSide: {
      amount: 1111,
      feeAmount: 22.22,
      totalAmount: 1133.22,
      currency: 'USD',
    },
    sender: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb3',
      created: '2022-09-21T05:20:21.062293+00:00',
      modified: '2022-09-23T05:20:21.062293+00:00',
      legalName: 'Name',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: null,
        city: null,
        street: null,
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'LT333250092880563317',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
    },
    state: 'completed',
    system: 'swift',
    description: 'Outgoing transfer',
    documents: [],
    recipient: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb4',
      created: '2022-09-22T05:20:21.062293+00:00',
      modified: '2022-09-25T05:20:21.062293+00:00',
      recipientType: 'individual',
      legalName: 'Name',
      firstName: 'Diego',
      lastName: 'Velázquez',
      registrationAddress: {
        country: 'ES',
        postalCode: '777',
        city: 'Madrid',
        street: 'Velazquez street',
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'ES9121000418450200051332',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
      isVisible: true,
    },
  },
  {
    id: '8',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'outgoing',
    reason: null,
    senderSide: {
      amount: 5000,
      feeAmount: 50,
      totalAmount: 5050,
      currency: 'USD',
    },
    sender: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb3',
      created: '2022-09-21T05:20:21.062293+00:00',
      modified: '2022-09-23T05:20:21.062293+00:00',
      legalName: 'Name',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: null,
        city: null,
        street: null,
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'LT333250092880563317',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
    },
    state: 'completed',
    system: 'sepa',
    description: 'Outgoing transfer',
    documents: [],
    recipient: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb4',
      created: '2022-09-22T05:20:21.062293+00:00',
      modified: '2022-09-25T05:20:21.062293+00:00',
      recipientType: 'individual',
      legalName: 'Name',
      firstName: 'Diego',
      lastName: 'Velázquez',
      registrationAddress: {
        country: 'ES',
        postalCode: '777',
        city: 'Madrid',
        street: 'Velazquez street',
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'ES9121000418450200051332',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
      isVisible: true,
    },
  },
];

export const mockIncomingTransfers: TIncomingTransferDto[] = [
  {
    id: '3',
    created: '2022-09-23T04:30:22.062293+00:00',
    modified: '2022-09-24T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'incoming',
    reason: null,
    recipientSide: {
      amount: 9000,
      feeAmount: 91,
      totalAmount: 8909,
      currency: 'USD',
    },
    sender: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb3',
      created: '2022-09-21T05:20:21.062293+00:00',
      modified: '2022-09-23T05:20:21.062293+00:00',
      legalName: 'PAYRNET LIMITED T/AS LIGHTNINGAID',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: null,
        city: null,
        street: null,
      },
      account: {
        ledgerAccountId: null,
        iban: 'LT333250092880563317',
        accountNumber: null,
        bic: null,
        bankCountry: 'GB',
        bankName: null,
        currency: 'EUR',
      },
    },
    state: 'completed',
    system: 'swift',
    description: '',
    recipient: {
      id: 'b0aa0979-643a-4210-b2f2-a500fc68beb4',
      created: '2022-09-22T05:20:21.062293+00:00',
      modified: '2022-09-25T05:20:21.062293+00:00',
      legalName: 'Name',
      firstName: 'Diego',
      lastName: 'Velázquez',
      registrationAddress: {
        country: 'ES',
        postalCode: '777',
        city: 'Madrid',
        street: 'Velazquez street',
      },
      account: {
        ledgerAccountId: 'dcef90c3-5a04-466c-baf4-05368e0bc817',
        iban: 'ES9121000418450200051332',
        accountNumber: null,
        bic: '',
        bankCountry: 'ES',
        bankName: 'SpainBank',
        currency: 'EUR',
      },
    },
  },
];

export const mockFxTransfers: TFxTransferDto[] = [
  {
    state: 'processing',
    operationType: 'fx',
    clientId: '1',
    sender: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: null,
        accountNumber: '20101978500004A',
        accountName: 'Euro - EUR',
        ledgerAccountId: '4db3933f-00c1-4006-87d2-c62063cdd5c6',
        bankCountry: null,
        bankName: 'MY EU PAY Ltd.',
        currency: 'EUR',
      },
      id: '12312321',
      created: '2024-07-02T06:51:04.031493+00:00',
      modified: '2024-07-03T06:51:04.031493+00:00',
    },
    senderSide: {
      amount: 100,
      feeAmount: null,
      totalAmount: null,
      currency: 'EUR',
    },
    recipient: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101826500004',
        accountName: 'GBP account',
        ledgerAccountId: 'f40982aa-5a9f-4b9b-9b25-6e225f0765d0',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'GBP',
      },
      id: 'f3e3dba2-c135-4749-b35f-4d2e7e947da5',
      created: '2024-07-01T06:51:04.031495+00:00',
      modified: '2024-07-01T06:51:04.031494+00:00',
    },
    recipientSide: {
      amount: null,
      feeAmount: null,
      totalAmount: null,
      currency: 'GBP',
    },
    exchangeRate: null,
    reason: null,
    id: '1',
    created: '2024-02-07T14:25:54.646102+00:00',
    modified: '2024-05-07T14:25:56.415772+00:00',
  },
  {
    state: 'failed',
    operationType: 'fx',
    clientId: '1',
    sender: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101826500004',
        accountName: 'GBP account',
        ledgerAccountId: 'f40982aa-5a9f-4b9b-9b25-6e225f0765d0',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'GBP',
      },
      id: 'c5208226-90ab-4f68-b0e3-b73de7ef4547',
      created: '2024-07-01T06:51:07.29702+00:00',
      modified: '2024-07-01T06:51:07.29702+00:00',
    },
    senderSide: {
      amount: 120,
      feeAmount: null,
      totalAmount: null,
      currency: 'GBP',
    },
    recipient: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101978500004',
        accountName: 'EUR account',
        ledgerAccountId: 'bb288432-2ce4-45ae-8d34-2fcf6266e7cd',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'EUR',
      },
      id: '02f36550-3893-4735-bb65-942adabf3594',
      created: '2024-07-01T06:51:07.29702+00:00',
      modified: '2024-07-01T06:51:07.29702+00:00',
    },
    recipientSide: {
      amount: null,
      feeAmount: null,
      totalAmount: null,
      currency: 'EUR',
    },
    exchangeRate: null,
    reason: null,
    id: '2',
    created: '2024-06-11T08:13:05.596259+00:00',
    modified: '2024-06-11T08:13:06.068976+00:00',
  },
  {
    state: 'failed',
    operationType: 'fx',
    clientId: '295efb7f-4997-4088-8d1a-7471034bd9bd',
    sender: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101826500004',
        accountName: 'GBP account',
        ledgerAccountId: 'f40982aa-5a9f-4b9b-9b25-6e225f0765d0',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'GBP',
      },
      id: 'c5208226-90ab-4f68-b0e3-b73de7ef4547',
      created: '2024-07-01T06:51:07.29702+00:00',
      modified: '2024-07-01T06:51:07.29702+00:00',
    },
    senderSide: {
      amount: 979.44,
      feeAmount: null,
      totalAmount: null,
      currency: 'GBP',
    },
    recipient: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101978500004',
        accountName: 'EUR account',
        ledgerAccountId: 'bb288432-2ce4-45ae-8d34-2fcf6266e7cd',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'EUR',
      },
      id: '02f36550-3893-4735-bb65-942adabf3594',
      created: '2024-07-01T06:51:07.29702+00:00',
      modified: '2024-07-01T06:51:07.29702+00:00',
    },
    recipientSide: {
      amount: null,
      feeAmount: null,
      totalAmount: null,
      currency: 'EUR',
    },
    exchangeRate: null,
    reason: null,
    id: '6',
    created: '2024-06-11T08:13:05.596259+00:00',
    modified: '2024-06-11T08:13:06.068976+00:00',
  },
  {
    state: 'failed',
    operationType: 'fx',
    clientId: '1',
    sender: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101826500004',
        accountName: 'GBP account',
        ledgerAccountId: 'f40982aa-5a9f-4b9b-9b25-6e225f0765d0',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'GBP',
      },
      id: 'c5208226-90ab-4f68-b0e3-b73de7ef4547',
      created: '2024-07-01T06:51:07.29702+00:00',
      modified: '2024-07-01T06:51:07.29702+00:00',
    },
    senderSide: {
      amount: 120,
      feeAmount: null,
      totalAmount: null,
      currency: 'GBP',
    },
    recipient: {
      legalName: 'Transfer_High',
      firstName: null,
      lastName: null,
      registrationAddress: {
        country: null,
        postalCode: '777777777',
        city: 'SaintCity',
        street: 's',
      },
      account: {
        bic: 'PYYPGB21',
        iban: 'GB62PYYP00993915500004',
        accountNumber: '20101978500004',
        accountName: 'EUR account',
        ledgerAccountId: 'bb288432-2ce4-45ae-8d34-2fcf6266e7cd',
        bankCountry: 'GB',
        bankName: 'MY EU PAY Ltd.',
        currency: 'EUR',
      },
      id: '02f36550-3893-4735-bb65-942adabf3594',
      created: '2024-07-01T06:51:07.29702+00:00',
      modified: '2024-07-01T06:51:07.29702+00:00',
    },
    recipientSide: {
      amount: 232.92,
      feeAmount: 4.19,
      totalAmount: null,
      currency: 'EUR',
    },
    exchangeRate: 1.1646,
    reason: null,
    id: '7',
    created: '2024-06-21T08:13:05.596259+00:00',
    modified: '2024-06-25T08:13:06.068976+00:00',
  },
];

export const mockTransfers: TTransferDto[] = [
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[0],
    id: '1',
    created: '2022-09-22T05:30:21.062293+00:00',
    modified: '2022-09-22T05:30:21.062293+00:00',
  },
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[1],
    id: '2',
    created: '2022-09-22T05:30:21.062293+00:00',
    modified: '2022-09-22T05:30:21.062293+00:00',
  },
  {
    outgoing: null,
    incoming: mockIncomingTransfers[0],
    fx: null,
    id: '3',
    created: '2022-09-22T05:20:21.062293+00:00',
    modified: '2022-09-22T05:20:21.062293+00:00',
  },
  {
    outgoing: mockOutgoingTransfers[0],
    incoming: null,
    fx: null,
    id: '4',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
  },
  {
    outgoing: mockOutgoingTransfers[1],
    incoming: null,
    fx: null,
    id: '5',
    created: '2022-09-22T03:35:00.062293+00:00',
    modified: '2022-09-22T03:35:00.062293+00:00',
  },
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[2],
    id: '6',
    created: '2022-09-22T03:30:00.062293+00:00',
    modified: '2022-09-22T03:30:00.062293+00:00',
  },
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[3],
    id: '7',
    created: '2022-09-22T03:30:00.062293+00:00',
    modified: '2022-09-22T03:30:00.062293+00:00',
  },
  {
    outgoing: mockOutgoingTransfers[2],
    incoming: null,
    fx: null,
    id: '8',
    created: '2022-09-22T03:35:00.062293+00:00',
    modified: '2022-09-22T03:35:00.062293+00:00',
  },
];
