import { Button, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { useCreateOutgoingTransferMutation } from '../../hooks/transfers/queries';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from './TransferWizard';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import {
  REQUISITES_NOT_FOUND_ERROR,
  useGetAxiosError,
  useHandleFormError,
} from '@payler/bank-utils';
import { usePermissions } from '../../hooks/use-permissions';
import { useFormContext } from 'react-hook-form';
import { FormOption } from '@payler/ui-components';
import { beautifyIban, getRecipientTitle } from '../../helpers/recipient';
import { useRecipientBankInfo } from '../../hooks/use-recipient-bank-info';
import { useTransferMoneyInfo } from '../../hooks/use-transfer-money-info';
import { DocumentsWrapper } from '../../components/DocumentLink/DocumentsWrapper';
import { useParticipantAddress } from '../../helpers/use-participant-address';

const fieldsMap: Record<string, keyof TTransferDetailsForm> = {
  Amount: 'amount',
  Description: 'description',
};

export const TransferConfirmation = () => {
  const { setStep, transfer, setTransferId, isSelfTransfer, setError } =
    useTransferWizardContext();
  const { t } = useTranslation(['accounts']);
  const getError = useGetAxiosError();
  const [errorText, setErrorText] = useState('');
  const { isGlobalAccounts } = usePermissions();

  const { mutate: createOutgoingTransfer, isPending: isCreateTransferLoading } =
    useCreateOutgoingTransferMutation();

  const methods = useFormContext<TTransferDetailsForm>();
  const handleFormError = useHandleFormError<TTransferDetailsForm>();

  const handleSubmit = useCallback(() => {
    if (transfer) {
      createOutgoingTransfer(transfer, {
        onSuccess: (transferId: string) => {
          setTransferId(transferId);
          setStep('otp');
        },
        onError: (e) => {
          const { fieldErrors } = getError(e);
          if (fieldErrors?.[0]?.fieldErrorCode === REQUISITES_NOT_FOUND_ERROR) {
            setError(e);
            setStep('error');
            return;
          }
          const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
          if (unknownFieldErrors.length)
            setErrorText(unknownFieldErrors.join(' '));
        },
      });
    }
  }, [
    createOutgoingTransfer,
    handleFormError,
    methods,
    setStep,
    setTransferId,
    transfer,
  ]);

  return (
    <>
      <ModalHeaderActions
        isDisableBack={isCreateTransferLoading}
        isShowBack={true}
        onBack={() => setStep('form')}
      />
      <BankModalTitle title={t('accounts:transferMoney.confirmation')} />
      <BankModalBody>
        <VStack spacing={3} height="100%" justifyContent="space-between">
          <VStack spacing={2} width="100%" alignItems="baseline">
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {t('accounts:transferMoney.confirmationText')}
            </Text>
            <VStack pb={0.25} spacing={2} width="100%" alignItems="baseline">
              {isSelfTransfer ? <SelfTransferInfo /> : <CommonTransferInfo />}
              {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          variant="primary"
          w="100%"
          onClick={() => {
            if (!isGlobalAccounts) {
              setStep('demo');
            } else {
              handleSubmit();
            }
          }}
          isLoading={isCreateTransferLoading}
          data-testid="button_continue"
        >
          {t('accounts:transferMoney.continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};

const Row: React.FC<{
  title: string;
  description: ReactNode | string | undefined;
  errorMessage?: string;
}> = ({ title, description, errorMessage }) => {
  if (!description) return null;
  return (
    <FormOption isInvalid={!!errorMessage} errorMessage={errorMessage}>
      <VStack spacing={0.5} width="100%" alignItems="baseline">
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {title}
        </Text>
        {typeof description === 'string' ? (
          <Text textStyle={TextStyles.BodyUI16Regular} color="primary.500">
            {description}
          </Text>
        ) : (
          description
        )}
      </VStack>
    </FormOption>
  );
};

const SelfTransferInfo = () => {
  const { t } = useTranslation(['accounts']);
  const { senderAccount, receiverAccount, transfer, transfersFee } =
    useTransferWizardContext();
  const {
    formState: { errors },
  } = useFormContext<TTransferDetailsForm>();

  const currency = receiverAccount?.currency || '';

  const { amount, fee, totalAmount } = useTransferMoneyInfo({
    currency,
    feeAmount: transfersFee?.feeAmount,
    sendingAmount: transfer?.sendingAmount,
  });

  return (
    <>
      <Row
        title={t('accounts:transferMoney.senderAccount')}
        description={senderAccount?.number}
      />
      <Row
        title={t('accounts:transferMoney.receiverAccount')}
        description={receiverAccount?.number}
      />
      <Row
        title={t('accounts:transferMoney.amount')}
        description={amount}
        errorMessage={errors.amount?.message}
      />
      <Row title={t('accounts:transferMoney.fee')} description={fee} />
      <Row
        title={t('accounts:transferMoney.total')}
        description={totalAmount}
      />
    </>
  );
};

const CommonTransferInfo = () => {
  const { t } = useTranslation(['accounts', 'transfers']);
  const { transfer, senderAccount, recipient, transferSystem, transfersFee } =
    useTransferWizardContext();
  const { formatAmountByCurrency } = useLanguageFeatures();
  const {
    formState: { errors },
  } = useFormContext<TTransferDetailsForm>();

  const currency = recipient?.account.currency || '';

  const recipientTitle = useMemo(
    () => getRecipientTitle(recipient),
    [recipient],
  );

  const iban = beautifyIban(recipient?.account?.iban || '');

  const { amount, fee, totalAmount } = useTransferMoneyInfo({
    currency,
    feeAmount: transfersFee?.feeAmount,
    sendingAmount: transfer?.sendingAmount,
  });

  const recipientAddress = useParticipantAddress(
    recipient?.registrationAddress,
  );

  const { label: bankInfoTitle, value: bankInfoDescription } =
    useRecipientBankInfo({
      name: recipient?.account.bankName,
      country: recipient?.account.bankCountry,
      bic: recipient?.account.bic,
    });

  return (
    <>
      <Row
        title={t('accounts:transferMoney.senderAccount')}
        description={senderAccount?.number}
      />
      <Row
        title={t('accounts:transferMoney.recipient')}
        description={recipientTitle}
      />
      {iban && (
        <Row title={t('accounts:transferMoney.iban')} description={iban} />
      )}
      {recipient?.account.accountNumber && (
        <Row
          title={t('accounts:transferMoney.accountNumber')}
          description={recipient?.account.accountNumber}
        />
      )}
      <Row
        title={t('accounts:transferMoney.amount')}
        description={amount}
        errorMessage={errors.amount?.message}
      />
      <Row title={t('accounts:transferMoney.fee')} description={fee} />
      <Row
        title={t('accounts:transferMoney.total')}
        description={totalAmount}
      />
      <Row title={bankInfoTitle} description={bankInfoDescription} />
      <Row
        title={t('accounts:transferMoney.transferType')}
        description={
          transferSystem ? t(`transfers:mappedSystems.${transferSystem}`) : ''
        }
      />
      <Row
        title={t('accounts:transferMoney.address')}
        description={recipientAddress}
      />
      <Row
        title={t('accounts:transferMoney.description')}
        description={transfer?.description || '-'}
        errorMessage={errors.description?.message}
      />
      {transfer?.documents && transfer?.documents.length > 0 && (
        <Row
          title={t('accounts:transferMoney.attachedDocuments')}
          description={
            <DocumentsWrapper
              documents={transfer.documents}
              isClickable={false}
            />
          }
        />
      )}
    </>
  );
};
