import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import AnalyticsManager from './AnalyticsManager';

const AnalyticsManagerContext = createContext<AnalyticsManager | null>(null);

export const AnalyticsManagerContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const analyticsManager = useMemo(() => {
    return new AnalyticsManager();
  }, []);

  return (
    <AnalyticsManagerContext.Provider value={analyticsManager}>
      {children}
    </AnalyticsManagerContext.Provider>
  );
};

export const useAnalyticsManager = (): AnalyticsManager => {
  const analyticsManager = useContext(AnalyticsManagerContext);
  if (!analyticsManager) {
    throw new Error(
      'useAnalyticsManager must be used within an AnalyticsManagerContextProvider',
    );
  }
  return analyticsManager;
};
