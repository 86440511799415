import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, TDropdownProps } from '../Dropdown';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React from 'react';
import { RequiredStyledProvider } from '../../../required-styled-provider';

export type TDropdownFieldProps = Omit<
  TDropdownProps,
  'value' | 'onChange' | 'onBlur' | 'floatingLabel'
> & {
  /**
   * Имя поля формы
   */
  fieldName: string;
  /**
   * заголовок поля Type 1
   */
  label?: string;
  /**
   * Плавающие заголовки поля?
   */
  floating?: boolean;
};

export const DropdownField = (props: TDropdownFieldProps) => {
  const { fieldName, label, floating, ...dropdownProps } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.fieldName}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={!!fieldState.error}>
            {!floating && label && (
              <FormLabel as="legend">{props.label}</FormLabel>
            )}
            <RequiredStyledProvider>
              <Dropdown
                {...dropdownProps}
                floatingLabel={floating && label ? label : undefined}
                value={field.value}
                onChange={(v) => field.onChange(v ?? '')}
                onBlur={field.onBlur}
                isError={!!fieldState.error}
              />
              {fieldState.error && fieldState.error.message && (
                <FormHelperText
                  color="red.500"
                  mt={1}
                  textStyle={TextStyles.Caption12Regular}
                  px={1.5}
                >
                  {fieldState.error.message}
                </FormHelperText>
              )}
            </RequiredStyledProvider>
          </FormControl>
        );
      }}
    />
  );
};

export default DropdownField;
