import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const CheckboxStyles: ComponentStyleConfig = {
  baseStyle: {
    control: {
      outline: 'none',
      border: 'none',
      boxShadow: 'checkbox',
      _hover: {
        boxShadow: 'checkboxHover',
      },
      _disabled: {
        boxShadow: 'checkboxDisabled',
      },
      _focus: {
        boxShadow: 'checkboxHover',
      },
      _checked: {
        bg: 'brands.500',
        boxShadow: 'none',
        _hover: {
          boxShadow: 'checkboxFocus',
          bg: 'brands.hover',
        },
        _focus: {
          boxShadow: 'checkboxFocus',
        },
        _disabled: {
          bg: 'brands.300',
          boxShadow: 'none',
        },
      },
    },
    icon: {
      color: 'white.500',
    },
    label: {
      color: 'primary.350',
      textStyle: TextStyles.Subtitle14Regular,
    },
  },
  sizes: {
    md: {
      control: {
        w: 2,
        h: 2,
      },
      icon: {
        w: 2,
        h: 0.75,
      },
    },
  },
  variants: {
    rounded: {
      control: {
        borderRadius: '50px',
      },
    },
    square: {
      control: {
        borderRadius: '2px',
      },
    },
    activeLabel: {
      label: {
        opacity: '1 !important',
        color: 'primary.500',
      },
    },
  },
  defaultProps: {
    variant: 'square',
  },
};
