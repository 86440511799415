import { TSortOrder, TSortParams } from '../types';
import { MayBe } from './maybe';

export const getSortParams = (
  sortParam: MayBe<string>,
  order: MayBe<TSortOrder | string>,
): TSortParams => {
  const result: TSortParams = {};
  if (sortParam) {
    result.SortBy = sortParam;

    if (order === 'desc' || order === 'asc') {
      result.SortOrder = order;
    }
  }

  return result;
};

export const DEFAULT_SORT = getSortParams('created', 'desc');
