import { stockFonts } from './fonts';
import { CSSObject } from '@emotion/styled';

/**
 * Стили шрифтов для использования в @emotion
 */
const emotionTextStyles1 = {
  /**
   * Headline / H1
   */
  h1: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: `GolosTextWebDemiBold, ${stockFonts}`,
  },
  /**
   * Headline / H2
   */
  h2: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: `GolosTextWebDemiBold, ${stockFonts}`,
  },
  /**
   * Headline / H3
   */
  h3: {
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: `GolosTextWebDemiBold, ${stockFonts}`,
  },
  /**
   * Headline / H4
   */
  h4: {
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: `GolosTextWebDemiBold, ${stockFonts}`,
  },
  /**
   * Body Text/14 - Medium
   */
  bodyText14medium: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '1px',
    fontWeight: 400,
    fontFamily: `GolosTextWebMedium, ${stockFonts}`,
  },
  /**
   * Body UI/14 - Semibold
   */
  bodyUI14Semibold: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.08px',
    fontWeight: 700,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Body UI/14 - Medium
   */
  bodyUI14Medium: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.08px',
    fontWeight: 500,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Body UI/14 - Regular
   */
  bodyUI14Regular: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Body UI/16 - Semibold
   */
  bodyUI16Semibold: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 700,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Body UI/16 - Medium
   */
  bodyUI16Medium: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Body UI/16 - Regular
   */
  bodyUI16Regular: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Body Text/16 - Semibold
   */
  bodyText16semibold: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 700,
    fontFamily: `GolosTextWebDemiBold, ${stockFonts}`,
  },
  /**
   * Body Text/16 - Medium
   */
  bodyText16medium: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: `GolosTextWebMedium, ${stockFonts}`,
  },
  /**
   * Body Text/16 - Regular
   */
  bodyText16regular: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.08px',
    fontWeight: 400,
    fontFamily: `GolosTextWebRegular, ${stockFonts}`,
  },
  /**
   * Subtitle/14 - Semibold
   */
  subtitle14semibold: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 700,
    fontFamily: `GolosTextWebDemiBold, ${stockFonts}`,
  },
  /**
   * Subtitle/14 - Medium
   */
  subtitle14medium: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: `GolosTextWebMedium, ${stockFonts}`,
  },
  /**
   * Subtitle/14 - Regular
   */
  subtitle14regular: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: `GolosTextWebRegular, ${stockFonts}`,
  },
  /**
   * Caption / 12 - Semibold
   */
  caption12semibold: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 700,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Caption / 12 - Medium
   */
  caption12medium: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 400,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Caption / 12 - Regular
   */
  caption12regular: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 400,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Tagline / 14 - Medium
   */
  tagline14medium: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '1px',
    fontWeight: 400,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Tagline / 10 - Bold
   */
  tagline10bold: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.8px',
    fontWeight: 700,
    fontFamily: `GolosUIWebBold, ${stockFonts}`,
  },
  /**
   * Buttons / 16 - Medium
   */
  buttons16medium: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 400,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Buttons / 12 - Small
   */
  buttons12small: {
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: 0,
    fontWeight: 400,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Buttons / 12 - Medium
   */
  buttons12medium: {
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: 0,
    fontWeight: 500,
    fontFamily: `GolosUIWebMedium, ${stockFonts}`,
  },
  /**
   * Additional / Exchange
   */
  additional16exchange: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 400,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Additional / Tables
   */
  additional15tables: {
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
  /**
   * Additional / Code
   */
  additional14code: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.16px',
    fontWeight: 400,
    fontFamily: `Roboto Mono, ${stockFonts}`,
  },
  /**
   * Additional / Link
   */
  additional15link: {
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.12px',
    fontWeight: 400,
    fontFamily: `GolosUIWebRegular, ${stockFonts}`,
  },
};

type K = keyof typeof emotionTextStyles1;
export const emotionTextStyles: Record<K, CSSObject> = emotionTextStyles1;
