export const payment = {
  paymentToSubmitPage: {
    paymentDetails: 'Payment details',
    selectAccount: 'Please, select an account number',
    merchantName: 'Merchant name',
    merchantReference: 'Merchant reference',
    approve: 'Approve',
    decline: 'Decline',
    insufficientFunds:
      'Insufficient funds: The payment amount exceeds your current account balance',
  },
  paymentSuccessPage: {
    paymentCompletedTitle: 'Payment successfully completed',
    paymentAcceptedTitle: 'Payment Accepted',
    paymentAcceptedSubtitle:
      'Your payment has been confirmed and will be processed shortly',
    backToStore: 'Back to the store',
    backToWallet: 'Back to the wallet',
  },
  paymentInProgressPage: {
    pleaseWait: 'Please, wait',
    paymentIsProcessing: 'Your payment is processing...',
  },
  errorPage: {
    reloadPage: 'Reload page',
    sessionExpired: 'Payment session has expired, please try again',
    somethingWentWrong: 'Something went wrong',
    sessionTimeout: 'Payment expired',
    paymentRejected: 'Payment rejected',
    paymentCanceled: 'Payment canceled',
    tryAgain: 'Please try again or contact support',
    notFound: 'Not found',
  },
  controls: {
    returnToWallet: 'Return to the wallet',
    returnToMerchant: 'Return to the merchant site',
  },
};
