import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { theme as baseTheme } from '@payler/ui-theme';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'payler',
};

export const theme = extendTheme({
  ...baseTheme,
  config,
  styles: {
    global: {
      '.grecaptcha-badge': {
        visibility: 'hidden',
      },
      body: {
        backgroundColor: 'menuBG'
      },
    }
  },
  shadows: {
    ...baseTheme.shadows,
    popover:
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
    detailsBlock:
      '0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)',
  },
});
