import { Flex, Icon, Spinner } from '@chakra-ui/react';
import { ArrowRightIcon } from '@payler/ui-icons';
import { FlexProps } from '@chakra-ui/layout';

export interface ArrowButtonProps extends FlexProps {
  disabled?: boolean;
  isHover?: boolean;
  isLoading?: boolean;
}

export const ArrowButton: FCC<ArrowButtonProps> = ({
  children,
  disabled,
  onClick,
  isLoading = false,
  isHover = false,
  ...rest
}) => {
  return (
    <Flex
      px={2}
      py="18px"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      cursor={disabled ? 'default' : 'pointer'}
      _hover={{
        bg: isHover && !disabled ? 'primary.25' : undefined,
      }}
      transitionDuration="0.2s"
      flexDirection="row"
      onClick={!disabled ? onClick : undefined}
      {...rest}
    >
      {children}
      {!disabled && !isLoading && (
        <Icon as={ArrowRightIcon} color="primary.300" />
      )}
      {isLoading && <Spinner boxSize={2.5} color="brands.500" />}
    </Flex>
  );
};
