/**
 * Используются для:
 * - цветов (вне зависимости от colorMode)
 *
 * @see  {@link https://chakra-ui.com/docs/styled-system/features/semantic-tokens}
 */
export const semanticTokens = {
  colors: {
    'primary.500': {
      _light: 'light.primary.500',
      _dark: 'dark.primary.500',
      default: 'dark.primary.500',
    },
    'primary.400': {
      _light: 'light.primary.400',
      _dark: 'dark.primary.400',
      default: 'dark.primary.400',
    },
    'primary.350': {
      _light: 'light.primary.350',
      _dark: 'dark.primary.350',
      default: 'dark.primary.350',
    },
    'primary.300': {
      _light: 'light.primary.300',
      _dark: 'dark.primary.300',
      default: 'dark.primary.300',
    },
    'primary.200': {
      _light: 'light.primary.200',
      _dark: 'dark.primary.200',
      default: 'dark.primary.200',
    },
    'primary.100': {
      _light: 'light.primary.100',
      _dark: 'dark.primary.100',
      default: 'dark.primary.100',
    },
    'primary.50': {
      _light: 'light.primary.50',
      _dark: 'dark.primary.50',
      default: 'dark.primary.50',
    },
    'primary.25': {
      _light: 'light.primary.25',
      _dark: 'dark.primary.25',
      default: 'dark.primary.25',
    },
    'secondary.500': {
      _light: 'light.secondary.500',
      _dark: 'dark.secondary.500',
      default: 'dark.secondary.500',
    },
    'secondary.400': {
      _light: 'light.secondary.400',
      _dark: 'dark.secondary.400',
      default: 'dark.secondary.400',
    },
    'secondary.350': {
      _light: 'light.secondary.350',
      _dark: 'dark.secondary.350',
      default: 'dark.secondary.350',
    },
    'secondary.300': {
      _light: 'light.secondary.300',
      _dark: 'dark.secondary.300',
      default: 'dark.secondary.300',
    },
    'secondary.200': {
      _light: 'light.secondary.200',
      _dark: 'dark.secondary.200',
      default: 'dark.secondary.200',
    },
    'secondary.100': {
      _light: 'light.secondary.100',
      _dark: 'dark.secondary.100',
      default: 'dark.secondary.100',
    },
    'secondary.50': {
      _light: 'light.secondary.50',
      _dark: 'dark.secondary.50',
      default: 'dark.secondary.50',
    },
    'secondary.25': {
      _light: 'light.secondary.25',
      _dark: 'dark.secondary.25',
      default: 'dark.secondary.25',
    },
    bgTooltip: {
      _light: 'rgba(255, 255, 255, 0.8)',
      _dark: 'rgba(0, 0, 0, 0.8)',
      default: 'rgba(0, 0, 0, 0.8)',
    },
    bg: {
      _light: 'light.other.bg',
      _dark: 'dark.other.bg',
      default: 'dark.other.bg',
    },
    menuBG: {
      _light: 'light.other.menuBG',
      _dark: 'dark.other.menuBG',
      default: 'dark.other.menuBG',
    },
    segmentedPickerOptionBg: {
      _light: 'white.500',
      default: 'white.300',
    },
    buttonText: {
      _light: 'light.other.buttonText',
      _dark: 'dark.other.buttonText',
      default: 'dark.other.buttonText',
    },
  },
  shadows: {
    segmentedPickerOption: {
      default: '0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04)',
      _dark: 'none',
    },
    languageSelector: {
      default:
        '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      _dark: 'none',
    },
    accountInfo: {
      default: 'inset 0px 0 0px 1px rgb(8 8 8 / 8%)',
      _dark: 'none',
    },
  },
};
