import { TTransferStateDto } from '@payler/api/client-office';
import { TAlertStatus } from '@payler/ui-components';

export const getTransferStatus = (
  state?: TTransferStateDto,
): TAlertStatus | undefined => {
  switch (state) {
    case 'processing':
      return 'warning';
    case 'failed':
    case 'technicalProblem':
      return 'error';
    case 'completed':
    default:
      return undefined;
  }
};
