import isNil from 'lodash/isNil';
import { TStepTypeDto } from '@payler/api/client-office';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useOnboardingStepParamFromUrl = () => {
  const [params] = useSearchParams();
  return useMemo(() => {
    return params.has('nextStep')
      ? (params.get('nextStep') as TStepTypeDto) || null
      : undefined;
  }, [params]);
};

export const useGetParamsFromCookies = () => {
  return useCallback((names: string[]) => {
    return document.cookie
      .split(';')
      .reduce((result: Record<string, string>, item) => {
        const [name, value] = item.trim().split('=');
        if (!isNil(name) && !isNil(value) && names.includes(name.trim())) {
          result[name.trim()] = value.trim();
        }
        return result;
      }, {});
  }, []);
};

export const useSetCookieParamsToUrl = () => {
  return useCallback((cookies: Record<string, string>, url: URL) => {
    Object.entries(cookies).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
    return url;
  }, []);
};
