import {
  useCanChangeMonth,
  useCurrentMonth,
  useLocale,
  useMonths,
  usePickerState,
  useSetNextMonth,
  useSetPrevMonth,
} from './state';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ChevronDownIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';

export const DaysHeader = () => {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      userSelect="none"
      data-testid="days-header"
    >
      <Prev />
      <Button />
      <Next />
    </HStack>
  );
};

const Button = () => {
  const monthDate = useCurrentMonth();
  const months = useMonths();
  const locale = useLocale();
  const [, setPickerState] = usePickerState();
  const firstMonth = months[0] ?? 0;

  let text = monthDate
    .add(firstMonth, 'month')
    .locale(locale)
    .format('MMM YYYY');

  if (months.length > 1) {
    const lastMonth = months[months.length - 1] ?? 0;
    const fromDate = monthDate.add(firstMonth, 'month').locale(locale);
    const toDate = monthDate.add(lastMonth, 'month').locale(locale);
    const fromFormat = fromDate.isSame(toDate, 'year') ? 'MMMM' : 'MMMM YYYY';
    const from = fromDate.format(fromFormat);
    const to = toDate.format('MMMM YYYY');
    text = `${from}\u00a0-\u00a0${to}`;
  }
  return (
    <HStack
      spacing={0.5}
      justifyContent="center"
      cursor="pointer"
      role="button"
      onClick={() => setPickerState('years')}
      data-testid="button_change-state"
    >
      <Text as="h4" textStyle={TextStyles.BodyUI16Semibold}>
        {text}
      </Text>
      <Icon as={ChevronDownIcon} w="14px" h="14px" color="primary.300" />
    </HStack>
  );
};

const Prev = () => {
  const canChange = useCanChangeMonth('prev');
  const prev = useSetPrevMonth();
  return (
    <Icon
      as={ChevronLeftIcon}
      w={3}
      h={3}
      onClick={canChange ? prev : undefined}
      cursor={canChange ? 'pointer' : 'not-allowed'}
      color={canChange ? 'primary.400' : 'primary.100'}
      _hover={{
        color: canChange ? 'primary.500' : 'primary.100',
      }}
      data-testid="prev"
    />
  );
};

const Next = () => {
  const canChange = useCanChangeMonth('next');
  const next = useSetNextMonth();

  return (
    <Icon
      as={ChevronRightIcon}
      w={3}
      h={3}
      onClick={canChange ? next : undefined}
      cursor={canChange ? 'pointer' : 'not-allowed'}
      color={canChange ? 'primary.400' : 'primary.100'}
      _hover={{
        color: canChange ? 'primary.500' : 'primary.100',
      }}
      data-testid="next"
    />
  );
};
