import { RecoilRoot } from 'recoil';
import { Box, PositionProps, useOutsideClick, VStack } from '@chakra-ui/react';
import React, { memo, useEffect, useRef } from 'react';
import { CalendarMonths } from './CalendarMonths';
import { ThinDivider } from './ThinDivider';
import { DaysHeader } from './DaysHeader';
import {
  useIsSelecting,
  usePaylerDatepickerProps,
  usePickerState,
  useResetSelection,
  useSetActiveMonth,
  useSetStartSelection,
  useShowBorder,
  useShowHeader,
} from './state';
import { useTranslation } from 'react-i18next';
import { YearsHeader } from './YearsHeader';
import { ChooseYear } from './ChooseYear';
import { ChooseMonth } from './ChooseMonth';
import { MonthsHeader } from './MonthsHeader';
import { PaylerDatepickerProps } from './PaylerDatepickerProps';

const useInitializePicker = (props: PaylerDatepickerProps) => {
  const setActiveMonth = useSetActiveMonth();
  const isSelecting = useIsSelecting();
  const setSelectionStart = useSetStartSelection();

  const [, setProps] = usePaylerDatepickerProps();
  const {
    i18n: { language },
  } = useTranslation();

  //sync props
  useEffect(() => {
    const newProps: PaylerDatepickerProps = {
      showHeader: true,
      showBorder: true,
      locale: language ?? 'ru',
      months: [0],
      layout: 'horizontal',
      showWeekDays: true,
      ...props,
    };
    setProps(newProps);
  }, [props, language, setProps]);

  const { activeMonth } = props;
  useEffect(() => {
    if (activeMonth && !isSelecting) {
      setActiveMonth(activeMonth);
    }
  }, [activeMonth, setActiveMonth, isSelecting]);

  const { rangeStartDate } = props;
  useEffect(() => {
    if (rangeStartDate) {
      setSelectionStart(rangeStartDate);
    }
  }, [rangeStartDate, setSelectionStart]);
};

const Inner = (props: PaylerDatepickerProps) => {
  useInitializePicker(props);
  const ref = useRef(null);
  const [pickerState] = usePickerState();

  useOutsideClick({
    ref: ref,
    handler: props.onClickOutside,
  });

  return (
    <Box ref={ref} position="relative" minWidth="fit-content" userSelect="none">
      <Days />
      {pickerState === 'month' && <MonthPicker />}
      {pickerState === 'years' && <YearPicker />}
    </Box>
  );
};

const vStackNoBorderProps = {
  // maxW: 'fit-content',
  alignItems: 'stretch',
  spacing: 2,
  bg: 'secondary.500',
  divider: <ThinDivider />,
};

const vStackProps = {
  ...vStackNoBorderProps,
  p: 3,
  borderRadius: 1.5,
  border: '1px solid',
  borderColor: 'primary.100',
};

const absolute = {
  position: 'absolute' as PositionProps['position'],
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  maxW: '100%',
};

const YearPicker = () => {
  const showBorder = useShowBorder();
  const showHeader = useShowHeader();
  return (
    <VStack {...(showBorder ? vStackProps : vStackNoBorderProps)} {...absolute}>
      {showHeader && <YearsHeader />}
      <ChooseYear />
    </VStack>
  );
};

const MonthPicker = () => {
  const showBorder = useShowBorder();
  const showHeader = useShowHeader();
  return (
    <VStack {...(showBorder ? vStackProps : vStackNoBorderProps)} {...absolute}>
      {showHeader && <MonthsHeader />}
      <ChooseMonth />
    </VStack>
  );
};

const Days = () => {
  const showBorder = useShowBorder();
  const showHeader = useShowHeader();
  const resetSelection = useResetSelection();
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        e.stopPropagation();
        resetSelection();
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [resetSelection]);
  return (
    <VStack {...(showBorder ? vStackProps : vStackNoBorderProps)}>
      {showHeader && <DaysHeader />}
      <CalendarMonths />
    </VStack>
  );
};

export const PaylerDatepicker = memo((props: PaylerDatepickerProps) => {
  return (
    <RecoilRoot>
      <Inner {...props} />
    </RecoilRoot>
  );
});

export default PaylerDatepicker;
export const PaylerDatepickerWithoutRoot = Inner;
