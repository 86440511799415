import React, { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { BoxProps } from '@chakra-ui/layout';

export interface SegmentedPickerProps {
  /** Массив с опциями */
  options: Array<string | ReactElement>;
  /** Индекс выбранного элемента */
  selection?: number;
  /** Callback для обработки выбора опции */
  onSelectionChange: (newSelection: number) => void;
  size?: 'default' | 'lg';
  className?: string;
  optionPaddingX?: number;
  /**
   * Отображать на всю ширину контейнера
   */
  isFullWidth?: boolean;
  /**
   * @type {BoxProps} Props для отдельного пункта
   */
  optionProps?: BoxProps;
}

export interface SegmentedPickerOptionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /** Toggle с выборанным элементом */
  isSelected: boolean;
  size?: SegmentedPickerProps['size'];
  optionPaddingX?: number;
}

/**
 *
 * @param {string[]} options - Массив с опциями
 * @param {number} selection - Индекс выбранного элемента
 * @param {Function} onSelectionChange - Callback для обработки выбора опции
 * @param className
 * @param optionPaddingX - принудительное значения горизонтального отступа в опциях
 * @param size - default - 42px, lg - 48px
 * @param isFullWidth - Отображать на всю ширину контейнера
 * @param optionProps - Props для отдельного пункта
 * @constructor
 *
 * @example
 * function App() {
 *   const [selection, setSelection] = useState(0);
 *   const options = ['Apple', 'Orange', 'Pear', 'Watermelon'];
 *
 *   return (
 *      <SegmentedPicker
 *        options={options}
 *        selection={selection}
 *        onSelectionChange={(newSelection) => {
 *          setSelection(newSelection);
 *        }}
 *      />
 *   );
 * }
 */
export const SegmentedPicker: React.FC<SegmentedPickerProps> = ({
  options,
  selection,
  onSelectionChange,
  className,
  optionPaddingX,
  size = 'default',
  isFullWidth,
  optionProps,
}) => {
  const wrapperDisplay = isFullWidth ? 'flex' : 'inline-flex';
  const wrapperHeight = size === 'lg' ? '48px' : '42px';
  const optionWidth = isFullWidth ? '100%' : undefined;
  const optionPadding = `0 ${optionPaddingX ?? 24}px`;
  const optionHeight = size === 'lg' ? '40px' : '34px';

  const componentOptions = useMemo(() => {
    return options.map((optionText, index) => {
      // Не показывать разделитель если выбран текущий или предыдущий индекс
      const showSeparator =
        index !== 0 && !(index === selection || index - 1 === selection);

      return (
        <React.Fragment key={index}>
          <Box
            opacity={showSeparator ? 1 : 0}
            backgroundColor="primary.200"
            width="1px"
            height="16px"
          />
          <Box
            textStyle={TextStyles.BodyText14Medium}
            height={optionHeight}
            color="primary.400"
            display="inline-flex"
            alignItems="center"
            text-align="center"
            flex-grow="1"
            flex-basis="0"
            padding={optionPadding}
            borderRadius={1}
            cursor="pointer"
            userSelect="none"
            width={optionWidth}
            textAlign="center"
            justifyContent="center"
            data-testid={`segmented-picker-option-${index}${
              index === selection ? '-active' : ''
            }`}
            whiteSpace="nowrap"
            onClick={() => {
              onSelectionChange(index);
            }}
            {...optionProps}
            {...(index === selection && {
              color: 'primary.500',
              backgroundColor: 'segmentedPickerOptionBg',
              boxShadow: 'segmentedPickerOption',
            })}
          >
            {optionText}
          </Box>
        </React.Fragment>
      );
    });
  }, [
    options,
    selection,
    optionHeight,
    optionPadding,
    optionWidth,
    onSelectionChange,
    optionProps,
  ]);

  return (
    <Box
      display={wrapperDisplay}
      alignItems="center"
      padding={0.5}
      borderRadius={1.5}
      backgroundColor="primary.25"
      overflow="hidden"
      boxShadow="segmentedPickerContainer"
      height={wrapperHeight}
      className={className}
      data-testid="segmented-picker"
    >
      {componentOptions}
    </Box>
  );
};
SegmentedPicker.displayName = 'SegmentedPicker';
