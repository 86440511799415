import { createContext, useContext } from 'react';
import {
  ApiClientOffice,
  DemoApiClientOffice,
  IApiClientOffice,
} from '@payler/api/client-office';

const ApiCtx = createContext<{ api: IApiClientOffice }>({
  api: new ApiClientOffice(''),
});

export const ApiContext: FCC<{
  api: ApiClientOffice | DemoApiClientOffice;
}> = ({ children, api }) => {
  return <ApiCtx.Provider value={{ api }}>{children}</ApiCtx.Provider>;
};

export const useApi = <T = IApiClientOffice,>() => {
  const { api } = useContext(ApiCtx);
  return api as T;
};
