import { useEffect, useRef } from 'react';

type TUsePreviousRenderValue = {
  <T>(value: T, isUndefinedOnFirstRender: true): T | undefined;
  <T>(value: T, isUndefinedOnFirstRender: false): T;
  <T>(value: T): T;
};

/**
 * Возвращает значение, которое было на предыдущем рендере
 * @param value значение
 * @param isUndefinedOnFirstRender возвращает undefined или текущее значение при первом рендере (default = false)
 * @returns значение на предыдущем рендере
 */
export const usePreviousRenderValue: TUsePreviousRenderValue = <T>(
  value: T,
  isUndefinedOnFirstRender?: boolean,
) => {
  const valueRef = useRef(isUndefinedOnFirstRender ? undefined : value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
};
