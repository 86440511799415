import { ReactComponent } from '*.svg';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { ArrowButton, ArrowButtonProps } from './ArrowButton';
import { TextStyles } from '@payler/ui-theme';

export const RegularArrowButton = ({
  title,
  subtitle,
  regularIcon,
  customIcon,
  ...props
}: {
  title: string | undefined;
  subtitle: string | undefined;
  regularIcon?: typeof ReactComponent;
  customIcon?: JSX.Element;
} & ArrowButtonProps) => {
  return (
    <ArrowButton {...props}>
      <HStack spacing={2}>
        {customIcon}
        {regularIcon && (
          <Icon as={regularIcon} w="40px" h="40px" color="brands.500" />
        )}
        <VStack spacing={0} alignItems="baseline">
          <Text textStyle={TextStyles.Subtitle14Medium} color="primary.500">
            {title}
          </Text>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
            {subtitle}
          </Text>
        </VStack>
      </HStack>
    </ArrowButton>
  );
};
