import { Fade, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { Skeleton } from '../Skeleton/Skeleton';
import React from 'react';
import { components } from '@payler/ui-theme';

export const TableSkeleton = () => {
  return (
    <Fade in={true}>
      <TableContainer position="relative">
        <Table styleConfig={components.Table}>
          <Tbody>
            {Array.from(Array(8).keys()).map((x, i) => (
              <Tr key={i}>
                <Td>
                  <Skeleton width="100%" h={2} />
                </Td>
                <Td>
                  <Skeleton width="100%" h={2} />
                </Td>
                <Td textAlign="center">
                  <Skeleton width="100%" h={2} />
                </Td>
                <Td>
                  <Skeleton width="100%" h={2} />
                </Td>
                <Td textAlign="right">
                  <Skeleton width="100%" h={2} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Fade>
  );
};

export default TableSkeleton;
