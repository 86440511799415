import createLogger from 'debug';
import { useAuth } from '@payler/auth';
import { TToken } from '@payler/api/client-office';

const log = createLogger('hooks:useProfileInfo');

export const useProfileInfo = (): TToken => {
  const { tokenParsed } = useAuth<TToken>();
  if (tokenParsed) {
    return tokenParsed;
  } else {
    log('token not found, using mock');
    return mockTokenParsed;
  }
};

const mockTokenParsed: TToken = {
  exp: 1669697016,
  iat: 1669696716,
  auth_time: 1669696715,
  jti: '99fee318-81f8-49bf-981e-c8ab2955f2ea',
  iss: 'https://my.dev.account.payler.com/auth/realms/Bank',
  aud: 'account',
  sub: 'fa245506-f66e-49d9-8232-00a13b26be1c',
  typ: 'Bearer',
  azp: 'FrontOffice',
  nonce: '3192aa2b-2fc6-4f03-84d5-84a1052d2190',
  session_state: '4c49bf5f-17ff-4dc1-b7b8-1bc93a1cede8',
  acr: '1',
  'allowed-origins': ['*'],
  realm_access: {
    roles: ['offline_access', 'default-roles-bank', 'uma_authorization'],
  },
  resource_access: {
    account: {
      roles: ['manage-account', 'manage-account-links', 'view-profile'],
    },
  },
  scope: 'openid phone email address if_client_id_scope profile',
  sid: '4c49bf5f-17ff-4dc1-b7b8-1bc93a1cede8',
  email_verified: true,
  address: {},
  name: 'Storybook user',
  'if-client-id': '37dd16af-bb14-41e4-9026-3e8e0752877b',
  preferred_username: 'storybook',
  given_name: 'Storybook',
  family_name: 'Mock',
  email: 'storybook@payler.com',
};
