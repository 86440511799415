import { chakra } from '@chakra-ui/react';

export const ThinDivider = chakra('div', {
  baseStyle: {
    border: 'none',
    borderWidth: 0,
    height: '1px',
    bgGradient:
      'linear(to-b, primary.100 0%, primary.100 51%, transparent 51%)',
    backgroundSize: 'auto 1px',
    alignSelf: 'stretch',
  },
});
