import { TMultiDropdownFieldProps } from './MultiDropdownField';

type TMultiDropdownPreset = Omit<
  Partial<TMultiDropdownFieldProps>,
  'preset' | 'fieldName' | 'label'
>;

/**
 * Пример - Настройки / Уведомления / Триггеры
 */
export const mc1Preset: TMultiDropdownPreset = {
  selectAllClickBehaviour: 'select-all',
  selectAllOptionsBehaviour: 'unselected',
  lastSelectedOptionBehaviour: 'keep',
  showSelectAllLabelAsValue: true,
  floating: true,
  hideClearButton: true,
  noEllipsis: true,
};

/**
 * Пример - Создать отчёт / Статус
 */
export const mc2Preset: TMultiDropdownPreset = {
  selectAllOptionsBehaviour: 'unselected',
  nullValueAsAllSelected: true,
  showSelectAllLabelAsValue: true,
  hideClearButton: true,
  floating: true,
  noEllipsis: true,
};

/**
 * Пример - Создать отчёт / Контракты
 */
export const mc3Preset: TMultiDropdownPreset = {
  noEllipsis: true,
  floating: true,
  hideClearButton: true,
  selectAllOptionsBehaviour: 'unselected',
  showSelectAllLabelAsValue: true,
  selectAllClickBehaviour: 'select-all',
  lastSelectedOptionBehaviour: 'keep',
};
