import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const PageTitle = chakra('h2', {
  baseStyle: {
    textStyle: TextStyles.h2,
    mt: 1,
    mb: { base: 2, sm: 5 },
  },
});
