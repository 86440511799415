import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import createLogger from 'debug';

const log = createLogger('query:accounts:cache');
/**
 * Сбросить кэш счетов
 */
export const useDropAccountsCache = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient
      .invalidateQueries({ queryKey: ['accounts'] })
      .then(() => log('accounts cache dropped'));
  }, [queryClient]);
};
