import { Icon, IconProps } from '@chakra-ui/react';
import { PaylerLogo } from '@payler/ui-icons';
import { Link } from 'react-router-dom';

export const Logo = ({
  forLogin,
  ...rest
}: Omit<IconProps, 'css'> & { forLogin?: boolean }) => {
  const logo = (
    <Icon
      as={PaylerLogo}
      w="154px"
      h="48px"
      color="primary.500"
      m={{ base: 2, sm: 0 }}
      flexGrow={0}
      {...rest}
    />
  );
  if (forLogin) return logo;
  return <Link to="/">{logo}</Link>;
};
