import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { components } from './components';
import { sizeOverrides } from './size-overrides';
import { semanticTokens } from './semanticTokens';
import { fonts } from './fonts';
import { textStyles } from './textStyles';
import { colors } from './colors';
import { shadows } from './shadows';
import { breakpoints } from './breakpoints';

const themeConfig: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'payler',
};

export const overrides = {
  config: themeConfig,
  ...sizeOverrides,
  colors,
  breakpoints,
  semanticTokens,
  components,
  fonts,
  shadows,
  styles: {
    global: {
      body: {
        minHeight: '100vh',
      },
    },
  },
  textStyles,
};

export const theme = extendTheme(overrides);

export type Theme = typeof theme;
