import { useEffect, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const sideMenuPlaceholderOpenedState = atom<Set<string>>({
  key: 'side-menu-opened',
  default: new Set(),
});

export const useSideMenuPlaceholderHandlers = () => {
  const setState = useSetRecoilState(sideMenuPlaceholderOpenedState);
  return useMemo(() => {
    return {
      off: (drawerId: string) => {
        setState((curr) => {
          const newSet = new Set(curr);
          newSet.delete(drawerId);
          return newSet;
        });
      },
      on: (drawerId: string) => {
        setState((curr) => {
          const newSet = new Set(curr);
          newSet.add(drawerId);
          return newSet;
        });
      },
    };
  }, [setState]);
};

export const useSideMenuPlaceholderOpened = () =>
  useRecoilValue(sideMenuPlaceholderOpenedState).size > 0;

/** Хук для синхронизации состояния открытости дровера с плейсхолдером */
export const useSynchronizeSideMenuPlaceholder = (
  isDrawerOpened: boolean,
  drawerId: string,
) => {
  const { on, off } = useSideMenuPlaceholderHandlers();
  useEffect(() => {
    isDrawerOpened ? on(drawerId) : off(drawerId);
    return () => {
      off(drawerId);
    };
  }, [drawerId, isDrawerOpened, off, on]);
};
