import { useMediaQuery } from '@chakra-ui/react';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { breakpoints } from '@payler/ui-theme';

type TLayout = 'mobile' | 'tablet' | 'desktop';
type TLayoutContext = {
  layout: TLayout;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const LayoutContext = createContext<TLayoutContext>({
  layout: 'mobile',
  isMobile: true,
  isTablet: false,
  isDesktop: false,
});

export const LayoutContextProvider: FC<PropsWithChildren> = (props) => {
  const [isMobile = false, isTablet = false, isDesktop = false] = useMediaQuery(
    [
      `(max-width: ${breakpoints.xsm})`,
      `(min-width: ${breakpoints.xsm}) and (max-width: ${breakpoints.md})`,
      `(min-width: ${breakpoints.md})`,
    ],
  );

  const ctx = useMemo<TLayoutContext>(() => {
    const tabletOrDesktop = isTablet ? 'tablet' : 'desktop';
    const layout = isMobile ? 'mobile' : tabletOrDesktop;
    return { isMobile, isTablet, isDesktop, layout };
  }, [isMobile, isTablet, isDesktop]);
  return <LayoutContext.Provider {...props} value={ctx} />;
};

export const useLayoutContext = () => useContext(LayoutContext);
