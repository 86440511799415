import { VStack, Text, Icon, Flex, Container, Box } from '@chakra-ui/react';
import { ErrorBoundary, Input, ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { Trans, useTranslation } from 'react-i18next';
import { Search2Icon } from '@chakra-ui/icons';
import {
  BankModalBody,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { useTransferWizardContext } from './TransferWizard';
import React, { useCallback, useState } from 'react';
import { mockRecipients, TRecipientDto } from '@payler/api/client-office';
import { beautifyIban, getRecipientTitle } from '../../helpers/recipient';
import { useRecipientIcon } from '../../hooks/recipients/icon';
import { useTransferRecipientsInfiniteQuery } from '../../hooks/recipients/queries';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { usePermissions } from '../../hooks/use-permissions';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDebounce } from '@payler/utils';
import { BankLoader } from '../../components/BankLoader/BankLoader';
import { EmptyListIcon, TransactionsIcon, UsersIcon } from '@payler/bank-utils';
import { RegularArrowButton } from '../../components/ArrowButton/RegularArrowButton';
import { useAccountsQuery } from '../../hooks/accounts/queries';

export const TransferSelectRecipient = ({
  onAddRecipientClick,
}: {
  onAddRecipientClick: VoidFunction;
}) => {
  const { t } = useTranslation(['accounts']);
  const [search, setSearch] = useState('');
  const { setRecipient, setStep, setIsSelfTransfer, senderAccount } =
    useTransferWizardContext();
  const { isGlobalAccounts } = usePermissions();
  const { data: accounts } = useAccountsQuery({ enabled: !senderAccount });

  const handleNewTransferClick = useCallback(() => {
    if (!isGlobalAccounts) {
      setRecipient(mockRecipients[0]);
      setStep('form');
    } else {
      onAddRecipientClick();
    }
  }, [isGlobalAccounts, onAddRecipientClick, setRecipient, setStep]);

  const isReceiverExists = accounts?.some(
    (account) =>
      account.currency === senderAccount?.currency &&
      account.id !== senderAccount.id,
  );

  const handleTransferToSelfClick = useCallback(() => {
    if (isReceiverExists) {
      setStep('form');
      setIsSelfTransfer(true);
    } else {
      setStep('selfWarning');
    }
  }, [isReceiverExists, setIsSelfTransfer, setStep]);

  return (
    <>
      <ModalHeaderActions />
      <BankModalTitle title={t('accounts:transferMoney.whoToPay')} />
      <BankModalBody>
        <VStack spacing={2} width="100%" alignItems="baseline">
          <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
            {t('accounts:transferMoney.selectRecipient')}
          </Text>
          <VStack
            w="full"
            bgColor="primary.25"
            borderRadius={1.5}
            divider={<ThinDivider m="0 !important" />}
          >
            <RegularArrowButton
              title={t('accounts:transferMoney.transferToSelfTitle')}
              subtitle={t('accounts:transferMoney.betweenAccounts')}
              regularIcon={TransactionsIcon}
              onClick={handleTransferToSelfClick}
              disabled={!isGlobalAccounts}
              data-testid="button_transfer-to-self"
              borderTopRadius={1.5}
              _hover={{ bg: 'primary.50' }}
              cursor={!isGlobalAccounts ? 'not-allowed' : 'pointer'}
            />
            <RegularArrowButton
              title={t('accounts:transferMoney.transferToNewRecipient')}
              subtitle={t('accounts:transferMoney.mainTransferMethod')}
              regularIcon={UsersIcon}
              onClick={handleNewTransferClick}
              data-testid="button_transfer-to-new-recipient"
              borderBottomRadius={1.5}
              _hover={{ bg: 'primary.50' }}
            />
          </VStack>
          <Container p={0} bgColor="primary.25" borderRadius={1.5}>
            <Box pt={2} px={2}>
              <Input
                leftElementProps={{
                  children: (
                    <Search2Icon w="20px" h="20px" color="primary.400" />
                  ),
                  pointerEvents: 'none',
                  paddingLeft: 2,
                }}
                textColor="primary.400"
                textStyle={TextStyles.BodyText16Medium}
                placeholder={t('accounts:transferMoney.search')}
                w="100%"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                data-testid="input_search"
                bgColor="secondary.500"
                borderRadius={1}
              />
            </Box>
            <React.Suspense fallback={<LoaderBox />}>
              <ErrorBoundary>
                <RecipientTransferList search={search} />
              </ErrorBoundary>
            </React.Suspense>
          </Container>
        </VStack>
      </BankModalBody>
    </>
  );
};

type TRecipientEntityClick = (recipient: TRecipientDto) => void;

const RecipientTransferList = ({ search }: { search: string }) => {
  const { initialSenderAccount, setRecipient, setStep, setIsSelfTransfer } =
    useTransferWizardContext();
  const debouncedSearch = useDebounce(search, 350, search);

  const handleRecipientClick: TRecipientEntityClick = useCallback(
    (recipient) => {
      setRecipient(recipient);
      setIsSelfTransfer(false);
      setStep('form');
    },
    [setIsSelfTransfer, setRecipient, setStep],
  );

  const { data, fetchNextPage, hasNextPage, isFetching, isError } =
    useTransferRecipientsInfiniteQuery({
      Currency: initialSenderAccount?.currency,
      RecipientName: debouncedSearch,
    });

  const [anchorRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: isError,
    rootMargin: '0px 0px 300px 0px',
  });

  if (!data?.pages?.[0]?.data?.length)
    return <EmptyRecipientList isSearch={!!debouncedSearch} />;

  return (
    <VStack spacing={0} width="100%">
      {data?.pages?.map((page) =>
        page.data.map((item) => (
          <RecipientEntity
            key={item.id}
            data={item}
            onClick={handleRecipientClick}
          />
        )),
      )}
      {(isFetching || hasNextPage) && (
        <Flex ref={anchorRef} alignItems="center" h="55px">
          <BankLoader />
        </Flex>
      )}
    </VStack>
  );
};

const RecipientEntity = ({
  data,
  onClick,
}: {
  data: TRecipientDto;
  onClick: TRecipientEntityClick;
}) => {
  const title = getRecipientTitle(data);
  const subtitle =
    (data?.account.iban
      ? beautifyIban(data?.account.iban)
      : data?.account.accountNumber) ?? undefined;
  const icon = useRecipientIcon(title);
  const { isGlobalAccounts } = usePermissions();

  return (
    <RegularArrowButton
      title={title}
      subtitle={subtitle}
      customIcon={icon}
      key={data.id}
      onClick={() => isGlobalAccounts && onClick(data)}
      cursor={!isGlobalAccounts ? 'not-allowed' : 'pointer'}
      py={2}
      px={2}
      data-testid="option_recipient"
      _hover={{ bg: 'primary.50' }}
    />
  );
};

export const EmptyRecipientList = ({ isSearch }: { isSearch: boolean }) => {
  const { t } = useTranslation(['accounts']);
  return (
    <VStack w="full" spacing={3} py={6}>
      <Icon as={EmptyListIcon} w="150px" h="150px" color="brands.500" />
      <VStack w="full" spacing={0}>
        <Text textStyle={TextStyles.BodyText16Semibold} color="primary.350">
          {isSearch
            ? t('accounts:transferMoney.nothingFound')
            : t('accounts:transferMoney.recipientsNotAdded')}
        </Text>
        {!isSearch && (
          <Text textStyle={TextStyles.Subtitle14Medium} color="primary.300">
            <Trans
              i18nKey="accounts:transferMoney.noRecipientsDescription"
              components={{
                b: <Text as="span" color="primary.350" />,
              }}
              ns={['accounts']}
            />
          </Text>
        )}
      </VStack>
    </VStack>
  );
};
