import { CardContent } from '@payler/ui-components';
import React from 'react';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { TransfersTableWithData } from '../ActivitiesTable/ActivitiesTable';
import { useAccountIdFromUrl } from '../../hooks/accounts/queries';

const NUMBER_OF_TRANSFERS_ON_ACCOUNTS_PAGE = 6;

export const ActivityFilteredTable = () => {
  return (
    <React.Suspense fallback={<LoaderBox variant="table" />}>
      <CardContent
        p={0}
        bg="secondary.500"
        borderRadius={1.5}
        border="0.5px solid"
        borderColor="primary.100"
        overflow="hidden"
        width="100%"
      >
        <Transfers />
      </CardContent>
    </React.Suspense>
  );
};

const Transfers = () => {
  const accountId = useAccountIdFromUrl();
  return (
    <React.Suspense fallback={<LoaderBox />}>
      <TransfersTableWithData
        params={{
          PageSize: NUMBER_OF_TRANSFERS_ON_ACCOUNTS_PAGE,
          AccountId: accountId || undefined,
        }}
        enabled={!!accountId}
      />
    </React.Suspense>
  );
};
