import { useMemo } from 'react';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { useAccountsQuery } from '../../hooks/accounts/queries';

export const useAccountsBlockDisplaySettings = () => {
  const { data: accounts } = useAccountsQuery();
  const { isMobile, isTablet } = useLayoutContext();

  return useMemo(() => {
    const accountsCount = accounts?.length ?? 0;
    const maxViewedAccounts = isMobile ? 0 : isTablet ? 2 : 3;
    const hasMore = accountsCount > maxViewedAccounts;
    const hiddenNumberAccounts = hasMore
      ? accountsCount - maxViewedAccounts
      : 0;

    return {
      accountsCount,
      hasMore,
      hiddenNumberAccounts,
      maxViewedAccounts,
    };
  }, [accounts?.length, isMobile, isTablet]);
};
