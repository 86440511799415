import {
  useActiveDate,
  useLayout,
  useLocale,
  useMonths,
  useMonthWeeks,
  useShowWeekDays,
} from './state';
import { WeekDays } from './WeekDays';
import { chakra, Stack, VStack } from '@chakra-ui/react';
import { Week } from './Week';
import React from 'react';
import { TextStyles } from '@payler/ui-theme';

export const CalendarMonth = ({ offset }: { offset: number }) => {
  const weeks = useMonthWeeks(offset);
  const showWeekDays = useShowWeekDays();
  const locale = useLocale();
  return (
    <div>
      <MonthName offset={offset} />
      {showWeekDays && <WeekDays offset={offset} locale={locale} />}
      <div>
        <VStack spacing={0} alignItems="stretch">
          {weeks.map((week, i) => (
            <Week key={i} week={week} offset={offset} />
          ))}
        </VStack>
      </div>
    </div>
  );
};

const MonthName = ({ offset }: { offset: number }) => {
  const locale = useLocale();
  const layout = useLayout();
  const text = useActiveDate()
    .add(offset, 'month')
    .locale(locale)
    .format('MMMM YYYY');
  const monthTitle = text.charAt(0).toUpperCase() + text.slice(1);
  if (layout === 'horizontal') return null;
  return (
    <chakra.div
      pb={2}
      textStyle={TextStyles.BodyUI16Semibold}
      borderBottom="1px solid"
      borderBottomColor="primary.100"
      textAlign="left"
    >
      {monthTitle}
    </chakra.div>
  );
};

export const CalendarMonths = () => {
  const months = useMonths();
  const layout = useLayout();
  return (
    <Stack
      spacing={5}
      justifyContent="space-between"
      alignItems="flexStart"
      direction={layout === 'horizontal' ? 'row' : 'column'}
    >
      {months.map((x) => (
        <CalendarMonth key={x} offset={x} />
      ))}
    </Stack>
  );
};
