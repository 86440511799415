import { Box, VStack, Text, Button, HStack, Icon } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { RecipientsBlock } from '../../components/RecipientsBlock/RecipientsBlock';
import { RecipientInfoDrawer } from '../../drawers/RecipientInfoDrawer/RecipientInfoDrawer';
import { PlusCircleIcon } from '../../icons';
import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import {
  AddRecipientModal,
  useShowAddRecipientModal,
} from '../../modals/AddRecipientModal/AddRecipientModal';

export const RecipientsPage = () => {
  const { t } = useTranslation(['menu', 'recipients']);
  const showAddRecipientModal = useShowAddRecipientModal();
  return (
    <ContentLayout>
      <Header />
      <VStack spacing={3} alignItems="start">
        <HStack spacing={2}>
          <Button
            onClick={showAddRecipientModal}
            size="small"
            data-testid="button_add-recipient"
          >
            <Icon as={PlusCircleIcon} mr={1} />
            {t('recipients:addRecipient')}
          </Button>
          {/* todo: убрана кнопка из-за не готовности апи */}
          {/* <Button
            onClick={notImplementedHandler}
            variant="secondary"
            size="small"
          >
            <Icon as={FilterIcon} mr={1} />
            {t('common:filters')}
          </Button> */}
        </HStack>
        <RecipientsBlock />
      </VStack>
      <RecipientInfoDrawer />
      <AddRecipientModal />
    </ContentLayout>
  );
};

const Header = () => {
  const { t } = useTranslation(['menu']);
  return (
    <Box>
      <Text as="h2" textStyle={TextStyles.h2} mb={2} color="primary.500">
        {t('menu:recipients')}
      </Text>
    </Box>
  );
};
