import { ComponentStyleConfig } from '@chakra-ui/react';

export const SwitchStyles: ComponentStyleConfig = {
  baseStyle: {
    track: {
      transition: 'all 0.125s linear',
      boxSizing: 'border-box',
      outline: 'none',
      boxShadow: 'switch',
      bg: 'primary.50',
      _hover: {
        boxShadow: 'switch',
        bg: 'primary.200',
      },
      _disabled: {
        boxShadow: 'switch',
        cursor: 'not-allowed',
        bg: 'primary.25',
        _hover: {
          boxShadow: 'switch',
          bg: 'primary.25',
        },
      },
      _focus: {
        outline: 'none',
        boxShadow: 'switch',
      },
      _checked: {
        boxShadow: 'switch',
        bg: 'brands.500',
        _hover: {
          boxShadow: 'switch',
          bg: 'brands.hover',
        },
        _disabled: {
          boxShadow: 'switch',
          bg: 'brands.300',
          _hover: {
            boxShadow: 'switch',
            bg: 'brands.300',
          },
        },
      },
    },
    thumb: {
      boxShadow: 'switchThumb',
    },
    label: {
      verticalAlign: 'middle',
      _disabled: {
        cursor: 'not-allowed',
      },
    },
  },
  sizes: {
    standard: {
      track: {
        w: 8,
        h: '36px',
        p: 0.5,
      },
      thumb: {
        w: '28px',
        h: '28px',
        _checked: {
          transform: 'translateX(28px)',
        },
      },
    },
    small: {
      track: {
        w: 5,
        h: 3,
        p: 0.5,
      },
      thumb: {
        w: 2,
        h: 2,
        _checked: {
          transform: 'translateX(16px)',
        },
      },
    },
  },
  defaultProps: {
    size: 'standard',
  },
};
