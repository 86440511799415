import { Icon, Stack, StackProps } from '@chakra-ui/react';
import { CalendarIcon } from '@payler/ui-icons';

/**
 * Кнопка с календариком для DateInput
 * @param props
 */
export const CalendarButton = (props: StackProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      bg="transparent"
      borderRadius="50%"
      mx={-0.5}
      w={3}
      h={3}
      transitionDuration="normal"
      _hover={{
        bg: 'primary.100',
        svg: {
          transitionDuration: 'normal',
          color: 'primary.400',
        },
      }}
      cursor="pointer"
      {...props}
    >
      <Icon w={2.5} h={2.5} as={CalendarIcon} color="primary.300" />
    </Stack>
  );
};
