export default [
  {
    code: 'AL',
    name: 'Albania',
    alpha_3_code: 'ALB',
    numeric_code: 8,
    dial_in_code: 355,
  },
  {
    code: 'DZ',
    name: 'Algeria',
    alpha_3_code: 'DZA',
    numeric_code: 12,
    dial_in_code: 213,
  },
  {
    code: 'AS',
    name: 'American Samoa',
    alpha_3_code: 'ASM',
    numeric_code: 16,
    dial_in_code: 1684,
  },
  {
    code: 'AD',
    name: 'Andorra',
    alpha_3_code: 'AND',
    numeric_code: 20,
    dial_in_code: 376,
  },
  {
    code: 'AO',
    name: 'Angola',
    alpha_3_code: 'AGO',
    numeric_code: 24,
    dial_in_code: 244,
  },
  {
    code: 'AI',
    name: 'Anguilla',
    alpha_3_code: 'AIA',
    numeric_code: 660,
    dial_in_code: 1264,
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    alpha_3_code: 'ATA',
    numeric_code: 10,
    dial_in_code: 672,
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    alpha_3_code: 'ATG',
    numeric_code: 28,
    dial_in_code: 1268,
  },
  {
    code: 'AR',
    name: 'Argentina',
    alpha_3_code: 'ARG',
    numeric_code: 32,
    dial_in_code: 54,
  },
  {
    code: 'AM',
    name: 'Armenia',
    alpha_3_code: 'ARM',
    numeric_code: 51,
    dial_in_code: 374,
  },
  {
    code: 'AW',
    name: 'Aruba',
    alpha_3_code: 'ABW',
    numeric_code: 533,
    dial_in_code: 297,
  },
  {
    code: 'AU',
    name: 'Australia',
    alpha_3_code: 'AUS',
    numeric_code: 36,
    dial_in_code: 61,
  },
  {
    code: 'AT',
    name: 'Austria',
    alpha_3_code: 'AUT',
    numeric_code: 40,
    dial_in_code: 43,
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    alpha_3_code: 'AZE',
    numeric_code: 31,
    dial_in_code: 994,
  },
  {
    code: 'BS',
    name: 'Bahamas',
    alpha_3_code: 'BHS',
    numeric_code: 44,
    dial_in_code: 1242,
  },
  {
    code: 'BH',
    name: 'Bahrain',
    alpha_3_code: 'BHR',
    numeric_code: 48,
    dial_in_code: 973,
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    alpha_3_code: 'BGD',
    numeric_code: 50,
    dial_in_code: 880,
  },
  {
    code: 'BB',
    name: 'Barbados',
    alpha_3_code: 'BRB',
    numeric_code: 52,
    dial_in_code: 1246,
  },
  {
    code: 'BE',
    name: 'Belgium',
    alpha_3_code: 'BEL',
    numeric_code: 56,
    dial_in_code: 32,
  },
  {
    code: 'BZ',
    name: 'Belize',
    alpha_3_code: 'BLZ',
    numeric_code: 84,
    dial_in_code: 501,
  },
  {
    code: 'BJ',
    name: 'Benin',
    alpha_3_code: 'BEN',
    numeric_code: 204,
    dial_in_code: 229,
  },
  {
    code: 'BM',
    name: 'Bermuda',
    alpha_3_code: 'BMU',
    numeric_code: 60,
    dial_in_code: 1441,
  },
  {
    code: 'BT',
    name: 'Bhutan',
    alpha_3_code: 'BTN',
    numeric_code: 64,
    dial_in_code: 975,
  },
  {
    code: 'BO',
    name: 'Bolivia (Plurinational State of)',
    alpha_3_code: 'BOL',
    numeric_code: 68,
    dial_in_code: 591,
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha_3_code: 'BES',
    numeric_code: 535,
    dial_in_code: 599,
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    alpha_3_code: 'BIH',
    numeric_code: 70,
    dial_in_code: 387,
  },
  {
    code: 'BW',
    name: 'Botswana',
    alpha_3_code: 'BWA',
    numeric_code: 72,
    dial_in_code: 267,
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
    alpha_3_code: 'BVT',
    numeric_code: 74,
    dial_in_code: 47,
  },
  {
    code: 'BR',
    name: 'Brazil',
    alpha_3_code: 'BRA',
    numeric_code: 76,
    dial_in_code: 55,
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    alpha_3_code: 'IOT',
    numeric_code: 86,
    dial_in_code: 246,
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
    alpha_3_code: 'VGB',
    numeric_code: 92,
    dial_in_code: 1284,
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    alpha_3_code: 'BRN',
    numeric_code: 96,
    dial_in_code: 673,
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    alpha_3_code: 'BGR',
    numeric_code: 100,
    dial_in_code: 359,
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    alpha_3_code: 'BFA',
    numeric_code: 854,
    dial_in_code: 226,
  },
  {
    code: 'BI',
    name: 'Burundi',
    alpha_3_code: 'BDI',
    numeric_code: 108,
    dial_in_code: 257,
  },
  {
    code: 'CV',
    name: 'Cabo Verde',
    alpha_3_code: 'CPV',
    numeric_code: 132,
    dial_in_code: 238,
  },
  {
    code: 'KH',
    name: 'Cambodia',
    alpha_3_code: 'KHM',
    numeric_code: 116,
    dial_in_code: 855,
  },
  {
    code: 'CM',
    name: 'Cameroon',
    alpha_3_code: 'CMR',
    numeric_code: 120,
    dial_in_code: 237,
  },
  {
    code: 'CA',
    name: 'Canada',
    alpha_3_code: 'CAN',
    numeric_code: 124,
    dial_in_code: 1,
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    alpha_3_code: 'CYM',
    numeric_code: 136,
    dial_in_code: 1345,
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    alpha_3_code: 'CAF',
    numeric_code: 140,
    dial_in_code: 236,
  },
  {
    code: 'TD',
    name: 'Chad',
    alpha_3_code: 'TCD',
    numeric_code: 148,
    dial_in_code: 235,
  },
  {
    code: 'CL',
    name: 'Chile',
    alpha_3_code: 'CHL',
    numeric_code: 152,
    dial_in_code: 56,
  },
  {
    code: 'CN',
    name: 'China',
    alpha_3_code: 'CHN',
    numeric_code: 156,
    dial_in_code: 86,
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    alpha_3_code: 'CXR',
    numeric_code: 162,
    dial_in_code: 61,
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    alpha_3_code: 'CCK',
    numeric_code: 166,
    dial_in_code: 61,
  },
  {
    code: 'CO',
    name: 'Colombia',
    alpha_3_code: 'COL',
    numeric_code: 170,
    dial_in_code: 57,
  },
  {
    code: 'KM',
    name: 'Comoros',
    alpha_3_code: 'COM',
    numeric_code: 174,
    dial_in_code: 269,
  },
  {
    code: 'CG',
    name: 'Congo',
    alpha_3_code: 'COG',
    numeric_code: 178,
    dial_in_code: 242,
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    alpha_3_code: 'COK',
    numeric_code: 184,
    dial_in_code: 682,
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    alpha_3_code: 'CRI',
    numeric_code: 188,
    dial_in_code: 506,
  },
  {
    code: 'HR',
    name: 'Croatia',
    alpha_3_code: 'HRV',
    numeric_code: 191,
    dial_in_code: 385,
  },
  {
    code: 'CW',
    name: 'Curaçao',
    alpha_3_code: 'CUW',
    numeric_code: 531,
    dial_in_code: 599,
  },
  {
    code: 'CY',
    name: 'Cyprus',
    alpha_3_code: 'CYP',
    numeric_code: 196,
    dial_in_code: 357,
  },
  {
    code: 'CZ',
    name: 'Czechia',
    alpha_3_code: 'CZE',
    numeric_code: 203,
    dial_in_code: 420,
  },
  {
    code: 'DK',
    name: 'Denmark',
    alpha_3_code: 'DNK',
    numeric_code: 208,
    dial_in_code: 45,
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    alpha_3_code: 'DJI',
    numeric_code: 262,
    dial_in_code: 253,
  },
  {
    code: 'DM',
    name: 'Dominica',
    alpha_3_code: 'DMA',
    numeric_code: 212,
    dial_in_code: 1767,
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    alpha_3_code: 'DOM',
    numeric_code: 214,
    dial_in_code: 1809,
  },
  {
    code: 'EC',
    name: 'Ecuador',
    alpha_3_code: 'ECU',
    numeric_code: 218,
    dial_in_code: 593,
  },
  {
    code: 'EG',
    name: 'Egypt',
    alpha_3_code: 'EGY',
    numeric_code: 818,
    dial_in_code: 20,
  },
  {
    code: 'SV',
    name: 'El Salvador',
    alpha_3_code: 'SLV',
    numeric_code: 222,
    dial_in_code: 503,
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    alpha_3_code: 'GNQ',
    numeric_code: 226,
    dial_in_code: 240,
  },
  {
    code: 'ER',
    name: 'Eritrea',
    alpha_3_code: 'ERI',
    numeric_code: 232,
    dial_in_code: 291,
  },
  {
    code: 'EE',
    name: 'Estonia',
    alpha_3_code: 'EST',
    numeric_code: 233,
    dial_in_code: 372,
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    alpha_3_code: 'ETH',
    numeric_code: 231,
    dial_in_code: 251,
  },
  {
    code: 'FO',
    name: 'Faeroe Islands',
    alpha_3_code: 'FRO',
    numeric_code: 234,
    dial_in_code: 298,
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    alpha_3_code: 'FLK',
    numeric_code: 238,
    dial_in_code: 500,
  },
  {
    code: 'FJ',
    name: 'Fiji',
    alpha_3_code: 'FJI',
    numeric_code: 242,
    dial_in_code: 679,
  },
  {
    code: 'FI',
    name: 'Finland',
    alpha_3_code: 'FIN',
    numeric_code: 246,
    dial_in_code: 358,
  },
  {
    code: 'FR',
    name: 'France',
    alpha_3_code: 'FRA',
    numeric_code: 250,
    dial_in_code: 33,
  },
  {
    code: 'GF',
    name: 'French Guiana',
    alpha_3_code: 'GUF',
    numeric_code: 254,
    dial_in_code: 594,
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    alpha_3_code: 'PYF',
    numeric_code: 258,
    dial_in_code: 689,
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
    alpha_3_code: 'ATF',
    numeric_code: 260,
    dial_in_code: 262,
  },
  {
    code: 'GA',
    name: 'Gabon',
    alpha_3_code: 'GAB',
    numeric_code: 266,
    dial_in_code: 241,
  },
  {
    code: 'GM',
    name: 'Gambia',
    alpha_3_code: 'GMB',
    numeric_code: 270,
    dial_in_code: 220,
  },
  {
    code: 'GE',
    name: 'Georgia',
    alpha_3_code: 'GEO',
    numeric_code: 268,
    dial_in_code: 995,
  },
  {
    code: 'DE',
    name: 'Germany',
    alpha_3_code: 'DEU',
    numeric_code: 276,
    dial_in_code: 49,
  },
  {
    code: 'GH',
    name: 'Ghana',
    alpha_3_code: 'GHA',
    numeric_code: 288,
    dial_in_code: 233,
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    alpha_3_code: 'GIB',
    numeric_code: 292,
    dial_in_code: 350,
  },
  {
    code: 'GR',
    name: 'Greece',
    alpha_3_code: 'GRC',
    numeric_code: 300,
    dial_in_code: 30,
  },
  {
    code: 'GL',
    name: 'Greenland',
    alpha_3_code: 'GRL',
    numeric_code: 304,
    dial_in_code: 299,
  },
  {
    code: 'GD',
    name: 'Grenada',
    alpha_3_code: 'GRD',
    numeric_code: 308,
    dial_in_code: 1473,
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    alpha_3_code: 'GLP',
    numeric_code: 312,
    dial_in_code: 590,
  },
  {
    code: 'GU',
    name: 'Guam',
    alpha_3_code: 'GUM',
    numeric_code: 316,
    dial_in_code: 1671,
  },
  {
    code: 'GT',
    name: 'Guatemala',
    alpha_3_code: 'GTM',
    numeric_code: 320,
    dial_in_code: 502,
  },
  {
    code: 'GG',
    name: 'Guernsey',
    alpha_3_code: 'GGY',
    numeric_code: 831,
    dial_in_code: 44,
  },
  {
    code: 'GN',
    name: 'Guinea',
    alpha_3_code: 'GIN',
    numeric_code: 324,
    dial_in_code: 224,
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    alpha_3_code: 'GNB',
    numeric_code: 624,
    dial_in_code: 245,
  },
  {
    code: 'GY',
    name: 'Guyana',
    alpha_3_code: 'GUY',
    numeric_code: 328,
    dial_in_code: 592,
  },
  {
    code: 'HT',
    name: 'Haiti',
    alpha_3_code: 'HTI',
    numeric_code: 332,
    dial_in_code: 509,
  },
  {
    code: 'HM',
    name: 'Heard & McDonald Islands',
    alpha_3_code: 'HMD',
    numeric_code: 334,
    dial_in_code: 672,
  },
  {
    code: 'HN',
    name: 'Honduras',
    alpha_3_code: 'HND',
    numeric_code: 340,
    dial_in_code: 504,
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    alpha_3_code: 'HKG',
    numeric_code: 344,
    dial_in_code: 852,
  },
  {
    code: 'HU',
    name: 'Hungary',
    alpha_3_code: 'HUN',
    numeric_code: 348,
    dial_in_code: 36,
  },
  {
    code: 'IS',
    name: 'Iceland',
    alpha_3_code: 'ISL',
    numeric_code: 352,
    dial_in_code: 354,
  },
  {
    code: 'IN',
    name: 'India',
    alpha_3_code: 'IND',
    numeric_code: 356,
    dial_in_code: 91,
  },
  {
    code: 'ID',
    name: 'Indonesia',
    alpha_3_code: 'IDN',
    numeric_code: 360,
    dial_in_code: 62,
  },
  {
    code: 'IE',
    name: 'Ireland',
    alpha_3_code: 'IRL',
    numeric_code: 372,
    dial_in_code: 353,
  },
  {
    code: 'IM',
    name: 'Isle of Man',
    alpha_3_code: 'IMN',
    numeric_code: 833,
    dial_in_code: 44,
  },
  {
    code: 'IL',
    name: 'Israel',
    alpha_3_code: 'ISR',
    numeric_code: 376,
    dial_in_code: 972,
  },
  {
    code: 'IT',
    name: 'Italy',
    alpha_3_code: 'ITA',
    numeric_code: 380,
    dial_in_code: 39,
  },
  {
    code: 'JM',
    name: 'Jamaica',
    alpha_3_code: 'JAM',
    numeric_code: 388,
    dial_in_code: 1876,
  },
  {
    code: 'JP',
    name: 'Japan',
    alpha_3_code: 'JPN',
    numeric_code: 392,
    dial_in_code: 81,
  },
  {
    code: 'JE',
    name: 'Jersey',
    alpha_3_code: 'JEY',
    numeric_code: 832,
    dial_in_code: 44,
  },
  {
    code: 'JO',
    name: 'Jordan',
    alpha_3_code: 'JOR',
    numeric_code: 400,
    dial_in_code: 962,
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    alpha_3_code: 'KAZ',
    numeric_code: 398,
    dial_in_code: 7,
  },
  {
    code: 'KE',
    name: 'Kenya',
    alpha_3_code: 'KEN',
    numeric_code: 404,
    dial_in_code: 254,
  },
  {
    code: 'KI',
    name: 'Kiribati',
    alpha_3_code: 'KIR',
    numeric_code: 296,
    dial_in_code: 686,
  },
  {
    code: 'KW',
    name: 'Kuwait',
    alpha_3_code: 'KWT',
    numeric_code: 414,
    dial_in_code: 965,
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    alpha_3_code: 'KGZ',
    numeric_code: 417,
    dial_in_code: 996,
  },
  {
    code: 'LA',
    name: "Lao People's Democratic Republic",
    alpha_3_code: 'LAO',
    numeric_code: 418,
    dial_in_code: 856,
  },
  {
    code: 'LV',
    name: 'Latvia',
    alpha_3_code: 'LVA',
    numeric_code: 428,
    dial_in_code: 371,
  },
  {
    code: 'LB',
    name: 'Lebanon',
    alpha_3_code: 'LBN',
    numeric_code: 422,
    dial_in_code: 961,
  },
  {
    code: 'LS',
    name: 'Lesotho',
    alpha_3_code: 'LSO',
    numeric_code: 426,
    dial_in_code: 266,
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    alpha_3_code: 'LIE',
    numeric_code: 438,
    dial_in_code: 423,
  },
  {
    code: 'LT',
    name: 'Lithuania',
    alpha_3_code: 'LTU',
    numeric_code: 440,
    dial_in_code: 370,
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    alpha_3_code: 'LUX',
    numeric_code: 442,
    dial_in_code: 352,
  },
  {
    code: 'MO',
    name: 'Macao',
    alpha_3_code: 'MAC',
    numeric_code: 446,
    dial_in_code: 853,
  },
  {
    code: 'MG',
    name: 'Madagascar',
    alpha_3_code: 'MDG',
    numeric_code: 450,
    dial_in_code: 261,
  },
  {
    code: 'MW',
    name: 'Malawi',
    alpha_3_code: 'MWI',
    numeric_code: 454,
    dial_in_code: 265,
  },
  {
    code: 'MY',
    name: 'Malaysia',
    alpha_3_code: 'MYS',
    numeric_code: 458,
    dial_in_code: 60,
  },
  {
    code: 'MV',
    name: 'Maldives',
    alpha_3_code: 'MDV',
    numeric_code: 462,
    dial_in_code: 960,
  },
  {
    code: 'ML',
    name: 'Mali',
    alpha_3_code: 'MLI',
    numeric_code: 466,
    dial_in_code: 223,
  },
  {
    code: 'MT',
    name: 'Malta',
    alpha_3_code: 'MLT',
    numeric_code: 470,
    dial_in_code: 356,
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    alpha_3_code: 'MHL',
    numeric_code: 584,
    dial_in_code: 692,
  },
  {
    code: 'MQ',
    name: 'Martinique',
    alpha_3_code: 'MTQ',
    numeric_code: 474,
    dial_in_code: 596,
  },
  {
    code: 'MR',
    name: 'Mauritania',
    alpha_3_code: 'MRT',
    numeric_code: 478,
    dial_in_code: 222,
  },
  {
    code: 'MU',
    name: 'Mauritius',
    alpha_3_code: 'MUS',
    numeric_code: 480,
    dial_in_code: 230,
  },
  {
    code: 'YT',
    name: 'Mayotte',
    alpha_3_code: 'MYT',
    numeric_code: 175,
    dial_in_code: 262,
  },
  {
    code: 'MX',
    name: 'Mexico',
    alpha_3_code: 'MEX',
    numeric_code: 484,
    dial_in_code: 52,
  },
  {
    code: 'FM',
    name: 'Micronesia (Federated States of)',
    alpha_3_code: 'FSM',
    numeric_code: 583,
    dial_in_code: 691,
  },
  {
    code: 'MC',
    name: 'Monaco',
    alpha_3_code: 'MCO',
    numeric_code: 492,
    dial_in_code: 377,
  },
  {
    code: 'MN',
    name: 'Mongolia',
    alpha_3_code: 'MNG',
    numeric_code: 496,
    dial_in_code: 976,
  },
  {
    code: 'ME',
    name: 'Montenegro',
    alpha_3_code: 'MNE',
    numeric_code: 499,
    dial_in_code: 382,
  },
  {
    code: 'MS',
    name: 'Montserrat',
    alpha_3_code: 'MSR',
    numeric_code: 500,
    dial_in_code: 1664,
  },
  {
    code: 'MA',
    name: 'Morocco',
    alpha_3_code: 'MAR',
    numeric_code: 504,
    dial_in_code: 212,
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    alpha_3_code: 'MOZ',
    numeric_code: 508,
    dial_in_code: 258,
  },
  {
    code: 'NA',
    name: 'Namibia',
    alpha_3_code: 'NAM',
    numeric_code: 516,
    dial_in_code: 264,
  },
  {
    code: 'NR',
    name: 'Nauru',
    alpha_3_code: 'NRU',
    numeric_code: 520,
    dial_in_code: 674,
  },
  {
    code: 'NP',
    name: 'Nepal',
    alpha_3_code: 'NPL',
    numeric_code: 524,
    dial_in_code: 977,
  },
  {
    code: 'NL',
    name: 'Netherlands',
    alpha_3_code: 'NLD',
    numeric_code: 528,
    dial_in_code: 31,
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    alpha_3_code: 'NCL',
    numeric_code: 540,
    dial_in_code: 687,
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    alpha_3_code: 'NZL',
    numeric_code: 554,
    dial_in_code: 64,
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    alpha_3_code: 'NIC',
    numeric_code: 558,
    dial_in_code: 505,
  },
  {
    code: 'NE',
    name: 'Niger',
    alpha_3_code: 'NER',
    numeric_code: 562,
    dial_in_code: 227,
  },
  {
    code: 'NG',
    name: 'Nigeria',
    alpha_3_code: 'NGA',
    numeric_code: 566,
    dial_in_code: 234,
  },
  {
    code: 'NU',
    name: 'Niue',
    alpha_3_code: 'NIU',
    numeric_code: 570,
    dial_in_code: 683,
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    alpha_3_code: 'NFK',
    numeric_code: 574,
    dial_in_code: 672,
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    alpha_3_code: 'MNP',
    numeric_code: 580,
    dial_in_code: 1,
  },
  {
    code: 'NO',
    name: 'Norway',
    alpha_3_code: 'NOR',
    numeric_code: 578,
    dial_in_code: 47,
  },
  {
    code: 'OM',
    name: 'Oman',
    alpha_3_code: 'OMN',
    numeric_code: 512,
    dial_in_code: 968,
  },
  {
    code: 'PK',
    name: 'Pakistan',
    alpha_3_code: 'PAK',
    numeric_code: 586,
    dial_in_code: 92,
  },
  {
    code: 'PW',
    name: 'Palau',
    alpha_3_code: 'PLW',
    numeric_code: 585,
    dial_in_code: 680,
  },
  {
    code: 'PA',
    name: 'Panama',
    alpha_3_code: 'PAN',
    numeric_code: 591,
    dial_in_code: 507,
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    alpha_3_code: 'PNG',
    numeric_code: 598,
    dial_in_code: 675,
  },
  {
    code: 'PY',
    name: 'Paraguay',
    alpha_3_code: 'PRY',
    numeric_code: 600,
    dial_in_code: 595,
  },
  {
    code: 'PE',
    name: 'Peru',
    alpha_3_code: 'PER',
    numeric_code: 604,
    dial_in_code: 51,
  },
  {
    code: 'PH',
    name: 'Philippines',
    alpha_3_code: 'PHL',
    numeric_code: 608,
    dial_in_code: 63,
  },
  {
    code: 'PN',
    name: 'Pitcairn',
    alpha_3_code: 'PCN',
    numeric_code: 612,
    dial_in_code: 870,
  },
  {
    code: 'PL',
    name: 'Poland',
    alpha_3_code: 'POL',
    numeric_code: 616,
    dial_in_code: 48,
  },
  {
    code: 'PT',
    name: 'Portugal',
    alpha_3_code: 'PRT',
    numeric_code: 620,
    dial_in_code: 351,
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    alpha_3_code: 'PRI',
    numeric_code: 630,
    dial_in_code: 1,
  },
  {
    code: 'QA',
    name: 'Qatar',
    alpha_3_code: 'QAT',
    numeric_code: 634,
    dial_in_code: 974,
  },
  {
    code: 'KR',
    name: 'Republic of Korea',
    alpha_3_code: 'KOR',
    numeric_code: 410,
    dial_in_code: 82,
  },
  {
    code: 'MD',
    name: 'Republic of Moldova',
    alpha_3_code: 'MDA',
    numeric_code: 498,
    dial_in_code: 373,
  },
  {
    code: 'RO',
    name: 'Romania',
    alpha_3_code: 'ROU',
    numeric_code: 642,
    dial_in_code: 40,
  },
  {
    code: 'RW',
    name: 'Rwanda',
    alpha_3_code: 'RWA',
    numeric_code: 646,
    dial_in_code: 250,
  },
  {
    code: 'RE',
    name: 'Réunion',
    alpha_3_code: 'REU',
    numeric_code: 638,
    dial_in_code: 262,
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
    alpha_3_code: 'BLM',
    numeric_code: 652,
    dial_in_code: 590,
  },
  {
    code: 'SH',
    name: 'Saint Helena',
    alpha_3_code: 'SHN',
    numeric_code: 654,
    dial_in_code: 290,
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    alpha_3_code: 'KNA',
    numeric_code: 659,
    dial_in_code: 1869,
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    alpha_3_code: 'LCA',
    numeric_code: 662,
    dial_in_code: 1758,
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
    alpha_3_code: 'MAF',
    numeric_code: 663,
    dial_in_code: 590,
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    alpha_3_code: 'SPM',
    numeric_code: 666,
    dial_in_code: 508,
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    alpha_3_code: 'VCT',
    numeric_code: 670,
    dial_in_code: 1784,
  },
  {
    code: 'WS',
    name: 'Samoa',
    alpha_3_code: 'WSM',
    numeric_code: 882,
    dial_in_code: 685,
  },
  {
    code: 'SM',
    name: 'San Marino',
    alpha_3_code: 'SMR',
    numeric_code: 674,
    dial_in_code: 378,
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    alpha_3_code: 'STP',
    numeric_code: 678,
    dial_in_code: 239,
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    alpha_3_code: 'SAU',
    numeric_code: 682,
    dial_in_code: 966,
  },
  {
    code: 'SN',
    name: 'Senegal',
    alpha_3_code: 'SEN',
    numeric_code: 686,
    dial_in_code: 221,
  },
  {
    code: 'RS',
    name: 'Serbia',
    alpha_3_code: 'SRB',
    numeric_code: 688,
    dial_in_code: 381,
  },
  {
    code: 'SC',
    name: 'Seychelles',
    alpha_3_code: 'SYC',
    numeric_code: 690,
    dial_in_code: 248,
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    alpha_3_code: 'SLE',
    numeric_code: 694,
    dial_in_code: 232,
  },
  {
    code: 'SG',
    name: 'Singapore',
    alpha_3_code: 'SGP',
    numeric_code: 702,
    dial_in_code: 65,
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    alpha_3_code: 'SXM',
    numeric_code: 534,
    dial_in_code: 1721,
  },
  {
    code: 'SK',
    name: 'Slovakia',
    alpha_3_code: 'SVK',
    numeric_code: 703,
    dial_in_code: 421,
  },
  {
    code: 'SI',
    name: 'Slovenia',
    alpha_3_code: 'SVN',
    numeric_code: 705,
    dial_in_code: 386,
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    alpha_3_code: 'SLB',
    numeric_code: 90,
    dial_in_code: 677,
  },
  {
    code: 'SO',
    name: 'Somalia',
    alpha_3_code: 'SOM',
    numeric_code: 706,
    dial_in_code: 252,
  },
  {
    code: 'ZA',
    name: 'South Africa',
    alpha_3_code: 'ZAF',
    numeric_code: 710,
    dial_in_code: 27,
  },
  {
    code: 'GS',
    name: 'South Georgia & South Sandwich Islands',
    alpha_3_code: 'SGS',
    numeric_code: 239,
    dial_in_code: 500,
  },
  {
    code: 'ES',
    name: 'Spain',
    alpha_3_code: 'ESP',
    numeric_code: 724,
    dial_in_code: 34,
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    alpha_3_code: 'LKA',
    numeric_code: 144,
    dial_in_code: 94,
  },
  {
    code: 'SR',
    name: 'Suriname',
    alpha_3_code: 'SUR',
    numeric_code: 740,
    dial_in_code: 597,
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen Islands',
    alpha_3_code: 'SJM',
    numeric_code: 744,
    dial_in_code: 47,
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    alpha_3_code: 'SWZ',
    numeric_code: 748,
    dial_in_code: 268,
  },
  {
    code: 'SE',
    name: 'Sweden',
    alpha_3_code: 'SWE',
    numeric_code: 752,
    dial_in_code: 46,
  },
  {
    code: 'CH',
    name: 'Switzerland',
    alpha_3_code: 'CHE',
    numeric_code: 756,
    dial_in_code: 41,
  },
  {
    code: 'TW',
    name: 'Taiwan',
    alpha_3_code: 'TWN',
    numeric_code: 158,
    dial_in_code: 886,
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    alpha_3_code: 'TJK',
    numeric_code: 762,
    dial_in_code: 992,
  },
  {
    code: 'TH',
    name: 'Thailand',
    alpha_3_code: 'THA',
    numeric_code: 764,
    dial_in_code: 66,
  },
  {
    code: 'MK',
    name: 'The former Yugoslav Republic of Macedonia',
    alpha_3_code: 'MKD',
    numeric_code: 807,
    dial_in_code: 389,
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    alpha_3_code: 'TLS',
    numeric_code: 626,
    dial_in_code: 670,
  },
  {
    code: 'TG',
    name: 'Togo',
    alpha_3_code: 'TGO',
    numeric_code: 768,
    dial_in_code: 228,
  },
  {
    code: 'TK',
    name: 'Tokelau',
    alpha_3_code: 'TKL',
    numeric_code: 772,
    dial_in_code: 690,
  },
  {
    code: 'TO',
    name: 'Tonga',
    alpha_3_code: 'TON',
    numeric_code: 776,
    dial_in_code: 676,
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    alpha_3_code: 'TTO',
    numeric_code: 780,
    dial_in_code: 1868,
  },
  {
    code: 'TN',
    name: 'Tunisia',
    alpha_3_code: 'TUN',
    numeric_code: 788,
    dial_in_code: 216,
  },
  {
    code: 'TR',
    name: 'Turkey',
    alpha_3_code: 'TUR',
    numeric_code: 792,
    dial_in_code: 90,
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    alpha_3_code: 'TKM',
    numeric_code: 795,
    dial_in_code: 993,
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    alpha_3_code: 'TCA',
    numeric_code: 796,
    dial_in_code: 1649,
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    alpha_3_code: 'TUV',
    numeric_code: 798,
    dial_in_code: 688,
  },
  {
    code: 'UM',
    name: 'US Outlying Islands',
    alpha_3_code: 'UMI',
    numeric_code: 581,
    dial_in_code: 1,
  },
  {
    code: 'UG',
    name: 'Uganda',
    alpha_3_code: 'UGA',
    numeric_code: 800,
    dial_in_code: 256,
  },
  {
    code: 'UA',
    name: 'Ukraine',
    alpha_3_code: 'UKR',
    numeric_code: 804,
    dial_in_code: 380,
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    alpha_3_code: 'ARE',
    numeric_code: 784,
    dial_in_code: 971,
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    alpha_3_code: 'GBR',
    numeric_code: 826,
    dial_in_code: 44,
  },
  {
    code: 'TZ',
    name: 'United Republic of Tanzania',
    alpha_3_code: 'TZA',
    numeric_code: 834,
    dial_in_code: 255,
  },
  {
    code: 'VI',
    name: 'United States Virgin Islands',
    alpha_3_code: 'VIR',
    numeric_code: 850,
    dial_in_code: 1340,
  },
  {
    code: 'US',
    name: 'United States of America',
    alpha_3_code: 'USA',
    numeric_code: 840,
    dial_in_code: 1,
  },
  {
    code: 'UY',
    name: 'Uruguay',
    alpha_3_code: 'URY',
    numeric_code: 858,
    dial_in_code: 598,
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    alpha_3_code: 'UZB',
    numeric_code: 860,
    dial_in_code: 998,
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    alpha_3_code: 'VUT',
    numeric_code: 548,
    dial_in_code: 678,
  },
  {
    code: 'VA',
    name: 'Vatican City',
    alpha_3_code: 'VAT',
    numeric_code: 336,
    dial_in_code: 379,
  },
  {
    code: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
    alpha_3_code: 'VEN',
    numeric_code: 862,
    dial_in_code: 58,
  },
  {
    code: 'VN',
    name: 'Viet Nam',
    alpha_3_code: 'VNM',
    numeric_code: 704,
    dial_in_code: 84,
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna Islands',
    alpha_3_code: 'WLF',
    numeric_code: 876,
    dial_in_code: 681,
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    alpha_3_code: 'ESH',
    numeric_code: 732,
    dial_in_code: 212,
  },
  {
    code: 'YE',
    name: 'Yemen',
    alpha_3_code: 'YEM',
    numeric_code: 887,
    dial_in_code: 967,
  },
  {
    code: 'ZM',
    name: 'Zambia',
    alpha_3_code: 'ZMB',
    numeric_code: 894,
    dial_in_code: 260,
  },
  {
    code: 'AX',
    name: 'Åland Islands',
    alpha_3_code: 'ALA',
    numeric_code: 248,
    dial_in_code: 358,
  },
];
