import React, {
  createContext,
  FC,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { chakra, Flex, Icon } from '@chakra-ui/react';
import { AccordionChevronIcon } from '@payler/ui-icons';
import { ChevronRightIcon } from '@chakra-ui/icons';

type TAccordionContext = {
  expanded?: boolean;
  toggle?: VoidFunction;
  variant?: 'chevron' | 'triangle';
};
const AccordionContext = createContext<TAccordionContext>({});
const useAccordionContext = () => useContext(AccordionContext);

export const AccordionComp: FC<
  PropsWithChildren<{
    className?: string;
    expanded?: boolean;
    onChangeState?: (expanded: boolean) => void;
    variant?: TAccordionContext['variant'];
  }>
> = ({ onChangeState, ...props }) => {
  const [expanded, setExpanded] = useState(props.expanded);

  const expandedUsed = props.expanded ?? expanded;

  const toggle = useCallback(() => {
    if (onChangeState && props.expanded !== undefined) {
      onChangeState(!props.expanded);
    } else {
      setExpanded((v) => !v);
    }
  }, [onChangeState, props.expanded]);

  return (
    <chakra.div cursor="pointer" className={props.className}>
      <AccordionContext.Provider
        value={{ expanded: expandedUsed, toggle, variant: props.variant }}
      >
        {props.children}
      </AccordionContext.Provider>
    </chakra.div>
  );
};
export const Accordion = chakra(AccordionComp);

const AccordionHeaderComp = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ className?: string }>
>(({ children, className }, ref) => {
  const { expanded, toggle, variant } = useAccordionContext();
  return (
    <Flex
      p={2}
      alignItems="center"
      className={className}
      onClick={toggle}
      ref={ref}
      userSelect="none"
    >
      <chakra.div flex={1} minW={0}>
        {children}
      </chakra.div>
      <Icon
        ml={1}
        as={variant === 'chevron' ? ChevronRightIcon : AccordionChevronIcon}
        transitionProperty="transform color"
        transitionDuration="0.2s"
        transform={expanded ? 'rotate(90deg)' : 'rotate(0)'}
        color={expanded ? 'primary.500' : 'primary.300'}
      />
    </Flex>
  );
});

export const AccordionHeader = chakra(AccordionHeaderComp);

const AccordionBodyComp: React.FC<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  const { expanded } = useAccordionContext();
  return (
    <AnimatePresence initial={false}>
      {expanded && (
        <motion.section
          css={{ overflow: 'hidden' }}
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: 'auto',
            },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.2 }}
          className={className}
        >
          {children}
        </motion.section>
      )}
    </AnimatePresence>
  );
};
export const AccordionBody = chakra(AccordionBodyComp);

export default Accordion;
