import { chakra } from '@chakra-ui/react';

export const VerticalDivider = chakra('div', {
  baseStyle: {
    h: 3,
    w: '1px',
    bgGradient:
      'linear(to-r, primary.100 0%, primary.100 60%, transparent 70%)',
    backgroundSize: '1px auto',
    borderWidth: 0,
    border: 'none',
  },
});
