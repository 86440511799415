import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { OtpField } from '@payler/ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useApi } from '../../state/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EXCEEDED_OTP_ATTEMPTS_ERROR,
  EXHAUSTED_OTP_ERROR,
  useCountdownTimer,
  useGetAxiosError,
  useHandleFormError,
  useHandleToastError,
} from '@payler/bank-utils';
import { TextStyles } from '@payler/ui-theme';
import {
  BankModalBody,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import {
  useCancelExchangeTransferMutation,
  useCancelOutgoingTransferMutation,
  useExchangeTransferConfirmMutation,
  useOutgoingTransferConfirmMutation,
} from '../../hooks/transfers/queries';
import { useClientQuery } from '../../hooks/clients/queries';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { ExitOtpAlert, useShowExitOtpAlert } from './ExitOtpAlert';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { DEFAULT_OTP_LENGTH } from '../../const';

const EXPIRATION_TIME = 180_000;
const RESEND_CODE_TIME = 60_000;

type TTransferOTPForm = {
  code: string;
};

const fieldsMap: Record<string, keyof TTransferOTPForm> = {
  Code: 'code',
};

const useResolver = () => {
  const { t } = useTranslation();

  return yupResolver(
    yup.object().shape({
      code: yup
        .string()
        .required(t('validate.required'))
        .typeError(t('validate.required')),
    }),
  );
};

export const CommonOtp = ({
  transferId,
  close: closeTransferModal,
  onBack,
  onSuccess,
  isOutgoingTransfer,
}: {
  transferId: string | undefined;
  close: VoidFunction;
  onBack: VoidFunction;
  onSuccess: VoidFunction;
  isOutgoingTransfer: boolean;
}) => {
  const { t } = useTranslation(['accounts']);
  const api = useApi();
  const getError = useGetAxiosError<TTransferOTPForm>();
  const handleFormError = useHandleFormError<TTransferOTPForm>();
  const showExitOtpAlert = useShowExitOtpAlert();
  const handleToastError = useHandleToastError();

  const [errorText, setErrorText] = useState('');
  const [isResendLoading, setIsResendLoading] = useState<boolean>(false);
  const [isDisableOtpInput, setIsDisableOtpInput] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);

  const { data: client } = useClientQuery();

  const { otpLength = DEFAULT_OTP_LENGTH } = useClientOfficeConfig();

  const {
    isPending: isConfirmOutgoingLoading,
    mutate: confirmOutgoingTransfer,
  } = useOutgoingTransferConfirmMutation();

  const {
    isPending: isConfirmExchangeLoading,
    mutate: confirmExchangeTransfer,
  } = useExchangeTransferConfirmMutation();

  const confirmTransfer = isOutgoingTransfer
    ? confirmOutgoingTransfer
    : confirmExchangeTransfer;
  const isConfirmTransferLoading = isOutgoingTransfer
    ? isConfirmOutgoingLoading
    : isConfirmExchangeLoading;

  const { mutate: cancelOutgoingTransfer } =
    useCancelOutgoingTransferMutation();

  const { mutate: cancelExchangeTransfer } =
    useCancelExchangeTransferMutation();

  const cancelTransfer = isOutgoingTransfer
    ? cancelOutgoingTransfer
    : cancelExchangeTransfer;

  const {
    remainingTime: expirationCountdown,
    resetTimer: resetExpirationCountdown,
    isPaused: isExpirationCountdownStopped,
    setPaused: setExpirationCountdownStopped,
  } = useCountdownTimer(EXPIRATION_TIME);
  const {
    remainingTime: resendCodeCountdown,
    resetTimer: resetResendCodeCountdown,
    isPaused: isResendCodeCountdownStopped,
    setPaused: setResendCodeCountdownStopped,
  } = useCountdownTimer(RESEND_CODE_TIME);

  const methods = useForm<TTransferOTPForm>({
    resolver: useResolver(),
  });

  const {
    formState: { errors },
    setError,
  } = methods;

  const disableInput = useCallback(() => {
    setIsDisableOtpInput(true);
    setExpirationCountdownStopped(true);
    setResendCodeCountdownStopped(true);
  }, [setExpirationCountdownStopped, setResendCodeCountdownStopped]);

  const resendCode = useCallback(async () => {
    setIsResendLoading(true);
    try {
      await api.generateConfirmationCode(transferId);
      resetExpirationCountdown();
      resetResendCodeCountdown();
    } catch (e) {
      const { fieldErrors } = getError(e);
      if (
        fieldErrors &&
        fieldErrors[0]?.fieldErrorCode === EXHAUSTED_OTP_ERROR
      ) {
        disableInput();
      }
      const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
      if (unknownFieldErrors.length) setErrorText(unknownFieldErrors.join(' '));
    } finally {
      setIsResendLoading(false);
    }
  }, [
    api,
    disableInput,
    getError,
    handleFormError,
    methods,
    resetExpirationCountdown,
    resetResendCodeCountdown,
    transferId,
  ]);

  useEffect(() => {
    if (isExpirationCountdownStopped) {
      return;
    }
    if (expirationCountdown <= 0) {
      setError('code', { message: t('accounts:transferMoney.otpExpired') });
      setIsDisableOtpInput(true);
      return;
    }
  }, [expirationCountdown]);

  const handleBackClick = useCallback(async () => {
    if (isDisableOtpInput) {
      onBack();
      return;
    }
    setIsCancelLoading(true);
    if (transferId) {
      cancelTransfer(transferId, {
        onError: (e) => handleToastError(e),
        onSettled: () => {
          setIsCancelLoading(false);
          onBack();
        },
      });
    }
  }, [cancelTransfer, handleToastError, isDisableOtpInput, onBack, transferId]);

  const handleModalClose = useCallback(() => {
    if (isDisableOtpInput) {
      closeTransferModal();
    } else {
      showExitOtpAlert();
    }
  }, [closeTransferModal, isDisableOtpInput, showExitOtpAlert]);

  const onSubmit = useMemo(
    () =>
      methods.handleSubmit((values) => {
        if (transferId) {
          confirmTransfer(
            {
              transferId,
              confirmationCode: values.code,
            },
            {
              onSuccess: onSuccess,
              onError: (e) => {
                const { fieldErrors } = getError(e);
                if (
                  fieldErrors &&
                  fieldErrors[0]?.fieldErrorCode === EXCEEDED_OTP_ATTEMPTS_ERROR
                ) {
                  disableInput();
                }
                const unknownFieldErrors = handleFormError(
                  e,
                  methods,
                  fieldsMap,
                );
                if (unknownFieldErrors.length)
                  setErrorText(unknownFieldErrors.join(' '));
              },
            },
          );
        }
      }),
    [
      confirmTransfer,
      disableInput,
      getError,
      handleFormError,
      methods,
      onSuccess,
      transferId,
    ],
  );

  if (!transferId) {
    throw new Error(t('accounts:transferMoney.transferIdNotFound'));
  }

  return (
    <FormProvider {...methods}>
      <ModalHeaderActions
        isDisableBack={isConfirmTransferLoading || isCancelLoading}
        isShowBack={true}
        onBack={handleBackClick}
        onModalClose={handleModalClose}
      />
      <BankModalTitle title={t('accounts:transferMoney.otpTitle')} />
      <BankModalBody>
        <VStack spacing={2}>
          <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
            <Trans
              i18nKey="accounts:transferMoney.otpDescription"
              ns={['accounts']}
              values={{ phone: client?.phoneNumber }}
              components={{
                phone: (
                  <Text
                    as="span"
                    textStyle={TextStyles.Subtitle14Medium}
                    color="primary.500"
                  />
                ),
              }}
            />
          </Text>
          <OtpField
            autoFocus
            name="code"
            length={otpLength}
            isInvalid={!!errors.code?.message}
            onComplete={() => onSubmit()}
            isDisabled={
              isExpirationCountdownStopped ||
              isConfirmTransferLoading ||
              isDisableOtpInput
            }
          />
          {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
          {!isDisableOtpInput && (
            <HStack w="100%" justifyContent="space-between">
              <Text
                textStyle={TextStyles.Subtitle14Regular}
                color="primary.350"
              >
                <Trans
                  i18nKey="accounts:transferMoney.expirationDescription"
                  ns={['accounts']}
                  values={{ expirationCountdown }}
                  components={{
                    timer: (
                      <Text
                        as="span"
                        textStyle={TextStyles.Subtitle14Medium}
                        color="primary.500"
                      />
                    ),
                  }}
                />
              </Text>
              <Button
                variant="link"
                color="brands.500"
                textStyle={TextStyles.Subtitle14Medium}
                _hover={{ underline: 'none' }}
                _active={{ color: 'brands.500' }}
                isDisabled={
                  !isResendCodeCountdownStopped || isExpirationCountdownStopped
                }
                isLoading={isResendLoading}
                onClick={resendCode}
                data-testid="button_resend-code"
              >
                {isResendCodeCountdownStopped ? (
                  t('accounts:transferMoney.resendCode')
                ) : (
                  <Text
                    as="span"
                    textStyle={TextStyles.Subtitle14Medium}
                    color="primary.200"
                  >
                    {t('accounts:transferMoney.resendCodeCountdown', {
                      countdown: resendCodeCountdown,
                    })}
                  </Text>
                )}
              </Button>
            </HStack>
          )}
        </VStack>
      </BankModalBody>
      <ExitOtpAlert
        transferId={transferId}
        closeTransferModal={closeTransferModal}
        cancelTransfer={cancelTransfer}
      />
    </FormProvider>
  );
};
