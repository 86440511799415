import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as SuccessIcon } from './icons/Success.svg';
import { ReactComponent as AlertIcon } from './icons/Alert.svg';
import { ReactComponent as TrashIcon } from './icons/Trash.svg';
import { TextStyles } from '@payler/ui-theme';
import { MouseEvent, useCallback } from 'react';
import { formatBytes } from '../../utils';
import { useDropzoneComponentContext } from './DropzoneContext';

type DropzoneFileProps = {
  file: File;
  index: number;
};

const blocker = (e: MouseEvent<HTMLElement>) => e.stopPropagation();

export const DropzoneFile = ({ file, index }: DropzoneFileProps) => {
  const { removeFile, maxFileSize } = useDropzoneComponentContext();
  const isError = file.size > maxFileSize;

  const handleRemove = useCallback(() => {
    removeFile(index);
  }, [index, removeFile]);

  return (
    <HStack
      spacing={2}
      p={2}
      onClick={blocker}
      cursor="default"
      data-testid="dropzone-file"
    >
      <Icon as={isError ? AlertIcon : SuccessIcon} w={2} h={2} />
      <VStack spacing={0} alignItems="stretch" w="100%" overflow="auto">
        <HStack spacing={1} flex={1} overflow="auto">
          <Text
            textStyle={TextStyles.Buttons16Medium}
            color="primary.500"
            isTruncated
            data-testid="dropzone-file-filename"
          >
            {file.name}
          </Text>
          <Text
            textStyle={TextStyles.Caption12Regular}
            color="primary.300"
            whiteSpace="nowrap"
            data-testid="dropzone-file-size"
          >
            ({formatBytes(file.size)})
          </Text>
        </HStack>
        {isError && (
          <Text
            textStyle={TextStyles.Caption12Regular}
            color="red.500"
            data-testid="dropzone-file-error"
          >
            File size exceeded
          </Text>
        )}
      </VStack>

      <Icon
        as={TrashIcon}
        w={2}
        h={2}
        transition="fast"
        color="primary.350"
        _hover={{ color: 'primary.400' }}
        onClick={handleRemove}
        cursor="pointer"
        data-testid="dropzone-file-trash"
      />
    </HStack>
  );
};
