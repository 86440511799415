const sizes = {
  default: {
    height: '200px',
    width: '200px',
    padding: '14px',
    numberSize: 172,
  },
  small: {
    height: '110px',
    width: '110px',
    padding: '10px',
    numberSize: 90,
  },
  medium: {
    height: '140px',
    width: '140px',
    padding: '10px',
    numberSize: 120,
  },
  large: {
    height: '200px',
    width: '200px',
    padding: '10px',
    numberSize: 180,
  },
  xl: {
    height: '250px',
    width: '250px',
    padding: '17px',
    numberSize: 215,
  },
};

export const getSize = (size: keyof typeof sizes) => sizes[size];
