import { useEffect, useState } from 'react';

export function useDebounce<T>(
  value: T,
  time: number,
  initialValue?: T
): T | undefined {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(
    initialValue
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, setDebouncedValue, time]);

  return debouncedValue;
}
