import {
  useCancelYearMonthSelect,
  useCanChangeYearsInChooseMonth,
  useChosenYear,
  useSetYear,
} from './state';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ChevronDownIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';

export const MonthsHeader = () => {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      userSelect="none"
      data-testid="months-header"
    >
      <Prev />
      <Button />
      <Next />
    </HStack>
  );
};

const Button = () => {
  const cancel = useCancelYearMonthSelect();
  const year = useChosenYear();
  return (
    <HStack
      spacing={0.5}
      justifyContent="center"
      cursor="pointer"
      role="button"
      onClick={cancel}
      data-testid="button_change-state"
    >
      <Text as="h4" textStyle={TextStyles.BodyUI16Semibold}>
        {year}
      </Text>
      <Icon
        as={ChevronDownIcon}
        w="14px"
        h="14px"
        color="primary.300"
        transform="rotate(0.5turn)"
      />
    </HStack>
  );
};

const Prev = () => {
  const canChange = useCanChangeYearsInChooseMonth('prev');
  const setYear = useSetYear();
  const year = useChosenYear();
  const prev = () => year && setYear(year - 1);
  return (
    <Icon
      as={ChevronLeftIcon}
      w={3}
      h={3}
      onClick={canChange ? prev : undefined}
      cursor={canChange ? 'pointer' : 'not-allowed'}
      color={canChange ? 'primary.400' : 'primary.100'}
      _hover={{
        color: canChange ? 'primary.500' : 'primary.100',
      }}
      data-testid="prev"
    />
  );
};

const Next = () => {
  const canChange = useCanChangeYearsInChooseMonth('next');
  const setYear = useSetYear();
  const year = useChosenYear();
  const next = () => year && setYear(year + 1);

  return (
    <Icon
      as={ChevronRightIcon}
      w={3}
      h={3}
      onClick={canChange ? next : undefined}
      cursor={canChange ? 'pointer' : 'not-allowed'}
      color={canChange ? 'primary.400' : 'primary.100'}
      _hover={{
        color: canChange ? 'primary.500' : 'primary.100',
      }}
      data-testid="next"
    />
  );
};
