import { chakra } from '@chakra-ui/react';
import { emotionColors } from '@payler/ui-theme';

export const ThinDivider = chakra('div', {
  baseStyle: {
    border: 'none',
    borderWidth: 0,
    height: '1px',
    backgroundImage: `linear-gradient(to bottom, ${emotionColors.primary100} 0%, ${emotionColors.primary100} 51%, transparent 51%)`,
    backgroundSize: `auto 1px`,
    alignSelf: 'stretch',
  },
});
