import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { beautifyIban, getRecipientTitle } from '../../../helpers/recipient';
import { useRecipientIcon } from '../../../hooks/recipients/icon';
import { usePermissions } from '../../../hooks/use-permissions';
import {
  AMOUNT_FOR_MANDATORY_DESCRIPTION,
  AMOUNT_FOR_MANDATORY_DOCUMENT,
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import {
  useTransferFeeQuery,
  useTransferTypeQuery,
} from '../../../hooks/transfers/queries';
import { CompositionEvent, useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  TCreateOutgoingTransferDto,
  TFeeBaasProvider,
} from '@payler/api/client-office';
import {
  BankModalBody,
  BankModalFooter,
} from '../../../components/BankModal/BankModal';
import {
  Box,
  Button,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { getCurrencyIcon } from '../../../currency';
import { DetailsCard, TransferWizardAmountField } from './TransferDetailsForm';
import {
  DropzoneField,
  FloatingInputField,
  ThinDivider,
} from '@payler/ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import {
  MIN_DESCRIPTION_LENGTH,
  isValidDescriptionCharacter,
  useToasts,
} from '@payler/bank-utils';
import ExchangeDropdownField from '../../../components/ExchangeDropdown/ExchangeDropdownField';
import { TExchangeDropdownOption } from '../../../components/ExchangeDropdown/ExchangeDropdownOption';
import { useAccountsQuery } from '../../../hooks/accounts/queries';
import { ChevronCircledIcon } from '../../../icons';

export const TransferDetailsCommon = () => {
  const { t } = useTranslation(['accounts', 'transfers', 'common']);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const toasts = useToasts();
  const {
    setStep,
    setTransferDetails,
    recipient,
    senderAccount,
    setSenderAccount,
    setTransferSystem,
    setTransfersFee,
    setTransfer,
    isRepeatTransfer,
  } = useTransferWizardContext();
  const { data: accounts } = useAccountsQuery();

  const methods = useFormContext<TTransferDetailsForm>();
  const {
    formState: { errors },
  } = methods;

  const { isGlobalAccounts } = usePermissions();
  const recipientTitle = getRecipientTitle(recipient);
  const recipientIcon = useRecipientIcon(recipientTitle);

  const amount = methods.watch('amount');
  const files = methods.watch('files');
  const description = methods.watch('description');

  const { data: transferSystem } = useTransferTypeQuery({
    RecipientId: recipient?.id,
    Currency: senderAccount?.currency,
  });

  useEffect(() => {
    if (transferSystem) {
      setTransferSystem(transferSystem);
    }
  }, [setTransferSystem, transferSystem]);

  const { data: fee, isFetching } = useTransferFeeQuery(
    {
      BaasProvider: senderAccount?.baasProvider.shortName as TFeeBaasProvider,
      TransferType: 'outgoing',
      Amount: amount,
      System: transferSystem,
      Currency: senderAccount?.currency,
    },
    !!amount,
  );

  const handleSubmit = methods.handleSubmit((values) => {
    if (!senderAccount) {
      toasts.error(t('accounts:transferMoney.accountNotFound'));
      return;
    }
    if (!recipient?.account) {
      toasts.error(t('accounts:transferMoney.recipientAccountNotFound'));
      return;
    }
    const transfer: TCreateOutgoingTransferDto = {
      senderAccountId: senderAccount.id,
      sendingAmount: values?.amount ? Number(values.amount) : 0,
      recipientId: recipient?.id,
      description: values?.description ?? '',
      documents: values.files,
      system: transferSystem,
    };
    setTransfer(transfer);
    setTransferDetails(values);
    setStep('info');
  });

  useEffect(() => {
    setTransfersFee(fee);
  }, [fee, setTransfersFee]);

  const isDocumentRequiredAndEmpty =
    amount && amount >= AMOUNT_FOR_MANDATORY_DOCUMENT && isEmpty(files);

  const isDescriptionRequiredAndEmpty =
    amount &&
    amount >= AMOUNT_FOR_MANDATORY_DESCRIPTION &&
    (!description || description.length < MIN_DESCRIPTION_LENGTH);

  const isButtonDisabled =
    isDocumentRequiredAndEmpty ||
    isDescriptionRequiredAndEmpty ||
    isFetching ||
    !isEmpty(errors);

  const senderAccountOptions: TExchangeDropdownOption[] = useMemo(() => {
    if (!accounts) return [];
    return accounts?.reduce((accum, account) => {
      if (
        account.currency.toLowerCase() ===
          recipient?.account?.currency.toLowerCase() &&
        account.allowOutgoingOperations
      ) {
        accum.push({
          label: formatAmountByCurrency(account.amount, account.currency),
          value: account.id,
          caption: account.name,
          icon: getCurrencyIcon(account.currency),
        });
      }
      return accum;
    }, [] as TExchangeDropdownOption[]);
  }, [
    accounts,
    formatAmountByCurrency,
    recipient?.account.currency,
    senderAccount?.id,
  ]);

  const onSenderAccountChange = useCallback(
    (accountId: string) => {
      const selectedAccount = accounts?.find(
        (account) => account.id === accountId,
      );
      setSenderAccount(selectedAccount);
    },
    [accounts, setSenderAccount],
  );

  return (
    <>
      <BankModalBody>
        <VStack
          id="externalTransferDetails"
          spacing={2}
          height="100%"
          flex="1"
          alignItems="stretch"
          as="form"
          onSubmit={handleSubmit}
        >
          <Text textStyle={TextStyles.Subtitle14Regular}>
            {t('accounts:transferMoney.transferDetailsDescription')}
          </Text>
          <VStack
            width="100%"
            spacing={3}
            height="100%"
            justifyContent="space-between"
            flex="1"
          >
            <VStack width="100%" spacing={2} height="100%" alignItems="stretch">
              <VStack
                gap={0}
                borderRadius={1.5}
                boxShadow="detailsBlock"
                divider={<ThinDivider m="0 !important" />}
              >
                {senderAccount && (
                  <ExchangeDropdownField
                    fieldName="senderAccountId"
                    options={senderAccountOptions}
                    triggerWrapperProps={{
                      borderBottomRadius: 0,
                      boxShadow: 'none',
                      bgColor: 'secondary.500',
                    }}
                    optionLabelProps={{
                      textStyle: TextStyles.Subtitle14Medium,
                    }}
                    dropdownIcon={{
                      component: ChevronCircledIcon,
                      props: { h: '26px', w: '26px', color: 'primary.500' },
                    }}
                    onChange={(value) => onSenderAccountChange(value as string)}
                    isDisabled={isRepeatTransfer}
                  />
                )}
                <DetailsCard
                  icon={recipientIcon}
                  title={recipientTitle}
                  subtitle={
                    recipient?.account?.iban
                      ? beautifyIban(recipient?.account.iban)
                      : recipient?.account?.accountNumber
                  }
                  data-testid="card_recipient-account"
                />
              </VStack>
              <TransferWizardAmountField />
              <HStack w="100%" justifyContent="space-between">
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.400"
                >
                  {`${transferSystem ? t(`transfers:mappedSystems.${transferSystem}` as const) : ''} ${t(
                    'accounts:transferMoney.transfer',
                  )}`}
                </Text>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.400"
                >
                  {isFetching
                    ? t('common:loading')
                    : t('accounts:transferMoney.transferFee', {
                        amount: fee?.feeAmount || 0,
                        currency: senderAccount?.currency.toUpperCase(),
                      })}
                </Text>
              </HStack>
              <Controller
                name="description"
                render={({ field: { onChange, value, name } }) => (
                  <FloatingInputField
                    name={name}
                    label={t('accounts:transferMoney.description')}
                    isReadOnly={!isGlobalAccounts}
                    value={value || ''}
                    onChange={onChange}
                    onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                      if (!isValidDescriptionCharacter(e.data)) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />

              <Dropzone errors={errors} />
              <WarningComponent amount={amount} />
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          type="submit"
          form="externalTransferDetails"
          variant="primary"
          w="100%"
          isDisabled={isButtonDisabled}
          data-testid="button_continue"
        >
          {t('accounts:transferMoney.continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};

const Dropzone = ({
  errors,
}: {
  errors: FieldErrors<TTransferDetailsForm>;
}) => {
  const { t } = useTranslation(['accounts']);

  return (
    <VStack spacing={0} alignItems="stretch">
      <DropzoneField
        name="files"
        title={t('accounts:transferMoney.dropzone.title')}
        description={t('accounts:transferMoney.dropzone.description')}
      />
      {errors.files && (
        <Text
          textStyle={TextStyles.BodyUI14Regular}
          px={1.5}
          mt={1}
          color="red.500"
        >
          {errors.files.message}
        </Text>
      )}
    </VStack>
  );
};

const WarningComponent = ({ amount }: { amount?: number }) => {
  if (!amount || amount < AMOUNT_FOR_MANDATORY_DESCRIPTION) return null;

  return (
    <Box
      textAlign="center"
      px={2}
      py={1}
      borderRadius={1}
      border="1px solid"
      borderColor="yellow.300"
      backgroundColor="yellow.300"
    >
      {amount >= AMOUNT_FOR_MANDATORY_DOCUMENT ? (
        <UnorderedList>
          <ListItem style={{ textAlign: 'start' }}>
            <RequiredDescriptionWarning />
          </ListItem>
          <ListItem style={{ textAlign: 'start' }}>
            <RequiredDocumentWarning />
          </ListItem>
        </UnorderedList>
      ) : (
        <RequiredDescriptionWarning />
      )}
    </Box>
  );
};

const RequiredDocumentWarning = () => (
  <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
    <Trans
      i18nKey="accounts:transferMoney.documentWarning"
      ns={['accounts']}
      components={{
        bold: <Text as="span" textStyle={TextStyles.Subtitle14Medium} />,
      }}
    />
  </Text>
);

const RequiredDescriptionWarning = () => (
  <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
    <Trans
      i18nKey="accounts:transferMoney.descriptionWarning"
      ns={['accounts']}
      components={{
        bold: <Text as="span" textStyle={TextStyles.Subtitle14Medium} />,
      }}
    />
  </Text>
);
