import { ComponentType, lazy, Suspense } from 'react';
import { LoaderBox } from '@payler/ui-components';

const withLazyLoader = (loader: () => Promise<{ default: ComponentType }>) => {
  const Component = lazy(loader);
  return () => (
    <Suspense fallback={<LoaderBox />}>
      <Component />
    </Suspense>
  );
};

export const SpushOnboardingPageLazy = withLazyLoader(() =>
  import('../pages/SpushOnboardingPage/SpushOnboardingPage').then((module) => ({
    default: module.SpushOnboardingPage,
  }))
);

export const SpushRejectionPageLazy = withLazyLoader(() =>
  import('../pages/SpushOnboardingPage/RejectionPage').then((module) => ({
    default: module.RejectionPage,
  }))
);

export const SpushPaymentPageLazy = withLazyLoader(() =>
  import('../pages/PaymentPage/PaymentPage').then((module) => ({
    default: module.PaymentPage,
  }))
);
