import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Link as RouterDomLink } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import { Helmet } from 'react-helmet-async';

export const ErrorPage = () => {
  const { t } = useTranslation(['titles', 'common']);
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      h="100%"
      justifyContent="center"
    >
      <Helmet title={t('titles:errorPage')} />
      <Box mb={5}>
        <Text
          textStyle={TextStyles.code}
          fontWeight="500"
          fontSize="104px"
          color="primary.100"
          lineHeight="104px"
        >
          50X
        </Text>
      </Box>
      <Text
        as="h2"
        textStyle={TextStyles.h2}
        fontSize="40px"
        fontWeight="500"
        lineHeight="48px"
      >
        {t('common:errorPage.header')}
      </Text>
      <Box mt={1}>
        <Text
          textStyle={TextStyles.BodyText16Regular}
          color="primary.400"
          fontSize="20px"
          lineHeight="28px"
        >
          {t('common:errorPage.description')}
        </Text>
      </Box>

      <Box mt={5}>
        <Link
          to="#"
          as={RouterDomLink}
          onClick={window.location.reload}
          colorScheme="primary"
        >
          {t('common:errorPage.linkBack')}
        </Link>
      </Box>
    </Flex>
  );
};

export default ErrorPage;
