import React from 'react';
import { ReactComponent } from '*.svg';
import { Badge, Box, HStack, Icon, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { emotionColors, emotionTextStyles } from '@payler/ui-theme';
import { ClassNames } from '@emotion/react';

const linkStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  alignSelf: 'stretch',
  borderRadius: '12px',
  border: `0.5px solid transparent`,
  color: emotionColors.primary350,
  ...emotionTextStyles.subtitle14medium,
  '&:hover': {
    textDecoration: 'none',
    outline: 'none',
    color: emotionColors.brands500,
    '& svg': {
      color: emotionColors.brands500,
    },
  },
  '&:focus': {
    outline: 'none',
  },
  '& svg': {
    color: emotionColors.primary350,
  },
};

const activeLinkStyle = {
  ...linkStyle,
  color: emotionColors.brands500,
  backgroundColor: emotionColors.brands100,
  '& svg': {
    color: emotionColors.brands500,
  },
};

const disabledLinkStyle = {
  ...linkStyle,
  color: emotionColors.primary300,
  '&:hover': {
    color: 'none',
    cursor: 'default',
  },
  '& svg': {
    color: emotionColors.primary300,
  },
};

const bottomLinkStyle = {
  ...linkStyle,
  color: emotionColors.primary400,
  '&:hover': {
    ...linkStyle['&:hover'],
    color: emotionColors.primary500,
    '& svg': {
      color: emotionColors.primary500,
    },
  },
};

const bottomLinkActiveStyle = {
  ...bottomLinkStyle,
  color: emotionColors.brands500,
  '&:hover': {
    color: emotionColors.brands500,
  },
  '& svg': {
    color: emotionColors.brands500,
  },
};

export const MenuLink: React.FC<{
  route: string;
  title: string;
  icon: typeof ReactComponent;
  badge?: string;
  isDisabled?: boolean;
}> = ({ icon, route, title, badge, isDisabled, ...rest }) => {
  return (
    <ClassNames>
      {({ css }) => {
        const active = css(activeLinkStyle);
        const normal = css(linkStyle);
        const disabled = css(disabledLinkStyle);
        return !isDisabled ? (
          <NavLink
            to={route}
            className={({ isActive }) => (isActive ? active : normal)}
            {...rest}
          >
            <HStack spacing={0}>
              <Icon as={icon} mr={2} w="20px" h="20px" />
              <Text>{title}</Text>
            </HStack>
            {badge && <Badge variant="green">{badge}</Badge>}
          </NavLink>
        ) : (
          <Box className={disabled}>
            <HStack spacing={0}>
              <Icon as={icon} mr={2} w="20px" h="20px" />
              <Text>{title}</Text>
            </HStack>
            {badge && <Badge variant="grey">{badge}</Badge>}
          </Box>
        );
      }}
    </ClassNames>
  );
};

export const BottomMenuLink: React.FC<{
  route: string;
  title: string;
  icon: typeof ReactComponent;
}> = ({ icon, route, title }) => {
  return (
    <ClassNames>
      {({ css }) => {
        const active = css(bottomLinkActiveStyle);
        const normal = css(bottomLinkStyle);
        return (
          <NavLink
            to={route}
            className={({ isActive }) => (isActive ? active : normal)}
          >
            <Icon as={icon} mr={2} w="20px" h="20px" color="primary.300" />
            {title}
          </NavLink>
        );
      }}
    </ClassNames>
  );
};
