import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { createRecoilKey } from '../../state/createKey';
import { useCallback, useMemo } from 'react';

const transferFiltersOpenedAtom = atom<boolean>({
  key: createRecoilKey('transferFiltersOpenedAtom'),
  default: false,
});
const transferFiltersErrorAtom = atom<unknown>({
  key: createRecoilKey('transferFiltersErrorAtom'),
  default: undefined,
});

export const useTransferFiltersDrawer = () => {
  const setOpenState = useSetRecoilState(transferFiltersOpenedAtom);
  const isOpen = useRecoilValue(transferFiltersOpenedAtom);
  const error = useRecoilValue(transferFiltersErrorAtom);
  const setErrorState = useSetRecoilState(transferFiltersErrorAtom);

  const open = useCallback(() => {
    setOpenState(true);
  }, [setOpenState]);
  const close = useCallback(() => {
    setOpenState(false);
  }, [setOpenState]);
  const setError = useCallback(
    (e: unknown) => {
      setErrorState(e);
    },
    [setErrorState],
  );
  return useMemo(
    () => ({ open, close, isOpen, error, setError }),
    [open, close, isOpen, error, setError],
  );
};
