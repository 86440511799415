import { motion } from 'framer-motion';
import { chakra } from '@chakra-ui/react';

export type HamburgerProps = {
  onClick?: VoidFunction;
  className?: string;
  isOpen: boolean;
};

const HamburgerComp = ({ onClick, className, isOpen }: HamburgerProps) => {
  return (
    <motion.div
      className={className}
      onClick={onClick}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <motion.path
          strokeWidth={2.5}
          stroke="currentColor"
          variants={{
            closed: { d: 'M2 4l20 0' },
            open: { d: 'M 6 6 l 12 12' },
          }}
        />
        <motion.path
          strokeWidth={2.5}
          stroke="currentColor"
          d="M2 12L22 12"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <motion.path
          strokeWidth={2.5}
          stroke="currentColor"
          variants={{
            closed: { d: 'M2 20l20 0' },
            open: { d: 'M 6 18 l12 -12' },
          }}
        />
      </svg>
    </motion.div>
  );
};

export const Hamburger = chakra(HamburgerComp);

export default HamburgerComp;
