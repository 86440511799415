import { Text, TextProps } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { TextStyles, emotionTextStyles } from '@payler/ui-theme';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

type TCutDirection = 'left' | 'right' | 'both';

type TDynamicShortTextProps = Omit<TextProps, 'textStyle' | 'children'> & {
  children?: string;
  /** Максимальная ширина текста в px, до которой необходимо сократить текст */
  maxTextWidth: number;
  textStyle: TextStyles;
  /** Направление обрезки текста, по умолчанию = 'both' */
  cutDirection?: TCutDirection;
  /** Лимит обрезки текста по направлению обрезки, по умолчанию = 1 */
  cutLimit?: number;
  /** Количество защищенных символов с обратной от обрезания стороны (для both не используется), по умолчанию = 1 */
  protectedSymbolsCount?: number;
  /** Разделитель для текста (по умолчанию троеточие) */
  divider?: string;
};

const MIN_DEFAULT_CUT_LIMIT = 1;

// Мапинг текстовых стилей chakra к текстовым стилям emotion
const mappingChakraToEmotionTextStyles: Record<TextStyles, CSSObject> = {
  [TextStyles.h1]: emotionTextStyles.h1,
  [TextStyles.h2]: emotionTextStyles.h2,
  [TextStyles.h3]: emotionTextStyles.h3,
  [TextStyles.h4]: emotionTextStyles.h4,
  [TextStyles.BodyUI14Semibold]: emotionTextStyles.bodyUI14Semibold,
  [TextStyles.BodyUI14Medium]: emotionTextStyles.bodyUI14Medium,
  [TextStyles.BodyUI14Regular]: emotionTextStyles.bodyUI14Regular,
  [TextStyles.BodyUI16Semibold]: emotionTextStyles.bodyUI16Semibold,
  [TextStyles.BodyUI16Medium]: emotionTextStyles.bodyUI16Medium,
  [TextStyles.BodyUI16Regular]: emotionTextStyles.bodyUI16Regular,
  [TextStyles.BodyText16Regular]: emotionTextStyles.bodyText16regular,
  [TextStyles.BodyText16Semibold]: emotionTextStyles.bodyText16semibold,
  [TextStyles.BodyText16Medium]: emotionTextStyles.bodyText16medium,
  [TextStyles.BodyText14Medium]: emotionTextStyles.bodyText14medium,
  [TextStyles.Subtitle14Semibold]: emotionTextStyles.subtitle14semibold,
  [TextStyles.Subtitle14Medium]: emotionTextStyles.subtitle14medium,
  [TextStyles.Subtitle14Regular]: emotionTextStyles.subtitle14regular,
  [TextStyles.Caption12Semibold]: emotionTextStyles.caption12semibold,
  [TextStyles.Caption12Medium]: emotionTextStyles.caption12medium,
  [TextStyles.Caption12Regular]: emotionTextStyles.caption12regular,
  [TextStyles.Tagline14Medium]: emotionTextStyles.tagline14medium,
  [TextStyles.Tagline10Bold]: emotionTextStyles.tagline10bold,
  [TextStyles.Buttons16Medium]: emotionTextStyles.buttons16medium,
  [TextStyles.Buttons12Small]: emotionTextStyles.buttons12small,
  [TextStyles.Buttons12Medium]: emotionTextStyles.buttons12medium,
  [TextStyles.code]: emotionTextStyles.additional14code,
  [TextStyles.exchange]: emotionTextStyles.additional16exchange,
  [TextStyles.tables]: emotionTextStyles.additional15tables,
  [TextStyles.link]: emotionTextStyles.additional15link,
};

// Конвертация CamelCase в kebab-case
const toKebabCase = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

// Конвертация CSSObject в нативный CSS
const convertJsStyleToCss = (obj: CSSObject | undefined) => {
  if (!obj) return '';
  let result = '';
  for (const key in obj) {
    if (obj[key]) {
      result += `${toKebabCase(key)}: ${obj[key]};`;
    }
  }
  return result;
};

// Хук запускает загрузку указанного семейства шрифтов и проверяет состояние загруженности любого шрифта из указанного семейства
const useAnyFamilyFontFaceLoaded = (fontFamily: string) => {
  const [isLoaded, setIsLoaded] = useState(false);
  // ref нужен для отмены наблюдения за предыдущим семейством при изменении семейства
  const fontFamilyRef = useRef(fontFamily);

  // синхронизируем реф и сбрасываем состояние загрузки
  useEffect(() => {
    fontFamilyRef.current = fontFamily;
  }, [fontFamily]);

  useEffect(() => {
    const promises: Promise<FontFace>[] = [];
    let isAlreadyLoaded = false;

    document.fonts.forEach((font) => {
      // Ищем шрифты семейства, которое было захвачено в эффект
      if (fontFamily === font.family) {
        if (font.status !== 'loaded' && font.status !== 'loading') {
          // Если шрифт ещё не грузится, то запускаем загрузку
          font.load();
        }
        promises.push(font.loaded);
        if (font.status === 'loaded') {
          isAlreadyLoaded = true;
        }
      }
    });

    // Устанавливаем текущее состояние загрузки
    setIsLoaded(isAlreadyLoaded);

    if (!isAlreadyLoaded) {
      // Если шрифт изначально не загружен, то ждем первое выполнение из найденных
      Promise.race(promises).then((font) => {
        if (font.family === fontFamilyRef.current) {
          // Если во время загрузки не изменилось семейство, то считаем что загрузилось то что нужно
          setIsLoaded(true);
        }
      });
    }
  }, [fontFamily]);

  return isLoaded;
};

export const DynamicShortText: FC<TDynamicShortTextProps> = ({
  children,
  maxTextWidth,
  textStyle,
  cutDirection = 'both',
  cutLimit = MIN_DEFAULT_CUT_LIMIT,
  protectedSymbolsCount = MIN_DEFAULT_CUT_LIMIT,
  divider = '...',
  ...rest
}) => {
  const [shortText, setShortText] = useState<string>();
  const styleString = useMemo(
    () => convertJsStyleToCss(mappingChakraToEmotionTextStyles[textStyle]),
    [textStyle]
  );

  // Проверяем только первый шрифт, так как если проверять все указанные шрифты, то они все никогда не загрузятся
  const firstFontFace = useMemo(
    () =>
      String(mappingChakraToEmotionTextStyles[textStyle].fontFamily ?? '')
        .split(',')
        .map((v) => ({ family: v }))[0] ?? { family: '' },
    [textStyle]
  );
  const isFontLoaded = useAnyFamilyFontFaceLoaded(firstFontFace.family);

  // Данный эффект запустит расчет короткого текста
  useEffect(() => {
    if (!children || cutLimit < 0 || protectedSymbolsCount < 0) {
      setShortText(children);
    } else if (isFontLoaded) {
      const { shortText } = getShortText(
        children,
        maxTextWidth,
        styleString,
        cutDirection,
        cutLimit,
        protectedSymbolsCount,
        divider
      );
      setShortText(shortText);
    }
  }, [
    children,
    cutDirection,
    cutLimit,
    divider,
    isFontLoaded,
    maxTextWidth,
    protectedSymbolsCount,
    styleString,
  ]);

  return (
    <Text textStyle={textStyle} {...rest}>
      {shortText}
    </Text>
  );
};

const getShortText = (
  value: string,
  maxWidth: number,
  styleString: string,
  cutDirection: TCutDirection,
  cutLimit: number,
  protectedSymbolsCount: number,
  divider: string
): { shortText: string; isShorted: boolean; originalText: string } => {
  if (
    (cutDirection === 'both' && value.length < divider.length + cutLimit * 2) ||
    (cutDirection !== 'both' &&
      value.length < divider.length + cutLimit + protectedSymbolsCount)
  ) {
    // текст нет смысла обрезать, он не станет меньше
    return {
      isShorted: false,
      originalText: value,
      shortText: value,
    };
  }

  const element = document.createElement('span');
  element.style.cssText = styleString;
  element.style.visibility = 'hidden';
  element.style.position = 'absolute';
  document.body.appendChild(element);

  const shortText = calcShort(
    element,
    value,
    maxWidth,
    divider,
    cutDirection,
    cutLimit,
    protectedSymbolsCount
  );

  element.remove();

  return {
    isShorted: value !== shortText,
    originalText: value,
    shortText,
  };
};

const calcShort = (
  element: HTMLSpanElement,
  text: string,
  maxWidth: number,
  divider: string,
  cutDirection: TCutDirection,
  cutLimit: number,
  protectedSymbolsCount: number,
  // Индекс уже добавленного разделителя в тексте
  dividerIndex = -1
) => {
  let shortText = text;
  element.innerText = text;

  const width = element.getBoundingClientRect().width;
  if (maxWidth < width) {
    // Пытаемся сократить ещё раз
    let newDividerIndex = dividerIndex;
    let leftText = '';
    let rightText = '';

    // Разбиваем текст на две части
    if (dividerIndex < 0) {
      // Не было ранее разделителя, первый проход
      if (cutDirection === 'both') {
        const startDividerIndex = Math.floor(text.length / 2);
        leftText = text.substring(0, startDividerIndex);
        rightText = text.slice(startDividerIndex);
      } else if (cutDirection === 'right') {
        const startDividerIndex = protectedSymbolsCount;
        leftText = text.substring(0, startDividerIndex);
        rightText = text.slice(startDividerIndex);
      } else {
        const startDividerIndex = text.length - protectedSymbolsCount;
        leftText = text.substring(0, startDividerIndex);
        rightText = text.slice(startDividerIndex);
      }
    } else {
      // Ранее уже был добавлен разделитель
      leftText = text.substring(0, dividerIndex);
      rightText = text.substring(dividerIndex + divider.length, text.length);
    }

    // Проверяем можно ли ещё сокращать
    if (
      !isCanShortedMore(
        leftText,
        rightText,
        cutDirection,
        cutLimit,
        protectedSymbolsCount
      )
    ) {
      // Сокращать больше нельзя, возвращаем что получилось
      return shortText;
    }

    // Сокращаем текст согласно правилам
    let newShortText = '';
    if (cutDirection === 'both') {
      if (leftText.length > rightText.length) {
        // Сокращаем начало, так как оно больше
        newShortText = `${leftText.slice(0, -1)}${divider}${rightText}`;
        newDividerIndex = leftText.length - 1;
      } else {
        // Сокращаем конец, так как он больше
        newShortText = `${leftText}${divider}${rightText.slice(1)}`;
        newDividerIndex = leftText.length;
      }
    } else if (cutDirection === 'right') {
      // Сокращаем правую часть, так как только её разрешено сократить
      newShortText = `${leftText}${divider}${rightText.slice(1)}`;
      newDividerIndex = leftText.length;
    } else {
      // Сокращаем левую часть, так как только её разрешено сократить
      newShortText = `${leftText.slice(0, -1)}${divider}${rightText}`;
      newDividerIndex = leftText.length - 1;
    }

    shortText = calcShort(
      element,
      newShortText,
      maxWidth,
      divider,
      cutDirection,
      cutLimit,
      protectedSymbolsCount,
      newDividerIndex
    );
  }

  return shortText;
};

const isCanShortedMore = (
  leftText: string,
  rightText: string,
  cutDirection: TCutDirection,
  cutLimit: number,
  protectedSymbolsCount: number
) => {
  if (
    cutDirection === 'left' &&
    leftText.length <= cutLimit &&
    rightText.length <= protectedSymbolsCount
  ) {
    // Если со стороны обрезания достигли лимита, а с другой достигли защищенных символов, то продолжать нельзя
    return false;
  } else if (
    cutDirection === 'right' &&
    leftText.length <= protectedSymbolsCount &&
    rightText.length <= cutLimit
  ) {
    // Если со стороны обрезания достигли лимита, а с другой достигли защищенных символов, то продолжать нельзя
    return false;
  } else if (
    cutDirection === 'both' &&
    leftText.length <= cutLimit &&
    rightText.length <= cutLimit
  ) {
    // Если достигли лимитов с обоих сторон, то продолжать нельзя
    return false;
  }
  return true;
};
