import React, { useMemo } from 'react';
import { DEFAULT_ROWS_PER_PAGE } from './const';
import { useTranslation } from 'react-i18next';
import Dropdown from '../Dropdown/Dropdown';
import { Flex, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

interface RowsPerPageProps {
  currentRowPerPage: number;
  rowsPerPage?: number[];
  setPageSize: (option: number) => void;
  showRowsPerPageLabel: boolean;
  showRowsPerPage: boolean;
}

export const RowsPerPage: React.FC<RowsPerPageProps> = React.memo((props) => {
  const { t } = useTranslation();
  // Выводить на страницу по х - записей
  const rowPerPageOptions = useMemo(
    () =>
      (props?.rowsPerPage || DEFAULT_ROWS_PER_PAGE).map((pageSize) => ({
        label: String(pageSize),
        value: pageSize,
      })),
    [props?.rowsPerPage]
  );

  if (!props.showRowsPerPage) return null;

  return (
    <Flex
      flexShrink={0}
      alignItems="center"
      data-testid="rows-per-page-container"
    >
      {props.showRowsPerPageLabel && (
        <Text
          as="span"
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
          textAlign="right"
          mr={2}
        >
          {t('pager.rowsPerPage', 'Rows Per Page')}
        </Text>
      )}
      <Dropdown
        triggerWrapperProps={{ minH: 6, minW: 9 }}
        options={rowPerPageOptions}
        value={props.currentRowPerPage}
        onChange={(v) => props.setPageSize(Number(v) ?? 10)}
        hideClearButton
        alignOptionsCenter
        testId="rows-per-page-select"
      />
    </Flex>
  );
});
RowsPerPage.displayName = 'RowsPerPage';
