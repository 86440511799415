import React, { FC, ReactNode } from 'react';
import { Icon, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ReactComponent } from '*.svg';
import { useTranslation } from 'react-i18next';
import { Alert } from '../Alert/Alert';

export type NotificationWithIconProps = {
  icon?: typeof ReactComponent;
  title?: ReactNode;
  text?: ReactNode;
  errorText?: string;
};
export const NotificationWithIcon: FC<NotificationWithIconProps> = ({
  title,
  text,
  icon,
  errorText,
}) => {
  const { t } = useTranslation('common');
  return (
    <VStack w="100%" alignItems="stretch" mb={errorText ? 3 : 5}>
      {icon && <Icon as={icon} w={8} h={8} mb={5} alignSelf="center" />}
      {title && (
        <Text textStyle={TextStyles.h1} textAlign="center" color="primary.500">
          {title}
        </Text>
      )}
      {typeof text === 'string' ? (
        <Text
          textStyle={TextStyles.BodyText16Regular}
          textAlign="center"
          color="primary.400"
        >
          {text}
        </Text>
      ) : (
        text
      )}
      {errorText && (
        <Alert
          status="error"
          label={t('error')}
          description={errorText}
          mt={3}
        />
      )}
    </VStack>
  );
};
