import * as React from 'react';
import { forwardRef, MouseEvent, ReactNode, useCallback } from 'react';
import {
  Box,
  HStack,
  Icon,
  Spinner,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FloatingLabel } from './FloatingLabel';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { ClearButton } from '../PaylerDatepicker/fields/ClearButton';
import { TDropdownOption } from './types';
import { TriggerWrapper } from './TriggerWrapper';

export type TDropdownTriggerProps = {
  floatingLabel?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedOption?: TDropdownOption;
  onClear?: VoidFunction;
  noEllipsis?: boolean;
  hideClearButton?: boolean;
  placeholder?: string;
  triggerWrapperProps?: Omit<StackProps, 'borderColor'>;
  isSearchable?: boolean;
  searchField?: ReactNode;
  isFocused?: boolean;
  isLoading?: boolean;
};

export const DropdownTrigger = forwardRef<
  HTMLDivElement,
  TDropdownTriggerProps
>((props, ref) => {
  const borderColor = getBorderColor(!!props.isActive, !!props.isError);

  const bg = getBgColor(props);

  const hasValue =
    props.searchField && props.isSearchable
      ? !!props.selectedOption || !!props.isFocused || !!props.isActive
      : !!props.selectedOption;
  const cursor = props.isDisabled ? 'not-allowed' : 'pointer';

  const valuePadding = getValuePadding(props);
  const textColor = getTextColor(props);

  const chevronColor = props.isActive ? 'primary.500' : 'primary.300';
  const chevronTransform = props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)';

  const showIcon = props.selectedOption?.icon && !props.floatingLabel;

  const text =
    props.selectedOption?.label ??
    (props.floatingLabel ? undefined : props.placeholder);

  const showClearButton =
    !props.hideClearButton && hasValue && !props.isDisabled;

  const handleClear = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      props.onClear?.();
    },
    [props],
  );

  return (
    <TriggerWrapper
      ref={ref}
      borderColor={borderColor}
      cursor={cursor}
      bg={bg}
      {...props.triggerWrapperProps}
    >
      {props.floatingLabel && (
        <FloatingLabel
          label={props.floatingLabel}
          hasValue={hasValue}
          isDisabled={props.isDisabled}
        />
      )}
      {props.isSearchable && props.searchField ? (
        props.searchField
      ) : (
        <Box minW={0} flex={1}>
          {text && (
            <HStack
              spacing={1}
              padding={valuePadding}
              transitionDuration="fast"
            >
              {showIcon && (
                <Icon
                  as={props.selectedOption?.icon}
                  w={3}
                  h={3}
                  flexShrink={0}
                  flexBasis={3}
                />
              )}
              <Box minW={0} flexShrink={1}>
                <Text
                  {...getEllipsisProps(props)}
                  color={textColor}
                  transitionDuration="fast"
                  textStyle={TextStyles.BodyUI16Regular}
                >
                  {text}
                </Text>
              </Box>
            </HStack>
          )}
        </Box>
      )}
      {showClearButton && <ClearButton onClick={handleClear} mx={1} />}
      {props.isLoading ? (
        <Spinner
          boxSize={2}
          color="brands.500"
          data-testid="dropdown-spinner"
        />
      ) : (
        <Icon
          as={ChevronDownIcon}
          transitionDuration="fast"
          color={chevronColor}
          transform={chevronTransform}
        />
      )}
    </TriggerWrapper>
  );
});

function getValuePadding(props: TDropdownTriggerProps) {
  return !!props.selectedOption && props.floatingLabel ? '24px 0 8px' : '0 0';
}

function getTextColor(props: TDropdownTriggerProps) {
  if (props.isDisabled) {
    return 'primary.300';
  }
  const textIsPlaceholder =
    !!props.selectedOption && props.placeholder && !props.floatingLabel;
  return textIsPlaceholder ? 'primary.350' : 'primary.500';
}

function getEllipsisProps(props: TDropdownTriggerProps): TextProps {
  if (props.noEllipsis) return {};
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };
}

function getBgColor(props: TDropdownTriggerProps) {
  if (props.isError) {
    return 'red.100';
  }
  return props.isActive ? 'brands.100' : 'primary.25';
}

function getBorderColor(isActive: boolean, isError: boolean) {
  if (isError) return 'red.500';
  return isActive ? 'brands.500' : 'primary.100';
}
