import { Box } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useGetAxiosError } from './use-get-axios-error';
import { useToasts } from './use-toasts';

export const useHandleToastError = () => {
  const getError = useGetAxiosError();
  const toasts = useToasts();

  return useCallback(
    (e: unknown) => {
      const { errorTitle, fieldErrors, errorCode } = getError(e);
      const description = fieldErrors ? (
        <Box>
          {fieldErrors?.map((item, index) => (
            <Box key={index}>{`${item.fieldName}: ${item.errorMessage}`}</Box>
          ))}
        </Box>
      ) : (
        errorCode
      );
      toasts.error(description, errorTitle);
    },
    [getError, toasts],
  );
};
