import { ComponentStyleConfig } from '@chakra-ui/react';

export const PopoverStyles: ComponentStyleConfig = {
  baseStyle: {
    popper: {
      zIndex: 'popover',
    },
  },
  variants: {
    dropdown: {
      popper: {
        boxShadow: 'dropdown',
        borderRadius: 1.5,
        minW: 'unset !important',
      },
      content: {
        bg: 'secondary.500',
        border: '1px solid',
        borderColor: 'primary.100',
        borderRadius: 1.5,
        width: 'unset',
        maxW: '100%',
        minW: 'unset',
      },
    },
  },
};
