import { URLSearchParams } from 'url';
import {
  TRANSFER_FX_ID,
  TRANSFER_INCOMING_ID,
  TRANSFER_OUTGOING_ID,
} from './queries';
import { SetURLSearchParams } from 'react-router-dom';

export const deleteTransferIdsFromUrl = (
  params: URLSearchParams,
  setParams?: SetURLSearchParams,
) => {
  params.delete(TRANSFER_INCOMING_ID);
  params.delete(TRANSFER_OUTGOING_ID);
  params.delete(TRANSFER_FX_ID);
  setParams?.(params);
};
