import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  useControllableState,
} from '@chakra-ui/react';
import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarButton } from './CalendarButton';
import { DateInputProps } from './types';
import { ClearButton } from './ClearButton';
import { InputMask, MaskEvent } from '@react-input/mask';
import { RequiredStyledProvider } from '../../../required-styled-provider';

/**
 * Поле ввода даты с маской и кнопкой с календариком
 */
export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const [value, setValue] = useControllableState<string>({
      value: props.value,
      onChange: props.onChange,
      defaultValue: '',
    });

    const { onCalendarButtonClick, showClearButton = true } = props;
    const { dateFormat = 'DD-MM-YYYY' } = props;

    const handleClear = () => {
      setValue('');
      props.onClear?.();
    };

    const inputPlaceholder = t(`datePicker.${dateFormat}`, dateFormat);
    const inputVariant = props.floating ? 'floating' : undefined;
    const showCalendarButton = !!onCalendarButtonClick;
    const showButtons = showClearButton || showCalendarButton;
    const inputPaddingRight = showClearButton || showCalendarButton ? 4 : 2;

    return (
      <FormControl isInvalid={props.isError}>
        <RequiredStyledProvider>
          <InputGroup w={props.w} as="fieldset">
            {!props.floating && (
              <FormLabel as="legend">{props.label}</FormLabel>
            )}
            <InputMask
              component={Input}
              inputMode="decimal"
              ref={ref}
              variant={inputVariant}
              placeholder={inputPlaceholder}
              autoComplete="off"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value === dateFormat ? '' : e.target.value)
              }
              mask={dateFormat}
              replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
              separate={true}
              onBlur={props.onBlur}
            />
            {props.floating && (
              <FormLabel variant="floating">{props.label}</FormLabel>
            )}
            {showButtons && (
              <InputRightElement
                w={inputPaddingRight}
                transitionProperty="none"
              >
                <HStack
                  spacing={0}
                  w={inputPaddingRight}
                  transitionProperty="none"
                >
                  {showClearButton && value ? (
                    <ClearButton onClick={handleClear} />
                  ) : (
                    <CalendarButton onClick={onCalendarButtonClick} />
                  )}
                </HStack>
              </InputRightElement>
            )}
          </InputGroup>
          {props.children}
        </RequiredStyledProvider>
      </FormControl>
    );
  },
);

export default DateInput;
