import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { theme as paylerTheme } from '@payler/ui/client-office';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'payler',
};

export const theme = extendTheme({
  ...paylerTheme,
  config,
  styles: {
    global: {
      html: {
        'overscroll-behavior': 'none', // https://payler.atlassian.net/browse/TT-266
      },
      body: {
        'overscroll-behavior': 'none', // https://payler.atlassian.net/browse/TT-266
      },
      a: {
        '-webkit-touch-callout': 'none', // https://finlegoworkspace.slack.com/archives/C073Y0A7GLS/p1726071573862899?thread_ts=1725373567.155869&cid=C073Y0A7GLS
      },
    },
  },
});
