import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import {
  TTransferFiltersUrlParams,
  TTransferStateDto,
  TTransferSystemDto,
} from '@payler/api/client-office';
import { omitBy } from 'lodash';
import {
  TCurrency,
  isNilOrEmpty,
  stringArrayOrUndefined,
  stringOrUndefined,
} from '@payler/bank-utils';
import {
  TConvertDateToDate,
  useLanguageFeatures,
} from '../use-language-features';
import { TTransferFiltersForm } from '../../forms/TransferFiltersForm/helpers';

const useQueryToApiTransferParams = (
  params: URLSearchParams,
): TTransferFiltersUrlParams => {
  const { serverDateToServerDatetime } = useLanguageFeatures();
  const queryPars = {
    AccountId: stringOrUndefined(params, 'AccountId'),
    States: stringArrayOrUndefined(
      params.getAll('States'),
    ) as TTransferStateDto[],
    CreatedFrom: serverDateToServerDatetime(params.get('CreatedFrom'), 'start'),
    CreatedTo: serverDateToServerDatetime(params.get('CreatedTo'), 'end'),
    Currency: stringOrUndefined(params, 'Currency') as TCurrency,
    Type: stringOrUndefined(params, 'Type'),
    System: stringOrUndefined(params, 'System') as TTransferSystemDto,
    AmountFrom: params.get('AmountFrom') ?? undefined,
    AmountTo: params.get('AmountTo') ?? undefined,
  };
  return queryPars;
};

export const useTransfersQueryParamsFromUrl = () => {
  const [params] = useSearchParams();
  const queryParams = useQueryToApiTransferParams(params);
  return useMemo(() => queryParams, [queryParams]);
};

export const useSelectedTransfersFiltersCount = () => {
  const params = useTransfersQueryParamsFromUrl();
  return Object.entries(omitBy(params, isNilOrEmpty)).reduce((p) => p + 1, 0);
};

const useUrlParamsToFormValues = (
  params: URLSearchParams,
): TTransferFiltersForm => {
  const { serverDateToUiDate } = useLanguageFeatures();
  return {
    createdFrom: serverDateToUiDate(params.get('CreatedFrom')) ?? '',
    createdTo: serverDateToUiDate(params.get('CreatedTo')) ?? '',
    accountId: params.get('AccountId') ?? '',
    currency: params.get('Currency') ?? '',
    type: params.get('Type') ?? '',
    system: params.get('System') ?? '',
    status: params.getAll('States') ?? null,
    amountFrom: params.get('AmountFrom') ?? null,
    amountTo: params.get('AmountTo') ?? null,
  } as TTransferFiltersForm;
};

const formValuesToParams = (
  values: TTransferFiltersForm,
  uiToUrlDate: TConvertDateToDate,
): TTransferFiltersUrlParams => {
  const tmp = {
    AccountId: values.accountId ?? undefined,
    States: values.status ?? undefined,
    Currency: values.currency ?? undefined,
    Type: values.type ?? undefined,
    System: values.system ?? undefined,
    CreatedFrom: uiToUrlDate(values.createdFrom),
    CreatedTo: uiToUrlDate(values.createdTo),
    AmountFrom: values.amountFrom ?? undefined,
    AmountTo: values.amountTo ?? undefined,
  } as TTransferFiltersUrlParams;
  return omitBy(tmp, isNilOrEmpty);
};

export const useTransfersUrlParamsToFormValues = () => {
  const [params] = useSearchParams();
  const formValues = useUrlParamsToFormValues(params);
  return useMemo(() => formValues, [formValues]);
};

export const useSetTransfersUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { uiDateToServerDate } = useLanguageFeatures();
  return useCallback(
    (values: TTransferFiltersForm) => {
      const params: TTransferFiltersUrlParams = formValuesToParams(
        values,
        uiDateToServerDate,
      );
      //Page by default is 0
      setParams({ ...params });
    },
    [setParams, uiDateToServerDate],
  );
};
