import React, { forwardRef } from 'react';
import {
  Input as ChakraInput,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
} from '@chakra-ui/react';

export interface InputProps extends ChakraInputProps {
  /** Настройки для левого элемента */
  leftElementProps?: InputElementProps;
  /** Настройки для правого элемента */
  rightElementProps?: InputElementProps;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ leftElementProps, rightElementProps, ...rest }, ref) => {
    return (
      <InputGroup>
        {leftElementProps && <InputLeftElement {...leftElementProps} />}
        <ChakraInput {...rest} ref={ref} />
        {rightElementProps && <InputRightElement {...rightElementProps} />}
      </InputGroup>
    );
  }
);
Input.displayName = 'Input';
