import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function useCustomTheme(url: string) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getData = useCallback(async () => {
    axios
      .get([url, 'custom-theme.json'].join('/'))
      .then((response) => {
        setData(response.data);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);
  useEffect(() => {
    getData();
  }, [url, getData]);

  return useMemo(
    () => ({
      data,
      isLoading,
    }),
    [data, isLoading]
  );
}
