import { ComponentStyleConfig } from '@chakra-ui/react';

export const RadioStyles: ComponentStyleConfig = {
  baseStyle: {
    control: {
      border: 'none',
      bg: 'white.500',
      boxShadow: 'radio',
      _hover: {
        bg: 'white.500',
        boxShadow: 'radioHover',
      },
      _disabled: {
        cursor: 'not-allowed',
        bg: 'white.500',
        boxShadow: 'radioDisabled',
        _hover: {
          bg: 'white.500',
          boxShadow: 'radioDisabled',
        },
      },
      _checked: {
        bg: 'white.500',
        boxShadow: 'radioChecked',
        _before: {
          bg: 'brands.500',
          _disabled: {
            bg: 'brands.300',
          },
        },
        _hover: {
          bg: 'white.500',
          _before: {
            bg: 'brands.hover',
          },
          boxShadow: 'radioHover',
        },
        _disabled: {
          bg: 'white.500',
          _before: {
            bg: 'brands.300',
          },
          boxShadow: 'radioDisabledChecked',
          _hover: {
            bg: 'white.500',
            _before: {
              bg: 'brands.300',
            },
            boxShadow: 'radioDisabledChecked',
          },
        },
      },
    },
    label: {
      _disabled: {
        cursor: 'not-allowed',
      },
    },
  },
  sizes: {
    md: {
      control: {
        w: 2,
        h: 2,
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
  variants: {
    activeLabel: {
      label: {
        opacity: '1 !important',
        color: 'primary.500',
      },
    },
  },
};
