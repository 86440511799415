import { TAccountDto } from '@payler/api/client-office';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAccountsQuery } from '../hooks/accounts/queries';
import { usePreviousRenderValue } from '@payler/utils';

type TAccountsReorderContextValue = {
  isReorderMode: boolean;
  setIsReorderMode: Dispatch<SetStateAction<boolean>>;
  reorderedAccounts: TAccountDto[] | null;
  setReorderedAccounts: Dispatch<SetStateAction<TAccountDto[] | null>>;
};

const AccountsReorderContext = createContext<TAccountsReorderContextValue>(
  {} as TAccountsReorderContextValue,
);

export const AccountsReorderContextProvider: FCC = (props) => {
  const { data } = useAccountsQuery();
  const [isReorderMode, setIsReorderMode] = useState(false);
  const [reorderedAccounts, setReorderedAccounts] = useState(data);
  const prevIsReorderMode = usePreviousRenderValue(isReorderMode);

  // При изменении серверных данных, приводим порядок к серверному
  useEffect(() => {
    // Если эффект вызван изменением режима, то ничего не делаем
    if (isReorderMode !== prevIsReorderMode) return;
    if (!isReorderMode) {
      // Если находимся не в режиме сортировки, то синхронизируем порядок к серверному
      setReorderedAccounts(data);
    }
  }, [data, isReorderMode, prevIsReorderMode]);

  const ctx = useMemo(
    () => ({
      isReorderMode,
      setIsReorderMode,
      reorderedAccounts,
      setReorderedAccounts,
    }),
    [isReorderMode, reorderedAccounts],
  );

  return <AccountsReorderContext.Provider {...props} value={ctx} />;
};

export const useAccountsReorderContext = () =>
  useContext(AccountsReorderContext);
