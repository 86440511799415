import React, { useCallback } from 'react';
import { useApi } from '../../state/api';
import { Badge, BadgeProps, Text, Tooltip } from '@chakra-ui/react';
import { MayBe, useGetAxiosError, useToasts } from '@payler/bank-utils';
import { TextStyles } from '@payler/ui-theme';

export const DocumentLink: React.FC<
  {
    documentName?: MayBe<string>;
    isClickable?: boolean;
  } & BadgeProps
> = ({ documentName, isClickable, ...rest }) => {
  const getError = useGetAxiosError();
  const toast = useToasts();
  const api = useApi();
  const onClickHandle = useCallback(async () => {
    try {
      if (documentName) {
        await api.getTransferDocument(documentName);
      }
    } catch (e: unknown) {
      toast.error(getError(e).errorTitle);
    }
  }, [api, documentName, getError, toast]);

  if (!documentName) return null;
  return (
    <Badge
      maxW="400px"
      bg="primary.50"
      borderRadius={1.5}
      border="none"
      color={isClickable ? 'primary.400' : 'primary.500'}
      onClick={isClickable ? onClickHandle : undefined}
      cursor={isClickable ? 'pointer' : 'default'}
      _hover={
        isClickable ? { bg: 'primary.100', color: 'primary.500' } : undefined
      }
      {...rest}
    >
      <Tooltip label={documentName} bg="primary.500" borderRadius={1.5}>
        <Text
          textStyle={TextStyles.Subtitle14Medium}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {documentName}
        </Text>
      </Tooltip>
    </Badge>
  );
};
