import { useMemo } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

const somethingOpened = atomFamily<boolean, string>({
  key: 'something-opened-selector-family',
  default: false,
});

export const useRecoilBoolean = (key: string) => {
  const setState = useSetRecoilState(somethingOpened(key));
  return useMemo(() => {
    return {
      toggle: () => setState((v) => !v),
      off: () => setState(false),
      on: () => setState(true),
    };
  }, [setState]);
};

export const useRecoilBooleanValue = (key: string) =>
  useRecoilValue(somethingOpened(key));

const somethingOpenedWithPayload = atomFamily<
  { isOpen: boolean; payload: unknown },
  string
>({
  key: 'something-opened-with-payload-selector-family',
  default: {
    isOpen: false,
    payload: undefined,
  },
});

export const useRecoilBooleanWithPayload = <T>(key: string) => {
  const setState = useSetRecoilState(somethingOpenedWithPayload(key));

  return useMemo(() => {
    return {
      off: () =>
        setState({
          isOpen: false,
          payload: undefined,
        }),
      on: (payload: T) =>
        setState({
          isOpen: true,
          payload,
        }),
    };
  }, [setState]);
};

export const useRecoilBooleanWithPayloadValue = <T>(key: string) =>
  useRecoilValue(somethingOpenedWithPayload(key)) as {
    isOpen: boolean;
    payload: T | undefined;
  };
