import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**
 * Функция для конвертации даты в строку вида 2019-01-25T00:00:00.000Z
 * Отличие от стокового метода toISOString в том,
 * что в зависимости от типа интервала возвращается дата либо начала дня, либо конца дня, либо undefined если дата не была передана
 * @param type - тип интервала
 * @param date - дата
 * @example
 * 2019-01-25T00:00:00.000Z
 *
 * @return {string | undefined}
 */
export const getISODateString = (type: 'start' | 'end', date?: Date) => {
  if (!date) return undefined;
  if (type === 'start')
    return dayjs(date).format('YYYY-MM-DDT00:00:00.000') + 'Z'; // Z сделано так, тк у dayjs Z это значение текущей таймзоны
  return dayjs(date).format('YYYY-MM-DDT23:59:59.000') + 'Z';
};

/**
 * Если строка валидная дата - дата, если нет - то null
 * @param v
 * @param dateFormat
 */
export const getDayjsOrNull = (
  v: string | null | undefined,
  dateFormat: string
) => {
  if (!v) return null;
  const tmp = dayjs(v, dateFormat, true);
  return tmp.isValid() ? tmp : null;
};
