import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const BreadcrumbStyles: ComponentStyleConfig = {
  baseStyle: {
    link: {
      color: 'primary.350',
      textStyle: TextStyles.Caption12Regular,
      _activeLink: {
        textStyle: TextStyles.Caption12Medium,
        color: 'primary.500',
      },
    },
    separator: {
      color: 'primary.200',
      textStyle: TextStyles.Caption12Regular,
      mx: 1,
    },
  },
  defaultProps: {
    separator: '﹣',
  },
};
