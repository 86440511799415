import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import { PaylerDatepickerProps } from './PaylerDatepickerProps';
import createLogger from 'debug';

const log = createLogger('components:PaylerDatePicker:state');

//region types
/**
 * Состояние пикера. Выбор дат/года/месяца
 */
type PickerState = 'days' | 'years' | 'month';

/**
 * Признаки даты (для отрисовки)
 */
export type DayVariant =
  | 'normal'
  | 'today'
  | 'anotherMonth'
  | 'inSelectionRange'
  | 'selectionStart'
  | 'selectionEnd'
  | 'selectedStart'
  | 'selectedEnd'
  | 'selected'
  | 'selectedSingle'
  | 'disabled'
  | 'hidden';

//endregion

//region utils
const inRange = (
  date?: Dayjs | null,
  from?: Dayjs | null,
  to?: Dayjs | null
) => {
  if (!date || !from || !to) return false;
  const left = from.isAfter(to) ? to : from;
  const right = to.isAfter(from) ? to : from;
  return (
    date.isSame(left, 'date') ||
    date.isSame(right, 'date') ||
    (date.isAfter(left) && date.isBefore(right))
  );
};
//endregion

//region atoms
/**
 * Пропсы компонента
 */
const propsAtom = atom<PaylerDatepickerProps>({
  key: 'propsAtom',
  default: {
    locale: 'en',
    months: [0],
    mode: 'single',
    onChange: console.log,
  },
  effects: [
    ({ onSet }) => {
      onSet((newValue) => log('propsAtom: %O', newValue));
    },
  ],
});

/**
 * текущая дата месяца пикера
 */
const activeMonthDate = atom({
  key: 'activeMonthDate',
  default: dayjs(),
});

/**
 * Выбор списка лет в выборе года
 */
const chooseYearOffsetAtom = atom({
  key: 'chooseYearOffsetAtom',
  default: 0,
});

/**
 * состояние пикера: выбор дня / выбор года / выбор месяца
 */
const pickerState = atom<PickerState>({
  key: 'pickerState',
  default: 'days',
});

/**
 * Выбранный при выборе года/месяца год
 */
const chooseYearAtom = atom<number | undefined>({
  key: 'chooseYearAtom',
  default: undefined,
});

/**
 * выделение диапазона дат
 */
const selection = atom<{ start: Dayjs | undefined; end: Dayjs | undefined }>({
  key: 'selection',
  default: { start: undefined, end: undefined },
});

/**
 * выбранный диапазон дат
 */
const selectedDatesAtom = atom<{
  start: Dayjs | undefined;
  end: Dayjs | undefined;
}>({
  key: 'selectedDatesAtom',
  default: { start: undefined, end: undefined },
});

/**
 * день над которым находится курсор
 */
const hoverDate = atom<Dayjs | undefined>({
  key: 'hoverDate',
  default: undefined,
});
//endregion

//region selectors
/**
 * Управляется извне
 */
const isControlled = selector({
  key: 'isControlled',
  get: ({ get }) => get(propsAtom).value !== undefined,
});

/**
 * сколько лет отображать при выборе года
 */
const yearsDisplayParams = selector({
  key: 'yearsDisplayParams',
  get: ({ get }) => {
    const { months } = get(propsAtom);
    if (months?.length === 1) {
      return {
        yearsToDisplay: 18,
        rowSize: 3,
      };
    } else {
      return {
        yearsToDisplay: 24,
        rowSize: 4,
      };
    }
  },
});

/**
 * список лет для отображения в выборе года (рядами)
 */
const years = selector({
  key: 'years',
  get: ({ get }) => {
    const offset = get(chooseYearOffsetAtom);
    const { yearsToDisplay, rowSize } = get(yearsDisplayParams);
    const minYear = get(propsAtom).minDate?.get('year') ?? 0;
    const maxYear =
      get(propsAtom).maxDate?.get('year') ?? Number.POSITIVE_INFINITY;

    const current = get(activeMonthDate)
      .add(yearsToDisplay * offset, 'years')
      .get('year');
    const from = current - yearsToDisplay / 2;
    const to = from + yearsToDisplay - 1;
    const tmp = [];
    for (let x = from; x <= to; x++) {
      tmp.push({ year: x, disabled: x < minYear || x > maxYear });
    }
    const res = [];
    for (let x = 0; x < tmp.length; x += rowSize) {
      res.push(tmp.slice(x, x + rowSize));
    }
    return { years: res, from, to };
  },
});

/**
 * Возможность выбора года в выборе дня (кнопками в хедере)
 */
const canChangeMonth = selectorFamily({
  key: 'canChangeMonth',
  get:
    (type: 'prev' | 'next') =>
    ({ get }) => {
      if (type === 'prev') {
        const props = get(propsAtom);
        const { months = [0], minDate } = props;
        const left = get(activeMonthDate).add(months[0] ?? 0, 'month');
        return !minDate ? true : minDate?.isBefore(left, 'month');
      } else {
        const props = get(propsAtom);
        const { months = [0], maxDate } = props;
        const right = get(activeMonthDate).add(
          months[months.length - 1] ?? 0,
          'month'
        );
        return !maxDate ? true : maxDate?.isAfter(right, 'month');
      }
    },
});

/**
 * Возможность выбора списка лет в выборе года (кнопками в хедере)
 */
const canChangeYearsInChooseYear = selectorFamily({
  key: 'canChangeYearsInChooseYear',
  get:
    (type: 'prev' | 'next') =>
    ({ get }) => {
      const props = get(propsAtom);
      const { years: yearsShown } = get(years);
      if (type === 'prev') {
        const { minDate } = props;
        const firstRow = yearsShown[0];
        const firstYear = firstRow?.[0];
        return !minDate
          ? true
          : firstYear
          ? minDate.year() < firstYear.year
          : false;
      } else {
        const { maxDate } = props;
        const lastRow = yearsShown[yearsShown.length - 1];
        const lastYear = lastRow?.[lastRow.length - 1];
        return !maxDate
          ? true
          : lastYear
          ? maxDate.year() > lastYear.year
          : false;
      }
    },
});

/**
 * Возможность выбора года в выборе месяца (кнопками в хедере)
 */
const canChangeYearsInChooseMonth = selectorFamily({
  key: 'canChangeYearsInChooseMonth',
  get:
    (type: 'prev' | 'next') =>
    ({ get }) => {
      const props = get(propsAtom);
      const year = get(chooseYearAtom);
      if (!year) return false;
      if (type === 'prev') {
        const { minDate } = props;
        return !minDate ? true : minDate.year() < year;
      } else {
        const { maxDate } = props;
        return !maxDate ? true : maxDate.year() > year;
      }
    },
});

/**
 * Возможность выбора месяца в списке выбора месяца
 */
const canSelectMonth = selectorFamily({
  key: 'canSelectMonth',
  get:
    (month: number) =>
    ({ get }) => {
      const year = get(chooseYearAtom);
      const date = dayjs(`${year}-${month}-01`).startOf('month');
      const { minDate, maxDate } = get(propsAtom);
      const min = minDate?.startOf('month');
      const max = maxDate?.startOf('month');
      if (min && date.isBefore(min, 'months')) {
        return false;
      }
      return !(max && date.isAfter(max, 'month'));
    },
});

/**
 * выбранный диапазон дат (внутренний стейт или пропсы)
 */
const selectedDatesSelector = selector({
  key: 'selectedDatesSelector',
  get: ({ get }) => {
    const controlled = get(isControlled);
    if (!controlled) {
      return get(selectedDatesAtom);
    }
    const { value, mode } = get(propsAtom);
    return mode === 'single'
      ? { start: value, end: value }
      : { start: value?.start, end: value?.end };
  },
});

/**
 * дата находится в диапазоне выделения
 */
const dayInSelectionRange = selectorFamily({
  key: 'dayInSelectionRange',
  get:
    (date: Dayjs) =>
    ({ get }) => {
      const { start } = get(selection);
      const hover = get(hoverDate);
      return inRange(date, start, hover);
    },
});

/**
 * дата находится в диапазоне выбранного периода
 */
const dayInSelectedRange = selectorFamily({
  key: 'dayInSelectedRange',
  get:
    (date: Dayjs) =>
    ({ get }) => {
      const { start, end } = get(selectedDatesSelector);
      return inRange(date, start, end);
    },
});

/**
 * дни, которые будут нарисованы в месяце
 */
const activeMonthDays = selectorFamily({
  key: 'activeMonthDays',
  get:
    (offset: number) =>
    ({ get }) => {
      const monthDate = get(activeMonthDate);
      const dateWithOffset = monthDate.add(offset, 'month');

      const { locale } = get(propsAtom);
      let date = dayjs(dateWithOffset)
        .locale(locale as string)
        .startOf('month')
        .startOf('w');
      const end = dayjs(dateWithOffset)
        .locale(locale as string)
        .endOf('month')
        .endOf('w');
      const res = [];
      while (date.isBefore(end) || date.isSame(end)) {
        res.push(date);
        date = date.add(1, 'd');
      }
      return res;
    },
});

/**
 * Недели (массив дней), который будут нарисованы в месяце
 */
const weekSelector = selectorFamily({
  key: 'activeMonthWeeks',
  get:
    (offset: number) =>
    ({ get }) => {
      const days = get(activeMonthDays(offset));
      const res = [];
      for (let x = 0; x < days.length; x += 7) {
        res.push(days.slice(x, x + 7));
      }
      return res;
    },
});

/**
 * массив признаков дня (для отображения дня в месяце)
 */
const dayMeta = selectorFamily<DayVariant[], { date: Dayjs; offset: number }>({
  key: 'dayMeta',
  get:
    ({ date, offset }) =>
    ({ get }) => {
      const inSelectionRange = get(dayInSelectionRange(date));
      const inSelectedRange = get(dayInSelectedRange(date));
      const { start } = get(selection);
      const isSelectingNow = !!start;
      const { minDate, maxDate, months, checkDateDisabledFunc } =
        get(propsAtom);
      const singleMonth = months?.length === 1;

      const month = get(activeMonthDate).add(offset, 'months');
      const inCurrentMonth = month.isSame(date, 'months');

      const isToday = dayjs().isSame(date, 'date');
      const hover = get(hoverDate);
      const selectionStart = start?.isBefore(hover) ? start : hover;
      const selectionEnd = start?.isBefore(hover) ? hover : start;
      const { start: selectedStart, end: selectedEnd } = get(
        selectedDatesSelector
      );

      const hidden = !singleMonth && !inCurrentMonth;
      const monthStart = month.startOf('month');
      const monthEnd = month.endOf('month');

      const isTailSelection =
        hidden &&
        date.isAfter(monthEnd) &&
        inRange(monthEnd, selectionStart, selectionEnd) &&
        inRange(monthEnd.add(1, 'day'), selectionStart, selectionEnd);

      const isHeadSelection =
        hidden &&
        date.isBefore(monthStart) &&
        inRange(monthStart, selectionStart, selectionEnd) &&
        inRange(monthStart.add(-1, 'day'), selectionStart, selectionEnd);

      const isTailSelected =
        hidden &&
        date.isAfter(monthEnd) &&
        inRange(monthEnd, selectedStart, selectedEnd) &&
        inRange(monthEnd.add(1, 'day'), selectedStart, selectedEnd);

      const isHeadSelected =
        hidden &&
        date.isBefore(monthStart) &&
        inRange(monthStart, selectedStart, selectedEnd) &&
        inRange(monthStart.add(-1, 'day'), selectedStart, selectedEnd);

      const selecting = !!start;
      const selectingToRight =
        (start && hover && hover.isAfter(start)) ||
        hover?.isSame(start, 'date');
      const selectingToLeft = start && hover && !selectingToRight;

      const res: DayVariant[] = ['normal'];

      if (isToday) {
        res.push('today');
      }
      if (!inCurrentMonth) {
        res.push('anotherMonth');
      }

      if (selecting && start?.isSame(date, 'date')) {
        res.push(selectingToLeft ? 'selectionEnd' : 'selectionStart');
      }

      if (
        (inSelectionRange && (singleMonth || inCurrentMonth)) ||
        isHeadSelection ||
        isTailSelection
      ) {
        res.push('inSelectionRange');
      }

      if (selectingToRight) {
        // hover > start
        if (start?.isSame(date, 'date')) {
          res.push('selectionStart');
        }

        if (hover?.isSame(date, 'date')) {
          res.push(
            start?.isBefore(date, 'date') ? 'selectionEnd' : 'selectedStart'
          );
        }
      }

      if (selectingToLeft) {
        // hover < start
        if (start?.isSame(date, 'date')) {
          res.push('selectionEnd');
        }
        if (hover?.isSame(date, 'date')) {
          res.push(
            start?.isBefore(date, 'date') ? 'selectionEnd' : 'selectedStart'
          );
        }
      }

      if (
        (inSelectedRange && (singleMonth || inCurrentMonth)) ||
        isHeadSelected ||
        isTailSelected
      ) {
        if (!isSelectingNow) res.push('selected');
      }

      if (
        !isSelectingNow &&
        selectedStart?.isSame(date, 'date') &&
        selectedEnd
      ) {
        res.push('selectedStart');
      }
      if (
        !isSelectingNow &&
        selectedEnd?.isSame(date, 'date') &&
        selectedStart
      ) {
        res.push('selectedEnd');
      }
      if (
        !isSelectingNow &&
        selectedStart?.isSame(date) &&
        selectedEnd?.isSame(date)
      ) {
        res.push('selectedSingle');
      }

      if (
        minDate?.isAfter(date, 'date') ||
        maxDate?.isBefore(date, 'date') ||
        checkDateDisabledFunc?.(date)
      ) {
        res.push('disabled');
      }
      if (hidden) {
        res.push('hidden');
      }
      return res;
    },
});

//endregion

//region hooks
export const useShowWeekDays = () => useRecoilValue(propsAtom).showWeekDays;

/**
 * Установить дату отображаемого месяца пикера
 */
export const useSetActiveMonth = () => useSetRecoilState(activeMonthDate);
/**
 * Пропсы компонента
 */
export const usePaylerDatepickerProps = () => useRecoilState(propsAtom);
/**
 * Локаль пикера
 */
export const useLocale = () => useRecoilValue(propsAtom).locale as string;
/**
 * текущая дата месяца пикера
 */
export const useActiveDate = () => useRecoilValue(activeMonthDate);
/**
 * рисовать бордер
 */
export const useShowBorder = () => useRecoilValue(propsAtom).showBorder;
/**
 * рисовать хедер
 */
export const useShowHeader = () => useRecoilValue(propsAtom).showHeader;
/**
 * layout пикера горизонтальный/вертикальный
 */
export const useLayout = () => useRecoilValue(propsAtom).layout;
/**
 * Количество отображаемых месяцев
 */
export const useMonthsDisplayedCount = () =>
  useRecoilValue(propsAtom).months?.length ?? 1;
/**
 * список лет для отображения в выборе года (рядами)
 */
export const useYears = () => useRecoilValue(years);
/**
 * Выбрать год в списках выбора года и месяца
 */
export const useSetYear = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      (year: number) => {
        log('useSetYear: %i', year);
        transact_UNSTABLE(({ set }) => {
          set(chooseYearAtom, year);
          set(pickerState, 'month');
        });
      },
    []
  );
/**
 * Выбранный при выборе года/месяца год
 */
export const useChosenYear = () => useRecoilValue(chooseYearAtom);
/**
 * Выбор месяца при выборе года/месяца. Устанавливает месяц пикера
 */
export const useSetMonth = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      (month: number) => {
        transact_UNSTABLE(({ get, set }) => {
          const year = get(chooseYearAtom);

          set(chooseYearOffsetAtom, 0);
          set(chooseYearAtom, undefined);
          set(pickerState, 'days');
          const value = dayjs(`${year}-${month}-01`);
          log(
            'useSetMonth: month: %i, year: %i, value: %O, value formatted: %s',
            month,
            year,
            value,
            value.format('YYYY-MM-DD')
          );
          set(activeMonthDate, value);
        });
      },
    []
  );
/**
 * отмена выбора года/месяца и возврат к выбору дня
 */
export const useCancelYearMonthSelect = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      () => {
        transact_UNSTABLE(({ set }) => {
          set(chooseYearOffsetAtom, 0);
          set(chooseYearAtom, undefined);
          set(pickerState, 'days');
        });
      },
    []
  );
/**
 * Выбор списка лет в выборе года
 */
export const useSetYearsOffset = () => useSetRecoilState(chooseYearOffsetAtom);

/**
 * состояние пикера: выбор дня / выбор года / выбор месяца
 */
export const usePickerState = () => useRecoilState(pickerState);

/**
 * В процессе выделения диапазона
 */
export const useIsSelecting = () => {
  const sel = useRecoilValue(selection);
  const { mode } = useRecoilValue(propsAtom);
  return mode === 'range' && sel.start;
};

/**
 * Возможность выбора месяца в списке выбора месяца
 */
export const useCanSelectMonth = (month: number) =>
  useRecoilValue(canSelectMonth(month));

/**
 * Возможность выбора списка лет в выборе года (кнопками в хедере)
 */
export const useCanChangeYearsInChooseYear = (type: 'prev' | 'next') =>
  useRecoilValue(canChangeYearsInChooseYear(type));

/**
 * Возможность выбора года в выборе месяца (кнопками в хедере)
 */
export const useCanChangeYearsInChooseMonth = (type: 'prev' | 'next') =>
  useRecoilValue(canChangeYearsInChooseMonth(type));

/**
 * Возможность выбора года в выборе дня (кнопками в хедере)
 */
export const useCanChangeMonth = (type: 'next' | 'prev') =>
  useRecoilValue(canChangeMonth(type));

/**
 * Массив с настройками отображаемых месяцев
 */
export const useMonths = () => useRecoilValue(propsAtom).months ?? [0];

/**
 * текущая дата месяца пикера
 */
export const useCurrentMonth = () => useRecoilValue(activeMonthDate);

/**
 * выбрать предыдущий месяц (в хедере выбора дат)
 */
export const useSetPrevMonth = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      () => {
        transact_UNSTABLE(({ set }) => {
          set(activeMonthDate, (v) => v.add(-1, 'months'));
        });
      },
    []
  );

/**
 * выбрать следующий месяц (в хедере выбора дат)
 */
export const useSetNextMonth = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      () => {
        transact_UNSTABLE(({ set }) => {
          set(activeMonthDate, (v) => v.add(1, 'months'));
        });
      },
    []
  );
/**
 * Сбросить выделение
 */
export const useResetSelection = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      () => {
        transact_UNSTABLE(({ set }) => {
          set(selection, { start: undefined, end: undefined });
        });
      },
    []
  );
/**
 * Установить дату над которой находится курсор
 */
export const useSetHoverDate = () =>
  useRecoilCallback(({ transact_UNSTABLE }) => (date: Dayjs | undefined) => {
    transact_UNSTABLE(({ get, set }) => {
      if (!get(selection).start) return set(hoverDate, undefined);
      set(hoverDate, date);
    });
  });

/**
 * Установить начало выделения
 */
export const useSetStartSelection = () =>
  useRecoilCallback(
    ({ transact_UNSTABLE }) =>
      (date: Dayjs) => {
        transact_UNSTABLE(({ get, set }) => {
          const { mode } = get(propsAtom);
          log('set selection start date: %O, mode: %s', date, mode);
          if (mode === 'single') return;
          set(selection, { start: date, end: undefined });
        });
      },
    []
  );
/**
 * обработчик клика по дате
 */
export const useOnDayClick = () =>
  useRecoilCallback(
    ({ snapshot, set }) =>
      async (date: Dayjs) => {
        const controlled = await snapshot.getPromise(isControlled);
        const { mode, onChange, onStartSelection } = await snapshot.getPromise(
          propsAtom
        );
        const { start } = await snapshot.getPromise(selection);
        const release = snapshot.retain();
        try {
          if (mode === 'single') {
            if (controlled) {
              onChange?.(date);
            } else {
              set(selectedDatesAtom, { start: date, end: date });
            }
            return;
          }

          if (!start) {
            if (controlled) {
              onChange?.({ start: undefined, end: undefined });
            } else {
              set(selectedDatesAtom, { start: undefined, end: undefined });
            }
            set(selection, { start: date, end: undefined });
            onStartSelection?.(date);
            return;
          }
          if (date.isBefore(start)) {
            if (controlled) {
              onChange?.({ start: date, end: start });
            } else {
              set(selectedDatesAtom, { start: date, end: start });
            }

            set(selection, { start: undefined, end: undefined });
            set(hoverDate, undefined);
            return;
          }
          if (controlled) {
            onChange?.({ start, end: date });
          } else {
            set(selectedDatesAtom, { start, end: date });
          }
          set(selection, { start: undefined, end: undefined });
          set(hoverDate, undefined);
        } finally {
          release();
        }
      },
    []
  );

/**
 *
 * массив признаков дня (для отображения дня в месяце)
 *
 * @param date - день
 * @param offset - смещение месяца
 */
export const useDayMeta = (date: Dayjs, offset: number) =>
  useRecoilValue(dayMeta({ date, offset }));

/**
 * Массив недель(массив дней) месяца
 * @param offset - смещение месяца
 */
export const useMonthWeeks = (offset: number) =>
  useRecoilValue(weekSelector(offset));
//endregion
