import {
  Avatar,
  Box,
  chakra,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ActivityIcon,
  ArrowRightIcon,
  Card20Icon,
  Exchange2_14Icon,
  HotWalletsIcon,
  ReportsIcon,
  StaffIcon,
  WalletIcon,
} from '@payler/ui-icons';
import { Suspense } from 'react';
import { MenuLink } from './MenuLink';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import { LoaderBox } from '../LoaderBox/LoaderBox';
import { useProfileInfo } from '../../hooks/use-profile-info';
import { HistoryIcon } from '../../icons';
import { usePermissions } from '../../hooks/use-permissions';
import { useAuth } from '@payler/auth';
import { Logo } from '@payler/ui-components';
import {
  useCheckAppFlagEnabled,
  useClientOfficeConfig,
} from '../../config/ConfigProvider';

const BaseMenu: FCC = ({ children }) => {
  const { staticPath, logoFileExtension } = useClientOfficeConfig();
  return (
    <Suspense fallback={null}>
      <VStack
        minH={{ base: '100%', sm: '100vh' }}
        h={{ base: '100%', sm: '100vh' }}
        maxH={{ base: '100%', sm: '100vh' }}
        as="nav"
        alignItems="stretch"
        spacing={0}
        px={3}
        pb={4}
        bg="bg"
      >
        <Box pt={{ base: 0, sm: '32px' }} pb={{ base: 0, sm: '40px' }}>
          <Logo path={staticPath} logoFileExtension={logoFileExtension} />
        </Box>
        {children}
      </VStack>
    </Suspense>
  );
};

export function Menu() {
  const { isGlobalAccounts } = usePermissions();
  return (
    <BaseMenu>
      <chakra.div flex="1 1 0" minH={0} userSelect="none">
        {isGlobalAccounts ? <Links /> : <DemoLinks />}
      </chakra.div>
      <BottomLinks />
    </BaseMenu>
  );
}

export default Menu;

const Links = () => {
  const { t } = useTranslation(['menu']);
  const isFeautureFinlegoDemoEnabled = useCheckAppFlagEnabled('finlego.demo');
  return (
    <VStack
      alignItems="stretch"
      p={0.5}
      borderRadius={1.5}
      bg="secondary.500"
      border="1px solid"
      borderColor="primary.100"
    >
      <MenuLink
        icon={HotWalletsIcon}
        route="/"
        title={t('menu:accounts')}
        data-testid="menu_accounts"
      />
      <MenuLink
        icon={StaffIcon}
        route="/recipients"
        title={t('menu:recipients')}
        data-testid="menu_recipients"
      />
      <MenuLink
        icon={ActivityIcon}
        route="/activity"
        title={t('menu:activity')}
        data-testid="menu_activity"
      />
      {isFeautureFinlegoDemoEnabled && (
        <MenuLink
          icon={Card20Icon}
          route="/cards"
          title={'Cards'}
          data-testid="menu_cards"
        />
      )}
      {isFeautureFinlegoDemoEnabled && (
        <MenuLink
          icon={WalletIcon}
          route="/crypto-wallet"
          title={'Crypto wallet'}
          data-testid="menu_crypto_wallet"
        />
      )}
      {/* {isFeautureFinlegoDemoEnabled && (
        <MenuLink
          icon={Exchange2_14Icon}
          route="/fx"
          title={'FX'}
          data-testid="menu_fx"
        />
      )} */}
    </VStack>
  );
};

const DemoLinks = () => {
  const { t } = useTranslation(['menu']);
  return (
    <VStack
      alignItems="stretch"
      p={0.5}
      borderRadius={1.5}
      bg="secondary.500"
      border="1px solid"
      borderColor="primary.100"
    >
      <MenuLink
        icon={HistoryIcon}
        route="/"
        title={t('menu:onboardingProcess')}
        data-testid="menu_onboarding-process"
      />
      <MenuLink
        icon={HotWalletsIcon}
        route="/accounts"
        title={t('menu:accounts')}
        badge={t('menu:demo')}
        data-testid="menu_accounts"
      />
      <MenuLink
        icon={StaffIcon}
        route="/recipients"
        title={t('menu:recipients')}
        badge={t('menu:demo')}
        isDisabled
        data-testid="menu_recipients"
      />
      <MenuLink
        icon={ReportsIcon}
        route="/activity"
        title={t('menu:activity')}
        badge={t('menu:demo')}
        isDisabled
        data-testid="menu_activity"
      />
    </VStack>
  );
};

export const BottomLinks = () => {
  return (
    <VStack
      alignItems="stretch"
      p={0.5}
      borderRadius={1.5}
      bg="secondary.500"
      _empty={{
        display: 'none',
      }}
      border="1px solid"
      borderColor="primary.100"
    >
      <UserSettings />
    </VStack>
  );
};

const UserSettings = () => {
  const { isAuthLoading } = useAuth();
  const profile = useProfileInfo();
  const { t } = useTranslation(['menu']);

  if (isAuthLoading) return <LoaderBox />;
  if (!profile['client-id']) return null;

  return (
    <NavLink to={'/profile'} data-testid="menu_profile">
      <HStack px="18px" py="14px" spacing="14px" cursor="pointer">
        <Box>
          <Avatar
            sx={{
              '& > div': {
                color: 'secondary.500',
                textStyle: TextStyles.Subtitle14Semibold,
              },
            }}
            width="36px"
            height="36px"
            name={profile.name}
            color="secondary.500"
            backgroundColor="brands.500"
          />
        </Box>
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <VStack width="100%" alignItems="baseline" spacing={0}>
            <Text
              textStyle={TextStyles.Subtitle14Semibold}
              color="primary.500"
              fontWeight="600"
              maxW="130px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {profile.given_name && profile.family_name
                ? `${profile.given_name} ${profile.family_name[0]}.`
                : profile.name || t('menu:johnDoe')}
            </Text>
          </VStack>
          <Icon as={ArrowRightIcon} color="primary.300" />
        </Flex>
      </HStack>
    </NavLink>
  );
};
