import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useGetConfig } from './use-get-config';

type TProviderProps = { path?: string; children?: ReactNode };
type TContext<T> = {
  environment: T;
  isLoading: boolean;
};

export function generateConfigContext<T>() {
  const ConfigContext = createContext<TContext<T>>({
    environment: {},
    isLoading: false,
  } as TContext<T>);
  const ConfigContextProvider = ({ children, path }: TProviderProps) => {
    const { data: environment, isLoading } = useGetConfig<T>(path ?? '');
    const ctx = useMemo<TContext<T>>(
      () => ({ environment, isLoading }),
      [environment, isLoading]
    );
    return (
      <ConfigContext.Provider value={ctx}>{children}</ConfigContext.Provider>
    );
  };

  function useConfigContext() {
    return useContext(ConfigContext);
  }

  return {
    ConfigContextProvider,
    useConfigContext,
  };
}
