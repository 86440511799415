import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const LinkStyles: ComponentStyleConfig = {
  baseStyle: {
    textStyle: TextStyles.BodyText16Regular,
    color: 'brands.500',
    ':hover': {
      color: 'brands.500',
    },
  },
};
