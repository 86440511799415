import { createContext, useCallback, useContext, useMemo } from 'react';
import {
  AuthProvider as ReactOidcContextAuthProvider,
  AuthProviderProps,
  useAuth as useReactOidcContextAuth,
} from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useAutomaticLogin } from './use-automatic-login';

export type TAuth<T> = {
  isAuthLoading: boolean;
  isAuthenticated: boolean;
  accessToken: string | undefined;
  tokenParsed?: T;
  updateToken: (minValidity?: number) => Promise<User | null>;
  logout: () => Promise<void>;
  login: () => Promise<void>;
  changePassword: () => Promise<void>;
};

const AuthContext = createContext<TAuth<any>>({
  isAuthLoading: false,
  isAuthenticated: false,
  accessToken: undefined,
  updateToken: () => Promise.resolve(null),
  logout: () => {
    alert('logout action');
    return Promise.resolve();
  },
  login: () => Promise.resolve(),
  changePassword: () => {
    alert('change password action');
    return Promise.resolve();
  },
});

type TUseAuth = <T extends JwtPayload>() => TAuth<T>;

export const getUrlWithoutAuthParams = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('state');
  url.searchParams.delete('session_state');
  url.searchParams.delete('code');
  return url.toString();
};

export const useAuth: TUseAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return auth;
};

const AuthContextProvider: FCC = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    user,
    signinSilent,
    signinRedirect,
    signoutRedirect,
    activeNavigator,
  } = useReactOidcContextAuth();

  const logout = useCallback(() => {
    return signoutRedirect({
      post_logout_redirect_uri: getUrlWithoutAuthParams(),
    });
  }, [signoutRedirect]);

  const changePassword = useCallback(() => {
    return signinRedirect({
      extraQueryParams: { kc_action: 'UPDATE_PASSWORD' },
      redirect_uri: getUrlWithoutAuthParams(),
    });
  }, [signinRedirect]);

  const updateToken = useCallback(
    async (minValidity?: number) => {
      if (!user?.access_token) {
        logout();
      } else {
        if (!minValidity || minValidity > Number(user.expires_in)) {
          const response = await signinSilent();
          if (response) {
            return response;
          } else {
            logout();
          }
        }
      }
      return null;
    },
    [logout, signinSilent, user?.access_token, user?.expires_in],
  );

  const tokenParsed = useMemo(
    () => (user?.access_token ? jwtDecode(user.access_token) : undefined),
    [user?.access_token],
  );

  const isAuthLoading = useMemo(
    () => isLoading && activeNavigator !== 'signinSilent',
    [activeNavigator, isLoading],
  );

  const ctx = useMemo(
    () => ({
      isAuthLoading,
      isAuthenticated,
      accessToken: user?.access_token,
      tokenParsed,
      updateToken,
      login: signinRedirect,
      logout,
      changePassword,
    }),
    [
      changePassword,
      isAuthLoading,
      isAuthenticated,
      logout,
      signinRedirect,
      tokenParsed,
      updateToken,
      user?.access_token,
    ],
  );

  useAutomaticLogin();

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

export const AuthProvider: FCC<{
  config: { url: string; realm: string; clientId: string };
}> = ({ children, config }) => {
  const onSigninCallback = useCallback((_user: User | void): void => {
    // Удаление параметров авторизации из url после редиректа
    window.history.replaceState(null, '', getUrlWithoutAuthParams());
  }, []);

  const oidcConfig: AuthProviderProps = {
    authority: `${config.url}/realms/${config.realm}`,
    client_id: config.clientId,
    redirect_uri: getUrlWithoutAuthParams(),
    onSigninCallback,
    automaticSilentRenew: false,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    response_mode: 'query',
  };

  return (
    <ReactOidcContextAuthProvider {...oidcConfig}>
      <AuthContextProvider>{children}</AuthContextProvider>
    </ReactOidcContextAuthProvider>
  );
};
