import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useImperativeHandle, useState } from 'react';
import {
  ApiErrorText,
  DatesFields,
  DropdownField,
  FloatingInputField,
  MultiDropdownField,
} from '@payler/ui-components';
import dayjs from 'dayjs';
import { HStack, VStack } from '@chakra-ui/react';
import { useTransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/transfer-filters-state';
import {
  useSetTransfersUrlSearchParams,
  useTransfersUrlParamsToFormValues,
} from '../../hooks/transfers/url';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { namedCurrencyOptions, useHandleFormError } from '@payler/bank-utils';
import { TTransferType } from '@payler/api/client-office';
import { useFilterWrapperContext } from '../../context/FilterWrapperContextProvider';
import { useAccountsQuery } from '../../hooks/accounts/queries';
import {
  EMPTY,
  TTransferFiltersForm,
  errorsToFields,
  useAccountOptions,
  useCreateResolver,
  useStatusOptions,
  useSystemOptions,
  useTypeOptions,
} from './helpers';

export const TransferFiltersForm = ({ formId }: { formId: string }) => {
  const { t } = useTranslation(['fields']);
  const { uiDateFormat } = useLanguageFeatures();
  const urlValues = useTransfersUrlParamsToFormValues();
  const createResolver = useCreateResolver();
  const handleFormError = useHandleFormError<TTransferFiltersForm>();
  const [errorText, setErrorText] = useState<string>('');

  const methods = useForm<TTransferFiltersForm>({
    defaultValues: urlValues,
    resolver: createResolver(),
    mode: 'onBlur',
  });
  const {
    formState: { isValid },
    watch,
    setValue,
  } = methods;
  const accountId = watch('accountId');
  const currency = watch('currency');
  const type = watch('type');

  const { data: accounts } = useAccountsQuery();

  const {
    close,
    error: queryError,
    setError: setQueryError,
  } = useTransferFiltersDrawer();

  useEffect(() => {
    if ((type as TTransferType) === 'fx') {
      setValue('system', '');
    }
  }, [setValue, type]);

  const setUrlParams = useSetTransfersUrlSearchParams();

  const handleSubmit = methods.handleSubmit((values) => {
    setUrlParams(values);
    close();
  });

  const { ref } = useFilterWrapperContext();
  useImperativeHandle(ref, () => ({
    reset: () => {
      methods.reset({ ...EMPTY });
    },
  }));

  const typeOptions = useTypeOptions();
  const statusOptions = useStatusOptions();
  const systemOptions = useSystemOptions();
  const accountOptions = useAccountOptions(accounts, currency);

  useEffect(() => {
    if (queryError && isValid) {
      const unknownFieldErrors = handleFormError(
        queryError,
        methods,
        errorsToFields,
      );
      if (unknownFieldErrors.length) setErrorText(unknownFieldErrors.join(' '));
      setQueryError(null);
    }
  }, [handleFormError, isValid, methods, queryError, setQueryError]);

  return (
    <FormProvider {...methods}>
      <VStack
        as="form"
        id={formId}
        height="100%"
        alignItems="stretch"
        flex={1}
        spacing={2}
        onSubmit={handleSubmit}
      >
        <DatesFields
          maxDate={dayjs()}
          dateFormat={uiDateFormat}
          fieldNames={['createdFrom', 'createdTo']}
          floating
          labelsKeys={['fields:dateFrom', 'fields:dateTo']}
        />
        <DropdownField
          options={accountOptions}
          fieldName="accountId"
          label={t('fields:account')}
          floating
          testId="field_account"
        />
        <DropdownField
          options={namedCurrencyOptions}
          fieldName="currency"
          label={t('fields:currency')}
          floating
          testId="field_currency"
          isDisabled={!!accountId}
        />
        <DropdownField
          options={typeOptions}
          fieldName="type"
          label={t('fields:type')}
          floating
          testId="field_type"
        />
        <DropdownField
          options={systemOptions}
          fieldName="system"
          label={t('fields:system')}
          floating
          testId="field_system"
          isDisabled={(type as TTransferType) === 'fx'}
        />
        <MultiDropdownField
          options={statusOptions}
          fieldName="status"
          label={t('fields:status')}
          floating
          testId="field_status"
        />
        <HStack spacing={2} alignItems="start">
          <FloatingInputField
            variant="currency"
            name="amountFrom"
            label={t('fields:amountFrom')}
            allowNegative={false}
          />
          <FloatingInputField
            variant="currency"
            name="amountTo"
            label={t('fields:amountTo')}
            allowNegative={false}
          />
        </HStack>
        {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
      </VStack>
    </FormProvider>
  );
};
