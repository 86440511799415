export const stockFonts =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const fonts = {
  DemiBold: `GolosTextWebDemiBold, ${stockFonts}`,
  UIRegular: `GolosUIWebRegular, ${stockFonts}`,
  UIMedium: `GolosUIWebMedium, ${stockFonts}`,
  Medium: `GolosTextWebMedium, ${stockFonts}`,
  Regular: `GolosTextWebRegular, ${stockFonts}`,
  UIBold: `GolosUIWebBold, ${stockFonts}`,
  Code: `Roboto Mono, ${stockFonts}`,
  body: `GolosTextWebRegular, ${stockFonts}`,
  code: `RobotoMono, ${stockFonts}`,
};
