import { ErrorInfo, PureComponent, ReactNode } from 'react';
import { chakra } from '@chakra-ui/react';
import createLogger from 'debug';

const log = createLogger('ErrorBoundary');

interface Props {
  children?: ReactNode;
  errorPage?: ReactNode;
}

interface State {
  hasError: boolean;
  stack?: ErrorInfo['componentStack'];
}

export class ErrorBoundary extends PureComponent<Props, State> {
  public override state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log('Uncaught error:', error, errorInfo);
    if (process.env.NODE_ENV === 'development') {
      this.setState({ stack: errorInfo.componentStack });
    }
  }

  public override render() {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === 'development') {
        return <chakra.pre color="red.400">Error, see console</chakra.pre>;
      }
      return this.props.errorPage || null;
    }

    return this.props.children;
  }
}
