import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const BadgeStyles: ComponentStyleConfig = {
  baseStyle: {
    px: 1,
    py: 0.5,
    border: '0.5px solid',
    borderRadius: 0.5,
    textStyle: TextStyles.Tagline10Bold,
    fontSize: 10,
  },
  variants: {
    green: {
      bg: 'green.100',
      color: 'green.500',
      borderColor: 'green.100',
    },
    yellow: {
      bg: 'yellow.100',
      color: 'yellow.500',
      borderColor: 'yellow.100',
    },
    red: {
      bg: 'red.100',
      borderColor: 'red.100',
      color: 'red.500',
    },
    grey: {
      bg: 'primary.25',
      borderColor: 'primary.50',
      color: 'primary.300',
    },
    orange: {
      color: 'orange.500',
      bg: 'orange.100',
      borderColor: 'orange.100',
    },
  },
};
