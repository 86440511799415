import React, { Suspense } from 'react';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { useTransferFiltersDrawer } from './transfer-filters-state';
import { useSelectedTransfersFiltersCount } from '../../hooks/transfers/url';
import { TransferFiltersForm } from '../../forms/TransferFiltersForm/TransferFiltersForm';
import { FiltersDrawer } from '../../components/FiltersDrawer/FiltersDrawer';

const TRANSFER_FILTERS_FORM_ID = 'transfer-filters-form';

export const TransferFiltersDrawer = () => {
  const { close, isOpen } = useTransferFiltersDrawer();
  const filtersCount = useSelectedTransfersFiltersCount();

  return (
    <FiltersDrawer
      isOpen={isOpen}
      close={close}
      selectedFiltersCount={filtersCount}
      formId={TRANSFER_FILTERS_FORM_ID}
    >
      <Suspense fallback={<LoaderBox />}>
        <TransferFiltersForm formId={TRANSFER_FILTERS_FORM_ID} />
      </Suspense>
    </FiltersDrawer>
  );
};
