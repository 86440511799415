import { TReportsStatementsQueryParams } from '@payler/api/client-office';
import { useCallback, useState } from 'react';
import { useApi } from '../../state/api';

export const useReportsStatementsQuery = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const handleReportsStatementsQuery = useCallback(
    async (params: TReportsStatementsQueryParams) => {
      try {
        setLoading(true);
        await api.getReportsStatements({
          ...params,
        });
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  return {
    handleReportsStatementsQuery,
    loading,
  };
};

export const useAccountConfirmationQuery = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const handleAccountConfirmationQuery = useCallback(
    async (accountId: string) => {
      try {
        setLoading(true);
        await api.getAccountConfirmation(accountId);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  return {
    handleAccountConfirmationQuery,
    loading,
  };
};

export const useTransferStatementQuery = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const handleTransferStatementQuery = useCallback(
    async (transferId: string) => {
      try {
        setLoading(true);
        await api.getTransferStatement(transferId);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  return {
    handleTransferStatementQuery,
    loading,
  };
};
