import { Circle, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const useRecipientIcon = (title: string | undefined) => {
  const letter = title && (title.length ?? 0) > 0 ? title.substring(0, 1) : '';
  return (
    <Circle
      size={5}
      bg="brands.100"
      borderColor="brands.100 !important"
      border="0.5px solid"
    >
      <Text textStyle={TextStyles.BodyUI16Semibold} color="brands.500">
        {letter.toUpperCase()}
      </Text>
    </Circle>
  );
};
