import { PropsWithChildren, ReactElement } from 'react';
import emotionStyled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { css, SerializedStyles } from '@emotion/react';

type StyleGetter<T> = {
  (props: T): SerializedStyles;
};

type ProxiedStyled = {
  [key in keyof JSX.IntrinsicElements]: <T>(
    styleGetter?: StyleGetter<T>,
    whitelist?: string[]
  ) => (props: PropsWithChildren<T>) => ReactElement<T>;
};

/**
 * Кастомный styled для того, чтобы не спускать в атрибуты html элемента кастомные props
 *
 * @example
 * interface Props {
 *   textColor: string;
 * }
 *
 * const Container = styled.div<Props>(({ textColor }) => css`
 *   position: relative;
 *   color: ${textColor};
 * `);
 */
export const styled = new Proxy({} as ProxiedStyled, {
  get(_, name: keyof JSX.IntrinsicElements) {
    return <T extends object>(
      styleGetter: StyleGetter<T> = () => css``,
      whitelist: (keyof T)[] = []
    ) =>
      emotionStyled(name, {
        shouldForwardProp: (prop: string) =>
          isPropValid(prop) || whitelist.includes(prop as keyof T),
      })(styleGetter);
  },
});
